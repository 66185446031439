import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import TaskCard from "../TaskCard";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useTranslate } from 'react-polyglot';

function DateDropdownWizPage(props) {
    const t = useTranslate();

    const [selection, setSelection] = useState(props.trait.text.split("|")[0] + "-" + props.trait.text.split("|")[1] + "-" + props.trait.text.split("|")[2]);
    const [y, setY] = useState(props.trait.text.split("|")[0]);
    const [m, setM] = useState(props.trait.text.split("|")[1]);
    const [d, setD] = useState(props.trait.text.split("|")[2]);

    function triggerPageUpdated(value = selection) {
        props.pageUpdatedCallback({
            ...props.trait,
            value: {
                date: value
            }
        });
    }

    function handleSelect(y, m ,d) {
        setSelection(y + "-" + m + "-" + d);
        setY(y);
        setM(m);
        setD(d);
        triggerPageUpdated(y + "-" + m + "-" + d);
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    function getSelectableYears() {
        // Return current year and 25 years back
        let years = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear; i >= currentYear - 25; i--) {
            years.push(i);
        }
        return years;
    }

    useEffect(() => {
        if (props.trait.value != undefined) {
            try {
                let value = JSON.parse(props.trait.value);
                setSelection(value.date);
            } catch (ex) {
                console.debug("no resume value");
            }
        }
    }, [props.trait.value]);

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard
                    taskCounter={props.trait.traitName}
                    taskHeader=""
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    style={{overflow: "visible"}}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="dropdown-container" >
                            <FormControlLabel
                                value="bottom"
                                control={
                                    <Select
                                        //labelId="demo-simple-select-label"
                                        //id="demo-simple-select"
                                        color="primary"
                                        value={selection != undefined && selection.split("-")[0] != undefined ? selection.split("-")[0] : ""}
                                        label={props.trait.text.split("|")[0]}
                                        onChange={(event) => handleSelect(event.target.value, m, d)}
                                    >
                                        {[
                                            selection != undefined ?
                                                selection.split("-")[0]
                                            :
                                                "", ...getSelectableYears()
                                        ].map((option, key) =>
                                            <MenuItem key={key} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </Select>
                                }
                            />
                            <FormControlLabel
                                value="bottom"
                                control={
                                    <Select
                                        //labelId="demo-simple-select-label"
                                        //id="demo-simple-select"
                                        color="primary"
                                        value={selection != undefined && selection.split("-")[1] != undefined ? selection.split("-")[1] : ""}
                                        label={props.trait.text.split("|")[1]}
                                        onChange={(event) => handleSelect(y, event.target.value, d)}
                                    >
                                        {[selection != undefined ? selection.split("-")[1] : "", ...[...Array(12).keys()].map(i => i+1)].map((option, key) =>
                                            <MenuItem key={key} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </Select>
                                }
                            />
                            <FormControlLabel
                                value="bottom"
                                control={
                                    <Select
                                        //labelId="demo-simple-select-label"
                                        //id="demo-simple-select"
                                        color="primary"
                                        value={selection != undefined && selection.split("-")[2] != undefined ? selection.split("-")[2] : ""}
                                        label={props.trait.text.split("|")[2]}
                                        onChange={(event) => handleSelect(y, m, event.target.value)}
                                    >
                                        {[selection != undefined ? selection.split("-")[2] : "", ...[...Array(31).keys()].map(i => i+1)].map((option, key) =>
                                            <MenuItem key={key} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </Select>
                                }
                            />
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

DateDropdownWizPage.propTypes = {
    trait: PropTypes.object,
    pageUpdatedCallback: PropTypes.func,
    display: PropTypes.bool
};

export default DateDropdownWizPage;