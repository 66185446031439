import React from "react";
import { Link } from "react-router-dom";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "@material-ui/core/Icon";
import HomeIcon from "@material-ui/icons/Home";
import AccountIcon from "@material-ui/icons/AccountCircle";

class Footer extends React.Component {
  state = {
    value: "recents",
    loggedin: localStorage.getItem("username") != undefined ? true : false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;

    if (this.state.loggedin) {
      return (
        <BottomNavigation
          value={value}
          onChange={this.handleChange}
          classes={{ root: "bottom-nav" }}
          showLabels={false}
        >
          >
          <BottomNavigationAction
            classes={{ root: "nav-icon"/*, selected: "nav-icon-selected"*/ }}
            component={Link}
            to="/"
            value="home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            classes={{ root: "nav-icon"/*, selected: "nav-icon-selected"*/ }}
            component={Link}
            to="/mydogs"
            value="mydogs"
            icon={
              <Icon>
                <img style={{verticalAlign: 'unset'}} src="assets/images/paw.svg" alt="" />
              </Icon>
            }
          />
          <BottomNavigationAction
            classes={{ root: "nav-icon"/*, selected: "nav-icon-selected"*/ }}
            component={Link}
            to="/mysettings"
            value="mysettings"
            icon={<AccountIcon />}
          />
          {/* <BottomNavigationAction
            classes={{ root: "nav-icon", selected: "nav-icon-selected" }}
            component={Link}
            to="/registration"
            value="notifications"
            icon={
              <Icon>
                <img src="assets/images/bell.svg" alt="" />
              </Icon>
            }
          />
          <BottomNavigationAction
            classes={{ root: "nav-icon", selected: "nav-icon-selected" }}
            component={Link}
            to="/registration"
            value="registration"
            icon={
              <Icon>
                <img src="assets/images/camera.svg" alt="" />
              </Icon>
            }
          /> */}
        </BottomNavigation>
      );
    } else return "";
  }
}

export default Footer;
