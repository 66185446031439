import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

function CardTypesNumber(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [amount, setAmount] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/number", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAmount(json.amount);
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-number">
            <h1>{t("dogexplorer.number")}</h1>
            <p>{amount}</p>
        </Card>
    );
}

export default CardTypesNumber;