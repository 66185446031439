import React, { useEffect, useState } from "react";

import { Bar, Chart } from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import { useTranslate } from "react-polyglot";
import _ from "lodash";

import MapAnswers from "../PhdAnswermapper";

function Youandyourdog(props) {
    const t = useTranslate();

    const [sourceData, setSourceData] = useState([]);
    const [sourceDataMyDog, setSourceDataMyDog] = useState([]);

    const [acquisitionmotivationData, setAcquisitionmotivationData] = useState([]);
    const [acquisitionmotivationDataMyDog, setAcquisitionmotivationDataMyDog] = useState([]);

    const [showDogNoshow, setShowDogNoshow] = useState(false);

    function GetPercentage(value, total) {
        return (value / total) * 100;
    }

    function InsertDogName(text) {
        if (t("statistics.main.yourdogs") != props.selectedDogName) {
            return text.replaceAll("[dog-name]", props.selectedDogName);
        } else {
            return text.replaceAll("[dog-name]", t("statistics.main.thedog"));
        }
    }

    function GetMyDogLatestAnswer(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        if (myDogAnswers[myDogAnswers.length - 1] != undefined)
            return [myDogAnswers[myDogAnswers.length - 1]];
        else
            return [];
    }

    function GetTraitsFromDataset(traitIds, dataset) {
        let traits = [];
        for (let data of dataset) {
            let trait = data.selections.find(x => traitIds.includes(x.traitId) && x.value != null && x.value != "null" && x.value != "");
            if (trait != undefined) traits.push({...trait, dog: data.dog});
        }
        return traits;
    }

    function GetAnswersFromData(answerId, data) {
        return MapAnswers(answerId, data);
    }

    function DrawCompleteWizardCard(title, wizardName) {
        return(
            <Card className={"info-card center"}>
                <card-content>
                    <div className={"info-card-content"}>
                        <div className={"info-card-header"}>
                            <div className={"info-card-text-header"}>
                                {title}
                            </div>
                        </div>
                        <hr/>
                        <p dangerouslySetInnerHTML={{__html: t("statistics.behavior.sub_general.content.youandyourdog.noaccess").replaceAll("\n", "<br/>").replaceAll("[survey]", title)}}></p>
                        &nbsp;
                    </div>
                </card-content>
            </Card>
        );
    }

    useEffect(() => {
        Chart.plugins.register({
            beforeDraw: function(c) {
               var legends = c.legend.legendItems;
               legends.forEach(function(e) {
                    if (
                        e.text == "Your dog" ||
                        e.text == "Din hund" ||
                        e.text == "Din hund"
                    ) {
                        e.fillStyle = 'rgba(255, 139, 18, 0.3)';
                    } else if (
                        e.text == "Male dogs" ||
                        e.text == "Hannhunder" ||
                        e.text == "Hanhundar" || 
                        e.text == "All dogs in race" ||
                        e.text == "Alle hunder i rasen" ||
                        e.text == "Alla hundar i ras"
                    ) {
                        e.fillStyle = 'rgba(56, 46, 146, 0.3)';
                    } else if (
                        e.text == "Female dogs" ||
                        e.text == "Tisper" ||
                        e.text == "Tikar"
                    ) {
                        e.fillStyle = '#ff000030';
                    }
               });
            }
        });
    }, []);

    useEffect(() => {
        setSourceData(GetAnswersFromData("D1", GetTraitsFromDataset([2100, 2200, 2300], props.youAndYourDogDataset).map(x => x.value.split(':"')[1].split('"')[0])));
        setAcquisitionmotivationData(GetAnswersFromData("D12", GetTraitsFromDataset([2113, 2213, 2313], props.youAndYourDogDataset).map(x => JSON.parse(x.value).options).flat()));

        if (props.myDogId != undefined && props.myDogId != 0) {
            setSourceDataMyDog(GetAnswersFromData("D1", GetTraitsFromDataset([2100, 2200, 2300], GetMyDogLatestAnswer(props.youAndYourDogDataset)).map(x => x.value.split(':"')[1].split('"')[0])));
            setAcquisitionmotivationDataMyDog(GetAnswersFromData("D12", GetTraitsFromDataset([2113, 2213, 2313], GetMyDogLatestAnswer(props.youAndYourDogDataset)).map(x => JSON.parse(x.value).options).flat()));
        }
    }, [props.youAndYourDogDataset, props.myDogId]);

    return (
        <React.Fragment>
            {
                props.myDogs.some(mydog => {
                    if (props.allBreedTraits != undefined) {
                        return props.allBreedTraits.filter(trait => 
                            trait.selections[0].categoryId == 76 ||
                            trait.selections[0].categoryId == 77 ||
                            trait.selections[0].categoryId == 78
                        ).some(trait => {
                            return trait.dogId == mydog.id;
                        })
                    } else {
                        return false;
                    }
                }) ? 
                    <>
                        <Card
                            className="statisticsinfocard"
                            onClick={() => {
                                if (showDogNoshow != "youandyourdog_noshow") {
                                    setShowDogNoshow("youandyourdog_noshow");
                                } else {
                                    setShowDogNoshow("");
                                }
                            }}
                            style={{
                                maxWidth: showDogNoshow == "youandyourdog_noshow" ? "1000px" : "220px",
                            }}
                        >
                            <card-content>
                                <h3>{t("statistics.areas.behavior.dognoshow_title")}</h3>
                                <span class="material-symbols-outlined icon">
                                    info
                                </span>
                                <div
                                    style={{
                                        maxHeight: showDogNoshow == "youandyourdog_noshow" ? "400px" : "0px",
                                    }}
                                >
                                    <p dangerouslySetInnerHTML={{__html: t("statistics.areas.behavior.youandyourdog.dognoshow_description").replaceAll("\n", "<br/>")}}></p>
                                </div>
                            </card-content>
                        </Card>
                        <Card className={"info-card center"}>
                            <card-content>
                                <div className={"info-card-content"}>
                                    <div className={"info-card-header"}>
                                        <div className={"info-card-text-header"}>
                                            {t("statistics.behavior.sub_general.content.youandyourdog.title")}
                                        </div>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.youandyourdog.source.title")}</h3>
                                    <p>{InsertDogName(t("statistics.behavior.sub_general.content.youandyourdog.source.description"))}</p>
                                    <div className="statistics-card-phd">
                                        <Bar
                                            chartId="barchart2-1"
                                            data={{
                                                labels: [
                                                    t("statistics.behavior.sub_general.content.youandyourdog.source.label_1"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.source.label_2"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.source.label_3"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.source.label_4")
                                                ],
                                                datasets: [
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.alldogs"),
                                                        backgroundColor: [
                                                            sourceDataMyDog.length != 0 && sourceDataMyDog == "Breeder" ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            sourceDataMyDog.length != 0 && sourceDataMyDog == "CurOwner" ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            sourceDataMyDog.length != 0 && sourceDataMyDog == "PrevOwner" ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            sourceDataMyDog.length != 0 && sourceDataMyDog == "Shelter" ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)"
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(sourceData.filter(x => x == "Breeder").length, sourceData.length),
                                                            GetPercentage(sourceData.filter(x => x == "CurOwner").length, sourceData.length),
                                                            GetPercentage(sourceData.filter(x => x == "PrevOwner").length, sourceData.length),
                                                            GetPercentage(sourceData.filter(x => x == "Shelter").length, sourceData.length)
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.yourdog"),
                                                        backgroundColor: "rgba(255, 139, 18, 0.3)",
                                                        stack: "1",
                                                        data: []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.youandyourdog.source.x_title")
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.youandyourdog.source.y_title")
                                                            },
                                                            ticks: {
                                                                max: 100,
                                                                min: 0,
                                                                fontColor: "#382e92",
                                                                fontSize: 14
                                                            }
                                                        }
                                                    ]
                                                },
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Bar>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.title")}</h3>
                                    <p>{InsertDogName(t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.description"))}</p>
                                    <div className="statistics-card-phd">
                                        <Bar
                                            chartId="barchart2-1"
                                            data={{
                                                labels: [
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_1"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_2"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_3"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_4"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_5"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_6"),
                                                    t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.label_7")
                                                ],
                                                datasets: [
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.alldogs"),
                                                        backgroundColor: [
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.1") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.2") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.3") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.4") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.5") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.6") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            acquisitionmotivationDataMyDog.length != 0 && acquisitionmotivationDataMyDog.includes("D12.7") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.1").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.2").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.3").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.4").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.5").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.6").length, acquisitionmotivationData.length),
                                                            GetPercentage(acquisitionmotivationData.filter(x => x == "D12.7").length, acquisitionmotivationData.length),
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.yourdog"),
                                                        backgroundColor: "rgba(255, 139, 18, 0.3)",
                                                        stack: "1",
                                                        data: []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.x_title")
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.y_title")
                                                            },
                                                            ticks: {
                                                                max: 100,
                                                                min: 0,
                                                                fontColor: "#382e92",
                                                                fontSize: 14
                                                            }
                                                        }
                                                    ]
                                                },
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Bar>
                                    </div>
                                </div>
                            </card-content>
                        </Card>
                    </>
                : DrawCompleteWizardCard(t("statistics.behavior.sub_general.content.youandyourdog.title"), "")
            }
        </React.Fragment>
    );
}

export default Youandyourdog;
