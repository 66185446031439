import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom"
import {useStompMsgHandler} from "../../../StompMsgHandler";
import Fab from "@material-ui/core/Fab";
import { useTranslate } from 'react-polyglot';

import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
toast.configure()

const RegisterPaymentButton = (props) => {
  const history = useHistory();
  let stompMsgHandler = useStompMsgHandler();

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }
  const t = useTranslate();

  const [orderId, setOrderId] = useState("")
  const [currencyCode, setCurrencyCode] = useState("")
  const [amount, setAmount] = useState("")
  const [orderDescription, setOrderDescription] = useState("")
  const [userId, setUserId] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [postCode, setPostCode] = useState("")
  const [products, setProducts] = useState([])
  const [productIds, setProductId] = useState([])
  const [loading, setLoading] = useState(true)

  const [hasDeal, setHasDeal] = useState(false);

  /*
  userid, firstname, lastname, email and postcode is stored in localStorage
  from login. The rest is connected to the deal object in backend and need to be
  fetched and sent in as props. The deal may very due to different products.
  */
  useEffect(() => {
    if(props) {
      let temp = []
      props.data.forEach(item => temp.push(item.id))

      setProductId(temp)

      setUserId(localStorage.getItem("userid"))
      setFirstName(localStorage.getItem("firstName"))
      setLastName(localStorage.getItem("lastName"))
      setEmail(localStorage.getItem("username"))
      setPostCode(localStorage.getItem("postCode"))
      setCurrencyCode("NOK");
      setOrderDescription("Test of multiple products in deal")
    }
  }, [])

  useEffect(() => {
    let temp = []
    props.data.forEach(item => temp.push(item.id))

    setProductId(temp)
  }, [props.data])

  useEffect(() => {
    if(loading === false)
    if(props.ownProduct) {
      // TODO: Create sendUpdateSubscriptionRequest
      // To update value of existing subscription on update
    } else {
      sendRegisterPayment();
    }
  }, [loading === false])

  const userHasDeal = () => {
    fetch(`/rest/deals/hasDeal`, {
      method:"GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        "Content-Type": "application/json"
      })
    })
    .then(response => response.json())
    .then(data => {
      setHasDeal(data);
    })
  }

  const createDeal = (e) => {
    if(stompMsgHandler.STOMPCLIENT.connected === true) {
      if(props.acceptDeal) {
        if(!hasDeal) {
          e.target.innerHTML = "Oppdaterer ..";
          fetch(`/rest/deals/create`, {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${localStorage.getItem("id_token")}`,
              "Content-Type": "application/json"
            }),
            body: JSON.stringify({"productIds" : productIds})
          })
          .then(response => response.json())
          .then(data => {
            fetch(`/auth/oauth/token?grant_type=refresh_token&refresh_token=` + localStorage.getItem("refresh_token"), {
              method: "POST",
              headers: new Headers({
                Authorization: `Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ=`
              })
            }).then(response => {
              if(response.ok && response.status === 200)
              return response.json()
            }).then(json => {
              localStorage.setItem("id_token", json.access_token);
              localStorage.setItem("refresh_token", json.refresh_token);
            }).then(() => {
              setOrderId(data.id)
              setProducts(data.products)
  
              let tempAmount = 0;
              data.products.forEach((item, i) => {
                tempAmount += parseFloat(item.product.price);
              });
              setAmount(tempAmount);
              setLoading(false);
              //window.location.reload();
            })
          })
        } else {
          updateDeal(e);
        }
      } else {
        notifyInfo("Du må først godta kjøpsbetingelsene")
      }

    } else {
      console.log("Client not connected to websocket...");
      notifyError("Klienten er ikke tilkoblet websocket, betaling ikke opprettet..")
    }
}

  const updateDeal = (e) => {
    e.target.innerHTML = "Oppdaterer ..";
    fetch(`/rest/deals/update`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({"productIds" : productIds})
    })
    .then(response => response.json())
    .then(data => {
      fetch(`/rest/nets/pay/byuser`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        })
      })
      .then(data2 => {
        fetch(`/auth/oauth/token?grant_type=refresh_token&refresh_token=` + localStorage.getItem("refresh_token"), {
          method: "POST",
          headers: new Headers({
            Authorization: `Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ=`
          })
        }).then(response => {
          if(response.ok && response.status === 200)
          return response.json()
        }).then(json => {
          localStorage.setItem("id_token", json.access_token);
          localStorage.setItem("refresh_token", json.refresh_token);
        }).then(() => {
          setOrderId(data.id)
          setProducts(data.products)

          let tempAmount = 0;
          data.products.forEach((item, i) => {
            tempAmount += parseFloat(item.price);
          });
          setAmount(tempAmount);
          setLoading(false);
          window.location.reload();
        })
      })
  })
  }

  const sendRegisterPayment =  () => {
    if(stompMsgHandler.STOMPCLIENT.connected === true) {
      stompMsgHandler.STOMPCLIENT.publish({ destination: '/app/msg', body: JSON.stringify({
          'messageType': 'no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgRegisterRecurring',
          'redirectUrl': `${window.location.origin}/netsmodule/payments/recurring/terminal-verify-response`,
          'payment': { 'orderId': orderId, 'currencyCode': currencyCode, 'amount': amount },
          'orderDescription': orderDescription,
          
          'recurringType': 'R',
          'recurringFrequency': '0',
          'recurringExpiryDate': '20400101',
          'updateStoredPayment': true,
          'userID': userId,
          'firstName': firstName,
          'lastName': lastName,
          'phoneNumber': "+4712345678",
          'email': email,
          'address': "NotImplemented",
          'postCode': postCode,
          'postName': "NotImplemented",
          'countryCode': "NotImplemented",
        })})
      } else {
        console.log("Client not connected to websocket...");
        notifyError("Noe gikk galt, logg ut og prøv igjen senere")
      }
    }

    const theDisabled = props.disabled;
    const hasProduct = props.ownProduct   
    const tempClass = theDisabled ? "add-button" : "add-button-enabled"

  return (
      <button
        disabled={props.disabled}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="Add"
        className={`${tempClass} ${theDisabled ? "registercard disabled" : "registercard"}`}
        style={{marginLeft: "20px"}}
        onClick={(e) => hasProduct ? updateDeal(e) : createDeal(e)}
      >
          {hasProduct ? "Oppdater produkter" : "Kjøp"}
      </button>
    )
}

export default RegisterPaymentButton
