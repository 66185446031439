import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";
import FilterListIcon from '@material-ui/icons/FilterList';
import InputTextField from "../../../../common/InputTextField";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Card } from "@material-ui/core";

import { Line } from 'react-chartjs-2';

function CardTypesSiblingsDistribution(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [data, setData] = useState({});

    const [filterMin, setFilterMin] = useState();
    const [filterMax, setFilterMax] = useState();

    const [appliedFilters, setAppliedFilters] = useState([]);

    const [filteringOpen, setFilteringOpen] = useState(false);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/siblingsdistribution", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setData(json);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-sexdistribution">
            <h1>{t("dogexplorer.siblingdistribution.description")}</h1>
            {data != undefined ?
                <Line
                    chartId="barchart1-1"
                    options={{
                        tooltips: {
                            intersect: false
                        },
                        scales: {
                            xAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.birthyeardistribution.yearofbirth")
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        suggestedMax: 10
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.birthyeardistribution.numberofpuppies")
                                    }
                                }
                            ]
                        }
                    }}
                    data={{
                        labels: data.labels,
                        datasets: [
                            {
                                backgroundColor: "transparent",
                                pointBackgroundColor: "#382e92",
                                borderColor: "#382e92",
                                pointRadius: 2,
                                label: t("dogexplorer.siblingdistribution.average"),
                                data: data.data1
                            },
                            {
                                backgroundColor: "transparent",
                                pointBackgroundColor: "#8292e3",
                                borderColor: "#8292e3",
                                pointRadius: 2,
                                label: t("dogexplorer.siblingdistribution.lowerquartile"),
                                data: data.data2
                            },
                            {
                                backgroundColor: "transparent",
                                pointBackgroundColor: "#8292e3",
                                borderColor: "#8292e3",
                                pointRadius: 2,
                                label: t("dogexplorer.siblingdistribution.upperquartile"),
                                data: data.data3
                            }
                        ]
                    }}
                    maxWidth={50}
                    maxHeight={50}
                    redraw={true}
                ></Line>
            : null}
            <p onClick={() => setFilteringOpen(!filteringOpen)} style={{ cursor: "pointer" }}>Filter <FilterListIcon></FilterListIcon></p>
            
            <div style={ filteringOpen ? { height: "auto" } : { height: "0px", overflow: "hidden" }}>
                <p>{t("dogexplorer.siblingdistribution.underdevelopment")}</p>
                <InputTextField
                    inputProps={{ style: { color: "#000000" } }}
                    InputLabelProps={{ classes: { root: "registration-text" } }}
                    id="filtermin"
                    value={filterMin}
                    label={t("dogexplorer.siblingdistribution.ispartofcoalover")}
                    placeholder=""
                    onChange={(evt) => setFilterMin(evt.target.value)}
                />
                <InputTextField
                    inputProps={{ style: { color: "#000000" } }}
                    InputLabelProps={{ classes: { root: "registration-text" } }}
                    id="filtermax"
                    value={filterMax}
                    label={t("dogexplorer.siblingdistribution.ispartofcoalbelow")}
                    placeholder=""
                    onChange={(evt) => setFilterMax(evt.target.value)}
                />
                <Button
                    style={{ width: "auto" }}
                    classes={{ root: "edit-button" }}
                    onClick={() => {
                        let filters = explorer.filters;

                        filters = filters.filter(i => i.type != "siblings");

                        if (filterMin != undefined) {
                            filters.push({ type: "siblings", stage: "pre", operation: "gt", value: Number(filterMin) });
                        }
                        if (filterMax != undefined) {
                            filters.push({ type: "siblings", stage: "pre", operation: "lt", value: Number(filterMax) });
                        }

                        explorer.updateFilters(filters);
                    }}
                >
                    {t("dogexplorer.siblingdistribution.addfilter")}
                </Button>
            </div>
        </Card>
    );
}

export default CardTypesSiblingsDistribution;