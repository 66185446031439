import React, {useEffect, useState, useRef, useContext} from "react";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Line } from 'react-chartjs-2';

function CardTypeEbvGenTrendHD(props) {
    let explorer = useContext(ExplorerContext);

    let chartRef = useRef();
    const t = useTranslate();

    const [data, setData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/ebvhdgentrend", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setData(json);
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-ebvhdgentrend">
            <h1>{t("dogexplorer.statistics.cards.ebvgentrendhd")}</h1>
            <div>
                <Line
                    ref={chartRef}
                    chartId="barchart1-1"
                    options={{
                        tooltips: {
                            intersect: false
                        },
                        scales: {
                            xAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Årstall"
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Gjennomsnitt"
                                    }
                                }
                            ]
                        }
                    }}
                    data={{
                        labels: data != undefined ? data.filter(i => i.year != "").map(i => i.year) : [],
                        datasets: [
                            {
                                backgroundColor: "transparent",
                                pointBackgroundColor: "#382e92",
                                borderColor: "#382e92",
                                label: "Gjennomsnitt",
                                data: data != undefined ? data.filter(i => i.year != "").map(i => i.avg) : []
                            }
                        ]
                    }}
                    maxWidth={50}
                    maxHeight={50}
                ></Line>
            </div>
        </Card>
    );
}

export default CardTypeEbvGenTrendHD;