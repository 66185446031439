import React, { useEffect, useState } from "react";

import { Scatter, Chart } from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import { useTranslate } from "react-polyglot";
import _ from "lodash";
import moment from "moment";

import MapAnswers from "../PhdAnswermapper";

function Earlierexperience(props) {

    const t = useTranslate();

    const [peopletimeData, setPeopletimeData] = useState([]);
    const [dogtimeData, setDogtimeData] = useState([]);
    const [restlessnessData, setRestlessnessData] = useState([]);

    const [peopletimeDataMyDog, setPeopletimeDataMyDog] = useState([]);
    const [dogtimeDataMyDog, setDogtimeDataMyDog] = useState([]);
    const [restlessnessDataMyDog, setRestlessnessDataMyDog] = useState([]);

    const [showDogNoshow, setShowDogNoshow] = useState("");

    function InsertDogName(text) {
        if (t("statistics.main.yourdogs") != props.selectedDogName) {
            return text.replaceAll("[dog-name]", props.selectedDogName);
        } else {
            return text.replaceAll("[dog-name]", t("statistics.main.thedog"));
        }
    }

    function GetMyDogLatestAnswer(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        if (myDogAnswers[myDogAnswers.length - 1] != undefined)
            return [myDogAnswers[myDogAnswers.length - 1]];
        else
            return [];
    }

    function GetDogLatestAnswer(dogId, data) {
        let dogAnswers = data.filter(x => x.dogId == dogId);
        dogAnswers = _.sortBy(dogAnswers, 'createdTs');
        if (dogAnswers[dogAnswers.length - 1] != undefined)
            return [dogAnswers[dogAnswers.length - 1]];
        else
            return [];
    }

    function GetOtherDogs(data) {
        if (props.myDogId != undefined && props.myDogId != 0) {
            let otherDogs = data.filter(x => x.dogId != props.myDogId);
            return otherDogs;
        } else {
            return data;
        }
    }

    function GetTraitsFromDataset(traitIds, dataset) {
        let traits = [];
        for (let data of dataset) {
            let trait = data.selections.find(x => traitIds.includes(x.traitId) && x.value != null && x.value != "null" && x.value != "");
            if (trait != undefined) traits.push({...trait, dog: data.dog});
        }
        return traits;
    }

    function GetAnswersFromData(answerId, data) {
        return MapAnswers(answerId, data);
    }

    function GetAcquisitionDate(dogId) {
        return GetAnswersFromData("D2", GetTraitsFromDataset([2101, 2201, 2301], GetDogLatestAnswer(dogId, props.youAndYourDogDataset)));
    }

    function SliderTraitToStringWithAge(sliderTrait) {
        let acqisitionDate = GetAcquisitionDate(sliderTrait.dog.id)[0];
        if (acqisitionDate != undefined) {
            if (isNaN(acqisitionDate.split("-")[0]) || isNaN(acqisitionDate.split("-")[1])) { 
                console.log("No date present");
            } else {
                if (isNaN(acqisitionDate.split("-")[2])) acqisitionDate = acqisitionDate.split("-")[0] + "-" + acqisitionDate.split("-")[1] + "-" + moment(acqisitionDate.split("-")[0] + "-" + acqisitionDate.split("-")[1] + "-1", 'YYYY-M-D').endOf("month").date();
            }
            
            let days = moment(acqisitionDate, "YYYY-M-D").diff(moment(sliderTrait.dog.birthDate), "days");

            // Setting the age to 84 days if dog is under 1 day old
            if (days <= 1) days = 84;

            return JSON.parse(sliderTrait.value).sliderValue + "|" + sliderTrait.dog.gender + "|" + days;
        }
        return undefined;
    }

    function DrawCompleteWizardCard(title, wizardName) {
        return(
            <Card className={"info-card center"}>
                <card-content>
                    <div className={"info-card-content"}>
                        <div className={"info-card-header"}>
                            <div className={"info-card-text-header"}>
                                {title}
                            </div>
                        </div>
                        <hr/>
                        <p dangerouslySetInnerHTML={{__html: t("statistics.behavior.sub_general.content.youandyourdog.noaccess").replaceAll("\n", "<br/>").replaceAll("[survey]", title)}}></p>
                        &nbsp;
                    </div>
                </card-content>
            </Card>
        );
    }

    useEffect(() => {
        Chart.plugins.register({
            beforeDraw: function(c) {
               var legends = c.legend.legendItems;
               legends.forEach(function(e) {
                    if (
                        e.text == "Your dog" ||
                        e.text == "Din hund" ||
                        e.text == "Din hund"
                    ) {
                        e.fillStyle = 'rgba(255, 139, 18, 0.3)';
                    } else if (
                        e.text == "Male dogs" ||
                        e.text == "Hannhunder" ||
                        e.text == "Hanhundar" || 
                        e.text == "All dogs in race" ||
                        e.text == "Alle hunder i rasen" ||
                        e.text == "Alla hundar i ras"
                    ) {
                        e.fillStyle = 'rgba(56, 46, 146, 0.3)';
                    } else if (
                        e.text == "Female dogs" ||
                        e.text == "Tisper" ||
                        e.text == "Tikar"
                    ) {
                        e.fillStyle = '#ff000030';
                    }
               });
            }
        });
    }, []);

    useEffect(() => {
        setShowDogNoshow("");
    }, [props.displaySubcategory]);

    useEffect(() => {
        setPeopletimeData(GetAnswersFromData("E5", GetTraitsFromDataset([1907, 1507, 1707], GetOtherDogs(props.earlyexperienceDataset)).map(x => {
            return SliderTraitToStringWithAge(x);
        }).filter(x => x != undefined)));
        setDogtimeData(GetAnswersFromData("E6", GetTraitsFromDataset([1908, 1508, 1708], GetOtherDogs(props.earlyexperienceDataset)).map(x => {
            return SliderTraitToStringWithAge(x);
        }).filter(x => x != undefined)));
        setRestlessnessData(GetAnswersFromData("E42", GetTraitsFromDataset([1948, 1548, 1748], GetOtherDogs(props.earlyexperienceDataset)).map(x => {
            return SliderTraitToStringWithAge(x);
        }).filter(x => x != undefined)));

        if (props.myDogId != undefined && props.myDogId != 0) {
            setPeopletimeDataMyDog(GetAnswersFromData("E5", GetTraitsFromDataset([1907, 1507, 1707], GetMyDogLatestAnswer(props.earlyexperienceDataset)).map(x => {
                return SliderTraitToStringWithAge(x);
            }).filter(x => x != undefined)));
            setDogtimeDataMyDog(GetAnswersFromData("E6", GetTraitsFromDataset([1908, 1508, 1708], GetMyDogLatestAnswer(props.earlyexperienceDataset)).map(x => {
                return SliderTraitToStringWithAge(x);
            }).filter(x => x != undefined)));
            setRestlessnessDataMyDog(GetAnswersFromData("E42", GetTraitsFromDataset([1948, 1548, 1748], GetMyDogLatestAnswer(props.earlyexperienceDataset)).map(x => {
                return SliderTraitToStringWithAge(x);
            }).filter(x => x != undefined)));
        }
    }, [props.earlyexperienceDataset, props.myDogId]);

    return (
        <React.Fragment>
            {
                props.myDogs.some(mydog => {
                    if (props.allBreedTraits != undefined) {
                        return props.allBreedTraits.filter(trait => 
                            trait.selections[0].categoryId == 70 ||
                            trait.selections[0].categoryId == 72 ||
                            trait.selections[0].categoryId == 74
                        ).some(trait => {
                            return trait.dogId == mydog.id;
                        })
                    } else {
                        return false;
                    }
                }) ? 
                    <>
                        <Card
                            className="statisticsinfocard"
                            onClick={() => {
                                if (showDogNoshow != "earlierexperience_noshow") {
                                    setShowDogNoshow("earlierexperience_noshow");
                                } else {
                                    setShowDogNoshow("");
                                }
                            }}
                            style={{
                                maxWidth: showDogNoshow == "earlierexperience_noshow" ? "1000px" : "220px",
                            }}
                        >
                            <card-content>
                                <h3>{t("statistics.areas.behavior.dognoshow_title")}</h3>
                                <span class="material-symbols-outlined icon">
                                    info
                                </span>
                                <div
                                    style={{
                                        maxHeight: showDogNoshow == "earlierexperience_noshow" ? "400px" : "0px",
                                    }}
                                >
                                    <p dangerouslySetInnerHTML={{__html: t("statistics.areas.behavior.earlyexperience.dognoshow_description").replaceAll("\n", "<br/>")}}></p>
                                </div>
                            </card-content>
                        </Card>
                        <Card className={"info-card center"}>
                            <card-content>
                                <div className={"info-card-content"}>
                                    <div className={"info-card-header"}>
                                        <div className={"info-card-text-header"}>
                                            {t("statistics.behavior.sub_general.content.earlyexperience.title")}
                                        </div>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.tick_zero").split("\n");
                                                                    } else if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.alldogs"),
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...peopletimeData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...peopletimeData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    // {
                                                    //     type: "scatter",
                                                    //     label: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.female"),
                                                    //     borderColor: "#ff000030",
                                                    //     borderWidth: 2,
                                                    //     pointRadius: 2,
                                                    //     pointBackgroundColor: "#ff000030",
                                                    //     data: 
                                                    // },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.yourdog"),
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: peopletimeDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.dogtimeData.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.dogtimeData.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.tick_zero").split("\n");
                                                                    } else if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.dogtimeData.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.alldogs"),
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...dogtimeData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...dogtimeData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    // {
                                                    //     type: "scatter",
                                                    //     label: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.female"),
                                                    //     borderColor: "#ff000030",
                                                    //     borderWidth: 2,
                                                    //     pointRadius: 2,
                                                    //     pointBackgroundColor: "#ff000030",
                                                    //     data: dogtimeData.filter(x => x.split("|")[1] == "false").map(x => {
                                                    //         return {
                                                    //             y: Number(x.split("|")[0]),
                                                    //             x: Number(x.split("|")[2])
                                                    //         }
                                                    //     })
                                                    // },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.peopletimeData.yourdog"),
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: dogtimeDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.restlessness.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.restlessness.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.restlessness.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.restlessness.tick_zero").split("\n");
                                                                    } else if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.restlessness.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.restlessness.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.alldogs"),
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...restlessnessData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: 100 - Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...restlessnessData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: 100 - Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    // {
                                                    //     type: "scatter",
                                                    //     label: t("statistics.behavior.sub_general.content.currentexperience.restlessness.female"),
                                                    //     borderColor: "#ff000030",
                                                    //     borderWidth: 2,
                                                    //     pointRadius: 2,
                                                    //     pointBackgroundColor: "#ff000030",
                                                    //     data: restlessnessData.filter(x => x.split("|")[1] == "false").map(x => {
                                                    //         return {
                                                    //             y: 100 - Number(x.split("|")[0]),
                                                    //             x: Number(x.split("|")[2])
                                                    //         }
                                                    //     })
                                                    // },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.restlessness.yourdog"),
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: restlessnessDataMyDog.map(x => {
                                                            return {
                                                                y: 100 - Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                </div>
                            </card-content>
                        </Card>
                    </>
                : DrawCompleteWizardCard(t("statistics.behavior.sub_general.content.earlyexperience.title"), "") 
            }
        </React.Fragment>
    );
}

export default Earlierexperience;
