import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import Typography from "@material-ui/core/Typography";
import Menu from "../menu/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Auth from "../../../Auth";
import DogExplorerFilter from "./dogExplorerFilter/DogExplorerFilter";
import DogExplorerTypeselector from "./dogExplorerTypeselector/DogExplorerTypeselector";
import DogExplorerStatistics from "./dogExplorerStatistics/DogExplorerStatistics";
import DogExplorerGallery from "./dogExplorerGallery/DogExplorerGallery";
import DogExplorerProfile from "./dogExplorerProfile/DogExplorerProfile";
import TopHeader from "./TopHeader";
import Footer from "./Footer";

import { ExplorerContext, getContextClass } from "./ExplorerContext";

import "./DogExplorer.css";

// Wrapper to allow using Context in parent Component
function DogExplorerWrapper(props) {

    return (
        <ExplorerContext.Provider value={getContextClass()}>
            <DogExplorer/>
        </ExplorerContext.Provider>
    );
}

function DogExplorer(props) {
    let _Auth = new Auth();
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [openTab, setOpenTab] = useState(0);

    const [loading, setLoading] = useState(false);

    function handleTabChange(evt, value) {
        setOpenTab(value);
    }
    
    useEffect(() => {
        
    }, [explorer.hits])

    return (
        <React.Fragment>
            <div className="statistics">
                <div className="landingpage">
                    <TopHeader></TopHeader>
                    
                    <div className="statistics-diclaimer">
                        <div className={"info-text-container"}>
                            <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry" }}>
                                {t("dogexplorer.description.1")}
                                <br/><br/>
                                {t("dogexplorer.description.2")}
                                <br/><br/>
                                {t("dogexplorer.description.4")} <a href="mailto:post@aninova.no">post@aninova.no</a>.
                                <br/><br/>
                            </Typography>
                        </div>
                    </div>

                    
                    <div className="dogexplorer">
                        <DogExplorerFilter
                            filters={explorer.filters}
                            onChange={(hits, types, filters) => {
                                //setHits(hits);
                                //setTypes(types);
                                //setFilters(JSON.parse(JSON.stringify(filters)));
                                setLoading(false);
                            } }
                            onChangeStart={() => setLoading(true)}
                            requestTabChange={(val) => setOpenTab(val)}
                            loading={loading}
                        ></DogExplorerFilter>
                        
                        {explorer.hits != 0 ?
                            <div className="dogexplorer-displayarea row">
                                <div className="col col-sm-12">
                                    <div className="dogexplorer-displayarea-tabs">
                                        <Tabs
                                            value={openTab == 2 && explorer.filters.filter(f => f.type == "regnumber" && f.value != "").length < 1 ? 1 : openTab}
                                            onChange={handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                        >
                                            <Tab label={t("dogexplorer.statistics.title")} />
                                            <Tab label={t("dogexplorer.gallery.title")} />
                                            {explorer.filters.filter(f => f.type == "regnumber" && f.value != "").length > 0 ?
                                                <Tab label={t("dogexplorer.profile.title")} />
                                            : null}
                                        </Tabs>
                                    </div>
                                    {openTab == 0 ?
                                        <DogExplorerStatistics
                                            loading={loading}
                                        ></DogExplorerStatistics>
                                    : null}
                                    {openTab == 1 || (openTab == 2 && explorer.filters.filter(f => f.type == "regnumber" && f.value != "").length < 1) ?
                                        <DogExplorerGallery
                                            loading={loading}
                                            requestTabChange= {(tabNumber) => {
                                                setOpenTab(tabNumber);
                                            }}
                                        ></DogExplorerGallery>
                                    : null}
                                    {openTab == 2 && explorer.filters.filter(f => f.type == "regnumber" && f.value != "").length > 0 ?
                                        <DogExplorerProfile
                                            loading={loading}
                                        ></DogExplorerProfile>
                                    : null}
                                </div>
                            </div>
                        : null}
                    </div>

                    <Footer></Footer>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerWrapper;