import norwegian from "./no_NB/translations.json"
import swedish from "./sv_SE/translations.json"
import english from "./en_US/translations.json"

function GetTexts(locale) {
    switch (locale) {
        case "no":
            return norwegian;
        case "se":
            return swedish;
        case "en":
            return english;
        default:
            return norwegian;
    }
}

export default GetTexts;