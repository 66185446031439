import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {
  blueberry
} from "./styles/themes/defaultTheme";

import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: 30,
      fontWeight: 300,
      color: blueberry[500]
    },
    h2: {
      fontSize: 30,
      fontWeight: 300
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      color: blueberry[500],
      letterSpacing: 0.3
    },
    h4: {
      fontSize: 35,
      fontWeight: 300
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
      color: blueberry[500],
      letterSpacing: 0.3
    },

    subtitle1: {
      fontSize: 16,
      color: blueberry[500],
      fontWeight: 700
    },
    body1: {
      fontSize: 14,
      color: blueberry[500],
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0.5
    },
    body2: {
      fontSize: 12,
      color: blueberry[500]
    },
    headline: {
      fontWeight: 300
    },
    title: {
      fontWeight: 300
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      color: blueberry[500]
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
      color: blueberry[500]
    }
  },
  palette: {
    primary: blueberry,
    secondary: blueberry,
    error: blueberry,
    text: {
      primary: blueberry[500],
      secondary: blueberry[500]
    }
  }
});

ReactDOM.render( <MuiThemeProvider theme = { theme } > { " " } <meta name="viewport" content="width=device-width, user-scalable=no"/> <App /> { " " } </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();