import { createContext, useState, useEffect } from 'react';

export const ExplorerContext = createContext({
    filters: [],
    filterHistory: [],
    updateFilters: () => {},
    goBack: () => {},
    getPrevious: () => {},
    getHistory: () => {},
    filtersChangeListeners: [],
    types: [],
    updateTypes: () => {},
    hits: [],
    updateHits: () => {},
});

export function getContextClass() {
	const [filters, setFilters] = useState([]);
	const [filterHistory, setFilterHistory] = useState([]);
	const [filterChangeListeners, setFilterChangeListeners] = useState([]);
	const [types, setTypes] = useState([]);
	const [hits, setHits] = useState(0);

    function addFilterChangeListener(key, func) {
        setFilterChangeListeners([...filterChangeListeners.filter(i => i.key != key), { key, func }]);
    }

    function updateFilters(data) {
        if (filters.length > 0) {
            setFilterHistory([...filterHistory, filters]);
        }
        setFilters(data);
    }

    function goBack() {
        setFilters(filterHistory[filterHistory.length - 1]);
        let newHistory = JSON.parse(JSON.stringify(filterHistory));
        newHistory.pop();
        setFilterHistory(newHistory);
    }

    function getPrevious() {
        return filterHistory[filterHistory.length - 1];
    }

    function getHistory() {
        return filterHistory;
    }

    function updateTypes(data) {
        setTypes(data);
    }

    function updateHits(data) {
        setHits(data);
    }

    useEffect(() => {
        filterChangeListeners.forEach(listener => {
            listener.func(filters);
        });
    }, [filters]);

    return {
        filters,
        filterHistory,
        goBack,
        getPrevious,
        getHistory,
        updateFilters,
        addFilterChangeListener,
        types,
        updateTypes,
        hits,
        updateHits
    };
}