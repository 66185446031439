import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";
import { Redirect } from 'react-router';
import {useStompMsgHandler} from "../../../StompMsgHandler";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import NavBar from "../../common/Navbar";
import { CardContent, Card } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslate } from 'react-polyglot';

const useStyles = makeStyles({
  title: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "18px",
    paddingBottom: "6px",
  },
  pos: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  card: {
    width: "100%",
    ["@media (min-width: 700px)"]: {
      width: "600px"
    },
    margin: "auto",
    boxShadow: "0px 0px 20px 0px #c1c1c1",
  },
  paymentarea: {
    marginTop: "16px",
    height: "500px",
    boxShadow: "0px 6px 6px 6px #e2e2e3",
  }
});

const Purchase = (props) => {

  const classes = useStyles();
  const matches = useMediaQuery('(min-width:700px)');

  const stompMsgHandler = useStompMsgHandler();
  const t = useTranslate();

  const [terminalUrl, setTerminalUrl] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if(props.history.location.state !== undefined) {
      setTerminalUrl(props.history.location.state.term)
    }
  },[terminalUrl])


  const renderIframe = (url) => {
     return (
       <iframe
         className="iframe-nets"
         style={{ width: "100%", height: 500 }}
         title="NETS"
         name="payment"
         src={url}
         frameBorder={0}
       />
     )
   }

    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("purchase.changepayment.paymentcard")}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {t("purchase.changepayment.addcard")}
            </Typography>
            <div className={`${"divclass"} ${classes.paymentarea}`}>
              {terminalUrl !== "" ?
                (
                  renderIframe(terminalUrl)
                ) : (
                  <div>{t("purchase.changepayment.nourlfound")}</div>
                )
              }
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }



export default Purchase;
