import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Card } from "@material-ui/core";

import { Line } from 'react-chartjs-2';

function CardTypePci6avgBirthyear(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [data, setData] = useState();

    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/pci6birthyear", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setData(json);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-pci6birthyear">
            <h1>{t("dogexplorer.profile.cardtypes.pci6avgbirthyear.description")}</h1>
            <Line
                chartId="barchart1-1"
                options={{
                    tooltips: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.pci6avgbirthyear.year"),
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.pci6avgbirthyear.average")
                                }
                            }
                        ]
                    }
                }}
                data={{
                    labels: data != undefined ? data.labels : [],
                    datasets: data != undefined ? 
                        [{
                            backgroundColor: "transparent",
                            pointBackgroundColor: "#382e92",
                            borderColor: "#382e92",
                            pointRadius: 2,
                            label: t("dogexplorer.profile.cardtypes.pci6avgbirthyear.averagePCI6"),
                            data: data.data
                        }]
                    : []
                }}
                maxWidth={50}
                maxHeight={50}
                redraw={true}
            ></Line>
        </Card>
    );
}

export default CardTypePci6avgBirthyear;