import React, {useState, useEffect} from 'react';

import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useTranslate } from 'react-polyglot';

import TaskCard from "../TaskCard";

function SliderWizPage(props) {
    const t = useTranslate();

    const [sliderValue, setSliderValue] = useState("");
    const [notAnyOptions, setNotAnyOptions] = useState(false);

    useEffect(() => {
        try {
            let value = JSON.parse(props.trait.value);
            setSliderValue(value.sliderValue);
            if (value.sliderValue == 'ikke mulighet') {
                setNotAnyOptions(true);
            }
        } catch (ex) {
            console.debug("no resume value");
        }
    }, []);

    useEffect(() => {
        triggerPageUpdated();
    }, [sliderValue, notAnyOptions]);

    function handleCheckboxSelect(event, value) {
        event.target.checked = value;
        setNotAnyOptions(value);
        if (value){
            setSliderValue('ikke mulighet');
        }
    }

    function handleSliderSelects(e, value) {
        if (!notAnyOptions)
            setSliderValue(value);
        else
            setSliderValue('ikke mulighet');
    }

    function triggerPageUpdated() {
        props.pageUpdatedCallback({
            ...props.trait,
            value: {
                sliderValue: sliderValue,
                minVal: props.trait.minVal,
                maxVal: props.trait.maxVal,
                traitDescription: props.trait.traitDescription.includes("%alias%") ? props.trait.traitDescription.replace(/%alias%/g, "hunden") : props.trait.traitDescription
            }
        });
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    function getMarkPosition(key) {
        let marks = props.trait.extra.split("|").length + 1;
        
        return (100 / marks) * (key + 1);
    }

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    taskHeader={""}
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    taskHelpText=""
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="dog-wizard-slider-wrapper">
                            <Slider
                                className={sliderValue === "" ? "notselectedvalue" : ""}
                                defaultValue={sliderValue == "" ? props.minVal : sliderValue}
                                value={sliderValue}
                                aria-labelledby="discrete-slider-small-steps"
                                step={props.maxVal}
                                marks={props.trait.extra.includes("|") ? [
                                    { value: 0, label: "" },
                                    ...props.trait.extra.split("|").map((mark, key) => {
                                        return { value: getMarkPosition(key), label: mark };
                                    }),
                                    { value: 100, label: "" },
                                ] : undefined}
                                min={props.trait.minVal}
                                max={props.trait.maxVal}
                                valueLabelDisplay={props.trait.maxVal > 50 ? "off" : "auto"}
                                onChange={handleSliderSelects}
                            />
                            <div className="slidermarks">
                                <p className="markleft">{props.trait.minValText}</p>
                                <p className="markright">{props.trait.maxValText}</p>
                            </div>
                            <div className="slidernotpossible">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={notAnyOptions}
                                            onChange={handleCheckboxSelect}
                                            value={notAnyOptions}
                                            color="default"
                                        />
                                    }
                                    label={t("registerfeatures.notpossible")}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

export default SliderWizPage