import React from "react";
import { useTranslate } from 'react-polyglot';

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import ExpansionParagraph from "../../common/ExpansionParagraph";
import NavBar from "../../common/Navbar";

function GDPR(){
  const t = useTranslate();

  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <NavBar />
      <div className="gdpr-commonly">
        <div className="title">
          <Typography gutterBottom variant="h3">
            {t("gdpr.privacystatement.title")}
          </Typography>
          <Divider classes={{ root: "divider" }} />
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.privacystatement.description1")}
              <br />
              <br />
              {t("gdpr.privacystatement.description2")}
              {" "}
          </Typography>
        </div>
        <ExpansionParagraph
          title={t("gdpr.about.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.about.description1")}
              <br />
              <br />
              {t("gdpr.about.contact.address1")}<br />
              {t("gdpr.about.contact.address2")}<br />
              {t("gdpr.about.contact.address3")}<br />
              <a href="mailto:post@aninova.no">post@aninova.no</a><br />
              62 51 01 00
              <br />
              <br />
              {t("gdpr.about.description2")}
              <br />
              <br />
              {t("gdpr.about.description3")}
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.personalinformation.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.personalinformation.description")}
              {" "}
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.informationcollection.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.informationcollection.description1")}
              <br />
              <br />
              {t("gdpr.informationcollection.description2")}
              <br />
              <br />
              {t("gdpr.informationcollection.description3")}
              <br />
              <br />
              {t("gdpr.informationcollection.description4")}
              <br />
              <br />
              {t("gdpr.informationcollection.description5")}
              <br />
              <br />
              {t("gdpr.informationcollection.description6")}
              <br />
              <br />
              {t("gdpr.informationcollection.description7")}
              <br />
              <br />
              {t("gdpr.informationcollection.description8")}
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.yourrights.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.yourrights.description1")}
              <br />
              <br />
              {t("gdpr.yourrights.description2")}
              <br />
              <br />
              {t("gdpr.yourrights.description3")}
              <br />
              <br />
              {t("gdpr.yourrights.description4")}
              <br />
              <br />
              {t("gdpr.yourrights.description5")}
              <br />
              <br />
              {t("gdpr.yourrights.description6")}
            </Typography>
          }
        />

        <ExpansionParagraph
          title={t("gdpr.transfer.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.transfer.description1")}
              <br />
              <br />
              {t("gdpr.transfer.description2")}
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.thirdstates.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.thirdstates.description1")} <a target="_blank" href="https://www.datatilsynet.no/rettigheter-og-plikter/virksomhetenes-plikter/overfore/hva-er-privacy-shield/">{t("gdpr.thirdstates.description2")}</a>
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.howdowedo.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.howdowedo.description")}
            </Typography>
          }
        />
        <ExpansionParagraph
          title={t("gdpr.supervisory.title")}
          shouldChangeColor={true}
          children={
            <Typography classes={{ root: "text" }}>
              {" "}
              {t("gdpr.supervisory.description1")}
              <br />
              <br />
              {t("gdpr.supervisory.description2")}
              <br />
              <br />
              {t("gdpr.supervisory.description3")}t<br />
              {t("gdpr.supervisory.description4")}<br />
              {t("gdpr.supervisory.description5")}
            </Typography>
          }
        />
      </div>
    </React.Fragment>
  );
}

export default GDPR;
