import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Select from 'react-select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ClearIcon from '@material-ui/icons/Clear';
import InputTextField from "../../../common/InputTextField";

import { FormGroup, FormControl } from "@material-ui/core";

import { ExplorerContext } from "../ExplorerContext";

function DogExplorerFilter(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [breeds, setBreeds] = useState([]);

    const [loading, setLoading] = useState(false);

    const [filterBreed, setFilterBreed] = useState("");
    const [filterMale, setFilterMale] = useState(true);
    const [filterFemale, setFilterFemale] = useState(true);
    const [filterRegnumber, setFilterRegnumber] = useState("");
    const [filterBirthyearFrom, setFilterBirthyearFrom] = useState("");
    const [filterBirthyearTo, setFilterBirthyearTo] = useState("");
    
    const [hits, setHits] = useState(0);

    const [appliedFilters, setAppliedFilters] = useState([]);
    
    function fetchDogs() {
        setLoading(true);
        if (props.onChangeStart != undefined) {
            props.onChangeStart();
        }
        
        fetch("/dataapi/api/dogs/filter", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            explorer.updateTypes(json.types);
            explorer.updateHits(json.hits);
            if (json.hits == 1) {
                props.requestTabChange(2);
            }

            setLoading(false);
        }).catch(error => console.error("Error:", error));
    }

    function fetchBreeds() {
        fetch("/rest/breedwiz/breeds/", {}).then(response => {
            return response.json();
        }).then(data => {
            data.unshift({
                breedId: "",
                breedName: "Alle raser"
            });
            setBreeds(data);
        }).catch(error => console.error("Error:", error));
    }

    function codeToText(code) {
        switch (code) {
            case undefined: return "";
            case "": return "";
            case "male": return t("dogexplorer.filter.codetotext.male");
            case "female": return t("dogexplorer.filter.codetotext.female");
            case "eq": return t("dogexplorer.filter.codetotext.equal");
            case "gt": return t("dogexplorer.filter.codetotext.over");
            case "lt": return t("dogexplorer.filter.codetotext.under");
            case "false": return t("dogexplorer.filter.codetotext.no");
            case "true": return t("dogexplorer.filter.codetotext.yes");
            default: return code;
        }
    }

    function filtercodeToText(filter) {
        let filterPreText = "";
        switch (filter.type.split(".")[0]) {
            case undefined: filterPreText += ""; break;
            case "": filterPreText += ""; break;
            case "helse":
                filterPreText += t("dogexplorer.filter.filterPreText.health");
                switch (filter.type.split(".")[1]) {
                    case "ebvstand":
                        filterPreText += t("dogexplorer.filter.filterPreText.ebv");
                        break;
                    default: filterPreText += filter.type.split(".")[1];
                }
                break;
            case "jaktprovescore": filterPreText += t("dogexplorer.filter.filterPreText.huntingtestscore"); break;
            case "hdscore": filterPreText += t("dogexplorer.filter.filterPreText.hdscore"); break;
            case "dogid": filterPreText += t("dogexplorer.filter.filterPreText.dogid"); break;
            case "examinationage": filterPreText += t("dogexplorer.filter.filterPreText.examinationage"); break;
            case "ebvrelationshipstand": filterPreText += t("dogexplorer.filter.filterPreText.ebvrelationshipstand"); break;
            case "siblings": filterPreText += t("dogexplorer.filter.filterPreText.siblings"); break;
            case "jakt":
                filterPreText += "Jakt-";
                switch (filter.type.split(".")[1]) {
                    case "ebvstand":
                        filterPreText += t("dogexplorer.filter.filterPreText.ebv");
                        switch (filter.type.split(".")[2]) {
                            case "1": filterPreText += t("dogexplorer.filter.filterPreText.hunt.1"); break;
                            case "2": filterPreText += t("dogexplorer.filter.filterPreText.hunt.2"); break;
                            case "3": filterPreText += t("dogexplorer.filter.filterPreText.hunt.3"); break;
                            case "4": filterPreText += t("dogexplorer.filter.filterPreText.hunt.4"); break;
                            case "5": filterPreText += t("dogexplorer.filter.filterPreText.hunt.5"); break;
                            case "6": filterPreText += t("dogexplorer.filter.filterPreText.hunt.6"); break;
                            case "7": filterPreText += t("dogexplorer.filter.filterPreText.hunt.7"); break;
                            case "8": filterPreText += t("dogexplorer.filter.filterPreText.hunt.8"); break;
                            case "9": filterPreText += t("dogexplorer.filter.filterPreText.hunt.9"); break;
                            case "10": filterPreText += t("dogexplorer.filter.filterPreText.hunt.10"); break;
                            case "11": filterPreText += t("dogexplorer.filter.filterPreText.hunt.11"); break;
                            case "12": filterPreText += t("dogexplorer.filter.filterPreText.hunt.12"); break;
                            default: break;
                        }
                        break;
                    default: filterPreText += filter.type.split(".")[1];
                }
                break;
            default: filterPreText = filter.type.split(".")[0]; break;
        }

        return filterPreText + " " + codeToText(filter.operation.toString()) + ": " + codeToText(filter.value.toString());
    }

    useEffect(() => {
        fetchBreeds();
    }, [])

    useEffect(() => {
        //if (JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters) && props.filters.length != 0) fetchDogs();
        if (JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)) {
            fetchDogs();
            let regFilter = explorer.filters.find(i => i.type == "regnumber" && i.value != "");
            if (regFilter != undefined) {
                setFilterRegnumber(regFilter.value);
            }
        }
    }, [explorer.filters])

    return (
        <React.Fragment>
            <div className="dogexplorer-filter">
                <h3>{t("dogexplorer.filter.title")}</h3>
                <div className="helpertext">
                    <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", fontSize: "16px" }}>
                        {t("dogexplorer.filter.description")}
                    </Typography>
                </div>
                <div className="row">
                    <div className="col col-md-6">
                        <div className="filterinputs">
                            <div>
                                <InputTextField
                                    inputProps={{ style: { color: "#000000" } }}
                                    InputLabelProps={{ classes: { root: "registration-text" } }}
                                    id="regnumber"
                                    value={filterRegnumber}
                                    label={t("dogexplorer.filter.regnummer.label")}
                                    placeholder={t("dogexplorer.filter.regnummer.placeholder")}
                                    onChange={(evt) => setFilterRegnumber(evt.target.value)}
                                />
                            </div>
                            <Select
                                className="registration-text"
                                inputId="react-select-single"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? 0 : 0,
                                        boxShadow: state.isFocused ? 0 : 0,
                                        "&:hover": {
                                            border: state.isFocused ? 0 : 0
                                        }
                                    })
                                }}
                                TextFieldProps={{
                                    label: 'Rase',
                                    InputLabelProps: {
                                    htmlFor: 'react-select-single',
                                    shrink: true,
                                    },
                                }}
                                //value={{ value: filterBreed }}
                                defaultValue={""}
                                placeholder={t("dogexplorer.filter.dogbreed.placeholder")}
                                options={breeds.map(breed => { return {value: breed.breedId, label: t("breeds.list." + breed.breedId) } })}
                                onChange={(val) => {
                                    if (val.value == "") 
                                        setFilterBreed(0)
                                    else
                                        setFilterBreed(val.value);
                                }}
                            />
                            <div style={{clear: "both", paddingTop: "20px"}} className={"birthyearfilters"}>
                                <InputTextField
                                    inputProps={{ style: { color: "#000000" } }}
                                    InputLabelProps={{ classes: { root: "registration-text" } }}
                                    id="birthfrom"
                                    value={filterBirthyearFrom}
                                    label={t("dogexplorer.filter.yearofbirth.label.from")}
                                    placeholder={t("dogexplorer.filter.yearofbirth.placeholder")}
                                    onChange={(evt) => setFilterBirthyearFrom(evt.target.value)}
                                />
                                <InputTextField
                                    inputProps={{ style: { color: "#000000" } }}
                                    InputLabelProps={{ classes: { root: "registration-text" } }}
                                    id="birthto"
                                    value={filterBirthyearTo}
                                    label={t("dogexplorer.filter.yearofbirth.label.to")}
                                    placeholder={t("dogexplorer.filter.yearofbirth.placeholder")}
                                    onChange={(evt) => setFilterBirthyearTo(evt.target.value)}
                                />
                            </div>
                            <FormControlLabel
                                control={<Checkbox checked={Boolean(explorer.filters.find(i => i.type == "male") != undefined ? explorer.filters.find(i => i.type == "male").value : filterMale)} onChange={(evt) => {
                                    setFilterMale(evt.target.checked);
                                    let filters = explorer.filters.filter(i => i.type != "male" );
                                    filters.push({ type: "male", stage: "pre", operation: "eq", value: filterMale });
                                    explorer.updateFilters(filters);
                                }} />}
                                label={t("dogexplorer.gender.male")}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={Boolean(explorer.filters.find(i => i.type == "female") != undefined ? explorer.filters.find(i => i.type == "female").value : filterFemale)} onChange={(evt) => {
                                    setFilterFemale(evt.target.checked);
                                    let filters = explorer.filters.filter(i => i.type != "female" );
                                    filters.push({ type: "female", stage: "pre", operation: "eq", value: filterFemale });
                                    explorer.updateFilters(filters);
                                }} />}
                                label={t("dogexplorer.gender.female")}
                            />
                        </div>
                        <div className="filterbutton">
                            <Button
                                style={{ width: "auto" }}
                                classes={{ root: "edit-button" }}
                                disabled={loading || filterBreed == "" || filterBreed == 0}
                                onClick={() => {
                                    if (filterBreed != "" && filterBreed != 0) {
                                        let filters = explorer.filters.filter(i =>
                                            i.type != "male" &&
                                            i.type != "female" &&
                                            i.type != "birthdate" &&
                                            i.type != "breed" &&
                                            i.type != "regnumber"
                                        );
                                        if (filterBreed != "" && filterBreed != 0) {
                                            filters.push({ type: "breed", stage: "pre", operation: "eq", value: filterBreed });
                                        }
                                        filters.push({ type: "male", stage: "pre", operation: "eq", value: filterMale });
                                        filters.push({ type: "female", stage: "pre", operation: "eq", value: filterFemale });
                                        if (filterRegnumber != "") {
                                            filters.push({ type: "regnumber", stage: "pre", operation: "eq", value: filterRegnumber });
                                        }
                                        if (filterBirthyearFrom != "") {
                                            filters.push({ type: "birthdate", stage: "pre", operation: "gt", value: filterBirthyearFrom });
                                        }
                                        if (filterBirthyearTo != "") {
                                            filters.push({ type: "birthdate", stage: "pre", operation: "lt", value: filterBirthyearTo });
                                        }

                                        explorer.updateFilters(filters);
                                        //fetchDogs();
                                    } else {
                                        alert(t("dogexplorer.filter.alert.chooserace"));
                                    }
                                }}
                            >
                                {
                                (filterBreed == "" || filterBreed == 0) ?
                                    t("dogexplorer.filter.chooserace")
                                :
                                    !loading ? t("dogexplorer.filter.getstatistics") : t("dogexplorer.filter.gettingstatistics")
                                }
                            </Button>
                        </div>
                        <div className="helpertext">
                            {!loading ?
                                <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", fontSize: "16px" }}>
                                    {t("dogexplorer.filter.datashows")} {explorer.hits} {explorer.hits == 1 ? t("dogexplorer.filter.dog") : t("dogexplorer.filter.dogs")}
                                </Typography>
                            :
                                <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", fontSize: "16px" }}>
                                    {t("dogexplorer.filter.loadingdata")}
                                </Typography>
                            }
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", fontSize: "16px" }}>
                            {t("dogexplorer.filter.selectedfilter")}
                        </Typography>
                        <div className="selectedfilters">
                            {explorer.filters.filter(i =>
                                i.type != "male" &&
                                i.type != "female" &&
                                i.type != "birthdate" &&
                                i.type != "breed" &&
                                i.type != "regnumber"
                            ).map((filter, key) => 
                                <div key={key} className="selectedfilter">
                                    <p>{t("dogexplorer.filter.dogswith")} {filtercodeToText(filter)} <ClearIcon onClick={() => {
                                        let tempfilters = explorer.filters;
                                        let index = tempfilters.indexOf(filter);
                                        let removed = tempfilters.splice(index, 1);
                                        if (removed.type == "regnumber") {
                                            if (props.requestTabChange != undefined) {
                                                props.requestTabChange(1);
                                            }
                                        }
                                        explorer.updateFilters(JSON.parse(JSON.stringify(tempfilters)));
                                        fetchDogs();
                                        //if (props.onChange != undefined) {
                                        //    props.onChange(hits, types, JSON.parse(JSON.stringify(tempfilters)));
                                        //}
                                    }}/></p>
                                </div> 
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerFilter;