import React, { useEffect, useState } from "react";

import { Scatter, Chart } from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import { useTranslate } from "react-polyglot";
import _ from "lodash";
import moment from "moment";

import MapAnswers from "../PhdAnswermapper";

function Physicalmentalactivities(props) {
    const t = useTranslate();

    const [trainingtimeData, setTrainingtimeData] = useState([]);
    const [trainingtimeDataMyDog, setTrainingtimeDataMyDog] = useState([]);

    const [stimulatingactivitiesData, setStimulatingactivitiesData] = useState([]);
    const [stimulatingactivitiesDataMyDog, setStimulatingactivitiesDataMyDog] = useState([]);

    const [restlessness_activityData, setRestlessness_activityData] = useState([]);
    const [restlessness_activityDataMyDog, setRestlessness_activityDataMyDog] = useState([]);

    const [restlessness_stimulatingactivitiesData, setRestlessness_stimulatingactivitiesData] = useState([]);
    const [restlessness_stimulatingactivitiesDataMyDog, setRestlessness_stimulatingactivitiesDataMyDog] = useState([]);

    const [showDogNoshow, setShowDogNoshow] = useState("");

    function InsertDogName(text) {
        if (t("statistics.main.yourdogs") != props.selectedDogName) {
            return text.replaceAll("[dog-name]", props.selectedDogName);
        } else {
            return text.replaceAll("[dog-name]", t("statistics.main.thedog"));
        }
    }

    function GetMyDogAllAnswers(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        return myDogAnswers;
    }

    function GetMyDogLatestAnswer(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        if (myDogAnswers[myDogAnswers.length - 1] != undefined)
            return [myDogAnswers[myDogAnswers.length - 1]];
        else
            return [];
    }

    function GetDogLatestAnswer(dogId, data) {
        let dogAnswers = data.filter(x => x.dogId == dogId);
        dogAnswers = _.sortBy(dogAnswers, 'createdTs');
        if (dogAnswers[dogAnswers.length - 1] != undefined)
            return [dogAnswers[dogAnswers.length - 1]];
        else
            return [];
    }

    function GetOtherDogs(data) {
        if (props.myDogId != undefined && props.myDogId != 0) {
            let otherDogs = data.filter(x => x.dogId != props.myDogId);
            return otherDogs;
        } else {
            return data;
        }
    }

    function GetTraitsFromDataset(traitIds, dataset) {
        let traits = [];
        for (let data of dataset) {
            let trait = data.selections.find(x => traitIds.includes(x.traitId) && x.value != null && x.value != "null" && x.value != "");
            if (trait != undefined) traits.push({...trait, dog: data.dog, dataset: data});
        }
        return traits;
    }

    function GetAnswersFromData(answerId, data) {
        return MapAnswers(answerId, data);
    }

    function GetAcquisitionDate(dogId) {
        return GetAnswersFromData("D2", GetTraitsFromDataset([2101, 2201, 2301], GetDogLatestAnswer(dogId, props.youAndYourDogDataset)));
    }

    function SliderTraitToStringWithAge(sliderTrait) {
        let acqisitionDate = GetAcquisitionDate(sliderTrait.dog.id)[0];
        if (acqisitionDate != undefined) {
            if (isNaN(acqisitionDate.split("-")[0]) || isNaN(acqisitionDate.split("-")[1])) { 
                console.log("No date present");
            } else {
                if (isNaN(acqisitionDate.split("-")[2])) acqisitionDate = acqisitionDate.split("-")[0] + "-" + acqisitionDate.split("-")[1] + "-" + moment(acqisitionDate.split("-")[0] + "-" + acqisitionDate.split("-")[1] + "-1", 'YYYY-M-D').endOf("month").date();
            }
            
            let days = moment(acqisitionDate, "YYYY-M-D").diff(moment(sliderTrait.dog.birthDate), "days");

            // Setting the age to 84 days if dog is under 1 day old
            if (days <= 1) days = 84;

            return JSON.parse(sliderTrait.value).sliderValue + "|" + sliderTrait.dog.gender + "|" + days;
        }
        return undefined;
    }

    function CombineSliderTraitsToString(sliderTrait, trait2Ids) {
        let dataset  = sliderTrait.dataset;
        let trait2 = dataset.selections.find(x => trait2Ids.includes(x.traitId) && x.value != null && x.value != "null" && x.value != "").value;

        if (trait2 != undefined && sliderTrait != undefined && sliderTrait.value != undefined) {
            return JSON.parse(sliderTrait.value).sliderValue + "|" + sliderTrait.dog.gender + "|" + JSON.parse(trait2).sliderValue;
        }
    }

    function DrawCompleteWizardCard(title, wizardName) {
        return(
            <Card className={"info-card center"}>
                <card-content>
                    <div className={"info-card-content"}>
                        <div className={"info-card-header"}>
                            <div className={"info-card-text-header"}>
                                {title}
                            </div>
                        </div>
                        <hr/>
                        <p dangerouslySetInnerHTML={{__html: t("statistics.behavior.sub_general.content.youandyourdog.noaccess").replaceAll("\n", "<br/>").replaceAll("[survey]", title)}}></p>
                        &nbsp;
                    </div>
                </card-content>
            </Card>
        );
    }

    useEffect(() => {
        Chart.plugins.register({
            beforeDraw: function(c) {
               var legends = c.legend.legendItems;
               legends.forEach(function(e) {
                    if (
                        e.text == "Your dog" ||
                        e.text == "Din hund" ||
                        e.text == "Din hund"
                    ) {
                        e.fillStyle = 'rgba(255, 139, 18, 0.3)';
                    } else if (
                        e.text == "Male dogs" ||
                        e.text == "Hannhunder" ||
                        e.text == "Hanhundar" || 
                        e.text == "All dogs in race" ||
                        e.text == "Alle hunder i rasen" ||
                        e.text == "Alla hundar i ras"
                    ) {
                        e.fillStyle = 'rgba(56, 46, 146, 0.3)';
                    } else if (
                        e.text == "Female dogs" ||
                        e.text == "Tisper" ||
                        e.text == "Tikar"
                    ) {
                        e.fillStyle = '#ff000030';
                    }
               });
            }
        });
    }, []);

    useEffect(() => {
        setShowDogNoshow("");
    }, [props.displaySubcategory]);

    useEffect(() => {
        setTrainingtimeData(GetAnswersFromData("W11", GetTraitsFromDataset([2411, 2511, 2611], GetOtherDogs(props.physicalmentalactivitiesDataset)).map(x => {
            return SliderTraitToStringWithAge(x);
        }).filter(x => x != undefined)));
        setStimulatingactivitiesData(GetAnswersFromData("W15", GetTraitsFromDataset([2415, 2515, 2615], GetOtherDogs(props.physicalmentalactivitiesDataset)).map(x => {
            return SliderTraitToStringWithAge(x);
        }).filter(x => x != undefined)));
        setRestlessness_activityData(GetAnswersFromData("W18", GetTraitsFromDataset([2418, 2518, 2618], GetOtherDogs(props.physicalmentalactivitiesDataset)).map(x => {
            return CombineSliderTraitsToString(x, [2411, 2511, 2611]);
        }).filter(x => x != undefined)));
        setRestlessness_stimulatingactivitiesData(GetAnswersFromData("W18", GetTraitsFromDataset([2418, 2518, 2618], GetOtherDogs(props.physicalmentalactivitiesDataset)).map(x => {
            return CombineSliderTraitsToString(x, [2415, 2515, 2615]);
        }).filter(x => x != undefined)));

        if (props.myDogId != undefined && props.myDogId != 0) {
            setTrainingtimeDataMyDog(GetAnswersFromData("W11", GetTraitsFromDataset([2411, 2511, 2611], GetMyDogLatestAnswer(props.physicalmentalactivitiesDataset)).map(x => {
                return SliderTraitToStringWithAge(x);
            }).filter(x => x != undefined)));
            setStimulatingactivitiesDataMyDog(GetAnswersFromData("W15", GetTraitsFromDataset([2415, 2515, 2615], GetMyDogLatestAnswer(props.physicalmentalactivitiesDataset)).map(x => {
                return SliderTraitToStringWithAge(x);
            }).filter(x => x != undefined)));
            setRestlessness_activityDataMyDog(GetAnswersFromData("W18", GetTraitsFromDataset([2418, 2518, 2618], GetMyDogAllAnswers(props.physicalmentalactivitiesDataset)).map(x => {
                return CombineSliderTraitsToString(x, [2411, 2511, 2611]);
            }).filter(x => x != undefined)));
            setRestlessness_stimulatingactivitiesDataMyDog(GetAnswersFromData("W18", GetTraitsFromDataset([2418, 2518, 2618], GetMyDogAllAnswers(props.physicalmentalactivitiesDataset)).map(x => {
                return CombineSliderTraitsToString(x, [2415, 2515, 2615]);
            }).filter(x => x != undefined)));
        }
    }, [props.physicalmentalactivitiesDataset, props.myDogId]);

    return (
        <React.Fragment>
            {
                props.myDogs.some(mydog => {
                    if (props.allBreedTraits != undefined) {
                        return props.allBreedTraits.filter(trait => 
                            trait.selections[0].categoryId == 79 ||
                            trait.selections[0].categoryId == 80 ||
                            trait.selections[0].categoryId == 81
                        ).some(trait => {
                            return trait.dogId == mydog.id;
                        })
                    } else {
                        return false;
                    }
                }) ? 
                    <>
                        <Card
                            className="statisticsinfocard"
                            onClick={() => {
                                if (showDogNoshow != "earlierexperience_noshow") {
                                    setShowDogNoshow("earlierexperience_noshow");
                                } else {
                                    setShowDogNoshow("");
                                }
                            }}
                            style={{
                                maxWidth: showDogNoshow == "earlierexperience_noshow" ? "1000px" : "220px",
                            }}
                        >
                            <card-content>
                                <h3>{t("statistics.areas.behavior.dognoshow_title")}</h3>
                                <span class="material-symbols-outlined icon">
                                    info
                                </span>
                                <div
                                    style={{
                                        maxHeight: showDogNoshow == "earlierexperience_noshow" ? "400px" : "0px",
                                    }}
                                >
                                    <p dangerouslySetInnerHTML={{__html: t("statistics.areas.behavior.physicalmentalactivities.dognoshow_description").replaceAll("\n", "<br/>")}}></p>
                                </div>
                            </card-content>
                        </Card>
                        <Card className={"info-card center"}>
                            <card-content>
                                <div className={"info-card-content"}>
                                    <div className={"info-card-header"}>
                                        <div className={"info-card-text-header"}>
                                            {t("statistics.behavior.sub_general.content.physicalmentalactivities.title")}
                                        </div>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.physicalmentalactivities.trainingtime.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.physicalmentalactivities.trainingtime.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.trainingtime.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                stepSize: 25,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return "0";
                                                                    } else if (value == 100) {
                                                                        return "20";
                                                                    } else if (value == 75) {
                                                                        return "15";
                                                                    } else if (value == 50) {
                                                                        return "10";
                                                                    } else if (value == 25) {
                                                                        return "5";
                                                                    } else {
                                                                        return value;
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.trainingtime.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.alldogs"), // No need to change
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...trainingtimeData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...trainingtimeData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.yourdog"), // No need to change
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: trainingtimeDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.physicalmentalactivities.stimulatingactivities.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.physicalmentalactivities.stimulatingactivities.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.stimulatingactivities.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                padding: 10,
                                                                stepSize: 25,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return "0";
                                                                    } else if (value == 100) {
                                                                        return "12";
                                                                    } else if (value == 75) {
                                                                        return "9";
                                                                    } else if (value == 50) {
                                                                        return "6";
                                                                    } else if (value == 25) {
                                                                        return "3";
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.stimulatingactivities.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.alldogs"), // No need to change
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...stimulatingactivitiesData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...stimulatingactivitiesData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.yourdog"), // No need to change
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: stimulatingactivitiesDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                max: 100,
                                                                stepSize: 25,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return "0";
                                                                    } else if (value == 25) {
                                                                        return "5";
                                                                    } else if (value == 50) {
                                                                        return "10";
                                                                    } else if (value == 75) {
                                                                        return "15";
                                                                    } else if (value == 100) {
                                                                        return "20";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                min: 0,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.tick_zero").split("\n");
                                                                    } else if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_activity.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.alldogs"), // No need to change
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...restlessness_activityData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...restlessness_activityData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.yourdog"), // No need to change
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: restlessness_activityDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                max: 100,
                                                                stepSize: 25,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return "0";
                                                                    } else if (value == 25) {
                                                                        return "3";
                                                                    } else if (value == 50) {
                                                                        return "6";
                                                                    } else if (value == 75) {
                                                                        return "9";
                                                                    } else if (value == 100) {
                                                                        return "12";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                min: 0,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.tick_zero").split("\n");
                                                                    } else if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.physicalmentalactivities.restlessness_stimulatingactivities.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.alldogs"), // No need to change
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...restlessness_stimulatingactivitiesData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...restlessness_stimulatingactivitiesData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.source.yourdog"), // No need to change
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: restlessness_stimulatingactivitiesDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                </div>
                            </card-content>
                        </Card>
                    </>
                : DrawCompleteWizardCard(t("statistics.behavior.sub_general.content.physicalmentalactivities.title"), "") 
            }
        </React.Fragment>
    );
}

export default Physicalmentalactivities;
