import React, {useContext, useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import ProfilecardGeneral from "./cardTypes/ProfilecardGeneral";
import ProfilecardHuntingResultsTable from "./cardTypes/ProfilecardHuntingResultsTable";
import ProfilecardHuntingWeb from "./cardTypes/ProfilecardHuntingWeb";
import ProfilecardFamilytree from "./cardTypes/ProfilecardFamilytree";
import ProfilecardChildren from "./cardTypes/ProfilecardChildren";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import { ExplorerContext } from "../ExplorerContext";

function DogExplorerProfile(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [type, setType] = useState("generelt");

    const [dog, setDog] = useState();
    const [loading, setLoading] = useState(false);
    const [dogId, setDogId] = useState(explorer.filters.find(i => i.type == "regnumber") != undefined ? explorer.filters.find(i => i.type == "regnumber")["value"] : "");

    function fetchDog() {
        setLoading(true);
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetch("/dataapi/api/dogs/profile/", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                regnumber: dogId
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setDog(json[0]);
            setLoading(false);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        setDogId(explorer.filters.find(i => i.type == "regnumber") != undefined ? explorer.filters.find(i => i.type == "regnumber")["value"] : "");
        fetchDog();
    }, [JSON.stringify(explorer.filters)])

    return (
        <React.Fragment>
            <div className="dogexplorer-profile">
                {dog != undefined ?
                    <div className="row">
                        <div className="col col-sm-12">
                            <Accordion expanded={type == "generelt"} onChange={() => setType(type != "generelt" ? "generelt" : "")}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                >
                                    <div>
                                        <h3>{t("dogexplorer.profile.generally")}</h3>
                                    </div>
                                    <Divider classes={{ root: "divider" }} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    {type == "generelt" ? 
                                        <div className="row">
                                            <div className="col col-lg-6 col-sm-12">
                                                <ProfilecardGeneral
                                                    dog={dog}
                                                ></ProfilecardGeneral>
                                            </div>
                                        </div>
                                    : null}
                                </AccordionDetails>
                            </Accordion>
                            {dog.examinations != undefined && dog.examinations.filter(i => i.type.includes("jaktprove")).length > 0 ?
                                <Accordion expanded={type == "jaktprover"} onChange={() => setType(type != "jaktprover" ? "jaktprover" : "")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-content"
                                        id="panel-header"
                                    >
                                        <div>
                                            <h3>{t("dogexplorer.profile.huntingtrials")}</h3>
                                        </div>
                                        <Divider classes={{ root: "divider" }} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {type == "jaktprover" ? 
                                            <div className="row">
                                                {dog.examinations.filter(i => i.type == "jaktprove").length > 0 ?
                                                    <React.Fragment>
                                                        <div className="col col-lg-6 col-sm-12">
                                                            <ProfilecardHuntingWeb
                                                                dog={dog}
                                                            ></ProfilecardHuntingWeb>
                                                        </div>
                                                        <div className="col col-sm-12">
                                                            <ProfilecardHuntingResultsTable
                                                                dog={dog}
                                                            ></ProfilecardHuntingResultsTable>
                                                        </div>
                                                    </React.Fragment>
                                                : null}
                                                {dog.examinations.filter(i => i.type == "jaktprovespor").length > 0 ?
                                                    <React.Fragment>
                                                        <p style={{marginLeft: "20px"}}>{t("dogexplorer.profile.notsupportyet")}</p>
                                                    </React.Fragment>
                                                : null}
                                            </div>
                                        : null}
                                    </AccordionDetails>
                                </Accordion>
                            : null}
                            <Accordion expanded={type == "slektskap"} onChange={() => setType(type != "slektskap" ? t("dogexplorer.profile.relationships") : "")}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                >
                                    <div>
                                        <h3>{t("dogexplorer.profile.Relationships")}</h3>
                                    </div>
                                    <Divider classes={{ root: "divider" }} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    {type == "slektskap" ? 
                                        <div className="row">
                                            <div className="col col-sm-4">
                                                <ProfilecardChildren
                                                    dog={dog}
                                                ></ProfilecardChildren>
                                            </div>
                                            <div className="col col-sm-8">
                                                <ProfilecardFamilytree
                                                    dog={dog}
                                                ></ProfilecardFamilytree>
                                            </div>
                                        </div>
                                    : null}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                : null}
            </div>
        </React.Fragment>
    );
}

export default DogExplorerProfile;