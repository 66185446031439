import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { useHistory } from "react-router-dom";
import { Redirect, Prompt } from "react-router";
import Select from 'react-select';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import NavBar from "../../common/Navbar";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SettingsCard from "./SettingsCard";
import MySubscriptionsCard from "./MySubscriptionsCard"
import MyDiscountsCard from "./MyDiscountsCard"
import LocaleHandler from '../../../LocaleHandler';
import Auth from "../../../Auth";

function MySettings(props){
  const selectStyle = {
    control: (base, state) => ({
      ...base,
      //border: isFocused ? 0 : 0,
      border: 0,
      // This line disable the blue border
      //boxShadow: isFocused ? 0 : 0,
      boxShadow:  0,
      "&:hover": {
        //border: isFocused ? 0 : 0
        border: 0

      }
    })
  };
  const  _Auth = new Auth();
  const history = useHistory();
  const { setLocale } = React.useContext(LocaleHandler);

  const [editInfoData, setEditInfoData] = useState({});
  const setEditFirstName = value => { setEditInfoData(editInfoData => ({...editInfoData, firstName: value})); setHasChanged(true);} 
  const setEditLastName = value => { setEditInfoData(editInfoData => ({...editInfoData, lastName: value})); setHasChanged(true);}
  const setEditEmail = value => { setEditInfoData(editInfoData => ({...editInfoData, email: value})); setHasChanged(true);}
  const setEditPostCode = value => { setEditInfoData(editInfoData => ({...editInfoData, postCode: value})); setHasChanged(true);}
  const setEditGjensidigepermission = value => { setEditInfoData(editInfoData => ({...editInfoData, gjensidigepermission: value})); setHasChanged(true);}
  const setEditGjensidigenumber = value => { setEditInfoData(editInfoData => ({...editInfoData, gjensidigenumber: value})); setHasChanged(true);}
  const setEditRole = value => { setEditInfoData(editInfoData => ({...editInfoData, role: value})); setHasChanged(true);}
  const setEditRoleNumber = value => { setEditInfoData(editInfoData => ({...editInfoData, roleNumber: value})); setHasChanged(true);}
  const setEditLanguage = value => { setEditInfoData(editInfoData => ({...editInfoData, language: value})); setHasChanged(true);}
  const setEditGalleryShowAnimalId = value => { setEditInfoData(editInfoData => ({...editInfoData, galleryShowAnimalId: value})); setHasChanged(true);}
  
  const setEditPassword = value => { setEditInfoData(editInfoData => ({...editInfoData, password: value})); setHasChanged(true);}

  const setEditGalleryShowOwnerName = value => { setEditInfoData(editInfoData => ({...editInfoData, galleryShowOwnerName: value})); setHasChanged(true);}
  const setEditNewsletter = value => { setEditInfoData(editInfoData => ({...editInfoData, newsletter: value})); setHasChanged(true);}

  const [editCardInfoData, SetEditCardInfoData] = useState({});
  const [hasCardInfo, setHasCardInfo] = useState(false);

  const [gjensidigepermissionsOpen, setGjensidigePermissionsOpen] = useState(false);

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");

  const [paymentHist, setPaymentHistory] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const t = useTranslate();
  const dbId = localStorage.getItem("userid")
  const languages = [
    { value: "no", label: t("mysettings.languages.norwegian") },
    { value: "se", label: t("mysettings.languages.swedish") },
    { value: "en", label: t("mysettings.languages.english") },
  ];

  const roleoptions = [
    { value: "Hundeeier", label: t("mysettings.roles.owner") },
    { value: "Veterinær", label: t("mysettings.roles.veterinarian") },
    { value: "Oppdretter", label: t("mysettings.roles.breeder") },
    { value: "Dommer", label: t("mysettings.roles.judge") }
  ];

  function editButtonClick(){
    if (editDisabled == false) {
      let settingPass = false;
      if (currentPass != "" && newPass != "" && newPassConfirm != "") {
        settingPass = true;
        if (newPass == "" || newPassConfirm == "" || newPass != newPassConfirm) {
          alert(t("mysettings.passchange.nomatch"));
          return;
        }
      }
      _Auth.fetch("/rest/users/", {
      method: "PUT",
      body: JSON.stringify({
        id: dbId,
        firstName: editInfoData["firstName"],
        lastName: editInfoData["lastName"],
        role: editInfoData["role"],
        postCode: editInfoData["postCode"],
        language: editInfoData["language"],
        roleNumber: editInfoData["roleNumber"],
        currentPass: settingPass ? currentPass : undefined,
        newPass: settingPass ? newPass : undefined,
        newPassConfirm: settingPass ? newPassConfirm : undefined,
        password: editInfoData["password"],
        email: editInfoData["email"].toLowerCase(),
        username: editInfoData["email"].toLowerCase(),
        approvedForPublicOwner: editInfoData["galleryShowOwnerName"],
        approvedForPublicAnimalId: editInfoData["galleryShowAnimalId"],
        approvedForNewsletters: editInfoData["newsletter"],
        gjensidigepermission: editInfoData["gjensidigepermission"],
        gjensidigenumber: editInfoData["gjensidigenumber"]
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response.password == "wrongpass") {
          alert(t("mysettings.passchange.wrongpass"));
          setCurrentPass("");
          setNewPass("");
          setNewPassConfirm("");
        } else {
          localStorage.setItem("language", editInfoData["language"]);
          alert(t("mysettings.passchange.changed"));
          setLocale(editInfoData["language"]);
          setCurrentPass("");
          setNewPass("");
          setNewPassConfirm("");
        }
        setHasChanged(false);
      })
      .catch(error => {
        history.push("/error");
      });
    }
    //setEditDisabled(!editDisabled);
  };

  function getInfo() {
    _Auth.fetch("/rest/users/id/" + localStorage.getItem("userid"))
      .then(response => response.json())
      .then(data => { 
        setEditInfoData(data);
        setLoading(false);
      })
      .catch(error => {
        history.push("/error");
      });
  }

  function getCardInfo(){
    fetch(`/rest/nets/get-subscription-by-order`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
    .then(response => {
      if(response.ok && response.status === 200) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    })
    .then(data => {
      SetEditCardInfoData(data);
      setHasCardInfo(true);
    })
    .catch(error => {
      console.log(error);
    })
  }

  function getPaymentHistory(){
    fetch(`/rest/nets/get-subscriptions-by-customer`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
    .then(response => {
      if(response.ok && response.status === 200) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    })
    .then(data => {
      setPaymentHistory(data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (loading) {
      getCardInfo();
      getPaymentHistory();
      getInfo();
    }
  }, []);
    
  return (
    <React.Fragment>
      {!loading ?
        <React.Fragment>
          <Prompt
            when={ hasChanged }
            message={
              t("mysettings.unfinished")
            }
          />
          <NavBar />
          <div className="my-dog">
            <div className="my-dog-title">
              <Typography variant="subtitle1">{t("mysettings.mypage")}</Typography>
            </div>
            <div className="text-wrapper">
              <Typography gutterBottom variant="h3">
                {t("mysettings.usersettings.title")}
              </Typography>
              <Divider className="divider" />
              <Typography gutterBottom variant="body1">
                {t("mysettings.usersettings.description")}
              </Typography>
            </div>
            <Card className="my-dog-card">
              <CardContent>
                <div className="edit-text-fields">
                  <h5>{t("mysettings.general")}</h5>
                  <TextField
                    id="standard-name"
                    label={t("mysettings.firstname")}
                    //className={classes.textField}
                    value={editInfoData["firstName"] != undefined ? editInfoData["firstName"] : ""}
                    onChange={event => setEditFirstName(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.lastname")}
                    //className={classes.textField}
                    value={editInfoData["lastName"] != undefined ? editInfoData["lastName"] : ""}
                    onChange={event => setEditLastName(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.email")}
                    //className={classes.textField}
                    value={editInfoData["email"] != undefined ? editInfoData["email"] : ""}
                    onChange={event => setEditEmail(event.target.value)}
                    margin="normal"
                    disabled={editDisabled} 
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.postnumber")}
                    //className={classes.textField}
                    value={editInfoData["postCode"] != undefined ? editInfoData["postCode"] : ""}
                    onChange={event => setEditPostCode(event.target.value)}
                    margin="normal"
                    disabled={editDisabled} 
                    fullWidth={true}
                  />
                  <hr/>
                  <h5>{t("mysettings.gjensidige.title")}</h5>
                  <FormControlLabel
                    disabled={editDisabled}
                    control={
                      <Checkbox
                        checked={editInfoData["gjensidigepermission"]}
                        color="primary"
                        value= {editInfoData["gjensidigepermission"] != undefined ? editInfoData["gjensidigepermission"] :"" }
                        onChange={event => setEditGjensidigepermission(event.target.checked)}
                      />
                    }
                    label={
                      <span>{t("mysettings.permits.gjensidigepermission")} <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={(event) => {
                          // Stop propagation to prevent the checkbox from being checked
                          event.stopPropagation();
                          event.preventDefault();
                          event.nativeEvent.stopImmediatePropagation();
                          setGjensidigePermissionsOpen(!gjensidigepermissionsOpen);
                        }}
                      >({t("mysettings.permits.readgjensidigepermission")})</span></span>
                    }
                    labelPlacement="start"
                  />
                  {gjensidigepermissionsOpen &&
                    <div>
                      <Typography variant="body1">{t("mysettings.permits.gjensidigepermissions")}</Typography>
                    </div>
                  }
                  <TextField
                    id="standard-name"
                    label={t("mysettings.gjensidigenumber")}
                    //className={classes.textField}
                    value={editInfoData["gjensidigenumber"] != undefined ? editInfoData["gjensidigenumber"] : ""}
                    onChange={event => setEditGjensidigenumber(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <hr/>
                  <h5>{t("mysettings.accountrole.title")}</h5>
                  <Select
                    className="registration-text"
                    inputId="react-select-single"
                    isDisabled={editDisabled}
                    styles={selectStyle}
                    TextFieldProps={{
                      label: t("mysettings.roles.title"),
                      InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                      },
                    }}
                    options={roleoptions}
                    value={{value: editInfoData["role"] != undefined ? editInfoData["role"] : "", label: editInfoData["role"] != undefined ? roleoptions.find( o => o.value == editInfoData["role"]).label : ""}}
                    placeholder={t("mysettings.roles.title")}
                    //onChange={handleChangeSingle}
                    onChange={event => setEditRole(event.value)}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.rolenumber")}
                    //className={classes.textField}
                    value={editInfoData["roleNumber"] != undefined ? editInfoData["roleNumber"] : ""}
                    onChange={event => setEditRoleNumber(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <hr/>
                  <h5>{t("mysettings.languages.title")}</h5>
                  <Select
                    className="registration-text"
                    inputId="react-select-single"
                    isDisabled={editDisabled}
                    styles={selectStyle}
                    TextFieldProps={{
                      label: t("mysettings.languages.title"),
                      InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                      },
                    }}
                    options={languages}
                    value={editInfoData["language"] != undefined ? languages.find(i => i.value == editInfoData["language"]) : ""}
                    placeholder={t("mysettings.languages.title")}
                    onChange={event => setEditLanguage(event.value)}
                  />
                  <hr/>
                  <h5>{t("mysettings.passchange.title")}</h5>
                  <p style={{ marginBottom: "0px" }}>{t("mysettings.passchange.description")}</p>
                  <TextField
                    id="standard-name"
                    label={t("mysettings.passchange.oldpass")}
                    autocomplete="new-password"
                    value={currentPass}
                    onChange={event => setCurrentPass(event.target.value)}
                    margin="normal"
                    type="password"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.passchange.newpass")}
                    autocomplete="new-password"
                    value={newPass}
                    onChange={event => setNewPass(event.target.value)}
                    margin="normal"
                    type="password"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-name"
                    label={t("mysettings.passchange.confirmpass")}
                    autocomplete="new-password"
                    value={newPassConfirm}
                    onChange={event => setNewPassConfirm(event.target.value)}
                    margin="normal"
                    type="password"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <hr/>
                  <h5>{t("mysettings.permits.title")}</h5>
                  <FormControlLabel
                    disabled={editDisabled}
                    control={
                      <Checkbox
                        checked={editInfoData["galleryShowAnimalId"]}
                        color="primary"
                        value={editInfoData["galleryShowAnimalId"] != undefined ? editInfoData["galleryShowAnimalId"] : ""}
                        onChange={event => setEditGalleryShowAnimalId(event.target.checked) }
                      />
                    }
                    label={t("mysettings.permits.showanimalid")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    disabled={editDisabled}
                    control={
                      <Checkbox
                        checked={editInfoData["galleryShowOwnerName"]}
                        color="primary"
                        value={editInfoData["galleryShowOwnerName"] != undefined ? editInfoData["galleryShowOwnerName"] : ""}
                        onChange={event => setEditGalleryShowOwnerName(event.target.checked)}
                      />
                    }
                    label={t("mysettings.permits.showownername")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    disabled={editDisabled}
                    control={
                      <Checkbox
                        checked={editInfoData["newsletter"]}
                        color="primary"
                        value= {editInfoData["newsletter"] != undefined ? editInfoData["newsletter"] :"" }
                        onChange={event => setEditNewsletter(event.target.checked)}
                      />
                    }
                    label={t("mysettings.permits.newsletter")}
                    labelPlacement="start"
                  />
                <div className="edit-container">
                  <Button
                    classes={{ root: "edit-button" }}
                    onClick={editButtonClick}
                  >
                    {editDisabled ? t("mysettings.edit") : t("mysettings.save")}
                  </Button>
                </div>
                </div>
              </CardContent>
            </Card>
            {hasCardInfo &&
              <SettingsCard orderId={editCardInfoData["orderId"]} maskedPan={editCardInfoData["maskedPan"]} issuer={editCardInfoData["issuer"]} expiryDate={editCardInfoData["expiryDate"]}></SettingsCard>
            }
            {/* <MySubscriptionsCard paymentHistory={paymentHist}></MySubscriptionsCard>
            <MyDiscountsCard></MyDiscountsCard> */}
          </div>
        </React.Fragment>
      :
        <React.Fragment>
          <NavBar />
          <div className="my-dog">
            <div className="my-dog-title">
              <Typography variant="subtitle1">{t("mysettings.title")}</Typography>
            </div>
            <Card className="my-dog-card">
              <Typography gutterBottom variant="h3" className="loading-text">
                {t("mysettings.loading")}
              </Typography>
            </Card>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default MySettings;
