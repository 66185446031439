import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as moment from 'moment';

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  },
  title: {
    width: "232px",
    height: "28px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "8px",
    lineHeight: "20px"
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  paymenthistoryitem: {
    borderBottom: "1px solid #ececec",
    marginBottom: "18px",
  }
})

const HistoryCard = (props) => {
  const classes = useStyles();

  const statusMessage = (statusFromNets, text) => {
      if (!statusFromNets || statusFromNets === "")
          return true;
      switch (statusFromNets) {
          case 'Paid':
              return 'Betalt'

          case 'Not-paid':
              return 'Ikke betalt'

          case 'Registered':
              return 'Kort registrert'

          case 'Credited':
              return 'Kreditert'

          case 'Not-credited':
              return 'Kreditering feilet'

          default:
              return statusFromNets.status;
      }
  }

  return (
    <div className={classes.paymenthistoryitem}>
      <Typography className={classes.text} color="textSecondary">
        <span>{moment(props.paymentHistory.dateAndTime).format('DD/MM/YYYY')}</span>
        {props.paymentHistory.paymentStatus.status === "Registered" ?
          ""
          :
          <span>{" - " + props.paymentHistory.amount / 100} kr</span>
        }

        <span style={{float: "right"}}>{statusMessage(props.paymentHistory.paymentStatus.status, "tekst")}</span>
      </Typography>
    </div>
  )
}

export default HistoryCard
