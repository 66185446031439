import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

import { ExplorerContext } from "../../../ExplorerContext";
import InputTextField from "../../../../../common/InputTextField";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Card } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FilterListIcon from '@material-ui/icons/FilterList';

import fetchHelperService from "../../../../../../services/fetchHelper";

import { Scatter } from 'react-chartjs-2';
import moment from 'moment';

function CardTypeJaktprovePointsAge(props) {
    let history = useHistory();

    let explorer = useContext(ExplorerContext);

    const [data, setData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/biotailweightage", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setData(json.data);
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-examinationagedistribution">
            <h1>Vekt</h1>
            <div style={{ pointerEvents: "none" }}>
                <Scatter
                    chartId="barchart1-1"
                    options={{
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        animation: {
                            duration: 0 // general animation time
                        },
                        responsiveAnimationDuration: 0, // animation duration after a resize
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 780,
                                        autoSkip: false,
                                        maxRotation: 52,
                                        minRotation: 25,
                                        callback: function(value, index, values) {
                                            return Math.round(value/52);
                                        }
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Alder i år"
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        max: 50
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Vekt i kg"
                                    }
                                }
                            ]
                        }
                    }}
                    data={{
                        datasets: [
                            {
                                type: "scatter",
                                backgroundColor: "#382e92",
                                label: "Vekt",
                                pointRadius: 2,
                                data: data
                            }
                        ]
                    }}
                    maxWidth={50}
                    maxHeight={50}
                    redraw={true}
                ></Scatter>
            </div>
        </Card>
    );
}

export default CardTypeJaktprovePointsAge;