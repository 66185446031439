import React from "react";
import { useTranslate } from 'react-polyglot';

import Typography from "@material-ui/core/Typography";

import InfoDivider from "../../common/Divider";
import NavBar from "../../common/Navbar";

function Error(){
  const t = useTranslate();

  return (
    <React.Fragment>
      <NavBar />
      <div className="error">
        <div className="oisann-container">
          <div className="oisann-content">
            <img src="/assets/images/oisann.svg" alt="" />
            <Typography variant="h3" gutterBottom>
              {t("error.text1")}
            </Typography>
            <InfoDivider />
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ marginTop: "5%" }}
            >
              {t("error.text2")}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t("error.text3")}
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Error;
