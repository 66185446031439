import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import TaskCard from "../TaskCard";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useTranslate } from 'react-polyglot';

function DropdownWizPage(props) {
    const t = useTranslate();

    const [selection, setSelection] = useState(props.trait.options[0]);

    function triggerPageUpdated(value = selection) {
        props.pageUpdatedCallback({
            ...props.trait,
            value: {
                option: value
            }
        });
    }

    function handleSelect(option) {
        setSelection(option);
        triggerPageUpdated(option);
    }

    useEffect(() => {
        if (props.trait.value != undefined) {
            try {
                let value = JSON.parse(props.trait.value);
                setSelection(value.option);
            } catch (ex) {
                console.debug("no resume value");
            }
        }
    }, [props.trait.value]);

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard
                    taskCounter={stringReplacer(props.trait.traitName)}
                    taskHeader=""
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    style={{overflow: "visible"}}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="dropdown-container" >
                            <FormControlLabel
                                value="bottom"
                                control={
                                    <Select
                                        //labelId="demo-simple-select-label"
                                        //id="demo-simple-select"
                                        color="primary"
                                        value={selection}
                                        //label="Age"
                                        default={props.trait.options[0]}
                                        onChange={(event) => handleSelect(event.target.value)}
                                    >
                                        {props.trait.options.map((option, key) =>
                                            <MenuItem key={key} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </Select>
                                }
                            />
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

DropdownWizPage.propTypes = {
    trait: PropTypes.object,
    pageUpdatedCallback: PropTypes.func,
    display: PropTypes.bool
};

export default DropdownWizPage;