import React from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import { Typography } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Divider from '@material-ui/core/Divider';
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

const headingContainerStyle = {
    overflow: "hidden"
}
const headingStyle = {
    float: "left",
    marginTop: "28px",
    marginLeft: "30px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.43",
    letterSpacing: "3.5px",
    textTransform: "uppercase",
    width: "calc(100% - 82px)"
}
const descriptionStyle = {
    marginLeft: "30px",
    marginTop: "15px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.13",
    letterSpacing: "0.3px",
    width: "calc(100% - 40px)"
}
const descriptionInfoStyle = {
    marginLeft: "30px",
    marginTop: "15px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.5px",
    width: "calc(100% - 40px)"
}
const headingButtonStyle = {
    float: "right",
    position: "inherit"
}

class TaskCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false, info: false }
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <React.Fragment>
                <Card classes={{ root: "card-details" }} style={{padding: "0px 0px 20px", ...this.props.style != undefined ? this.props.style : {} }}>
                    <CardContent classes={{ root: "card-content" }}>
                        <div className="wizard-card-header" style={ headingContainerStyle }>
                            <Typography style={ headingStyle } variant="h6" >
                                {this.props.taskCounter}
                            </Typography>
                            {!this.props.noInfo ?
                            <IconButton className="info-button" style={ headingButtonStyle } onClick={this.handleClickOpen}>
                                <img src="/assets/images/info_icon.svg" alt="" />
                            </IconButton> : null}
                        </div>
                        <Divider classes={{ root: "divider" }} />
                        <Typography variant="h3" style={ descriptionStyle }>{this.props.taskHeader}</Typography>
                        <Typography variant="body1" style={ descriptionInfoStyle }>
                            <span dangerouslySetInnerHTML={{__html: this.props.taskBody != undefined ? this.props.taskBody.replaceAll("\\n", "<br/>") : ""}}></span>
                        </Typography>
                        { this.props.taskHelpText != undefined && this.props.taskHelpText != "" ?
                            <Typography variant="body1" style={ descriptionInfoStyle }>{this.props.taskHelpText}</Typography>
                        : "" }
                        {this.props.children}
                    </CardContent>
                </Card>

                <Dialog  
                PaperProps={{
                    style: {
                      margin: '10px',
                      borderRadius: '12px',
                      overflow: "visible",
                      width: "100%"
                    },
                  }}
                    onClose={this.handleClose}
                    open={this.state.open}
                >
                    <div className="dialog-content">
                        <IconButton classes={{ root: "cross-button"}} aria-label="Close" onClick={this.handleClose}><CloseIcon/></IconButton>
                        <Typography variant="h3"  >
                            {this.props.taskInfoHeader}
                            
                        </Typography>
                        <Divider classes={{ root: "divider" }}  />
                        {
                            this.props.taskInfoBody.includes("movie|") ? 
                                <div>
                                    <video controls style={{width: "100%"}}>
                                        <source src={"/assets/movies/" + this.props.taskInfoBody.split("|")[1]} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            :
                                <Typography variant="body1">
                                    <span dangerouslySetInnerHTML={{__html: this.props.taskInfoBody.replaceAll("\\n", "<br/>")}}></span>
                                </Typography>
                        }
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

TaskCard.propTypes = {
    children: PropTypes.node,
    taskCounter: PropTypes.string.isRequired,
    taskHeader: PropTypes.string.isRequired,
    taskBody: PropTypes.string.isRequired,
    taskHelpText: PropTypes.string,
    taskInfoHeader: PropTypes.string.isRequired,
    taskInfoBody: PropTypes.string.isRequired,
    noInfo: PropTypes.bool
};

export default TaskCard;