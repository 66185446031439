import exif from 'exif-js';
/**
 * Service for resizing of images. 
 */
const ImageResizerService = {
    /**
     * @param {Base64String} image Picture that is to be resized
     * @param {number} MAX_WIDTH A max width the image should be resized to (if the aspect ratio is weird)
     * @param {number} MAX_HEIGHT A max height the image should be resized to (if the aspect ratio is weird)
     * @param {number} compressionRate A number between 0 and 1, where 1 is no compression
     * 
     * @returns {Promise} Promise with the resized image as Base64String
     */
    resizeForUpload: function (image, MAX_WIDTH, MAX_HEIGHT, compressionRate) {
        return new Promise(function (resolve) {
            var img = document.createElement('img');

            img.onload = function () {
                var canvas = document.createElement('canvas');


                exif.getData(img, function () {
                    var orientation = exif.getAllTags(this).Orientation;
                    var width = img.width;
                    var height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }


                    if ([5, 6, 7, 8].indexOf(orientation) > -1) {
                        canvas.width = height;
                        canvas.height = width;
                    } else {
                        canvas.width = width;
                        canvas.height = height;
                    }

                    var ctx = canvas.getContext("2d");


                    switch (orientation) {
                        case 2:
                            ctx.transform(-1, 0, 0, 1, width, 0);
                            break;
                        case 3:
                            ctx.transform(-1, 0, 0, -1, width, height);
                            break;
                        case 4:
                            ctx.transform(1, 0, 0, -1, 0, height);
                            break;
                        case 5:
                            ctx.transform(0, 1, 1, 0, 0, 0);
                            break;
                        case 6:
                            ctx.transform(0, 1, -1, 0, height, 0);
                            break;
                        case 7:
                            ctx.transform(0, -1, -1, 0, height, width);
                            break;
                        case 8:
                            ctx.transform(0, -1, 1, 0, 0, width);
                            break;
                        default:
                            ctx.transform(1, 0, 0, 1, 0, 0);
                    }

                    ctx.drawImage(img, 0, 0, width, height);

                    // No compresssion if image is small 
                    if (img.width < MAX_WIDTH || img.height < MAX_HEIGHT) {
                        compressionRate = 1;
                    }

                    var dataURI = canvas.toDataURL('image/jpeg', compressionRate);
                    resolve(dataURI);
                });
            }
            
            img.src = image;
        });

    },

        /**
     * @param {Base64String} image Picture that is to be resized
     * 
     * @returns {Promise} Promise with the size of image as a dict {height: number, width: number}
     */
    getImageSize: function (image) {
        return new Promise(function (resolve) {
            var img = document.createElement('img');
            img.src = image;
            img.onload = function () {

                var canvas = document.createElement('canvas');


                exif.getData(img, function () {
                    // var orientation = exif.getAllTags(this).Orientation;

                    let width = img.width;
                    let height = img.height;
                    let data = {width: width, height: height}
        
                    resolve(data);
                });
            }
        });
    }
}

export default ImageResizerService;