import React from "react";

const Dots = () => (
  <React.Fragment>
    <div className="dots">
      <div className="dots-container">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" style={{ backgroundColor: "#6cefa1" }} />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  </React.Fragment>
);

export default Dots;
