import React, { useState, useRef, useEffect } from "react";
import Hammer from 'react-hammerjs';

/* USAGE SAMPLE
<div className="testcontainer" style={{ marginLeft: "250px", width: "800px", height: "600px" }}> Outer sizes, as ImageMeasure will fill available space
    <ImageMeasure
        frameStyle={{
            backgroundImage: 'url("https://preview.pixlr.com/images/800wm/1402/2/1402200802.jpg")'
        }}
        boxStartLeft={600}
        boxStartTop={300}
        boxStartWidth={200}
        boxStartHeight={200}
        boxStyle={{}}
        buttonStyles={{ backgroundColor: "red" }}
        rotateButtonStyle={{}}
        resizeButtonStyle={{}}
        boxMoveButtonStyle={{}}
        lineMoveButtonStyle={{}}
        lineColor={"green"}
        lineWidth={"1px"}
        lineStyle={"dashed"}
        onLineMove={(val) => console.log(val)}
        onBoxResize={(val) => console.log(val)}
    ></ImageMeasure>
</div>
*/

function ImageMeasure(props) {
    const rotatebox = useRef();
    const box = useRef();
    const line = useRef();
    const hammer = useRef();

    const [usingMouse, setUsingMouse] = useState(true);
    const [mouseAction, setMouseAction] = useState("none");

    const [linedragStart, setLinedragStart] = useState(0);
    const [boxDragStartX, setBoxDragStartX] = useState(0);
    const [boxDragStartY, setBoxDragStartY] = useState(0);

    const [lineStart, setLineStart] = useState(props.lineStart != undefined ? props.lineStart : 50);

    const [boxStartX, setBoxStartX] = useState(props.boxStartLeft != undefined ? props.boxStartLeft : 400);
    const [boxStartY, setBoxStartY] = useState(props.boxStartTop != undefined ? props.boxStartTop : 200);
    const [boxStartW, setBoxStartW] = useState(props.boxStartWidth != undefined ? props.boxStartWidth : 200);
    const [boxStartH, setBoxStartH] = useState(props.boxStartHeight != undefined ? props.boxStartHeight : 200);
    const [boxStartR, setBoxStartR] = useState(props.startrotation != undefined ? props.startrotation : 0);

    /*const [action, setAction] = useState("none");
    const [rotation, setRotation] = useState(props.startrotation != undefined ? props.startrotation : 0);
    const [boxrotation, setBoxrotation] = useState(props.startrotation != undefined ? props.startrotation : 0);
    const [currentrotation, setCurrentrotation] = useState(props.startrotation != undefined ? props.startrotation : 0);

    const [leftPointer, setLeftPointer] = useState(0);
    const [topPointer, setTopPointer] = useState(0);

    const [touchStart0X, setTouchStart0X] = useState(0);
    const [touchStart0Y, setTouchStart0Y] = useState(0);
    const [touchStart1X, setTouchStart1X] = useState(0);
    const [touchStart1Y, setTouchStart1Y] = useState(0);

    const [boxStartTop, setBoxStartTop] = useState(0);
    const [boxStartLeft, setBoxStartLeft] = useState(0);
    const [boxStartHeight, setBoxStartHeight] = useState(0);
    const [boxStartWidth, setBoxStartWidth] = useState(0);

    const [touchMove0X, setTouchMove0X] = useState(0);
    const [touchMove0Y, setTouchMove0Y] = useState(0);
    const [touchMove1X, setTouchMove1X] = useState(0);
    const [touchMove1Y, setTouchMove1Y] = useState(0);

    const [startPanX, setStartPanX] = useState(0);
    const [startLineX, setStartLineX] = useState(0);
    const [lineLeft, setLineLeft] = useState(50);*/

    const btn = {
        width: "20px",
        height: "20px",
        backgroundColor: "white",
        borderRadius: "10px",
        position: "absolute",
        zIndex: "10",
        pointerEvents: "all",
        padding: "0px"
    }

    /*const handleRotateStart = (ev) => {
        setAction("none");
        setRotation(ev.rotation);

        setTouchStart0X(ev.pointers[0].offsetX);
        setTouchStart0Y(ev.pointers[0].offsetY);

        setTouchStart1X(ev.pointers[1].offsetX);
        setTouchStart1Y(ev.pointers[1].offsetY);

        setLeftPointer(ev.pointers[0].offsetX < ev.pointers[1].offsetX ? 0 : 1);
        setTopPointer(ev.pointers[0].offsetY < ev.pointers[1].offsetY ? 0 : 1);
        
        setBoxStartTop(rotatebox.current.offsetTop);
        setBoxStartLeft(rotatebox.current.offsetLeft);
        setBoxStartHeight(box.current.offsetHeight);
        setBoxStartWidth(box.current.offsetWidth);
    }*/

    /*const handleRotateMove = (ev) => {
        setUsingMouse(false);

        if (action == "none") {
            if (ev.scale > 1.15 || ev.scale < 0.85) {
                setAction("resizing");
            }
            if (rotation > ev.rotation + 10 || rotation < ev.rotation - 10) {
                setAction("rotating");
            }
        }

        setTouchMove0X(ev.pointers[0].offsetX);
        setTouchMove0Y(ev.pointers[0].offsetY);

        setTouchMove1X(ev.pointers[1].offsetX);
        setTouchMove1Y(ev.pointers[1].offsetY);

        if (action == "resizing")
            resizeBox(touchMove0X, touchMove0Y, touchMove1X, touchMove1Y);
        if (action == "rotating")
            rotateBox(ev.rotation);
    }*/

    /*const handleRotateEnd = (ev) => {
        setBoxrotation(currentrotation);
    }*/

    /*const handlePanStart = (ev) => {
        if (!usingMouse) {
            setAction("none");
            setStartPanX(ev.pointers[0].offsetX);
            setStartLineX(Number(line.current.style.left.slice(0, -2)));
        }
    }*/

    /*const handlePanMove = (ev) => {
        if (!usingMouse) {
            if (action == "none" && (startPanX + 10 < ev.pointers[0].offsetX || startPanX - 10 > ev.pointers[0].offsetX)) {
                setAction("panning");
            }
            if (action == "panning") {
                moveLine(ev.pointers[0].offsetX);
            }
        }
    }*/

    /*const handlePanEnd = (ev) => {
        if (!usingMouse) {
            setLineLeft(line.current.offsetLeft);
        }
    }*/

    /*function moveLine(x) {
        let newlinepos = (startLineX + (x - startPanX));
        if (newlinepos < 0) newlinepos = 0;
        if (newlinepos > box.current.offsetWidth) newlinepos = box.current.offsetWidth;
        line.current.style.left = newlinepos + "px";

        if (props.onLineMove != undefined) {
            props.onLineMove((newlinepos / box.current.offsetWidth));
        }
    }*/

    /*function rotateBox(evRotation) {
        let maxRotation = (props.startrotation != undefined ? props.startrotation : 0) + 90;
        let minRotation = (props.startrotation != undefined ? props.startrotation : 0) - 90;
        if (Math.round(boxrotation - (rotation - evRotation)) < maxRotation && Math.round(boxrotation - (rotation - evRotation)) > minRotation) {
            rotatebox.current.style.transform = 'rotate(' + Math.round(boxrotation - (rotation - evRotation)) + 'deg)';
            setCurrentrotation(boxrotation - (rotation - evRotation));
        }
    }*/

    /*function resizeBox(point0x, point0y, point1x, point1y) {
        let widthAdjustment = box.current.style.width.slice(0, -2);
        let heightAdjustment = box.current.style.height.slice(0, -2);

        let size = { w: 0, h: 0 };

        if (topPointer == 0 && leftPointer == 0)
            size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart1X, touchStart1Y, point1x, point1y);

        if (topPointer == 1 && leftPointer == 1)
            size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart0X, touchStart0Y, point0x, point0y);

        if (topPointer == 1 && leftPointer == 0)
            size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart1X, touchStart0Y, point1x, point0y);

        if (topPointer == 0 && leftPointer == 1)
            size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart0X, touchStart1Y, point0x, point1y);

        heightAdjustment = size.h;
        widthAdjustment = size.w;

        /*if ((topPointer == 0 ? boxStartTop + (point0y - touchStart0Y) : boxStartTop + (point1y - touchStart1Y)) + (topPointer == 0 ? (boxStartHeight + ((point1y - point0y) - (touchStart1Y - touchStart0Y))) : (boxStartHeight + ((point0y - point1y) - (touchStart0Y - touchStart1Y)))) < hammer.current.domElement.offsetHeight)
            heightAdjustment = topPointer == 0 ? (boxStartHeight + ((point1y - point0y) - (touchStart1Y - touchStart0Y))) : (boxStartHeight + ((point0y - point1y) - (touchStart0Y - touchStart1Y)));
        
        if ((leftPointer == 0 ? boxStartLeft + (point0x - touchStart0X) : boxStartLeft + (point1x - touchStart1X)) + (leftPointer == 0 ? (boxStartWidth + ((point1x - point0x) - (touchStart1X - touchStart0X))) : (boxStartWidth + ((point0x - point1x) - (touchStart0X - touchStart1X)))) < hammer.current.domElement.offsetWidth)
            widthAdjustment = leftPointer == 0 ? (boxStartWidth + ((point1x - point0x) - (touchStart1X - touchStart0X))) : (boxStartWidth + ((point0x - point1x) - (touchStart0X - touchStart1X)));
        */

        /*if (
            (leftPointer == 0 ? boxStartLeft + (point0x - touchStart0X) : boxStartLeft + (point1x - touchStart1X)) > 0 &&
            (leftPointer == 0 ? boxStartLeft + (point0x - touchStart0X) : boxStartLeft + (point1x - touchStart1X)) + widthAdjustment < hammer.current.offsetWidth
        ) {
            rotatebox.current.style.left = leftPointer == 0 ? boxStartLeft + (point0x - touchStart0X) + "px" : boxStartLeft + (point1x - touchStart1X) + "px";
            //size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart0X, touchStart1Y, point0x, point1y);
        }

        if (
            (topPointer == 0 ? boxStartTop + (point0y - touchStart0Y) : boxStartTop + (point1y - touchStart1Y)) > 0 &&
            (topPointer == 0 ? boxStartTop + (point0y - touchStart0Y) : boxStartTop + (point1y - touchStart1Y)) + heightAdjustment < hammer.current.offsetHeight
        ) {
            rotatebox.current.style.top = topPointer == 0 ? boxStartTop + (point0y - touchStart0Y) + "px" : boxStartTop + (point1y - touchStart1Y) + "px";
            //size = resize(boxStartWidth, boxStartHeight, boxStartR, touchStart1X, touchStart0Y, point1x, point0y);
        }

        heightAdjustment = size.h;
        widthAdjustment = size.w;
        
        if (heightAdjustment < 0) heightAdjustment = 0;
        if (widthAdjustment < 0) widthAdjustment = 0;
        box.current.style.height = heightAdjustment + "px";
        box.current.style.width = widthAdjustment + "px";

        rotatebox.current.style.height = heightAdjustment + "px";
        rotatebox.current.style.width = widthAdjustment + "px";

        if (line.current.style.left.slice(0, -2) > widthAdjustment - 10) {
            if (widthAdjustment - 10 > 0) {
                setLineStart(widthAdjustment - 10);
                line.current.style.left = (widthAdjustment - 10) + "px";
            }
        }

        if (props.onLineMove != undefined) {
            props.onLineMove(Number((line.current.style.left.slice(0, 2)) / widthAdjustment));
        }

        if (props.onBoxResize != undefined) {
            props.onBoxResize((heightAdjustment) / (widthAdjustment));
        }
    }*/

    function initMouseControl() {
        if (!usingMouse) {
            setUsingMouse(true);
            console.log("mousecontrol initiated");
        }
    }

    function startBoxDrag() {
        setMouseAction("boxdrag");
        console.log("starting boxdrag");
        let e = window.event;
        let posX = e["clientX"];
        let posY = e["clientY"];
        setBoxDragStartX(posX);
        setBoxDragStartY(posY);
    }

    function getTopLeft(midx, midy, height, width, angle) {
        const angInRad = angle*Math.PI/180;
        const cosA = Math.cos(angInRad);
        const sinA = Math.sin(angInRad);
        
        return {
            x : midx + sinA*height/2 -cosA*width/2,
            y : midy - cosA*height/2 -sinA*width/2
        }
    }

    function getBackTopLeft(toprot, leftrot, height,width, angle) {
        const angInRad = angle*Math.PI/180;
        const cosA = Math.cos(angInRad);
        const sinA = Math.sin(angInRad);
        
        return {
            newleft : leftrot + (cosA*width/2) -(sinA*height/2) -(width/2),
            newtop : toprot + (cosA*height/2) +(sinA*width/2) - (height/2)
        }
    }

    function startBoxResize(ev) {
        setMouseAction("boxresize");
        console.log("starting boxresize");
        let e = window.event;
        let posX = e["clientX"];
        let posY = e["clientY"];
        setBoxDragStartX(posX);
        setBoxDragStartY(posY);

        try{
            let topleft = getTopLeft(
                Number(rotatebox.current.style.left.slice(0, -2)) + (rotatebox.current.offsetWidth / 2),
                Number(rotatebox.current.style.top.slice(0, -2)) + (rotatebox.current.offsetHeight / 2),
                rotatebox.current.offsetHeight,
                rotatebox.current.offsetWidth,
                rotatebox.current.style.transform != undefined ? Number(rotatebox.current.style.transform.split("(")[1].split("deg)")[0]) : 0
            );
    
            rotatebox.current.style.transformOrigin = "top left";
            rotatebox.current.style.left = topleft.x + "px";
            rotatebox.current.style.top = topleft.y + "px";
        } catch (ex) {
            let topleft = getTopLeft(
                Number(rotatebox.current.style.left.slice(0, -2)) + (rotatebox.current.offsetWidth / 2),
                Number(rotatebox.current.style.top.slice(0, -2)) + (rotatebox.current.offsetHeight / 2),
                rotatebox.current.offsetHeight,
                rotatebox.current.offsetWidth,
                0
            );
    
            rotatebox.current.style.transformOrigin = "top left";
            rotatebox.current.style.left = topleft.x + "px";
            rotatebox.current.style.top = topleft.y + "px";
        }
        
    }

    /*function endBoxResize() {
        rotatebox.current.style.transformOrigin = "center";
        rotatebox.current.style.left = rotateboxDefaultX + "px";
        rotatebox.current.style.top = rotateboxDefaultY + "px";
    }*/

    function startBoxRotate() {
        setMouseAction("boxrotate");
        console.log("starting boxrotate");
        let e = window.event;
        let posY = e["clientY"];
        let posX = e["clientX"];
        setBoxDragStartY(posY);
        setBoxDragStartX(posX);
        //setBoxRotateStartX(e[""]);
        //setBoxRotateStarty();
    }

    function startLineDrag() {
        setMouseAction("linedrag");
        let e = window.event;
        let posY = e["clientY"];
        let posX = e["clientX"];
        setBoxDragStartY(posY);
        setBoxDragStartX(posX);
        console.log("starting linedrag");
        setLinedragStart(posX);
    }

    function resize(w0, h0, a0, x1, y1, x2, y2) {
        const len = Math.sqrt((x2-x1) * (x2-x1) + (y2-y1) * (y2-y1));
        const totalangle = Math.atan2((y2-y1), (x2-x1)) ;
        const a0rad = a0 * (Math.PI / 180);
        const resizeangle = totalangle - a0rad;
        return {
            w: w0 + Math.cos(resizeangle) * len,
            h: h0 + Math.sin(resizeangle) * len
        }
    }
    
    function movesplit(w0, s0, a0, x1, y1, xm, ym) {
        const dx = xm - x1;
        const dy = ym - y1;
        const len = Math.sqrt(dx*dx + dy*dy);
        const totangle = Math.atan2(dy, dx) * 180 / Math.PI; //degrees
        const deltaangle = totangle - a0;
        const deltamove = len * Math.cos(deltaangle * Math.PI/180); //move in pixels
        const newpos = s0 + deltamove;
        
        // forbli innenfor boksen: 
        if (newpos < 0) return 0;
        if (newpos > w0) return w0;
        return newpos;
    }

    function rotate(w0, h0, a0, x1, y1, xm, ym) {
        // vinkel gjennom midten, relativt til firkant, i grader
        const ac0 = Math.atan2(h0,w0)*180/Math.PI;

        // total vinkel, med hensyn til opprinnelig rotasjon
        const atot = a0 - ac0;
        const atotrad = atot*Math.PI/180;

        // lengde på halve diagonalen til midten
        const diagonalhalvlengde = Math.sqrt(w0*w0+h0*h0)/2;

        // midtpunktet, i musens koordiatsystem
        const xc = x1 - Math.cos(atotrad)*diagonalhalvlengde;
        const yc = y1 - Math.sin(atotrad)*diagonalhalvlengde;

        // posisjon mus ift midtpunkt
        const dx = xm-xc;
        const dy = ym-yc;
        
        // vinkel til første museklikk (startposisjon)
        const a1 = Math.atan2(y1-yc,x1-xc);
        
        // opprinnelig box-vinkel i rad
        const a0rad = a0*Math.PI/180;
        
        // vinkel fra midt til museposisjon
        const a = Math.atan2(dy, dx);

        // ny korrigert boksvinkel 
        const anew = (a0rad + a - a1)*180/Math.PI;
        return anew;
    }

    function stopMouseAction(evt) {
        onMouseMove(evt);
        if (mouseAction != "none") {
            switch (mouseAction) {
                case "linedrag":
                    setLineStart(Number(line.current.style.left.slice(0, -2)));
                    props.onChange({left: boxStartX, top: boxStartY }, {width: boxStartW, height: boxStartH }, boxStartR, Number(line.current.style.left.slice(0, -2)));
                    break;
                case "boxdrag":
                    setBoxStartX(Number(rotatebox.current.style.left.slice(0, -2)));
                    setBoxStartY(Number(rotatebox.current.style.top.slice(0, -2)));
                    props.onChange({left: Number(rotatebox.current.style.left.slice(0, -2)), top: Number(rotatebox.current.style.top.slice(0, -2)) }, {width: boxStartW, height: boxStartH }, boxStartR, lineStart);
                    break;
                case "boxresize":
                    setBoxStartW(Number(box.current.style.width.slice(0, -2)));
                    setBoxStartH(Number(box.current.style.height.slice(0, -2)));

                    try{
                        let topleft = getBackTopLeft(
                            Number(rotatebox.current.style.top.slice(0, -2)),
                            Number(rotatebox.current.style.left.slice(0, -2)),
                            rotatebox.current.offsetHeight,
                            rotatebox.current.offsetWidth,
                            rotatebox.current.style.transform != undefined ? Number(rotatebox.current.style.transform.split("(")[1].split("deg)")[0]) : 0
                        );

                        rotatebox.current.style.transformOrigin = "center";
                        rotatebox.current.style.left = topleft.newleft + "px";
                        rotatebox.current.style.top = topleft.newtop + "px";
                        setBoxStartX(topleft.newleft);
                        setBoxStartY(topleft.newtop);
                        props.onChange({left: topleft.newleft, top: topleft.newtop }, {width: Number(box.current.style.width.slice(0, -2)), height: Number(box.current.style.height.slice(0, -2)) }, boxStartR, lineStart);
                    } catch(ex) {
                        let topleft = getBackTopLeft(
                            Number(rotatebox.current.style.top.slice(0, -2)),
                            Number(rotatebox.current.style.left.slice(0, -2)),
                            rotatebox.current.offsetHeight,
                            rotatebox.current.offsetWidth,
                            0
                        );

                        rotatebox.current.style.transformOrigin = "center";
                        rotatebox.current.style.left = topleft.newleft + "px";
                        rotatebox.current.style.top = topleft.newtop + "px";
                        setBoxStartX(topleft.newleft);
                        setBoxStartY(topleft.newtop);
                        props.onChange({left: boxStartX, top: boxStartY }, {width: Number(box.current.style.width.slice(0, -2)), height: Number(box.current.style.height.slice(0, -2)) }, boxStartR, lineStart);
                    }
                    
                    break;
                case "boxrotate":
                    setBoxStartR(Number(rotatebox.current.style.transform.slice(7, -4)));
                    props.onChange({left: boxStartX, top: boxStartY }, {width: boxStartW, height: boxStartH }, Number(rotatebox.current.style.transform.slice(7, -4)), lineStart);
                    break;
                default:
                    break;
            }

            setMouseAction("none");
            console.log("stopping mouseaction");
        }
    }

    useEffect(() => {
        if (props.defaultboxStartLeft != undefined) setBoxStartX(props.defaultboxStartLeft);
        if (props.defaultboxStartTop != undefined) setBoxStartY(props.defaultboxStartTop);
        if (props.defaultboxStartWidth != undefined) setBoxStartW(props.defaultboxStartWidth);
        if (props.defaultboxStartHeight != undefined) setBoxStartH(props.defaultboxStartHeight);
    }, []);

    useEffect(() => {
        console.log(props);
        if (props.onLineMove != undefined) {
            if (props.direction != undefined && props.direction == "left") {
                props.onLineMove((line.current.style.left.slice(0, -2) / box.current.clientWidth));
            } else {
                props.onLineMove(1 - (line.current.style.left.slice(0, -2) / box.current.clientWidth));
            }
        }
        if (props.onBoxResize != undefined) {
            props.onBoxResize(boxStartH / boxStartW);
        }
    }, [props.direction]);

    function onMouseMove(evt) {
        switch (mouseAction) {
            case "linedrag":
                let newlinepos = movesplit(boxStartW, lineStart, boxStartR, boxDragStartX, boxDragStartY, evt.clientX, evt.clientY);
                line.current.style.left = newlinepos + "px";

                if (props.onLineMove != undefined) {
                    if (props.direction != undefined && props.direction == "left")
                        props.onLineMove((newlinepos / box.current.clientWidth));
                    else
                        props.onLineMove(1 - (newlinepos / box.current.clientWidth));
                }
                break;
            case "boxdrag":
                if (
                    boxStartX + (evt.clientX - boxDragStartX) > 0 - (rotatebox.current.offsetWidth / 2) &&
                    (boxStartX + (evt.clientX - boxDragStartX)) + (rotatebox.current.offsetWidth / 2) < hammer.current.offsetWidth - 0
                ) {
                    rotatebox.current.style.left = boxStartX + (evt.clientX - boxDragStartX) + "px";
                }
                if (
                    boxStartY + (evt.clientY - boxDragStartY) > 0 - (rotatebox.current.offsetHeight / 2) &&
                    (boxStartY + (evt.clientY - boxDragStartY)) + (rotatebox.current.offsetHeight / 2) < hammer.current.offsetHeight - 0
                ) {
                    rotatebox.current.style.top = boxStartY + (evt.clientY - boxDragStartY) + "px";
                }
                break;
            case "boxresize":
                let size = resize(boxStartW, boxStartH, boxStartR, boxDragStartX, boxDragStartY, evt.clientX, evt.clientY);

                box.current.style.width = size.w + "px";
                box.current.style.height = size.h + "px";
                rotatebox.current.style.width = size.w + "px";
                rotatebox.current.style.height = size.h + "px";

                if (line.current.style.left.slice(0, -2) > boxStartW + (evt.clientX - boxDragStartX) - 10) {
                    if ((boxStartW + (evt.clientX - boxDragStartX)) - 10 > 0) {
                        setLineStart((boxStartW + (evt.clientX - boxDragStartX)) - 10);
                        line.current.style.left = (boxStartW + (evt.clientX - boxDragStartX) - 10) + "px";
                    }
                }

                if (props.onLineMove != undefined) {
                    if (props.direction != undefined && props.direction == "left")
                        props.onLineMove(Number((line.current.style.left.slice(0, 2)) / (boxStartW + (evt.clientX - boxDragStartX))));
                    else
                        props.onLineMove(1 - (Number((line.current.style.left.slice(0, 2)) / (boxStartW + (evt.clientX - boxDragStartX)))));
                }
                
                if (props.onBoxResize != undefined) {
                    props.onBoxResize(size.h / size.w);
                }
                break;
            case "boxrotate":
                let rotation = rotate(boxStartW, boxStartH, boxStartR, boxDragStartX, boxDragStartY, evt.clientX, evt.clientY);

                rotatebox.current.style.transform = "rotate(" + rotation + "deg)";
                break;
            default:
                break;
        }
    }
    document.onpointermove = onMouseMove;
    document.onpointerup = stopMouseAction;

    return (
        <div className="imagemeasure" onMouseOver={() => initMouseControl()} style={{ width: "100%", height: "100%" }}>
            <div
                id="frame"
                ref={hammer}
                style={{
                    ...props.frameStyle,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain"
                }}
            >
                <div>
                    <div id="rotatebox" ref={rotatebox} style={{
                        position: "absolute",
                        left: props.defaultboxStartLeft != undefined ? props.defaultboxStartLeft : props.boxStartLeft != undefined ? props.boxStartLeft : "400px",
                        top: props.defaultboxStartTop != undefined ? props.defaultboxStartTop : props.boxStartTop != undefined ? props.boxStartTop : "200px",
                        height: props.defaultboxStartHeight != undefined ? props.defaultboxStartHeight : props.boxStartHeight != undefined ? props.boxStartHeight : "200px",
                        width: props.defaultboxStartWidth != undefined ? props.defaultboxStartWidth : props.boxStartWidth != undefined ? props.boxStartWidth : "200px",
                        transformOrigin: "center",
                        ...props.startrotation != undefined ? {
                            transform: "rotate(" + props.startrotation + "deg)"
                        } : {},
                        pointerEvents: "none"
                    }}>
                        <div id="box" ref={box} style={{
                            border: props.defaultrotation != undefined && props.defaultrotation == "90" ? "2px solid yellow" : "1px dashed gray",
                            ...props.direction != undefined ? props.direction == "left" ? {
                                // LEFT
                                borderLeft: "2px solid green",
                                borderRight: "2px solid red",
                            } : {
                                // RIGHT
                                borderLeft: "2px solid red",
                                borderRight: "2px solid green",
                            } : {},
                            height: "100%",
                            width: "100%",
                            pointerEvents: "none",
                            transformOrigin: "top left",
                            position: "absolute",
                            ...props.boxStyle != undefined ? props.boxStyle : {},
                        }}>
                            <div
                                className="btn boxmovebtn"
                                style={ usingMouse ? {
                                    ...btn,
                                    display: "block",
                                    top: "-18px",
                                    left: "-18px",
                                    touchAction: "none",
                                    ...props.buttonStyle != undefined ? props.buttonStyle : {},
                                    ...props.boxMoveButtonStyle != undefined ? props.boxMoveButtonStyle : {},
                                } : { display: "none" }}
                                onPointerDown={() => startBoxDrag()}
                            ><img src="/assets/images/imagemeasure_move.svg" alt="" style={{
                                pointerEvents: "none",
                                width: "16px",
                                marginTop: "-10px"
                            }}/></div>
                            <div
                                className="btn boxresizebtn"
                                style={ usingMouse ? {
                                    ...btn,
                                    display: "block",
                                    bottom: "-18px",
                                    right: "-18px",
                                    touchAction: "none",
                                    ...props.buttonStyle != undefined ? props.buttonStyle : {},
                                    ...props.resizeButtonStyle != undefined ? props.resizeButtonStyle : {},
                                } : { display: "none" }}
                                onPointerDown={(ev) => startBoxResize(ev)}
                            ><img src="/assets/images/imagemeasure_size.svg" alt="" style={{
                                pointerEvents: "none",
                                width: "16px",
                                marginTop: "-10px"
                            }}/></div>
                            <div
                                className="btn boxrotatebtn"
                                style={ usingMouse ? {
                                    ...btn,
                                    display: "block",
                                    top: "-18px",
                                    right: "-18px",
                                    touchAction: "none",
                                    ...props.buttonStyle != undefined ? props.buttonStyle : {},
                                    ...props.rotateButtonStyle != undefined ? props.rotateButtonStyle : {},
                                } : { display: "none" }}
                                onPointerDown={() => startBoxRotate()}
                            ><img src="/assets/images/imagemeasure_rotate.svg" alt="" style={{
                                pointerEvents: "none",
                                width: "16px",
                                marginTop: "-10px"
                            }}/></div>
                            <div id="line" ref={line} style={{
                                width: "0px",
                                height: "100%",
                                borderLeft: "2px solid blue",
                                position: "relative",
                                left: props.lineStart != undefined && props.lineStart != 0 ? props.lineStart + "px" : "50px",
                                top: "0px",
                            }}>
                                <div
                                    className="btn linemovebtn"
                                    style={ usingMouse ? {
                                        ...btn,
                                        display: "block",
                                        bottom: "-20px",
                                        left: "-10px",
                                        touchAction: "none",
                                        ...props.buttonStyle != undefined ? props.buttonStyle : {},
                                        ...props.lineMoveButtonStyle != undefined ? props.lineMoveButtonStyle : {},
                                    } : { display: "none" }}
                                    onPointerDown={() => startLineDrag()}
                                ><img src="/assets/images/imagemeasure_line.svg" alt="" style={{
                                    pointerEvents: "none",
                                    width: "16px",
                                    marginTop: "-10px"
                                }}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageMeasure;