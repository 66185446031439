import React, {useState} from "react";

import Typography from "@material-ui/core/Typography";
import SimpleSlider from "../../common/SimpleSlider";
import ExpansionParagraph from "../../common/ExpansionParagraph";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button, TextField } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { useTranslate } from 'react-polyglot';

function FeatureModal(props) {
  const t = useTranslate();

  const [showCertificate, setShowCertificate] = useState(true);
  const [latestRegistrationDate, setLatestRegistrationDate] = useState(new Date(parseInt(props.selectedRegistration.createdTs + "000")));
  const [categoryId, setCategoryId] = useState(props.selectedRegistration.selections[0].categoryId);

  const [openParagraph, setOpenParagraph] = useState(null);

  function stringReplacer(str) {
    str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), (props.animalAlias != undefined && props.animalAlias != "" ? props.animalAlias : props.animalName));
    str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
    str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
    str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
    return str;
  }

  return (
    <React.Fragment>
      <div className={"feature-modal-content"}>
        <Card classes={{ root: "certificate-card" }}>
          <CardContent classes={{ root: "certificate-card-content" }}>
            <div className="card-certificate-title-container">
              <img
                className="background"
                src="assets/images/certificate.svg"
                alt=""
              />
              <div className="card-certificate-title">
                <Typography style={{ fontWeight: "normal", color: "white" }}>
                  {t("mydog.featuremodal.certificate")}
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    {props.animalName}
                  </span>
                  <br />
                  <br />
                  {latestRegistrationDate.toISOString().split("T")[0].split("-")[2] + "." +
                    latestRegistrationDate.toISOString().split("T")[0].split("-")[1] + "." +
                    latestRegistrationDate.toISOString().split("T")[0].split("-")[0]}

                </Typography>
              </div>
              <div className="certificate-image-container">
                <img
                  src="assets/images/sloyfe.svg"
                  alt=""
                />
                <img
                  src={props.profilePicture}
                  alt=""
                  className="certificate-image"
                />
              </div>
            </div>
            <div className="certificate-bottom-container">
              <Typography>{props.questionnaireName}</Typography>
            </div>
          </CardContent>
        </Card>

        <div className="certificate-shadow-card-mobile" style={{
          height: "auto",
          maxHeight: showCertificate ? "10000px" : "200px",
          position: "relative",
          top: "-180px",
          paddingTop: "200px",
          display: "block",
          overflow: "hidden",
          maxWidth: showCertificate ? "468px" : "330px",
          overflow: "hidden",
          margin: "auto",
          left: "-10px",
          zIndex: "5"
        }}>
          {props.selectedRegistration.selections.map((item, i) => {

            if (item.technicalTypeId == 1) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  disabled={
                    (item.value != undefined && JSON.parse(item.value) != null) &&
                    (JSON.parse(item.value).traitPicture == null && JSON.parse(item.value).sliderPicture == null)
                  }
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).traitPicture != null || JSON.parse(item.value).sliderPicture != null) ?
                        <div>
                          <Typography gutterBottom variant="body1">
                            {t("mydog.featuremodal.description")}
                          </Typography>
                          <SimpleSlider
                            sliderArray={[
                              JSON.parse(item.value).sliderPicture != null && JSON.parse(item.value).sliderPicture != "" ? {
                                id: item.traitId.toString(),
                                value: item.traitId.toString(),
                                sliderPicture: JSON.parse(item.value).sliderPicture,
                                children: [],
                                buttonName: ""
                              } : null,
                              JSON.parse(item.value).traitPicture != null ? {
                                id: item.traitId.toString(),
                                value: item.traitId.toString(),
                                sliderPicture: JSON.parse(item.value).traitPicture,
                                children: [],
                                buttonName: ""
                              } : null
                            ]}
                            selectorButton={false}
                          >
                          </SimpleSlider>
                        </div>
                        :
                        <Typography gutterBottom variant="body1">
                          {t("mydog.featuremodal.nomatchedselection")}
                        </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 2) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(JSON.parse(item.value).traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {JSON.parse(item.value).traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).sliderValue != 0) ?
                          (!isNaN(JSON.parse(item.value).sliderValue)) ?
                            <div>
                              <Slider
                                classes={{ root: "summary-slider-line", thumb: "summary-slider-thumb" }}
                                //style={{ marginTop: '15%', color: '#52af77' }}
                                disabled
                                value={JSON.parse(item.value).sliderValue}
                                aria-labelledby="discrete-slider-small-steps"
                                min={JSON.parse(item.value).minVal}
                                max={JSON.parse(item.value).maxVal}
                                valueLabelDisplay={JSON.parse(item.value).maxVal > 50 ? "off" : "auto"}
                              />
                            </div>
                          :
                            <Typography gutterBottom variant="body1" style={{
                              //textAlign: "center",
                              fontStyle: "italic",
                              fontWeight: 400,
                              marginTop: "20px"
                            }}>
                              {t("mydog.featuremodal.notpossible")}
                            </Typography>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                        </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 3) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).measurementValue != 0) ?
                        <TextField
                          style={{ width: "80%" }}
                          label={t("mydog.measureincm")}
                          type="Number"
                          value={JSON.parse(item.value).measurementValue}
                          disabled
                          margin="normal"
                          variant="outlined"
                        />
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                      </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 4) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  disabled={
                    (item.value != undefined && JSON.parse(item.value) != null) &&
                    (JSON.parse(item.value).capture == null && JSON.parse(item.value).capture == null)
                  }
                  children={
                    <div style={{ width: "100%" }}>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).capture != null) ?
                        <div className="proportionsfeaturecontainer">
                          <Typography gutterBottom variant="body1">
                            {JSON.parse(item.value).proportionType != undefined && JSON.parse(item.value).proportionType == 90 ?
                              t("mydog.featuremodal.featurecontainer.description1") + JSON.parse(item.value).partialPercentage.toFixed(1) + "%"
                            :
                              JSON.parse(item.value).direction == "left" ?
                              t("mydog.featuremodal.featurecontainer.description2") + JSON.parse(item.value).partialPercentage.toFixed(1) + "%"
                              :
                                t("mydog.featuremodal.featurecontainer.description2") + (100 - JSON.parse(item.value).partialPercentage).toFixed(1) + "%"
                            }
                          </Typography>
                          <Typography gutterBottom variant="body1">
                            {JSON.parse(item.value).proportionType != undefined && JSON.parse(item.value).proportionType == 90 ?
                              t("mydog.featuremodal.featurecontainer.length") + JSON.parse(item.value).headProportion.toFixed(2)
                            :
                              ""
                            }
                          </Typography>
                          <Typography gutterBottom variant="body1">
                            {t("mydog.featuremodal.featurecontainer.description3")}
                          </Typography>
                          <hr/>
                          <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                            {t("mydog.featuremodal.answertitle")}
                          </Typography>
                          <SimpleSlider
                            sliderArray={[
                              JSON.parse(item.value).capture != null && JSON.parse(item.value).capture != "" ? {
                                id: item.traitId.toString(),
                                value: item.traitId.toString(),
                                sliderPicture: JSON.parse(item.value).capture,
                                children: [],
                                buttonName: ""
                              } : null
                            ]}
                            selectorButton={false}
                          >
                          </SimpleSlider>
                        </div>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                            </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 5) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  disabled={
                    (item.value != undefined && JSON.parse(item.value) != null) &&
                    (JSON.parse(item.value).options == null || JSON.parse(item.value).options.every(k => k == ""))
                  }
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {(item.value != undefined && item.value != null && item.value != "null" && JSON.parse(item.value).options != undefined && !JSON.parse(item.value).options.every(k => k == "")) ?
                        <div>
                          <ul style={{
                            color: "#382e92",
                            marginLeft: "20px"
                          }}>
                            {JSON.parse(item.value).options.map((option, optionkey) => 
                              <li key={optionkey}>{stringReplacer(option)}</li>
                            )}
                          </ul>
                        </div>
                      :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                        </Typography>
                      }
                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 6) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(JSON.parse(item.value).traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {JSON.parse(item.value).traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).sliderValue != 0) ?
                        <div>
                          <Slider
                            classes={{ root: "summary-slider-line", thumb: "summary-slider-thumb" }}
                            style={{ marginTop: '15%', color: '#52af77' }}
                            disabled
                            value={JSON.parse(item.value).sliderValue}
                            aria-labelledby="discrete-slider-always"
                            min={JSON.parse(item.value).minVal}
                            max={JSON.parse(item.value).maxVal}
                            valueLabelDisplay="on"
                          />
                        </div>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                          </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 7) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {(item.value != undefined && JSON.parse(item.value) != null) ?
                        <div>
                          {(JSON.parse(item.value).yearValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="År"
                              type="Number"
                              value={JSON.parse(item.value).yearValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).monthValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Måneder"
                              type="Number"
                              value={JSON.parse(item.value).monthValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).weekValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Uker"
                              type="Number"
                              value={JSON.parse(item.value).weekValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).dayValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Dager"
                              type="Number"
                              value={JSON.parse(item.value).dayValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).hourValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Timer"
                              type="Number"
                              value={JSON.parse(item.value).hourValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).minuteValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Minutter"
                              type="Number"
                              value={JSON.parse(item.value).minuteValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          {(JSON.parse(item.value).secondValue != 0) ?
                            <TextField
                              style={{ width: "80%" }}
                              label="Sekunder"
                              type="Number"
                              value={JSON.parse(item.value).secondValue}
                              disabled
                              margin="normal"
                              variant="outlined"
                            />
                          : ""}
                          </div>
                        :
                          <Typography gutterBottom variant="body1" style={{
                            //textAlign: "center",
                            fontStyle: "italic",
                            fontWeight: 400,
                            marginTop: "20px"
                          }}>
                            {t("mydog.featuremodal.nomatchedselection")}
                          </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 8) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  disabled={
                    (item.value != undefined && JSON.parse(item.value) != null) &&
                    (JSON.parse(item.value).option == null || JSON.parse(item.value).option == "")
                  }
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {(item.value != undefined && JSON.parse(item.value) != undefined && JSON.parse(item.value).option != "") ?
                        <div>
                          <ul style={{
                            color: "#382e92",
                            marginLeft: "20px"
                          }}>
                            <li>
                              <span dangerouslySetInnerHTML={{__html: stringReplacer(JSON.parse(item.value).option.replaceAll("\\n", "<br/>"))}}></span>
                            </li>
                          </ul>
                        </div>
                      :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                        </Typography>
                      }
                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 9) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).text != undefined) ?
                          <Typography gutterBottom variant="body1" style={{fontWeight: 400}}>
                            {stringReplacer(JSON.parse(item.value).text)}
                          </Typography>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                      </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 10) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).date != undefined) ?
                          <Typography gutterBottom variant="body1">
                            {JSON.parse(item.value).date}
                          </Typography>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                      </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 12) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).option != undefined) ?
                          <Typography gutterBottom variant="body1" style={{fontWeight: 400}}>
                            {stringReplacer(JSON.parse(item.value).option)}
                          </Typography>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                      </Typography>
                      }

                    </div>
                  }
                />
              );
            }

            if (item.technicalTypeId == 13) {
              return (
                <ExpansionParagraph
                  onArrowClick={() => {
                    setOpenParagraph(openParagraph == i ? null : i);
                  }}
                  open={openParagraph == i}
                  title={item.traitName}
                  shouldChangeColor={true}
                  key={i}
                  children={
                    <div style={{ width: "100%" }}>
                      <Typography gutterBottom variant="body1">
                        <span dangerouslySetInnerHTML={{__html: stringReplacer(item.traitDescription.replaceAll("\\n", "<br/>"))}}></span>
                      </Typography>
                      {item.traitDescription != "" ? <hr/> : ""}
                      <Typography gutterBottom variant="body1" style={{fontWeight: "600"}}>
                        {t("mydog.featuremodal.answertitle")}
                      </Typography>
                      {
                        (item.value != undefined && JSON.parse(item.value) != null) &&
                        (JSON.parse(item.value).date != undefined) ?
                          <Typography gutterBottom variant="body1">
                            {JSON.parse(item.value).date}
                          </Typography>
                        :
                        <Typography gutterBottom variant="body1" style={{
                          //textAlign: "center",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "20px"
                        }}>
                          {t("mydog.featuremodal.nomatchedselection")}
                      </Typography>
                      }

                    </div>
                  }
                />
              );
            }

          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default FeatureModal;