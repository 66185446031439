import React from "react";

import FeatureWizPage from "./WizPageTypes/FeatureWizPage";
import SliderWizPage from "./WizPageTypes/SliderWizPage";
import ImageSliderWizPage from "./WizPageTypes/ImageSliderWizPage";
import MeasureWizPage from "./WizPageTypes/MeasureWizPage";
import ProportionWizPage from "./WizPageTypes/ProportionWizPage";
import CheckboxesWizPage from "./WizPageTypes/CheckboxesWizPage";
import DurationWizPage from "./WizPageTypes/DurationWizPage";
import RadioWizPage from "./WizPageTypes/RadioWizPage";
import FreetextWizPage from "./WizPageTypes/FreetextWizPage";
import DateWizPage from "./WizPageTypes/DateWizPage";
import DummytextWizPage from "./WizPageTypes/DummytextWizPage";
import DropdownWizPage from "./WizPageTypes/DropdownWizPage";
import DateDropdownWizPage from "./WizPageTypes/DateDropdownWizPage";
import SoundvideoWizPage from "./WizPageTypes/SoundvideoWizPage";

function WizPage(props) {
    //const [x, setX] = useState(0);
    if (props.trait != undefined) {
        switch(props.trait.type) {
            case "IMG_SELECT":
                return (
                    <FeatureWizPage
                      trait={props.trait}
                      currentPageNumber={props.currentPageNumber}
                      animalName={props.animalName}
                      animalGender={props.animalGender}
                      totalNumberOfPages={props.totalNumberOfPages}
                      display={props.display}
                      pageUpdatedCallback={props.pageUpdatedCallback}
                    />
                );
            case "SLIDER":
                return (
                    <SliderWizPage
                        trait={props.trait}
                        currentPageNumber={props.currentPageNumber}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        totalNumberOfPages={props.totalNumberOfPages}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                    />
                );
            case "MEASUREMENT":
                return (
                    <MeasureWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        updatePage={props.updatePage}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                    />
                );
            case "SLIDING_SQUARES":
                return (
                    <ProportionWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "CHECKBOXES":
                return (
                    <CheckboxesWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "IMAGESLIDER":
                return (
                    <ImageSliderWizPage
                        trait={props.trait}
                        currentPageNumber={props.currentPageNumber}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        totalNumberOfPages={props.totalNumberOfPages}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                    />
                );
            case "DURATION":
                return (
                    <DurationWizPage
                        trait={props.trait}
                        currentPageNumber={props.currentPageNumber}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        totalNumberOfPages={props.totalNumberOfPages}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                    />
                );
            case "RADIO":
                return (
                    <RadioWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "FREETEXT":
                return (
                    <FreetextWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "DATE":
                return (
                    <DateWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "DUMMYTEXT":
                return (
                    <DummytextWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "DROPDOWN":
                return (
                    <DropdownWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "DATEDROPDOWN":
                return (
                    <DateDropdownWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            case "SOUNDVIDEO":
                return (
                    <SoundvideoWizPage
                        trait={props.trait}
                        animalName={props.animalName}
                        animalGender={props.animalGender}
                        display={props.display}
                        pageUpdatedCallback={props.pageUpdatedCallback}
                        currentPageNumber={props.currentPageNumber}
                    />
                );
            default:
                return(
                    <div></div>
                );
        }
    } else {
        return (null);
    }
}

export default WizPage;