import React from "react";
import PropTypes from "prop-types";
import Divider from "../../common/Divider";

const itemStyles = {
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    height: "338px",
    padding: "10px"
}

const imageStyles = {
    width: "100%",
    height: "100%",
    objectFit: "cover"
}

const textAreaStyles = {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.3px",
    color: "var(--blueberry)",
    position: "relative",
    top: "-18px",
    width: "308px",
}

const imgboxStyles = {
    borderRadius: "10px",
    overflow: "hidden",
    width: "308px",
    height: "274px",
    position: "relative",
    top: "-30px",
    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ececec",
}

class GridItem extends React.Component {
    state = {
        redirect: false
    }

    render() {
        return (
            <div style={ itemStyles }>
                <div style={ imgboxStyles }>
                    <img style={ imageStyles } src={ this.props.imageSrc } alt=""/>
                </div>
                <div style={ textAreaStyles }>
                    <p style={{
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "block",
                        textOverflow: "ellipsis"
                    }}>{ this.props.line1 }</p>
                    <Divider />
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>

                        <p style={{
                            marginTop: "8px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "block",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "1.43",
                            letterSpacing: "0.5px"
                        }}>{ this.props.line2 }</p>
                        <p style={{
                            marginTop: "8px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "block",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "1.43",
                            textAlign: "right",
                            letterSpacing: "0.5px",

                            
                        }}>{ this.props.line3 }</p>
                        
                    </div>
                   
                </div>
            </div>
        );
    }
}

GridItem.propTypes = {
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    imageSrc: PropTypes.string
};

export default GridItem;
