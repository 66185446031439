export const blueberry = {
  50: "#e7e6f2",
  100: "#c3c0de",
  200: "#9c97c9",
  300: "#746db3",
  400: "#564da2",
  500: "#382e92",
  600: "#32298a",
  700: "#2b237f",
  800: "#241d75",
  900: "#171263",
  A100: "#9f9aff",
  A200: "#6f67ff",
  A400: "#3f34ff",
  A700: "#271aff",
  contrastDefaultColor: "light"
};
