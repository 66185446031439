import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import ExpansionParagraph from "./ExpansionParagraph";
import LocaleHandler from '../../LocaleHandler';
import Auth from "../../Auth";
import { Divider } from "@material-ui/core";

function DogDetails(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const { locale } = React.useContext(LocaleHandler);

  const [breedWiz, setBreedWiz] = useState(null);

  function getWiz(){
    let language = localStorage.getItem("language");
    _Auth.fetch("/rest/breedwiz/dynamic/" + props.breedId + (language != undefined ? ("?lang=" + language) : "")).then(response => response.json()).then(data => {
      setBreedWiz(data);
    })
    .catch(error => {
      console.error("Error:", error);
    });
  }

  useEffect(() => {
    getWiz();
  }, []);

  useEffect(() => {
    getWiz();
  }, [locale]);

  return(
    <Card classes={{ root: "card-details" }}>
      <CardContent classes={{ root: "card-content" }}>
        <ExpansionParagraph
          open={props.open}
          title={props.name}
          shouldChangeColor={false}
          children={
            <div className="card-content-container">
              <div className="dog-details-container">
                <div className="dog-details-row">
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("dogdetails.race")}
                  </Typography>
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("breeds.list." + props.breedId)}
                  </Typography>
                </div>
                <div className="dog-details-row">
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("dogdetails.birthday")}
                  </Typography>
                  <Typography classes={{ root: "dog-details-title" }}>
                    {props.age.split("T")[0].split("-")[2] + "." + props.age.split("T")[0].split("-")[1] + "." + props.age.split("T")[0].split("-")[0]}
                  </Typography>
                </div>
                <div className="dog-details-row">
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("dogdetails.sex")}
                  </Typography>
                  <Typography classes={{ root: "dog-details-title" }}>
                    {props.gender == "true" ? t("mydog.gender.ranges.male") : t("mydog.gender.ranges.female")}
                  </Typography>
                </div>
                <div className="dog-details-row">
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("dogdetails.weight")}
                  </Typography>
                  <Typography classes={{ root: "dog-details-title" }}>
                    {props.weight}
                  </Typography>
                </div>
                <div className="dog-details-row">
                  <Typography classes={{ root: "dog-details-title" }}>
                    {t("dogdetails.registrationid")}
                  </Typography>
                  <Typography classes={{ root: "dog-details-title" }}>
                    {props.animalId}
                  </Typography>
                </div>
              </div>
              <div>
                <div className="row" style={{width: "100%", paddingTop: "20px", marginRight: "0px", marginLeft: "0px"}}>
                  <div className="col-sm-6">
                    <div className="feature-button-small" onClick={() => history.push("/statistics/weight", { dbId: props.id, breedId: props.breedId })}>
                      <img src="/assets/images/icon_statistikk.svg" alt="" />
                      <h1>{t("dogdetails.statistics")}</h1>
                      <p>{t("dogdetails.gotostatisticts")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-button-small" onClick={() => history.push("/mydog", { dbId: props.id })}>
                      <img src="/assets/images/icon_profil.svg" alt="" />
                      <h1>{t("dogdetails.profilenweight")}</h1>
                      <p>{t("dogdetails.openprofile")}</p>
                    </div>
                  </div>
                </div>
                <Typography
                  variant="body1"
                  gutterBottom
                  className="discount"
                  onClick={() => history.push("/mysettings")}
                >
                  {t("dogdetails.gjensidigediscount")}
                </Typography>
                <Typography classes={{ root: "dog-details-questionnaires-title" }}>
                  {t("dogdetails.questionnaires")}
                </Typography>
                <div className="row" style={{width: "100%", paddingTop: "20px", marginRight: "0px", marginLeft: "0px"}}>
                  {breedWiz != null ? breedWiz.categories.map((category, key) => 
                    <div key={key} className="col-sm-4">
                      <div className="feature-button-large" onClick={(evt) => {
                        history.push("/dogfeatureregistration?dogId=" + props.id + "&breedId=" + props.breedId, {
                          featuresCategory: category.categoryId,
                          dbId: props.id,
                          breedId: props.breedId
                        });
                      }}>
                        { category.highlight != undefined && category.highlight != "" ?
                          <div className="highlightcontainer">
                            <span class="material-symbols-outlined">
                              {category.highlight}
                            </span>
                          </div>
                        : null}
                        <span class="material-symbols-outlined icon">
                          {category.icon != undefined ? category.icon : "assignment"}
                        </span>
                        <h1>{category.name}</h1>
                        <p>{t("dogdetails.register")} {category.name}</p>
                      </div>
                    </div>
                  ): null}
                </div>
              </div>
            </div>
          }
        />
      </CardContent>
    </Card>
    );
}

export default DogDetails;
