function MapAnswers(answerId, answers) {
    let mappedAnswers = [];
    switch (answerId) {
        case "D1":
            for (let answer of answers) {
                switch (answer) {
			        case "Avlet av nåværende eier":
                    case "Uppfödd av nuvarande ägare":
                    case "Bred by current owner":
                        mappedAnswers.push("CurOwner");
                        break;
            
                    case "Fra en omplasserings-organisasjon":
                    case "Kommer från härbärge eller annan omplaceringsorganisation":
                    case "Obtained from shelter or other re-homing organisation":
                        mappedAnswers.push("Shelter");
                        break;
                    
                    case "Direkte fra oppdretteren":
                    case "Kommer direkt från uppfödare":
                    case "Obtained directly from breeder":
                        mappedAnswers.push("Breeder");
                        break;
            
                    case "Fra en tidligere eier (ikke oppdretteren)":
                    case "Kommer från en tidigare ägare (inte uppfödaren)":
                    case "Obtained from a previous owner (not the breeder)":
                        mappedAnswers.push("PrevOwner");
                        break;

                    default:
                        break;
                }
            }
            break;
        case "D2":
            for (let answer of answers) {
                mappedAnswers.push(JSON.parse(answer.value).date);
            }
            break;
        case "D12":
            for (let answer of answers) {
                switch (answer) {
                    case "Family dog/companion":
					case "Familiehund/turkamerat":
					case "Familjehund/kamrat":
                        mappedAnswers.push("D12.1");
                        break;
						
					case "Participant in dog shows":
					case "Deltager i hundeutstillinger":
					case "Deltagare i hundutställningar":
                        mappedAnswers.push("D12.2");
                        break;
						
					case "Breeding stock":
					case "Avl":
					case "Avelsdjur":
                        mappedAnswers.push("D12.3");
                        break;
	
					case "Working dog (hunting, sledding, herding, etc.)":
					case "Brukshund (jakt, trekk, gjeting, osv.)":
					case "Brukshund (jakt, drag, vall, osv.)":
                        mappedAnswers.push("D12.4");
                        break;
						
					case "Service dog (police dog, guide dog, rescue dog, animal-assisted intervention, etc.)":
					case "Tjenestehund (politihund, førerhund, redningshund, dyreassisterte intervensjoner, osv.)":
					case "Tjänstehund (polishund, ledarhund, räddningshund, djurassisterad intervention, osv.)":
                        mappedAnswers.push("D12.5");
                        break;
						
					case "Participant in sport/hobby events (agility, tracking, obedience, etc.)":
					case "Deltager i sport/hobby arrangement (agility, spor, lydighet, osv.)":
					case "Deltagare i sport/hobbyevenemang (agility, spårning, lydnad, osv.)":
                        mappedAnswers.push("D12.6");
                        break;
	
					case "Not yet determined":
					case "Ikke bestemt enda":
					case "Ännu ej bestämt":
                        mappedAnswers.push("D12.7");
                        break;

                    default:
                        break;
                }
            }
            break;
        case "S1":
            for (let answer of answers) {
                switch (answer) {
                    case "Drektig eller lakterende tispe|true":
                    case "Dräktig eller digivande tik|true":
                    case "Pregnant or lactating female|true":
                        mappedAnswers.push("PregLactMale");
                        break;
            
                    case "Kirurgisk kastrert/sterilisert|true":
                    case "Kirurgiskt kastrerad/steriliserad|true":
                    case "Surgically neutered/spayed|true":
                        mappedAnswers.push("SurgicalMale");
                        break;
                    
                    case "Kjemisk kastrert/sterilisert|true":
                    case "Kemiskt kastrerad/steriliserad|true":
                    case "Chemically neutered/spayed|true":
                        mappedAnswers.push("ChemicalMale");
                        break;
            
                    case "Intakt|true":
                    case "Intact|true":
                        mappedAnswers.push("IntactMale");
                        break;

                    case "Drektig eller lakterende tispe|false":
                    case "Dräktig eller digivande tik|false":
                    case "Pregnant or lactating female|false":
                        mappedAnswers.push("PregLactFemale");
                        break;
            
                    case "Kirurgisk kastrert/sterilisert|false":
                    case "Kirurgiskt kastrerad/steriliserad|false":
                    case "Surgically neutered/spayed|false":
                        mappedAnswers.push("SurgicalFemale");
                        break;
                    
                    case "Kjemisk kastrert/sterilisert|false":
                    case "Kemiskt kastrerad/steriliserad|false":
                    case "Chemically neutered/spayed|false":
                        mappedAnswers.push("ChemicalFemale");
                        break;
            
                    case "Intakt|false":
                    case "Intact|false":
                        mappedAnswers.push("IntactFemale");
                        break;

                    default:
                        break;
                }
            }
            break;
        case "S2":
            for (let answer of answers) {
                switch (answer) {
                    case "Intakt|true":
                    case "Intact|true":
                        mappedAnswers.push("IntactMale");
                        break;

                    case "Kastrert/sterilisert for å unngå uønsket drektighet|true":
                    case "Kastrerad/steriliserad för att undvika oönskad graviditet|true":
                    case "Neutered/spayed to avoid unwanted pregnancies|true":
                        mappedAnswers.push("UnwantedPregnanciesMale");
                        break;
                    
                    case "Kastrert/sterilisert på grunn av helserelaterte bekymringer|true":
                    case "Kastrerad/steriliserad på grund av hälsorelaterade bekymmer|true":
                    case "Neutered/spayed due to health-related concerns|true":
                        mappedAnswers.push("HealthMale");
                        break;
            
                    case "Kastrert/sterilisert på grunn av adferdsmessige bekymringer|true":
                    case "Kastrerad/steriliserad på grund av beteende bekymmer|true":
                    case "Neutered/spayed due to behavioural concerns|true":
                        mappedAnswers.push("BehaviourMale");
                        break;
                    
                    case "Kastrert/sterilisert på grunn av andre årsaker|true":
                    case "Kastrerad/steriliserad på grund av andra skäl|true":
                    case "Neutered/spayed for other reasons|true":
                        mappedAnswers.push("OtherMale");
                        break;
            
                    case "Vet ikke|true":
                    case "Vet inte|true":
                    case "Don’t know|true":
                        mappedAnswers.push("DKMale");
                        break;


                        
                    case "Intakt|false":
                    case "Intact|false":
                        mappedAnswers.push("IntactFemale");
                        break;

                    case "Kastrert/sterilisert for å unngå uønsket drektighet|false":
                    case "Kastrerad/steriliserad för att undvika oönskad graviditet|false":
                    case "Neutered/spayed to avoid unwanted pregnancies|false":
                        mappedAnswers.push("UnwantedPregnanciesFemale");
                        break;
                    
                    case "Kastrert/sterilisert på grunn av helserelaterte bekymringer|false":
                    case "Kastrerad/steriliserad på grund av hälsorelaterade bekymmer|false":
                    case "Neutered/spayed due to health-related concerns|false":
                        mappedAnswers.push("HealthFemale");
                        break;
            
                    case "Kastrert/sterilisert på grunn av adferdsmessige bekymringer|false":
                    case "Kastrerad/steriliserad på grund av beteende bekymmer|false":
                    case "Neutered/spayed due to behavioural concerns|false":
                        mappedAnswers.push("BehaviourFemale");
                        break;
                    
                    case "Kastrert/sterilisert på grunn av andre årsaker|false":
                    case "Kastrerad/steriliserad på grund av andra skäl|false":
                    case "Neutered/spayed for other reasons|false":
                        mappedAnswers.push("OtherFemale");
                        break;
            
                    case "Vet ikke|false":
                    case "Vet inte|false":
                    case "Don’t know|false":
                        mappedAnswers.push("DKFemale");
                        break;

                    default:
                        break;
                }
            }
            break;
        case "S45":
        case "E5":
        case "E6":
        case "E42":
        case "W11":
        case "W15":
        case "W18":
            for (let answer of answers) {
                switch (answer.split("|")[0]) {
                    case "":
                        break;
                    default:
                        mappedAnswers.push(answer);
                }
            }
            break;

        default:
            break;
    }
    return mappedAnswers;
}

export default MapAnswers;