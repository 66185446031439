import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavBar from "../../common/Navbar";
import Stepper from "../../common/Stepper";
import WizGroup from "../../common/RegistrationWizard/WizGroup";
import WizFinishPage from "../../common/RegistrationWizard/WizFinishPage";
import RegistrationWizInfoPage from "../../common/RegistrationWizard/RegistrationWizInfoPage";

import { Typography } from '@material-ui/core';
import { Prompt } from 'react-router';
import { useTranslate } from 'react-polyglot';
import Select from 'react-select';
import LocaleHandler from '../../../LocaleHandler';

import Auth from "../../../Auth";

import "./DogRegistrationWizard.scss";

const header = [
  navigator.platform,
  navigator.userAgent,
  navigator.appVersion,
  navigator.vendor,
  window.opera
];

const browser = [
  { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
  { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
  { name: 'Safari', value: 'Safari', version: 'Version' },
  { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
  { name: 'Opera', value: 'Opera', version: 'Opera' },
  { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
  { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
]

const os = [
  { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
  { name: 'Windows', value: 'Win', version: 'NT' },
  { name: 'iPhone', value: 'iPhone', version: 'OS' },
  { name: 'iPad', value: 'iPad', version: 'OS' },
  { name: 'Kindle', value: 'Silk', version: 'Silk' },
  { name: 'Android', value: 'Android', version: 'Android' },
  { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
  { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
  { name: 'Macintosh', value: 'Mac', version: 'OS X' },
  { name: 'Linux', value: 'Linux', version: 'rv' },
  { name: 'Palm', value: 'Palm', version: 'PalmOS' }
]

function matchItem(string, data) {
  let i = 0,
      j = 0,
      html = '',
      regex,
      regexv,
      match,
      matches,
      version;
  
  for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                  if (j === 0) {
                      version += matches[j] + '.';
                  } else {
                      version += matches[j];
                  }
              }
          } else {
              version = '0';
          }
          return {
              name: data[i].name,
              version: parseFloat(version)
          };
      }
  }
  return { name: 'unknown', version: 0 };
}

function DogRegistrationWizard(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const { locale } = React.useContext(LocaleHandler);
  
  const dogId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("dogId=") !== -1).split("=")[1];
  const categoryId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1) != undefined ? window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1] : "";
  const t = useTranslate();

  const [breedWiz, setBreedWiz] = useState({});
  const [breedWizCategory, setBreedWizCategory] = useState({});
  const [breedWizId, setBreedWizId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [typeId, setTypeId] = useState("");
  const [loadingError, setLoadingError] = useState(false);
  const [visiblePage, setVisiblePage] = useState(0);
  const [edited, setEdited] = useState(false);
  const [changes, setChanges] = useState(false);
  const [infoVisible, setInfoVisible] = useState(true);
  const [dog, setDog] = useState();

  const [lastStep, setLastStep] = useState(0);

  const [language, setLanguage] = useState(localStorage.getItem("language") != undefined ? localStorage.getItem("language") : "no");
  const [languageOptions, setLanguageOptions] = useState([
    {label: "Norwegian (Norsk)", value: "no"},
    {label: "Swedish (Svenska)", value: "se"},
    {label: "English (English)", value: "en"},
  ]);
  
  useEffect(() => {
    getInfo();
    console.log(window.WURFL);
  }, [])

  useEffect(() => {
    getInfo();
  }, [window.location.href])

  useEffect(() => {
    setLanguage(locale);
  }, [locale])

  const wizardPageUpdated = (traitData) => {
    let tempBreedWizCategory = breedWizCategory;
    let traitFound = getWizardFromBreedWizCategory(tempBreedWizCategory).traits.find(item => item.traitId == traitData.traitId);
    if (traitFound != undefined) {
      traitFound.value = traitData.value;
      setBreedWizCategory(JSON.parse(JSON.stringify(tempBreedWizCategory)));
      setEdited(true);
      setChanges(true);
    }
  }

  function getInfo(lang = language) {
    let breedId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("breedId=") !== -1).split("=")[1];
    _Auth.fetch("/rest/breedwiz/dynamic/" + breedId + "?lang=" + lang).then(response => response.json()).then(data => {
      let existingId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("registrationId=") !== -1) != undefined ? window.location.search.split("?")[1].split("&").find(item => item.indexOf("registrationId=") !== -1).split("=")[1] : "";
      
      if (existingId != undefined && existingId != "") {
        _Auth.fetch("/rest/breedwiz/byId/" + existingId).then(response => response.json()).then(data2 => {
          if (data2 != undefined && data2["selections"] != undefined) {
            let tempBreedWizCategory = data.categories.find(category => category.categoryId == window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1]);
            let wizard = getWizardFromBreedWizCategory(tempBreedWizCategory, data2["selections"][0]["categoryId"]);
            
            data2["selections"].forEach(element => {
              let traitFound = wizard.traits.find(item => item.traitId == element.traitId);
              if (traitFound != undefined) {
                traitFound.value = element.value;
              }
            });

            setBreedWizCategory(JSON.parse(JSON.stringify(tempBreedWizCategory)));
            
            setEdited(true);
            setLoading(false);
            
            setBreedWizId(existingId);
            setTypeId(data2["selections"][0]["categoryId"]);
            setInfoVisible(false);
          }
        })
      } else {
        setBreedWiz(data);
        setBreedWizCategory(data.categories.find(category => category.categoryId == (props.location.state != undefined ? props.location.state.featuresCategory : window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1])));
        setLoading(false);
      }
    }).catch(error => {
      console.error("Error:", error);
      setLoadingError(true);
    });
  }

  function getDog() {
    _Auth.fetch("/rest/dogs/id/" + dogId).then(response => response.json()).then(data => {
      if (data != undefined) {
        setDog(data);
      }
    })
  }

  function getWizardFromBreedWizCategory(inputBreedWizCategory, type = typeId) {
    let subcategory = inputBreedWizCategory.dynSubCategories.find(subCategory => subCategory.dynWizards.some(wiz => wiz.wizardId == type));
    return subcategory.dynWizards.find(wiz => wiz.wizardId == type);
  }

  function getIncompleteSteps() {
    let incompleteSteps = [];
    
    let questions = getWizardFromBreedWizCategory(breedWizCategory).traits.filter(i => i.technicalTypeId != 11 && i.technicalTypeId != 9);
    let groupings = getWizardFromBreedWizCategory(breedWizCategory).groupings;

    for (let question of questions) {
      if (
        question.value == null ||
        question.value == undefined ||
        question.value.sliderValue === "" ||
        question.value.sliderValue === null
      ) {
        for (let i = 0; i < groupings.length; i++) {
          if (groupings[i].questions.includes(question.traitId)) {
            incompleteSteps.push(i + 1);
          }
        }
      }
    }

    return incompleteSteps;
  }

  function stepperChanged(value) {
    if (value == getWizardFromBreedWizCategory(breedWizCategory).groupings.length) {
      let incompleteSteps = [];
    
      let questions = getWizardFromBreedWizCategory(breedWizCategory).traits.filter(i => i.technicalTypeId != 11 && i.technicalTypeId != 9);
      let groupings = getWizardFromBreedWizCategory(breedWizCategory).groupings;

      for (let question of questions) {
        if (
          question.value == null ||
          question.value == undefined ||
          question.value.sliderValue === "" ||
          question.value.sliderValue === null
        ) {
          for (let i = 0; i < groupings.length; i++) {
            if (groupings[i].questions.includes(question.traitId)) {
              incompleteSteps.push(i + 1);
            }
          }
        }
      }

      let hasUnanswered = false;

      if (incompleteSteps.length > 0) {
        hasUnanswered = true;
      }

      if (hasUnanswered) {
        let answer = window.confirm(t("registerfeatures.prompt.unanswered_questions"));
        if (!answer) {
          setLastStep(lastStep + 1);
          return;
        } else {
          saveTraits(true);
          setVisiblePage(value);
          setTimeout(() => {
              window.scrollTo(0, 0);
          }, 300)
        }
      } else {
        saveTraits(true);
        setVisiblePage(value);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300)
      }
    } else {
      saveTraits(false);
      setVisiblePage(value);
      setTimeout(() => {
          window.scrollTo(0, 0);
      }, 300)
    }
  }

  function onCloseInfo() {
    setInfoVisible(false);
  }

  function completeClick() {
    
  }

  function saveTraits(completed = false) {
    let device = window.WURFL != undefined ? window.WURFL.complete_device_name : "unknown";
    let form = window.WURFL != undefined ? window.WURFL.form_factor : "unknown";
    let mobile = window.WURFL != undefined ? window.WURFL.is_mobile : "unknown";

    if (device.includes("Apple") && !device.includes("iPhone") && navigator.maxTouchPoints > 0) {
      form = "Tablet";
      mobile = "true";
    }

    _Auth.fetch("/rest/breedwiz/", {
      method: "PUT",
      body: JSON.stringify({
        complete: completed ? completed : undefined,
        dogId: dogId,
        id: breedWizId != null ? breedWizId : null,
        deviceId: "device:" + device + "|form:" + form + "|mobile:" + mobile,
        selections: getWizardFromBreedWizCategory(breedWizCategory).traits.map(item => {
          return {
            imageUrl: item.answerTraitPicture,
            traitValueImageUrl: item.answerTraitValuePicture,
            traitName: item.traitName,
            traitDescription: item.traitDescription,
            traitId: item.traitId,
            categoryId: item.categoryId,
            technicalTypeId: item.technicalTypeId,
            value: typeof item.value != "string" ? JSON.stringify(item.value) : item.value
          }
        })
      })
    })
      .then(res => {
        return res.json();
      })
      .then(function (response) {
        if (response.id !== undefined) {
          setBreedWizId(response.id);
        }
        setChanges(false);
      })
      .catch(error => console.error("Error:", error));
  }

  useEffect(() => {
    getInfo();
    getDog();
  }, [language]);

  return(
    <div>
      { loadingError ?
        <React.Fragment>
          <NavBar />
          <Typography gutterBottom variant="h3" className="breedwiz-loading-text">
            {t("registerfeatures.prompt.error")}<br />
            {t("registerfeatures.prompt.foundnodata")}
          </Typography>
        </React.Fragment>
      : null}
      { !loading ?
        <React.Fragment>
          <Prompt
            when={breedWiz.categories && changes}
            message={t("registerfeatures.prompt.changes")}
          />
          <NavBar />
          { infoVisible ?
            <React.Fragment>
              <div className="languageswitcher">
                <div className="wizardTitleContainer">
                  <Typography variant="h2" >
                    {t("registerfeatures.prompt.language")}
                  </Typography>
                </div>
                <Select
                  className={"registration-text"}
                  inputId="react-select-single"
                  label="Språk"
                  options={languageOptions}
                  value={languageOptions.find(i => i.value == language)}
                  onChange={(data) => setLanguage(data.value)}
                />
              </div>
              <div className="wizinfocontainer">
                <RegistrationWizInfoPage
                  nextButtonClick={onCloseInfo}
                  kategoriUpdate={(id) => { setTypeId(id);}}
                  type={categoryId}
                  category={breedWizCategory}
                />
              </div>
            </React.Fragment>
          : null}
          { !loading && typeId != "" && breedWizCategory != undefined ? getWizardFromBreedWizCategory(breedWizCategory).groupings.map((item, key) => 
            <WizGroup
              key={key}
              group={item}
              animalName={dog != undefined ? (dog.animalAlias != "" ? dog.animalAlias : dog.name) : ""}
              animalGender={dog != undefined && dog.gender != undefined ? (dog.gender == "true" ? "male" : "female" ) : "none"}
              alltraits={getWizardFromBreedWizCategory(breedWizCategory).traits}
              currentPageNumber={key + 1}
              totalNumberOfPages={getWizardFromBreedWizCategory(breedWizCategory).traits.length}
              display={key === visiblePage}
              pageUpdatedCallback={traitData => wizardPageUpdated(traitData)}
            ></WizGroup>
          ) : null}
          {typeId != "" && breedWizCategory != undefined ?
            <WizFinishPage
              edited={edited}
              display={getWizardFromBreedWizCategory(breedWizCategory).groupings.length == visiblePage && getWizardFromBreedWizCategory(breedWizCategory).groupings.length != 0}
              categoryId={typeId.toString()}
              dogId={dogId}
              breedId={window.location.search.split("?")[1].split("&").find(item => item.indexOf("breedId=") !== -1).split("=")[1]}
              animalName={dog != undefined ? (dog.animalAlias != "" ? dog.animalAlias : dog.name) : ""}
              goBack={() => {
                let breedId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("breedId=") !== -1).split("=")[1];
                let categoryId = "";
                if (window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1) != undefined) {
                  categoryId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1];
                }
                window.location.href = window.location.origin + "/dogfeatureregistration?dogId=" + dogId + "&breedId=" + breedId + (categoryId != "" ? ("&categoryId=" + categoryId) : "");
              }}
            />
          : null}

          {!infoVisible && breedWizCategory != undefined && getWizardFromBreedWizCategory(breedWizCategory).traits.length > 1 ?
            <Stepper
              incompleteSteps={getIncompleteSteps()}
              gotoLastStep={lastStep}
              activeListener={stepperChanged}
              length={getWizardFromBreedWizCategory(breedWizCategory).groupings.length + 1}
              onComplete={() => completeClick()}
            />
          :
            <p><br /></p>
          }

        </React.Fragment>
      :
        <React.Fragment>
          <NavBar />
          <Typography gutterBottom variant="h3" className="breedwiz-loading-text">
            {t("registerfeatures.loading")}
          </Typography>
        </React.Fragment>
      }
    </div>
  );
}
export default DogRegistrationWizard;