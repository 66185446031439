import React, { useEffect, useState } from "react";

import { Bar, Scatter, Chart } from 'react-chartjs-2';
import Card from "@material-ui/core/Card";
import { useTranslate } from "react-polyglot";
import _ from "lodash";
import moment from "moment";

import MapAnswers from "../PhdAnswermapper";

function Currentexperience(props) {
    const t = useTranslate();

    const [reproductivestatusData, setReproductivestatusData] = useState([]);
    const [neuterreasoningData, setNeuterreasoningData] = useState([]);
    const [vocalisationfreqData, setVocalisationfreqData] = useState([]);

    const [reproductivestatusDataMyDog, setReproductivestatusDataMyDog] = useState([]);
    const [neuterreasoningDataMyDog, setNeuterreasoningDataMyDog] = useState([]);
    const [vocalisationfreqDataMyDog, setVocalisationfreqDataMyDog] = useState([]);

    const [showDogNoshow, setShowDogNoshow] = useState("");

    function GetPercentage(value, total) {
        return (value / total) * 100;
    }

    function InsertDogName(text) {
        if (t("statistics.main.yourdogs") != props.selectedDogName) {
            return text.replaceAll("[dog-name]", props.selectedDogName);
        } else {
            return text.replaceAll("[dog-name]", t("statistics.main.thedog"));
        }
    }

    function GetMyDogAllAnswers(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        return myDogAnswers;
    }

    function GetMyDogLatestAnswer(data) {
        let myDogAnswers = data.filter(x => x.dogId == props.myDogId);
        myDogAnswers = _.sortBy(myDogAnswers, 'createdTs');
        if (myDogAnswers[myDogAnswers.length - 1] != undefined)
            return [myDogAnswers[myDogAnswers.length - 1]];
        else
            return [];
    }

    function GetOtherDogs(data) {
        if (props.myDogId != undefined && props.myDogId != 0) {
            let otherDogs = data.filter(x => x.dogId != props.myDogId);
            return otherDogs;
        } else {
            return data;
        }
    }

    function GetTraitsFromDataset(traitIds, dataset) {
        let traits = [];
        for (let data of dataset) {
            let trait = data.selections.find(x => traitIds.includes(x.traitId) && x.value != null && x.value != "null" && x.value != "");
            if (trait != undefined) traits.push({...trait, dog: data.dog});
        }
        return traits;
    }

    function GetAnswersFromData(answerId, data) {
        return MapAnswers(answerId, data);
    }

    function DrawCompleteWizardCard(title, wizardName) {
        return(
            <Card className={"info-card center"}>
                <card-content>
                    <div className={"info-card-content"}>
                        <div className={"info-card-header"}>
                            <div className={"info-card-text-header"}>
                                {title}
                            </div>
                        </div>
                        <hr/>
                        <p dangerouslySetInnerHTML={{__html: t("statistics.behavior.sub_general.content.youandyourdog.noaccess").replaceAll("\n", "<br/>").replaceAll("[survey]", title)}}></p>
                        &nbsp;
                    </div>
                </card-content>
            </Card>
        );
    }

    useEffect(() => {
        Chart.plugins.register({
            beforeDraw: function(c) {
               var legends = c.legend.legendItems;
               legends.forEach(function(e) {
                    if (
                        e.text == "Your dog" ||
                        e.text == "Din hund" ||
                        e.text == "Din hund"
                    ) {
                        e.fillStyle = 'rgba(255, 139, 18, 0.3)';
                    } else if (
                        e.text == "Male dogs" ||
                        e.text == "Hannhunder" ||
                        e.text == "Hanhundar" || 
                        e.text == "All dogs in race" ||
                        e.text == "Alle hunder i rasen" ||
                        e.text == "Alla hundar i ras"
                    ) {
                        e.fillStyle = 'rgba(56, 46, 146, 0.3)';
                    } else if (
                        e.text == "Female dogs" ||
                        e.text == "Tisper" ||
                        e.text == "Tikar"
                    ) {
                        e.fillStyle = '#ff000030';
                    }
               });
            }
        });
    }, []);

    useEffect(() => {
        setShowDogNoshow("");
    }, [props.displaySubcategory]);

    useEffect(() => {
        setReproductivestatusData(GetAnswersFromData("S1", GetTraitsFromDataset([1801, 1601, 2001], props.currentexperienceDataset).map(x => x.value.split(':"')[1].split('"')[0] + "|" + x.dog.gender)));
        setNeuterreasoningData(GetAnswersFromData("S2", GetTraitsFromDataset([1802, 1602, 2002], props.currentexperienceDataset).map(x => x.value.split(':"')[1].split('"')[0] + "|" + x.dog.gender)));
        
        setVocalisationfreqData(GetAnswersFromData("S45", GetTraitsFromDataset([1853, 1653, 2053], GetOtherDogs(props.currentexperienceDataset)).map(x => JSON.parse(x.value).sliderValue + "|" + x.dog.gender + "|" + moment(x.createdTs * 1000).diff(moment(x.dog.birthDate), "days") )));
        
        if (props.myDogId != undefined && props.myDogId != 0) {
            setReproductivestatusDataMyDog(GetAnswersFromData("S1", GetTraitsFromDataset([1801, 1601, 2001], GetMyDogLatestAnswer(props.currentexperienceDataset)).map(x => x.value.split(':"')[1].split('"')[0] + "|" + x.dog.gender)));
            setNeuterreasoningDataMyDog(GetAnswersFromData("S2", GetTraitsFromDataset([1802, 1602, 2002], GetMyDogLatestAnswer(props.currentexperienceDataset)).map(x => x.value.split(':"')[1].split('"')[0] + "|" + x.dog.gender)));
            
            setVocalisationfreqDataMyDog(GetAnswersFromData("S45", GetTraitsFromDataset([1853, 1653, 2053], GetMyDogAllAnswers(props.currentexperienceDataset)).map(x => JSON.parse(x.value).sliderValue + "|" + x.dog.gender + "|" + moment(x.createdTs * 1000).diff(moment(x.dog.birthDate), "days") )));
            
        }
    }, [props.currentexperienceDataset, props.myDogId]);

    return (
        <React.Fragment>
            {
                props.myDogs.some(mydog => {
                    if (props.allBreedTraits != undefined) {
                        return props.allBreedTraits.filter(trait => 
                            trait.selections[0].categoryId == 71 ||
                            trait.selections[0].categoryId == 73 ||
                            trait.selections[0].categoryId == 75
                        ).some(trait => {
                            return trait.dogId == mydog.id;
                        })
                    } else {
                        return false;
                    }
                }) ? 
                    <>
                        <Card
                            className="statisticsinfocard"
                            onClick={() => {
                                if (showDogNoshow != "currentexperience_noshow") {
                                    setShowDogNoshow("currentexperience_noshow");
                                } else {
                                    setShowDogNoshow("");
                                }
                            }}
                            style={{
                                maxWidth: showDogNoshow == "currentexperience_noshow" ? "1000px" : "220px",
                            }}
                        >
                            <card-content>
                                <h3>{t("statistics.areas.behavior.dognoshow_title")}</h3>
                                <span class="material-symbols-outlined icon">
                                    info
                                </span>
                                <div
                                    style={{
                                        maxHeight: showDogNoshow == "currentexperience_noshow" ? "400px" : "0px",
                                    }}
                                >
                                    <p dangerouslySetInnerHTML={{__html: t("statistics.areas.behavior.currentexperience.dognoshow_description").replaceAll("\n", "<br/>")}}></p>
                                </div>
                            </card-content>
                        </Card>
                        <Card className={"info-card center"}>
                            <card-content>
                                <div className={"info-card-content"}>
                                    <div className={"info-card-header"}>
                                        <div className={"info-card-text-header"}>
                                            {t("statistics.behavior.sub_general.content.currentexperience.title")}
                                        </div>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Bar
                                            chartId="barchart2-1"
                                            data={{
                                                labels: [
                                                    t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.label_1"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.label_2"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.label_3"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.label_4"),
                                                ],
                                                datasets: [
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.male"),
                                                        backgroundColor: [
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("IntactMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("SurgicalMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("ChemicalMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("PregLactMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(reproductivestatusData.filter(x => x == "IntactMale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "SurgicalMale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "ChemicalMale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "PregLactMale").length, reproductivestatusData.length),
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.female"),
                                                        backgroundColor: [
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("IntactFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("SurgicalFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("ChemicalFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            reproductivestatusDataMyDog.length != 0 && reproductivestatusDataMyDog.includes("PregLactFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(reproductivestatusData.filter(x => x == "IntactFemale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "SurgicalFemale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "ChemicalFemale").length, reproductivestatusData.length),
                                                            GetPercentage(reproductivestatusData.filter(x => x == "PregLactFemale").length, reproductivestatusData.length),
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.yourdog"),
                                                        backgroundColor: "rgba(255, 139, 18, 0.3)",
                                                        stack: "1",
                                                        data: []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.x_title")
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.reproductivestatus.y_title")
                                                            },
                                                            ticks: {
                                                                max: 100,
                                                                min: 0,
                                                                fontColor: "#382e92",
                                                                fontSize: 14
                                                            },
                                                            stacked: true
                                                        }
                                                    ]
                                                },
                                                legend: {
                                                    labels: {

                                                    }
                                                }
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Bar>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Bar
                                            chartId="barchart2-1"
                                            data={{
                                                labels: [
                                                    t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.label_2"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.label_3"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.label_4"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.label_5"),
                                                    t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.label_6"),
                                                ],
                                                datasets: [
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.male"),
                                                        backgroundColor: [
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("UnwantedPregnanciesMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("HealthMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("BehaviourMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("OtherMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("DKMale") ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(neuterreasoningData.filter(x => x == "UnwantedPregnanciesMale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "HealthMale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "BehaviourMale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "OtherMale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "DKMale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.female"),
                                                        backgroundColor: [
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("UnwantedPregnanciesFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("HealthFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("BehaviourFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("OtherFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                            neuterreasoningDataMyDog.length != 0 && neuterreasoningDataMyDog.includes("DKFemale") ? "rgba(255, 139, 18, 0.3)" : "#ff000030",
                                                        ],
                                                        stack: "1",
                                                        data: [
                                                            GetPercentage(neuterreasoningData.filter(x => x == "UnwantedPregnanciesFemale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "HealthFemale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "BehaviourFemale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "OtherFemale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                            GetPercentage(neuterreasoningData.filter(x => x == "DKFemale").length, neuterreasoningData.filter(x => x != "IntactMale" && x != "IntactFemale").length),
                                                        ]
                                                    },
                                                    {
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.yourdog"),
                                                        backgroundColor: "rgba(255, 139, 18, 0.3)",
                                                        stack: "1",
                                                        data: []
                                                    }
                                                ]
                                            }}
                                            options={{
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.x_title")
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 2,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.neuterreasoning.y_title")
                                                            },
                                                            ticks: {
                                                                max: 100,
                                                                min: 0,
                                                                fontColor: "#382e92",
                                                                fontSize: 14
                                                            },
                                                            stacked: true
                                                        }
                                                    ]
                                                },
                                                legend: {
                                                    labels: {

                                                    }
                                                }
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Bar>
                                    </div>
                                    <hr/>
                                    <h3>{t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.title")}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: InsertDogName(t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.description")) }}></p>
                                    <div className="statistics-card-phd">
                                        <Scatter
                                            chartId="scatterchart1"
                                            options={{
                                                legend: {
                                                    position: "top",
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                },
                                                animation: {
                                                    duration: 0
                                                },
                                                responsiveAnimationDuration: 0,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#EEEEEE"],
                                                                color: "#EEEEEE",
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                beginAtZero: true,
                                                                min: 0,
                                                                stepSize: 365,
                                                                callback: function(value, index, values) {
                                                                    return Math.round(value / 365);
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.x_title")
                                                            }
                                                        }
                                                    ],
                                                    yAxes: [
                                                        {
                                                            gridLines: {
                                                                zeroLineColor: ["#382e92"],
                                                                lineWidth: 0,
                                                                zeroLineWidth: 2,
                                                                drawTicks: true,
                                                                tickMarkLength: 8,
                                                            },
                                                            ticks: {
                                                                beginAtZero: true,
                                                                fontColor: "#382e92",
                                                                fontSize: 14,
                                                                max: 100,
                                                                padding: 10,
                                                                callback: function (value, index, ticks) {
                                                                    if (value == 100) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.tick_zero").split("\n");
                                                                    } else if (value == 0) {
                                                                        return t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.tick_hundred").split("\n");
                                                                    } else {
                                                                        return "";
                                                                    }
                                                                }
                                                            },
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 18,
                                                                labelString: t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.y_title")
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                            data={{
                                                datasets: [
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.youandyourdog.acquisitionmotivation.alldogs"),
                                                        borderColor: "#382e92",
                                                        borderWidth: 2,
                                                        pointRadius: 1,
                                                        pointBackgroundColor: "#382e92",
                                                        fill: false,
                                                        data: [
                                                            ...vocalisationfreqData.filter(x => x.split("|")[1] == "true").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            }),
                                                            ...vocalisationfreqData.filter(x => x.split("|")[1] == "false").map(x => {
                                                                return {
                                                                    y: Number(x.split("|")[0]),
                                                                    x: Number(x.split("|")[2])
                                                                }
                                                            })
                                                        ]
                                                    },
                                                    // {
                                                    //     type: "scatter",
                                                    //     label: t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.female"),
                                                    //     borderColor: "#382e92",
                                                    //     borderWidth: 2,
                                                    //     pointRadius: 1,
                                                    //     pointBackgroundColor: "#382e92",
                                                    //     data: vocalisationfreqData.filter(x => x.split("|")[1] == "false").map(x => {
                                                    //         return {
                                                    //             y: Number(x.split("|")[0]),
                                                    //             x: Number(x.split("|")[2])
                                                    //         }
                                                    //     })
                                                    // },
                                                    {
                                                        type: "scatter",
                                                        label: t("statistics.behavior.sub_general.content.currentexperience.vocalisationfreq.yourdog"),
                                                        borderColor: "rgb(255, 139, 18)",
                                                        borderWidth: 2,
                                                        pointRadius: 2,
                                                        pointBackgroundColor: "rgb(255, 139, 18)",
                                                        data: vocalisationfreqDataMyDog.map(x => {
                                                            return {
                                                                y: Number(x.split("|")[0]),
                                                                x: Number(x.split("|")[2])
                                                            }
                                                        })
                                                    }
                                                ]
                                            }}
                                            maxWidth={50}
                                            maxHeight={50}
                                            redraw={true}
                                        ></Scatter>
                                    </div>
                                </div>
                            </card-content>
                        </Card>
                    </>
                : DrawCompleteWizardCard(t("statistics.behavior.sub_general.content.currentexperience.title"), "") 
            }
        </React.Fragment>
    );
}

export default Currentexperience;
