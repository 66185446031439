import React, {useEffect, useState} from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Auth from "../../../../Auth";

function UsercontrolDeleteUser(props) {
    let _Auth = new Auth();

    const [email, setEmail] = useState("");

    async function deleteUser() {
        _Auth.fetch("/rest/users/delete", {
            method: "DELETE",
            body: email
        }).then(res => res.json()).then(response => {
            console.log(response);
            if (response == 1) {
                alert("Bruker slettet");
                setEmail("");
            } else {
                alert("Noe gikk galt");
            }
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        
    }, []);

    return (
        <React.Fragment>
            <div>
                <Typography gutterBottom variant="h6">
                    Slett bruker fra Biotail
                </Typography>
                <p>Slett bruker med angitt epost.</p>
                <p>Slettede brukere mister kontakt med alle former for betaling og hundedata.</p>
                <p><strong>MERK: Brukere som er slettet kan IKKE gjenopprettes!</strong></p>
                <TextField
                    id="deleteuseremail"
                    style={{
                        width: "100%",
                        marginBottom: "10px"
                    }}
                    label="Epost"
                    placeholder="Skriv inn epost"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    variant="outlined"
                />
                <Button
                    className="deleteuserbtn"
                    onClick={ (evt) => deleteUser() }
                    style={{
                        backgroundColor: "#2bb919",
                        color: "white",
                        padding: "6px",
                    }}
                >
                    Slett bruker
                </Button>
            </div>
        </React.Fragment>
    );
}

export default UsercontrolDeleteUser;