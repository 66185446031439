import React, { useEffect } from "react";

import WizPage from "./WizPage";

function WizGroup(props) {

    useEffect(() => {
        
    }, [props.animalName]);

    return (
        <React.Fragment>
            { props.group.questions.map((traitId, key) => 
                <WizPage
                    key={key}
                    trait={props.alltraits.find(t => t.traitId == traitId)}
                    currentPageNumber={props.currentPageNumber}
                    animalName={props.animalName}
                    animalGender={props.animalGender}
                    totalNumberOfPages={props.totalNumberOfPages}
                    display={props.display}
                    pageUpdatedCallback={props.pageUpdatedCallback}
                />
            ) }
            { /*props.display && (window.innerHeight < document.body.scrollHeight) ?
                <div style={{
                    position: "fixed",
                    bottom: "69px",
                    right: "20px",
                    zIndex: "10000",
                    fontWeight: "600",
                    color: "darkgray",
                }} onClick={() => {

                }}>
                V
                </div>
            : null*/ }
        </React.Fragment>
    );
}

export default WizGroup;