import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from 'react-select';
import InputDatePicker from "../../common/InputDatePicker";
import NavBar from "../../common/Navbar";
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UsercontrolDeleteUser from "./usercontrol/UsercontrolDeleteUser";
import UsercontrolGeneratePasswordlink from "./usercontrol/UsercontrolGeneratePasswordlink";

import Auth from "../../../Auth";
import Fileimport from "./fileimport/Fileimport";
import { useTranslate } from 'react-polyglot';

function saveByteArray(byte, reportName) {
  var blob = new Blob([byte], { encoding:"UTF-8", type: "application/csv;charset=UTF-8" });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};

const useStyles = makeStyles({
  roots: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  }
})

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
        border: state.isFocused ? 0 : 0
        }
    })
};


const headingContainerStyle = {
  overflow: "hidden"
}

const headingStyle = {
  float: "left",
  marginTop: "3px",
  marginLeft: "30px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  fontWeight: "bold",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.43",
  letterSpacing: "3.5px",
  textTransform: "uppercase",
  width: "calc(100% - 82px)"
}

const descriptionInfoStyle = {
  marginLeft: "30px",
  marginTop: "4px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.43",
  letterSpacing: "0.5px",
}

function useForceUpdate(){
    const [forcevalue, setForceValue] = useState(0); // integer state
    return () => setForceValue(forcevalue => ++forcevalue); // update the state to force render
}

toast.configure()

const Admin = (props) => {
  const classes = useStyles();

  let _Auth = new Auth();
  const forceUpdate = useForceUpdate();
  
  const [expanded, setExpanded] = useState("gallery");

  const [loadingFile, setLoadingFile] = useState(false);

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  // States for the goals
  const [imagesForApproval, setImagesForApproval] = useState([])
  const [goals, setGoals] = useState([])
  const [savingGoals, setSavingGoals] = useState(false)
  const [loading, setLoading] = useState(true)

  // States for the product creation
  const [textFieldValueName, setTextFieldValueName] = useState("")
  const [textFieldValueDescription, setTextFieldValueDescription] = useState("")
  const [textFieldValuePrice, setTextFieldValuePrice] = useState("")
  const [textFieldValueCurrency, setTextFieldValueCurrency] = useState("")
  const [textFieldValuePermission, setTextFieldValuePermission] = useState("")

  // States for the discount creation
  const [users, setUsers] = useState([])
  const [searchUser, setSearchUser] = useState([])
  const [products, setProducts] = useState([])
  const [searchProduct, setSearchProduct] = useState([])
  const [discountName, setDiscountName] = useState("")
  const [discountValidDate, setDiscountValidDate] = useState()
  const [discountUsed, setDiscountUser] = useState(false)
  const [discountPercentageOff, setDiscountPercentageOff] = useState("")
  const [discountCode, setDiscountCode] = useState("")

  const [numDogs, setNumDogs] = useState(0);
  const [numUsers, setNumUsers] = useState(0);

  const [accepting, setAccepting] = useState([]);

  const t = useTranslate();
  const loading_string = t("admin.admin.loading");

  const acceptBtn = (value) => {
    setAccepting([...accepting, value.id]);
    value.approved = true;
    value.verified = true;

    _Auth.fetch("/rest/images/verification", {
      method: "PUT",
      body: JSON.stringify([ value ])
    })
      .then(res => res.json())
      .then(response => {
        setAccepting(accepting.filter(i => i != value.id));

        let restImages = imagesForApproval.filter((image) => { return image.id != value.id });
        setImagesForApproval(restImages)
      })
      .catch(error => console.error("Error:", error));
  }

  const rejectBtn = (value) => {
    setAccepting([...accepting, value.id]);
    value.approved = false;
    value.verified = true;
    _Auth.fetch("/rest/images/verification", {
      method: "PUT",
      body: JSON.stringify([ value ])
    })
      .then(res => res.json())
      .then(response => {
        setAccepting(accepting.filter(i => i != value.id));
        
        let restImages = imagesForApproval.filter((image) => { return image.id != value.id });
        setImagesForApproval(restImages)
      })
      .catch(error => console.error("Error:", error));
  }

  const saveGoalsBtn = () => {
    setSavingGoals(true)
    goals.map((goal) => {
      _Auth.fetch("/rest/donationgoals/" + goal.id, {
        method: "PUT",
        body: JSON.stringify(goal)
      })
        .then(res => res.json())
        .then(response => {
          setSavingGoals(false)
        })
        .catch(error => console.error("Error:", error));
    });
  }

  const objIsEmpty = (obj) => {
    for(let key in obj) {
      if(obj.hasOwnProperty(key))
        return false
    }
    return true;
  }

  const runCronJob = () => {
    setSavingGoals(true)
    fetch(`/rest/nets/cronjob/pay`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
    .then(response => response.text())
    .then(data => {
      setSavingGoals(false)
      notifyInfo(data)
    })
  }

  const calcPrice = (price) => {
    let priceAsInt = parseInt(price, 10);
    let calculated = priceAsInt * 100;

    return calculated.toString();
  }

 /*
 Function to create new product
 Body:
   {
    "name":String,
    "description":String,
    "price":String,
    "currency":String,
    "permissionTag":String
   }

   EXAMPLE:
   {
    "name":"Eksteriørstatestikk",
    "description":"Abonner på dette produktet for å få tilgang til generell eksteriørstatestikk",
    "price":"3500", <-- 35kr
    "currency":"NOK",
    "permissionTag":"ROLE_P5"
   }
 */
  const createProduct = () => {

    fetch(`/rest/product/create`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
          "Content-Type":"application/json"
      }),
      body: JSON.stringify({
        "name":textFieldValueName,
        "description":textFieldValueDescription,
        "price":calcPrice(textFieldValuePrice),
        "currency":"NOK",
        "permissionTag":`ROLE_${textFieldValuePermission}`
      })
    })
    .then(response => response.text())
    .then(data => {
      setSavingGoals(false)
      getProducts();
      notifyInfo(data)
    })
  }

  const removeProduct = () => {
    /*
    fetch(`/rest/nets/cronjob/pay`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
    .then(response => response.text())
    .then(data => {
      setSavingGoals(false)
    })
    */
    notifyInfo("Not implemented")
  }

  const createDiscount = () => {
    fetch(`/rest/discounts/create`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
          "Content-Type":"application/json"
      }),
      body: JSON.stringify({
        "name":discountName,
        "validDate":discountValidDate,
        "used":discountUsed,
        "percentageOff":discountPercentageOff,
        "code":discountCode,
        "product":{
          "id":searchProduct[0].id
        },
        "user":{
          "id":searchUser[0].id
        }
      })
    })
    .then(response => response.text())
    .then(data => {
      if(objIsEmpty(data)) {
        setSavingGoals(false)
        notifyInfo("Discountcode already exists")
      } else {
        setSavingGoals(false)
        notifyInfo(data)
      }
    })
  }



  const goalNameChanged = (e, i) => {
    goals[i].goal = e.target.value;
    setGoals(goals)
    forceUpdate();
  }

  const goalValueChanged = (e, i) => {
    goals[i].progress = e.target.value;
    setGoals(goals)
    forceUpdate();
  }

  const getUsers = () => {
    _Auth.fetch("/rest/users/")
      .then(response => response.json())
      .then(data => {
        setUsers(data)
      })
      .catch(error => console.error("Error:", error));
  }

  const getNumUsers = () => {
    _Auth.fetch("/rest/users/count")
      .then(response => response.json())
      .then(data => {
        setNumUsers(data)
      })
      .catch(error => console.error("Error:", error));
  }
  
  const getNumDogs = () => {
    _Auth.fetch("/rest/dogs/count")
      .then(response => response.json())
      .then(data => {
        setNumDogs(data);
      })
      .catch(error => console.error("Error:", error));
  }

  const getProducts = () => {
    fetch("/rest/product", {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
      .then(response => response.json())
      .then(data => {
        setProducts(data)
      })
}

  const getGoals = () => {
    _Auth.fetch("/rest/donationgoals/")
      .then(response => response.json())
      .then(data => {
        setGoals(data)
      })
      .catch(error => console.error("Error:", error));
  }

  const handleSearchUser = (event) => {
    setSearchUser(users.filter(user => user.username == event.value))
  }

  const handleSearchProduct = (event) => {
    setSearchProduct(products.filter(product => product.name == event.value))
  }

  useEffect(() => {
    getNumDogs();
    getNumUsers();
    getUsers();
    getProducts();
    setLoading(true);
    _Auth.fetch("/rest/images/verification")
      .then(res => res.json())
      .then(response => {
        setImagesForApproval(response)
        setLoading(false);
      })
      .catch(error => console.error("Error:", error));
    getGoals();
  }, [])

    return (
      <React.Fragment>
        <NavBar />
        <div className="admin">
          <Typography gutterBottom variant="h3">
            {t("admin.admin.title")}
          </Typography>
          <Divider />

          <Accordion expanded={expanded == "gallery"} onChange={() => expanded == "gallery" ? setExpanded("") : setExpanded("gallery")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  {t("admin.admin.gallery")}
                </Typography>
              </div>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <Typography gutterBottom variant="body1">
                  {t("admin.admin.description")}
                </Typography>
                <div className="imageapproval" style={{ overflow: "hidden" }}>
                  <Typography gutterBottom variant="body1">
                    {t("admin.admin.imageapproval")}
                  </Typography>
                  { loading ?
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{
                        marginTop: "20px",
                        textAlign: "center"
                      }}
                    >
                     {t("admin.admin.loadingpictures")}
                    </Typography>
                  : null }
                  { imagesForApproval.map((image) => {
                    return(
                    <div key={ image.id } className="itemcontainer">
                      <img
                        src={ image.imageUrl }
                        alt=""
                      />
                      <Button
                          className="acceptbtn"
                          onClick={() => acceptBtn( image )}
                          disabled={accepting.includes(image.id)}
                      >
                        {t("admin.admin.approval")}
                      </Button>
                      <Button
                          className="rejectbtn"
                          onClick={() => rejectBtn( image )}
                      >
                        {t("admin.admin.decline")}
                      </Button>
                    </div>);
                  } ) }
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded == "information"} onChange={() => expanded == "information" ? setExpanded("") : setExpanded("information")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  {t("admin.admin.systemdata")}
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <div className="row">
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                        {t("admin.admin.systeminfo")}
                      </Typography>
                      <ul>
                        <li>{t("admin.admin.numberofusers")}{numUsers != 0 ? numUsers : loading_string }</li>
                        <li>{t("admin.admin.numberofdogs")}{numDogs != 0 ? numDogs : loading_string}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                        {t("admin.admin.download")}
                      </Typography>
                      <p>{t("admin.admin.downloaddata")}</p>
                      <Button
                        className="savegoalsbtn"
                        style={{
                          backgroundColor: !loadingFile ? "#2bb919" : "#98cf91",
                          color: "white",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: "10px"
                        }}
                        disabled={loadingFile}
                        onClick={() => {
                          setLoadingFile(true);
                          fetch(`/rest/users/allcsv`, {
                            method: "GET",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem("id_token")}`
                            })
                          }).then(res => {
                            let reader = res.body.getReader();
                            
                            let result = [];
                            reader.read().then(function processText({ done, value }) {
                              if (done) {
                                saveByteArray(new Uint8Array(result).buffer, 'biotail_users.csv');
                                setLoadingFile(false);
                                return;
                              }
                              result = [ ...result, ...value ];
                              
                              return reader.read().then(processText);
                            });
                          }, err => {
                            setLoadingFile(false);
                          })
                        }}
                      >
                        {t("admin.admin.csv.users")}
                      </Button>
                      <Button
                        className="savegoalsbtn"
                        style={{
                          backgroundColor: !loadingFile ? "#2bb919" : "#98cf91",
                          color: "white",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: "10px"
                        }}
                        disabled={loadingFile}
                        onClick={(evt) => {
                          setLoadingFile(true);
                          fetch(`/rest/dogs/allcsv`, {
                            method: "GET",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem("id_token")}`
                            })
                          }).then(res => {
                            let reader = res.body.getReader();
                            
                            let result = [];
                            reader.read().then(function processText({ done, value }) {
                              if (done) {
                                saveByteArray(new Uint8Array(result).buffer, 'biotail_dogs.csv');
                                setLoadingFile(false);
                                return;
                              }
                              result = [ ...result, ...value ];
                              
                              return reader.read().then(processText);
                            });
                          }, err => {
                            setLoadingFile(false);
                          })
                        }}
                      >
                       {t("admin.admin.csv.dogs")}
                      </Button>
                      <Button
                        className="savegoalsbtn"
                        style={{
                          backgroundColor: !loadingFile ? "#2bb919" : "#98cf91",
                          color: "white",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: "10px"
                        }}
                        disabled={loadingFile}
                        onClick={(evt) => {
                          setLoadingFile(true);
                          fetch(`/rest/breedwiz/allcsv`, {
                            method: "GET",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem("id_token")}`
                            })
                          }).then(res => {
                            let reader = res.body.getReader();
                            
                            let result = [];
                            reader.read().then(function processText({ done, value }) {
                              if (done) {
                                saveByteArray(new Uint8Array(result).buffer, 'biotail_exams.csv');
                                setLoadingFile(false);
                                return;
                              }
                              result = [ ...result, ...value ];
                              
                              return reader.read().then(processText);
                            });
                          }, err => {
                            setLoadingFile(false);
                          })
                        }}
                      >
                        {t("admin.admin.csv.surveys")}
                      </Button>
                      <hr/>
                      <Button
                        className="savegoalsbtn"
                        style={{
                          backgroundColor: !loadingFile ? "#2bb919" : "#98cf91",
                          color: "white",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: "10px"
                        }}
                        disabled={loadingFile}
                        onClick={(evt) => {
                          setLoadingFile(true);
                          fetch(`/rest/breedwiz/kimallcsv`, {
                            method: "GET",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem("id_token")}`
                            })
                          }).then(res => {
                            let reader = res.body.getReader();
                            
                            let result = [];
                            reader.read().then(function processText({ done, value }) {
                              if (done) {
                                saveByteArray(new Uint8Array(result).buffer, 'biotail_exams_kim.csv');
                                setLoadingFile(false);
                                return;
                              }
                              result = [ ...result, ...value ];
                              
                              return reader.read().then(processText);
                            });
                          }, err => {
                            setLoadingFile(false);
                          })
                        }}
                      >
                        {t("admin.admin.csv.kimsurveys")}
                      </Button>
                      {loadingFile ?
                        <p>{t("admin.admin.wrapperdata")}</p>
                      : null}
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          
          <Accordion expanded={expanded == "datasync"} onChange={() => expanded == "datasync" ? setExpanded("") : setExpanded("datasync")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  {t("admin.admin.dataloading")}
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <div className="row">
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                        {t("admin.admin.importfromNorsvin")}
                      </Typography>
                      <p>{t("admin.admin.importing")}</p>
                      <Button
                        className="savegoalsbtn"
                        style={{
                          backgroundColor: "#2bb919",
                          color: "white",
                          padding: "6px"
                        }}
                        onClick={() => {
                          window.open("/rest/species/update");
                        }}
                      >
                        {t("admin.admin.reload")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          
          <Accordion expanded={expanded == "dogexplorer"} onChange={() => expanded == "dogexplorer" ? setExpanded("") : setExpanded("dogexplorer")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  {t("admin.admin.dogexplorer")}
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <div className="row">
                  <div className="col col-sm-6">
                    <Fileimport></Fileimport>
                  </div>
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                       {t("admin.admin.biotail")}
                      </Typography>
                      <p>{t("admin.admin.biotailtoexplorer")}</p>
                      <Button
                        className="savegoalsbtn"
                        onClick={ (evt) => {
                          fetch(`/dataapi/api/internalimport/`, {
                            method: "POST",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem("id_token")}`,
                                "Content-Type": "application/json"
                            })
                          }).then(res => {
                            
                          })
                        }}
                        style={{
                          backgroundColor: "#2bb919",
                          color: "white",
                          padding: "6px",
                        }}
                      >
                        {t("admin.admin.start")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          
          <Accordion expanded={expanded == "shop"} onChange={() => expanded == "shop" ? setExpanded("") : setExpanded("shop")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  {t("admin.admin.subscription")}
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <div className="row">
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                       {t("admin.admin.discount.new")}
                      </Typography>
                      <div>
                        <TextField className={classes.textField}
                          id="outlined-discount"
                          label={t("admin.admin.discount.name.label")}
                          placeholder={t("admin.admin.discount.name.placeholder")}
                          value={discountName}
                          onChange={e => setDiscountName(e.target.value)}
                          variant="outlined"
                        />
                        <TextField className={classes.textField}
                          id="outlined-discount"
                          label={t("admin.admin.discount.percent.label")}
                          placeholder={t("admin.admin.discount.percent.placeholder")}
                          value={discountPercentageOff}
                          onChange={e => setDiscountPercentageOff(e.target.value)}
                          variant="outlined"
                        />
                        <TextField className={classes.textField}
                          id="outlined-discount"
                          label={t("admin.admin.discount.code.label")}
                          placeholder={t("admin.admin.discount.code.placeholder")}
                          value={discountCode}
                          onChange={e => setDiscountCode(e.target.value)}
                          variant="outlined"
                        />
                        <InputDatePicker
                          inputProps={{ style: { color: "#000000" } }}
                          InputLabelProps={{
                            // classes: { root: "registration-text" },
                            shrink: true
                          }}
                          id="validDate"
                          label={t("admin.admin.expirationdate")}
                          value={discountValidDate}
                          onChange={e => setDiscountValidDate(e.target.value)}
                          required
                        />
                        <Select
                          className="breed-dropdown"
                          inputId="react-select-single"
                          styles={selectStyle}
                          TextFieldProps={{
                              label: t("admin.admin.user.label"),
                              InputLabelProps: {
                                  htmlFor: 'react-select-single',
                                  shrink: true,
                              },
                          }}
                          placeholder={t("admin.admin.user.placeholder")}
                          options={[{ value: 9999, label: t("admin.admin.user.options") }, ...users.map(item => { return { label:item.username, value: item.username } })]}
                          onChange={handleSearchUser}
                          components={{ NoOptionsMessage: function() { return loading ? <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("admin.admin.user.loading")}</p> : <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("admin.admin.user.foundnodata")}</p>} }}
                        />
                        <Select
                          className="breed-dropdown"
                          inputId="react-select-single"
                          styles={selectStyle}
                          TextFieldProps={{
                              label: t("admin.admin.product.label"),
                              InputLabelProps: {
                                  htmlFor: 'react-select-single',
                                  shrink: true,
                              },
                          }}
                          placeholder={t("admin.admin.product.placeholder")}
                          options={[{ value: 9999, label: t("admin.admin.product.options") }, ...products.map(item => { return { label: item.name, value: item.name } })]}
                          onChange={handleSearchProduct}
                          components={{ NoOptionsMessage: function() { return loading ? <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("admin.admin.product.loading")}</p> : <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("admin.admin.product.foundnodata")}</p>} }}
                        />
                      </div>
                      <Button
                        className="savegoalsbtn"
                        onClick={ createDiscount }
                        style={{
                          backgroundColor: "#2bb919",
                          color: "white",
                          padding: "6px",
                          float: "left"
                        }}
                        disabled={ savingGoals }
                      >
                        {t("admin.admin.button.create")}
                      </Button>
                    </div>
                  </div>
                  <div className="col col-sm-6">
                    <div>
                      <Typography gutterBottom variant="h6">
                        {t("admin.admin.paymentcheck.title")}
                      </Typography>
                      <p>{t("admin.admin.paymentcheck.description")}</p>
                      <Button
                        className="savegoalsbtn"
                        onClick={ runCronJob }
                        style={{
                          backgroundColor: "#2bb919",
                          color: "white",
                          padding: "6px",
                          float: "left"
                        }}
                        disabled={ savingGoals }
                      >
                        {t("admin.admin.payment.title")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded == "usercontrol"} onChange={() => expanded == "usercontrol" ? setExpanded("") : setExpanded("usercontrol")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  Brukerkontroll
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div>
                <div className="row">
                  <div className="col col-sm-6">
                    <UsercontrolDeleteUser></UsercontrolDeleteUser>
                  </div>
                  <div className="col col-sm-6">
                    {/* <UsercontrolGeneratePasswordlink></UsercontrolGeneratePasswordlink> */}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          

          {/*
          <Typography gutterBottom variant="h6">
            Lag nytt produkt
          </Typography>
          <div>
            <TextField className={classes.textField}
              id="outlined-discount"
              label="Navn"
              placeholder="Skriv inn navnet på ett nytt produkt..."
              value={textFieldValueName}
              onChange={e => setTextFieldValueName(e.target.value)}
              variant="outlined"
            />
            <TextField className={classes.textField}
              id="outlined-discount"
              label="Beskrivelse"
              placeholder="Skriv en beskrivelse av produktet..."
              value={textFieldValueDescription}
              onChange={e => setTextFieldValueDescription(e.target.value)}
              variant="outlined"
            />
            <TextField className={classes.textField}
              id="outlined-discount"
              label="Pris i kr"
              placeholder="Skriv inn prisen på produktet..."
              value={textFieldValuePrice}
              onChange={e => setTextFieldValuePrice(e.target.value)}
              variant="outlined"
            />
            <TextField className={classes.textField}
              id="outlined-discount"
              label="Rettigheter (P4, P5...)"
              placeholder="Skriv inn en rettighetskode... (ROLE_P4, ROLE_P5...)"
              value={textFieldValuePermission}
              onChange={e => setTextFieldValuePermission(e.target.value)}
              variant="outlined"
            />
          </div>
          <br />
            <Button
              className="savegoalsbtn"
              onClick={ createProduct }
              style={{
                backgroundColor: "#2bb919",
                color: "white",
                padding: "6px",
                float: "left"
              }}
              disabled={ savingGoals }
            >
              Opprett produkt
            </Button>
          <br />
          <br />
          <br />
          <Divider />
          */}
          
          {/*<Typography gutterBottom variant="body1">
            Vipps-donasjonsmål
          </Typography>
          <div className="vipps-goals-admin" style={{ overflow: "hidden" }}>
            { goals.map((goal, i) => {
              return(
                <div className="vipps-goal" key={ i } style={{
                  width: "100%",
                  overflow: "hidden",
                  display: "block",
                  clear: "both",
                }}>
                  <TextField
                    id="standard-id"
                    label="Målnavn"
                    //className={classes.textField}
                    value={ goal.goal }
                    onChange={ e => goalNameChanged(e, i)}
                    margin="normal"
                    style={{
                      float: "left",
                      width: "400px"
                    }}
                  />
                  <TextField
                    id="standard-id"
                    label="Målverdi"
                    type="number"
                    //className={classes.textField}
                    value={ goal.progress }
                    onChange={ e => goalValueChanged(e, i)}
                    margin="normal"
                    style={{
                      float: "left",
                      width: "400px"
                    }}
                  />
                </div>
              );
            }) }
            <Button
              className="savegoalsbtn"
              onClick={ saveGoalsBtn }
              style={{
                backgroundColor: "#2bb919",
                color: "white",
                padding: "6px",
                float: "left"
              }}
              disabled={ savingGoals }
            >Legg inn
              Lagre donasjonsmål
            </Button>
            </div>*/}
        </div>
      </React.Fragment>
    );

}

export default Admin;
