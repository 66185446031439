import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import Menu from "../menu/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Auth from "../../../Auth";

function TopHeader(props) {
    let _Auth = new Auth();
    let history = useHistory();
    const t = useTranslate();

    const [showMenu, setShowMenu] = useState(false);
    
    useEffect(() => {
        
    }, [])

    return (
        <React.Fragment>
            { window.innerWidth > 600 ? (
                <div className="top-menu-container" style={ showMenu ? { 
                display: "block",
                position: "fixed",
                width: "400px",
                height: "auto",
                zIndex: "1000",
                top: "0px",
                right: "-20px",
                transition: "right 250ms linear"
                } : {
                display: "block",
                position: "fixed",
                width: "400px",
                zIndex: "1000",
                top: "0px",
                right: "-500px",
                transition: "right 250ms linear"
                }}>
                    <Menu
                        crossBtnListener={ () => setShowMenu(false) }
                    />
                </div>
            ) : (
                <div className="top-menu-container" style={ showMenu ? { 
                display: "block",
                position: "fixed",
                width: "100%",
                height: "auto",
                zIndex: "1000",
                top: "0px",
                left: "0px",
                transition: "left 250ms linear"
                } : {
                display: "block",
                position: "fixed",
                width: "100%",
                zIndex: "1000",
                top: "0px",
                left: "calc(-100% - 100px)",
                transition: "left 250ms linear"
                }}>
                    <Menu
                        crossBtnListener={ () => setShowMenu(false) }
                    />
                </div>
            )}
            <div className="heading">
                <div className="burger-container">
                    { _Auth.loggedIn() ? 
                        <Button onClick={ () => _Auth.logout() } className="loginbutton">
                            {t("dogexplorer.topheader.logout")}
                        </Button>
                    :
                        <Button component={Link} to="/login" className="loginbutton">
                            {t("dogexplorer.topheader.login")}
                        </Button>
                    }
                    { _Auth.loggedIn() ? 
                        ""
                    :
                        <Button component={Link} to="/registration" className="loginbutton">
                            {t("dogexplorer.topheader.newuser")}
                        </Button>
                    }
                    <IconButton
                        onClick={ () => setShowMenu(true) }
                        className="menubutton"
                    >
                        <img
                            className="burger"
                            src="/assets/images/burger_blue.svg"
                            alt=""
                        />
                    </IconButton>
                </div>
                <div className="biotail-container">
                    <img
                        onClick={() => history.push("/")}
                        className="biotail-logo"
                        style={{ cursor: "pointer" }}
                        src="/assets/images/biotail.svg"
                        alt=""
                    />
                </div>
            </div>
            <div className="header" style={{ backgroundColor: "#2e3694" }}>
                <img
                className="headerImage"
                src="/assets/images/headerbilde_statistikk.jpg"
                alt=""
                style={{ marginTop: "-130px", opacity: "0.3" }}
                />
                <div className="description">
                    <div className={"info-text-container"}>
                        <h3>{t("dogexplorer.topheader.breedstatistics")}</h3>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TopHeader;