
import React, { useState } from 'react';
import Auth from "../../../Auth";
import {toast} from "react-toastify"
import { useTranslate } from 'react-polyglot';
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";

import NavBar from "../../common/Navbar";
import "react-toastify/dist/ReactToastify.css"


function ResetPassword(props){
  toast.configure()
  const  _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();

  const resetToken = props.location.search
      .split("?")[1]
      .split("&")
      .find(item => item.indexOf("token=") != -1)
      .split("=")[1];

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(false);

  const notifyInfo = (text) => toast(text, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    className: "success-toast"
  })
 
  async function changePassword() {
      if (password == password2) {
        _Auth.fetch("/rest/resetPassword/reset?token=" + resetToken +"&password=" + password, {method: "POST"})
        .then( response => {
          if (response.status === 201) {
            setShowSuccess(true);
          }
        }).catch(error => {
          console.log(error);
          setError(true);
          notifyInfo(t("resetpassword.expiredtoken"));
        });
      } else {
        notifyInfo(t("resetpassword.notmatched"));
      }
  }

  return (
    <React.Fragment>
      <NavBar />
      <div className="login">
        {showSuccess &&
          <p>{t("resetpassword.success")}</p>
        }
        <Typography
          classes={{ root: "title" }}
          variant="h3"
          gutterBottom
        >
          {t("resetpassword.new")}
        </Typography>

        <FormControl>
          <InputLabel classes={{ root: "label" }} htmlFor="adornment-password1">
            {t("resetpassword.password")}
          </InputLabel>
          <Input
            classes={{
              underline: "underline",
              input: "input"
            }}
            id="adornment-password1"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={event => setPassword(event.target.value)}
            // onKeyPress={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ color: "#382e92" }}
                >
                  {showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl>
          <InputLabel
            classes={{ root: "label" }}
            htmlFor="adornment-password"
          >
            {t("resetpassword.confirm")}
          </InputLabel>
          <Input
            classes={{
              underline: "underline",
              input: "input"
            }}
            id="adornment-password"
            type={showPassword2 ? "text" : "password"}
            value={password2}
            // onKeyPress={handleKeyPress}
            onChange={event => setPassword2(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword2(!showPassword2)}
                  style={{ color: "#382e92" }}
                >
                  {showPassword2 ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <div className="button-container">
          <Button onClick={() => changePassword()} classes={{ root: "button" }}>
            {t("resetpassword.editpassword")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}


export default ResetPassword;
