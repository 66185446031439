import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import InfoDivider from "../../common/Divider";
import Dots from "../../common/Dots";
import ExpansionParagraph from "../../common/ExpansionParagraph";

import NavBar from "../../common/Navbar";


import SimpleSlider from '../../common/SimpleSlider';

const something = [
  {
      id: "1",
      value: "1",
      sliderPicture: "http://placekitten.com/g/400/300",
      buttonName: "knapp1",
      description: "a slider picture"
  },
  {
      id: "2",
      value: "2",
      sliderPicture: "http://placekitten.com/g/400/300",
      buttonName: "knapp2",
      description: "a slider picture"
      //title: "somethinag",
  },
  {
      id: "3",
      value: "3",
      sliderPicture: "http://placekitten.com/g/400/300",
      buttonName: "knapp3",
      description: "a slider picture"
  },
  {
      id: "4",
      value: "4",
      sliderPicture: "http://placekitten.com/g/400/300",
      buttonName: "knapp4",
      description: "a slider picture"
  },
  {
      id: "5",
      value: "5",
      sliderPicture: "http://placekitten.com/g/400/300",
      buttonName: "knapp5",
      description: "a slider picture"
  },
]

class Summary extends React.Component {


  
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="summary">
          <Card classes={{ root: "summary-card" }}>
            <CardContent>
              <div className="summary-card-title">
                <Typography variant="h3">Oppsummering</Typography>
                <InfoDivider />
                <br />
                <Typography variant="body1" gutterBottom>
                  Du har nå gjennomført alle punktene og kan se over
                  registreringene ved å åpne fanen for hver egenskap.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Dersom du skulle angre på valgt illustrasjon eller opplastet
                  bilde, kan du gå rett inn på hver egenskap og velge på nytt.
                </Typography>
              </div>
              <div className="container-dots">
                <Dots />
              </div>
              <ExpansionParagraph
                title="1. Topplinje

                "
                shouldChangeColor={true}
                children={
                  <Typography classes={{ root: "text" }} variant="subheader">
                    {" "}
                    Bildene dine er synlige for alle andre registrerte brukere i
                    hundeskyen hvis du ønsker, men du kan selv velge hvor mye
                    informasjon andre brukere kan se. Dersom du ønsker at andre
                    brukere kan kontakte deg, så går dette også.{" "}
                    <div className='slider-container' >
                    <SimpleSlider sliderArray={something} children={
                        <Button classes={{ root: "slider-button" }} onClick={this.props.buttonfunction}>
                            Velg
                        </Button>}>
                    </SimpleSlider>
                </div>
                  </Typography>
                }
              />

              
              <ExpansionParagraph
                title="2. Ører

                "
                shouldChangeColor={true}
                children={
                  <Typography classes={{ root: "text" }} variant="subheader">
                    {" "}
                    Bildene dine er synlige for alle andre registrerte brukere i
                    hundeskyen hvis du ønsker, men du kan selv velge hvor mye
                    informasjon andre brukere kan se. Dersom du ønsker at andre
                    brukere kan kontakte deg, så går dette også.{" "}

                  </Typography>
                }
              />
              <ExpansionParagraph
                title="3. Forpart

                "
                shouldChangeColor={true}
                children={
                  <Typography classes={{ root: "text" }} variant="subheader">
                    {" "}
                    Bildene dine er synlige for alle andre registrerte brukere i
                    hundeskyen hvis du ønsker, men du kan selv velge hvor mye
                    informasjon andre brukere kan se. Dersom du ønsker at andre
                    brukere kan kontakte deg, så går dette også.{" "}
                  </Typography>
                }
              />
              <ExpansionParagraph
                title="4. Bakpart

                "
                shouldChangeColor={true}
                children={
                  <Typography classes={{ root: "text" }} variant="subheader">
                    {" "}
                    Bildene dine er synlige for alle andre registrerte brukere i
                    hundeskyen hvis du ønsker, men du kan selv velge hvor mye
                    informasjon andre brukere kan se. Dersom du ønsker at andre
                    brukere kan kontakte deg, så går dette også.{" "}
                  </Typography>
                }
              />
              <ExpansionParagraph
                title="5. Lårvinkel 

                "
                shouldChangeColor={true}
                children={
                  <Typography classes={{ root: "text" }} variant="subheader">
                    {" "}
                    Bildene dine er synlige for alle andre registrerte brukere i
                    hundeskyen hvis du ønsker, men du kan selv velge hvor mye
                    informasjon andre brukere kan se. Dersom du ønsker at andre
                    brukere kan kontakte deg, så går dette også.{" "}
                  </Typography>
                }
              />
              <div className="button-container">
                <Button classes={{ root: "summary button-summary" }}>
                  Godkjenn registrering
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Summary;
