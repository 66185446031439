import React from "react";
import PropTypes from "prop-types";
import { layout } from 'react-stonecutter';
import GridItem from "./GridItem";

const styles = {
    listStyle: "none",
    zIndex: "-10"
}

class Grid extends React.Component {
    state = {
        redirect: false
    }

    render() {
        return (
            <this.props.gridResponsive
                style={ styles }
                component="ul"
                columns={5}
                columnWidth={324}
                gutterWidth={20}
                gutterHeight={226}
                layout={layout.simple}
                duration={400}
                easing="ease-out"
            >
                { this.props.items.map((item, i) => {
                    return (
                        <li key={ item.id }><GridItem line1={ item.animalName } line2={ item.ownerName } line3={ item.animalId } imageSrc={item.imageSrc} /></li>
                    );
                })}
            </this.props.gridResponsive>
        );
    }
}

Grid.propTypes = {
    items: PropTypes.array,
    gridResponsive: PropTypes.func
};

export default Grid;
