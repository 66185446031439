import React, {useState, useEffect} from "react"
import {useStompMsgHandler} from "../../../StompMsgHandler";
import Auth from "../../../Auth"
import Fab from "@material-ui/core/Fab";

import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useTranslate } from 'react-polyglot';

toast.configure()

const ChangePaymentButton = (props) => {
  let stompMsgHandler = useStompMsgHandler();
  let _Auth = new Auth();


  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }
  const t = useTranslate();

  // States
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    if(props) {
      setOrderId(props.orderId)
    }
  }, [])

  const sendChangePaymentRequest = () => {
    let oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        if(stompMsgHandler.STOMPCLIENT.connected === true) {
          // STORE STOMPCLIENT TO SESSIONSTORAGE TO GET BACK AGAIN ON REDIRECT
          sessionStorage.setItem("stompclient", window.STOMPCLIENT);
          stompMsgHandler.STOMPCLIENT.publish({ destination: '/app/msg', body: JSON.stringify({
              'messageType': 'no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgChangeCard',
              'redirectUrl': `${window.location.origin}/netsmodule/payments/recurring/terminal-change-card-response`,
              'payment': { 'orderId': orderId, 'currencyCode': 'NOK', 'amount': 100 }, // Amount må endres?
              'orderDescription': `Change card for deal ID: '${orderId}', and person ID: '${localStorage.getItem("userid")}'`,

              'recurringType': 'R',
              'recurringFrequency': '0',
              'recurringExpiryDate': "20400101",
              'updateStoredPayment': true,

              'userID': localStorage.getItem("userid")
          })});
        } else {
          console.log("Client not connected to websocket...");
          notifyError(t("purchase.changepayment.notify.warning.unknown"))
        }
  }

  return (
    <button
      disabled={props.disabled}
      variant="extended"
      size="medium"
      color="primary"
      aria-label="Add"
      className="add-button-enabled registercard changepaymentbtn"
      onClick={() => sendChangePaymentRequest(orderId)}
    >
        {t("purchase.changepayment.button.registernewcard")}
    </button>
  )
}

export default ChangePaymentButton
