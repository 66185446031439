import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ChangePaymentButton from "../purchase/ChangePaymentButton"
import { useTranslate } from 'react-polyglot';


const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  },
  title: {
    width: "232px",
    height: "28px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "8px",
    lineHeight: "20px"
  },
  cardName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  cardNumber: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  expireDate: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  buttonStyleLeft: {
    position: "relative",
    padding: "6px 45px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    color: "var(--blueberry)",
    border: "solid 2px var(--blueberry)",
    bottom: "-8px",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#dedede"
    },
  },
  buttonStyleRight: {
    float: "right",
    position: "relative",
    padding: "6px 45px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    border: "solid 2px var(--blueberry)",
    backgroundColor: "var(--blueberry)",
    color: "#ffffff"
  }

})


const SettingsCard = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const [hasCardInfo, setHasCardInfo] = useState(false)
  const t = useTranslate();

  useEffect(() => {
    if(props.maskedPan != "") {
      setHasCardInfo(true)
    }
  },[props.maskedPan])


  return (

    <div className="row">
      <div className="col" >
        {  hasCardInfo ?
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("settings.payment")}
            </Typography>
            <Typography className={classes.cardName} color="textSecondary">
              {props.issuer}
            </Typography>
            <Typography className={classes.cardNumber} color="textSecondary">
              {props.maskedPan}
            </Typography>
            <Typography className={classes.expireDate} component="p">
              {t("settings.expiration") + props.expiryDate}
            </Typography>
            {
              /*
              <button
              variant="extended"
              size="medium"
              aria-label="Add"
              onClick={() => alert("Not implemented")}
              className={classes.buttonStyleLeft}
              >Slett kort</button>
              */
            }
            <ChangePaymentButton orderId={props.orderId}> </ChangePaymentButton>
          </CardContent>
        </Card>
    :
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("settings.payment")}
            </Typography>
            <Typography className={classes.expireDate} component="p">
              {t("settings.mysubscriptions.description")}
            </Typography>
            {
              /*
              <button
                variant="extended"
                size="medium"
                aria-label="Add"
                onClick={() => alert("Not implemented")}
                className={classes.buttonStyleLeft}>Slett kort </button>
                */
            }
            <button
            variant="extended"
            size="medium"
            aria-label="Add"
            onClick={() => history.push("/products")}
            className={classes.buttonStyleRight}>{t("settings.products")}</button>
          </CardContent>
        </Card>
      }
</div>
</div>
)
}

export default SettingsCard;
