import React, { useState, useEffect } from 'react';

import TaskCard from "../TaskCard";
import { TextField } from '@material-ui/core';

import { useTranslate } from 'react-polyglot';

function DurationWizPage(props) {
    const t = useTranslate();

    const [yearValue, setYearValue] = useState(0);
    const [monthValue, setMonthValue] = useState(0);
    const [weekValue, setWeekValue] = useState(0);
    const [dayValue, setDayValue] = useState(0);
    const [hourValue, setHourValue] = useState(0);
    const [minuteValue, setMinuteValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);

    function triggerPageUpdated(e, timeframe) {
        if ( timeframe == "year" ) setYearValue(e.target.value);
        if ( timeframe == "month" ) setMonthValue(e.target.value);
        if ( timeframe == "week" ) setWeekValue(e.target.value);
        if ( timeframe == "day" ) setDayValue(e.target.value);
        if ( timeframe == "hour" ) setHourValue(e.target.value);
        if ( timeframe == "minute" ) setMinuteValue(e.target.value);
        if ( timeframe == "second" ) setSecondValue(e.target.value);

        props.pageUpdatedCallback({
            ...props.trait,
            value: {
                yearValue: timeframe == "year" ? e.target.value : yearValue,
                monthValue: timeframe == "month" ? e.target.value : monthValue,
                weekValue: timeframe == "week" ? e.target.value : weekValue,
                dayValue: timeframe == "day" ? e.target.value : dayValue,
                hourValue: timeframe == "hour" ? e.target.value : hourValue,
                minuteValue: timeframe == "minute" ? e.target.value : minuteValue,
                secondValue: timeframe == "second" ? e.target.value : secondValue
            }
        });
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        try {
            let value = JSON.parse(props.trait.value);
            setYearValue(value.yearValue);
            setMonthValue(value.monthValue);
            setWeekValue(value.weekValue);
            setDayValue(value.dayValue);
            setHourValue(value.hourValue);
            setMinuteValue(value.minuteValue);
            setSecondValue(value.secondValue);
        } catch (ex) {
            console.debug("no resume value");
        }
    }, []);

    return(
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskHeader={props.trait.traitName}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="duration-content-wrapper" style={{ padding: "20px" }}>
                            {props.trait.timeframe.split("|").map((timeframe, key) => 
                                <React.Fragment key={key} >
                                    <div className="duration-textfield" style={{
                                        width: "25%",
                                        float: "left",
                                        padding: "6px"
                                    }}>
                                        <TextField
                                            id={props.trait.traidId}
                                            label={
                                                timeframe == "year" ? t("registrationwizard.duration.year") :
                                                timeframe == "month" ? t("registrationwizard.duration.month") :
                                                timeframe == "week" ? t("registrationwizard.duration.week") :
                                                timeframe == "day" ? t("registrationwizard.duration.day") :
                                                timeframe == "hour" ? t("registrationwizard.duration.hour") :
                                                timeframe == "minute" ? t("registrationwizard.duration.minute") :
                                                timeframe == "second" ? t("registrationwizard.duration.second") : ""
                                            }
                                            type="number"
                                            placeholder={""}
                                            value={
                                                timeframe == "year" ? yearValue :
                                                timeframe == "month" ? monthValue :
                                                timeframe == "week" ? weekValue :
                                                timeframe == "day" ? dayValue :
                                                timeframe == "hour" ? hourValue :
                                                timeframe == "minute" ? minuteValue :
                                                timeframe == "second" ? secondValue : 0
                                            }
                                            onChange={(e) => triggerPageUpdated(e, timeframe)}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );

}

export default DurationWizPage
