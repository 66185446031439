import React, { useEffect } from "react";

function Docs(props) {

    const docsmap = {
        "0001": "/documents/Vaare_dyr.pdf"
    };

    useEffect(() => {
        window.location = docsmap[props.match.params.docid];
    }, []);

    return(
        <div className="docs">
            <p>Laster inn dokument ..</p>
        </div>
    );
}

export default Docs;
