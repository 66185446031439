import React from "react";
import { useTranslate } from 'react-polyglot';

import ExpansionParagraph from "./ExpansionParagraph";
import Typography from "@material-ui/core/Typography";

function Faq(){
    const t = useTranslate();

    return (
        <div className="expandable-container">
            <Typography classes={{ root: "title" }} variant="h3">
                {t("faq.title")}
            </Typography>
            <ExpansionParagraph
                title={t("faq.Q1.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q1.description")}
                    </Typography>
                }
            />
            <ExpansionParagraph
                title={t("faq.Q2.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q2.description")}
                    </Typography>
                }
            />
            <ExpansionParagraph
                title={t("faq.Q3.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q3.description")}
                    </Typography>
                }
            />
            {/*<ExpansionParagraph
                title="Hvordan får jeg billigere forsikring?"
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                    Ved å hake av at hunden er registrert i Biotail, får du 10
                    prosent avslag når du bestiller dyreforsikring hos Gjensidige.
                    Forsikringsselskapet har ikke tilgang til Biotails data
                    </Typography>
                }
            />*/}
            <ExpansionParagraph
                title={t("faq.Q4.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q4.description")}
                    </Typography>
                }
            />
            <ExpansionParagraph
                title={t("faq.Q5.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q5.description")}​
                    </Typography>
                }
            />
            <ExpansionParagraph
                title={t("faq.Q6.title")}
                shouldChangeColor={true}
                children={
                    <Typography classes={{ root: "text" }}>
                        {t("faq.Q6.description")}
                    </Typography>
                }
            />
        </div>
    );
}

export default Faq;
