import React, { useRef, useState, useEffect, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { Redirect } from "react-router";

import { Divider } from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Auth from "../../../Auth";
import Menu from "../menu/Menu";

import { useStompMsgHandler } from "../../../StompMsgHandler";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useTranslate } from 'react-polyglot';
import LocaleHandler from '../../../LocaleHandler';

toast.configure()


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "lightgray",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#ff6c5c',
  },
})(LinearProgress);

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

const LandingPage = (props) => {
  let _Auth = new Auth();
  let stompMsgHandler = useStompMsgHandler();
  let history = useHistory();
  const forceUpdate = useForceUpdate();
  const t = useTranslate();
  const { setLocale } = React.useContext(LocaleHandler);

  let vippsRef = null;

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const [redirectRegistration, setRedirectRegistration] = useState(false)
  const [redirectAbout, setRedirectAbout] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [goals, setGoals] = useState([])

  useEffect(() => {
    getInfo();
  }, [])


  const showMenuBtn = () => {
    setShowMenu(true)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const handleRedirectRegistration = () => {
    setRedirectRegistration(true)
  };

  const handleRedirectAbout = () => {
    setRedirectAbout(true)
  };

  const logout = () => {
    stompMsgHandler.handleLogout();
    _Auth.logout();
    forceUpdate()
  }

  const getInfo = () => {
    _Auth.fetch("/rest/donationgoals/")
      .then(response => response.json())
      .then(data => {
        setGoals(data)
        // Set opacity on the bars in the vipps thingy
        let index = 0;
        for (const item of document.getElementsByClassName("bar-item")) {
            item.children[0].style.opacity = data[index].progress / 100;
            index += 1;
        }
      })
      .catch(error => console.error("Error:", error));
  }

  const copyStringToClipboard = () => {
    let str = "564841"
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);


    notifyInfo("Kopiert");
 }

    if (redirectRegistration) {
      return <Redirect to={"/registration"} />;
    }

    if (redirectAbout) {
      return <Redirect to={"/howdoesitwork"} />;
    }
    return (
      <React.Fragment>
        <div className="landingpage">
          { window.innerWidth > 600 ? (
            <div className="top-menu-container" style={ showMenu ? {
              display: "block",
              position: "fixed",
              width: "400px",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              right: "-20px",
              transition: "right 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "400px",
              zIndex: "1000",
              top: "0px",
              right: "-500px",
              transition: "right 250ms linear"
            }}>
            <Menu
              crossBtnListener={ closeMenu }
            />
          </div>
          ) : (
            <div className="top-menu-container" style={ showMenu ? {
              display: "block",
              position: "fixed",
              width: "100%",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              left: "0px",
              transition: "left 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "100%",
              zIndex: "1000",
              top: "0px",
              left: "calc(-100% - 100px)",
              transition: "left 250ms linear"
            }}>
            <Menu
              crossBtnListener={ closeMenu }
            />
            </div>
          ) }
          <div className="heading">
            <div className="burger-container">
              <div className="langswitchers MuiButtonBase-root MuiButton-root MuiButton-text loginbutton">
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_no.svg"
                  alt=""
                  onClick={() => {
                    setLocale("no");
                    localStorage.setItem("language", "no");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_se.svg"
                  alt=""
                  onClick={() => {
                    setLocale("se");
                    localStorage.setItem("language", "se");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_gb.svg"
                  alt=""
                  onClick={() => {
                    setLocale("en");
                    localStorage.setItem("language", "en");
                  }}
                />
              </div>
              { _Auth.loggedIn() ?
                <Button onClick={ logout } className="loginbutton">
                  {t("landingpage.logout")}
                </Button>
              :
                <Button component={Link} to="/login" className="loginbutton">
                  {t("landingpage.login")}
                </Button>
              }
              { _Auth.loggedIn() ?
                ""
              :
                <Button component={Link} to="/registration" className="loginbutton">
                 {t("landingpage.newuser")}
                </Button>
              }
              <IconButton
                onClick={ showMenuBtn }
                className="menubutton"
              >
                <img
                  className="burger"
                  src="/assets/images/burger_blue.svg"
                  alt=""
                />
              </IconButton>
            </div>
            <div className="biotail-container">
              <img
                className="biotail-logo"
                src="/assets/images/biotail.svg"
                alt=""
              />
            </div>
          </div>
          <div className="header">
            <img
              className="headerImage"
              src="/assets/images/biotail-header2.jpg"
              alt=""
            />
            <div className="button-container">
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="Add"
                className="add-button"
                onClick={() => {
                  // Open link in new tab
                  window.open("https://aninova.no/nettkurs/", "_blank");
                }}
              >
                  {t("landingpage.goals")}
              </Fab>
            </div>
          </div>
          <div className="description">
            <div className={"info-text-container"}>
              <Typography className={"info-text"} style={{ color: "blueberry" }}>
                {t("landingpage.description1")}<br/>{t("landingpage.description2")}
              </Typography>
              <br/>
              <br/>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="Add"
                className="add-button"
                onClick={() => {
                  handleRedirectAbout();
                }}
              >
                  {t("landingpage.about")}
              </Fab>
            </div>
          </div>

          <div className="videopresentations-card">
            <Card className={"info-card first"}>
              <card-content>
                <div className={"info-card-content"}>
                  <div className={"info-card-header"}>
                    <img
                      className="info-card-image"
                      src="/assets/images/icon_statistikk.svg"
                      alt=""
                    />

                    <div className={"info-card-text-header"}>
                      {t("landingpage.info.videolecture.title")}
                    </div>
                  </div>
                  <div className={"info-card-body"}>
                    <div className={"info-card-body-text"}>
                      {t("landingpage.info.videolecture.description")}
                    </div>
                      <div className={"info-card-body-link"}>
                      <Button
                        //component={Link}
                        //to="/lectures"
                        onClick={() => {
                          // Open link in new tab
                          window.open("https://aninova.no/nettkurs/", "_blank");
                        }}
                        classes={{ root: "info-card-body-link-text" }}
                      >
                        <div className={"button-link-text"}>{t("landingpage.info.videolecture.gotolectures")}</div>
                      </Button>
                      <img
                        className="info-card-body-link-image"
                        src="/assets/images/pilhoyre.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </card-content>
            </Card>
          </div>

          <div className="doggo-image-container-withcontent">
          <div className={"info-card-container"}>
              <Card className={"info-card first"}>
                <card-content>
                  <div className={"info-card-content"}>
                    <div className={"info-card-header"}>
                      <img
                        className="info-card-image"
                        src="/assets/images/potegronn.svg"
                        alt=""
                      />

                      <div className={"info-card-text-header"}>
                        {t("landingpage.info.adddogs.title")}
                      </div>
                    </div>
                    <div className={"info-card-body"}>
                      <div className={"info-card-body-text"}>
                        {t("landingpage.info.adddogs.description")}
                        {/* <br />
                        <img className={"kommer-image"} src="/assets/images/coming_statistics2.svg" /> */}
                      </div>
                       <div className={"info-card-body-link"}>
                        <Button
                          component={Link}
                          to="/mydogs"
                          classes={{ root: "info-card-body-link-text" }}
                        >
                          <div className={"button-link-text"}>{t("landingpage.info.adddogs.gotomydogs")}</div>
                        </Button>
                        <img
                          className="info-card-body-link-image"
                          src="/assets/images/pilhoyre.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </card-content>
              </Card>


              <Card className={"info-card center"}>
                <card-content>
                  <div className={"info-card-content"}>
                    <div className={"info-card-header"}>
                      <img
                        className="info-card-image"
                        src="/assets/images/gronnhund.svg"
                        alt=""
                      />

                      <div className={"info-card-text-header"}>
                        {t("landingpage.info.addpics.title")}
                      </div>
                    </div>
                    <div className={"info-card-body"}>
                      <div className={"info-card-body-text"}>
                        {t("landingpage.info.addpics.description")}
                      </div>

                      <div className={"info-card-body-link"}>
                        <Button
                          component={Link}
                          to="/gallery"
                          classes={{ root: "info-card-body-link-text" }}
                        >
                          <div className={"button-link-text"}>{t("landingpage.info.addpics.gotogallery")}</div>
                        </Button>

                        <img
                          className="info-card-body-link-image"
                          src="/assets/images/pilhoyre.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </card-content>
              </Card>

              <Card className={"info-card last box"}>
                {/*<div className="ribbon ribbon-top-right"><span>{t("landingpage.info.statistics.new")}</span></div>*/}
                <card-content>
                  <div className={"info-card-content"}>
                    <div className={"info-card-header"}>
                      <img
                        className="info-card-image"
                        src="/assets/images/icon_statistikk.svg"
                        alt=""
                        style={{ width: "38px" }}
                      />
                      <div className={"info-card-text-header"}>
                        {t("landingpage.info.statistics.title")}
                      </div>
                    </div>
                    <div className={"info-card-body"}>
                      <div className={"info-card-body-text"}>
                        {t("landingpage.info.statistics.description")}
                      </div>
                      <div className={"info-card-body-link"}>
                        <Button
                          component={Link}
                          to="/statistics/weight"
                          classes={{ root: "info-card-body-link-text" }}
                        >
                          <div className={"button-link-text"}>{t("landingpage.info.statistics.gotostatistics")}</div>
                        </Button>
                        <img
                          className="info-card-body-link-image"
                          src="/assets/images/pilhoyre.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </card-content>
              </Card>
            </div>

            <Card id="news-card" className={"vipps-card"}>
              <card-content>
                <div className={"survey-card-content vipps"}>
                  <div className={"survey-card-body vipps"}>
                    <div className="survey-card-title-container">
                      <p className="survey-card-title">
                        {t("landingpage.survey.title")}
                      </p>
                    </div>
                    <Divider />
                    <div className="survey-card-content-container">
                      <div className="survey-card-content-column">
                        <Typography gutterBottom variant="body1">
                          {t("landingpage.survey.description1")}
                          <br/><br/>
                          {t("landingpage.survey.description2")}
                          <br/><br/>
                          {t("landingpage.survey.description3")}
                        </Typography>
                      </div>
                      <div className="survey-card-content-column">
                        <img className="survey-card-picture-resize" alt="" src="/assets/images/gjensidige_logo.svg"></img>
                      </div>
                    </div>
                    <div className={"info-card-body-link"}>
                      <Button
                        component={Link}
                        to="/mysettings"
                        classes={{ root: "info-card-body-link-text" }}
                      >
                        <div className={"button-link-text"}>{t("landingpage.survey.gotomypage")}</div>
                      </Button>
                      <img
                        className="info-card-body-link-image"
                        src="/assets/images/pilhoyre.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </card-content>
            </Card>
          </div>



          <div className={"doggo-image-container"}>
            <Card className={"survey-card"}>
              <card-content>
                <div className={"survey-card-content"}>
                  <div className={"survey-card-body"}>
                    <div className="survey-card-title-container">
                      <p className="survey-card-title">
                        {t("landingpage.survey.collaborators.title")}
                      </p>
                    </div>
                    <Divider />
                    <div className="survey-card-partners">
                      <a href="http://aninova.no" target="_blank">
                        <img
                          className="survey-card-partner"
                          src="/assets/images/logo_aninova.svg"
                          alt=""
                        />
                      </a>
                      <a href="https://gjensidige.no" target="_blank">
                        <img
                          style={{ maxHeight: "50px" }}
                          className="survey-card-partner"
                          src="/assets/images/logo_gjensidige.svg"
                          alt=""
                        />
                      </a>
                      <a href="https://heidner.no/" target="_blank">
                        <img
                          style={{ maxHeight: "50px" }}
                          className="survey-card-partner"
                          src="/assets/images/logo_biocluster.png"
                          alt=""
                        />
                      </a>
                      <a href="https://www.innovasjonnorge.no/" target="_blank">
                        <img
                          style={{ maxHeight: "50px" }}
                          className="survey-card-partner"
                          src="/assets/images/logo_innovasjonnorge.svg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </card-content>
            </Card>
            <div className="mobile-doggoimage" style={{ display: "none" }}>

            </div>
          </div>

          <div className={"bottom-text"}>
            <div className={"collaborators"}>
              <div className={"collaborators-title"}>
                <img
                  className="b-image2"
                  src="/assets/images/B.svg"
                  alt=""
                />
                <br/>
                <p className="ownertext">
                  {t("landingpage.survey.collaborators.owner")} <u><a style={{ color: "white" }} target="_blank" href="http://aninova.no/">Aninova</a></u>
                </p>
              </div>
              <div className={"strek2 hide-on-desktop"}></div>
            </div>
            <div className={"contact"}>
              <u><Link className={"link"} to="/howdoesitwork" >{t("landingpage.survey.collaborators.about")}</Link></u> <br />
              <u><Link className={"link"} to="/gdpr" >{t("landingpage.survey.collaborators.cookies")}</Link></u> <br />
              <div className={"strek2"}></div>
                {t("landingpage.survey.collaborators.contact")} <u><a className={"link"} href="mailto:post@aninova.no" >post@aninova.no</a></u> {t("landingpage.survey.collaborators.or")} <u><a style={{ color: "white" }} target="_blank" href="https://www.facebook.com/biotail/">Facebook</a></u>.
              <div className={"strek2"}></div>
              <div className={"developer-text"}>{t("landingpage.survey.collaborators.developedby")} <u><a className={"link"} href="https://kongarthur.no" target="_blank">Kong Arthur</a></u></div>
            </div>
          </div>
        </div>


      </React.Fragment>
    );

}

export default LandingPage;
