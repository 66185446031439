import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import DogExplorerStatisticsGeneral from "./dogExplorerStatisticsGeneral/DogExplorerStatisticsGeneral";
import DogExplorerStatisticsHealth from "./dogExplorerStatisticsHealth/DogExplorerStatisticsHealth";
import DogExplorerStatisticsEVA from "./dogExplorerStatisticsEVA/DogExplorerStatisticsEVA";
import DogExplorerStatisticsJaktprove from "./dogExplorerStatisticsJaktprove/DogExplorerStatisticsJaktprove";
import DogExplorerStatisticsOthergroup from "./dogExplorerStatisticsOthergroup/DogExplorerStatisticsOthergroup";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import DogExplorerTypeselector from "../dogExplorerTypeselector/DogExplorerTypeselector";

function DogExplorerStatistics(props) {
    let history = useHistory();
    const t = useTranslate();

    const [type, setType] = useState("generelt");

    function RenderContentArea(type) {
        switch (type) {
            case "generelt":
                return (
                    <DogExplorerStatisticsGeneral
                        type={props.type}
                        loading={props.loading}
                    ></DogExplorerStatisticsGeneral>
                );
            case "helse":
                return (
                    <DogExplorerStatisticsHealth
                        type={props.type}
                        loading={props.loading}
                    ></DogExplorerStatisticsHealth>
                );
            case "slektskap":
                return (
                    <DogExplorerStatisticsEVA
                        type={props.type}
                        loading={props.loading}
                    ></DogExplorerStatisticsEVA>
                );
            case "bruk":
                return (
                    <DogExplorerStatisticsJaktprove
                        type={props.type}
                        loading={props.loading}
                    ></DogExplorerStatisticsJaktprove>
                );
            case "othergroup":
                return (
                    <DogExplorerStatisticsOthergroup
                        type={props.type}
                        loading={props.loading}
                    ></DogExplorerStatisticsOthergroup>
                );
            default:
                return (
                    <DogExplorerStatisticsGeneral
                        type={"generelt"}
                        loading={props.loading}
                    ></DogExplorerStatisticsGeneral>
                );
        }
    }

    useEffect(() => {
        
    }, [props.filters])

    return (
        <React.Fragment>
            <div className="dogexplorer-statistics">
                <div className="row">
                    <div className="col col-md-12">
                        <Accordion expanded={type == "generelt"} onChange={() => setType(type != "generelt" ? "generelt" : "")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <div>
                                    <h3>{t("dogexplorer.statistics.generally")}</h3>
                                </div>
                                <Divider classes={{ root: "divider" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {type == "generelt" ? RenderContentArea("generelt") : null}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={type == "helse"} onChange={() => setType(type != "helse" ? "helse" : "")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <h3>{t("dogexplorer.statistics.health")}</h3>
                                <Divider classes={{ root: "divider" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {type == "helse" ? RenderContentArea("helse") : null}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={type == "slektskap"} onChange={() => setType(type != "slektskap" ? "slektskap" : "")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <h3>{t("dogexplorer.statistics.relationships")}</h3>
                                <Divider classes={{ root: "divider" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {type == "slektskap" ? RenderContentArea("slektskap") : null}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={type == "bruk"} onChange={() => setType(type != "bruk" ? "bruk" : "")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <h3>{t("dogexplorer.statistics.use")}</h3>
                                <Divider classes={{ root: "divider" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {type == "bruk" ? RenderContentArea("bruk") : null}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={type == "other"} onChange={() => setType(type != "other" ? "other" : "")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <h3>{t("dogexplorer.statistics.other")}</h3>
                                <Divider classes={{ root: "divider" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {type == "other" ? RenderContentArea("othergroup") : null}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    
                    {/* <div className="col col-md-3">
                        <DogExplorerTypeselector
                            onChange={(type) => {
                                setType(type);
                            }}
                        ></DogExplorerTypeselector>
                    </div>
                    <div className="col col-md-9">
                        {RenderContentArea(type)}
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerStatistics;