import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import { useHistory } from 'react-router';

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Menu from "../menu/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import NavBar from "../../common/Navbar";

import Auth from "../../../Auth";
import JwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useTranslate } from "react-polyglot";
import LocaleHandler from '../../../LocaleHandler';

function Lectures(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const { setLocale } = React.useContext(LocaleHandler);

  const [showMenu, setShowMenu] = useState(false);
  const [statisticsDisabled, setStatisticsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  
  const [selectedLecture, setSelectedLecture] = useState(null);

  const [lectures, setLectures] = useState([
    // {
    //   id: 1,
    //   title: "Lecture 1",
    //   description: "This is the first lecture",
    // },
    // {
    //   id: 2,
    //   title: "Lecture 2",
    //   description: "This is the first lecture",
    // },
    // {
    //   id: 3,
    //   title: "Lecture 3",
    //   description: "This is the first lecture",
    // },
    // {
    //   id: 4,
    //   title: "Lecture 4",
    //   description: "This is the first lecture",
    // }
  ]);

  function checkPaymentStatus() {
    let token = localStorage.getItem("id_token");
    if (token != undefined) {
      let decodedToken = JwtDecode(token);
      if (decodedToken.authorities != undefined && decodedToken.authorities.some(item => item == "ROLE_P1") ) {
        setStatisticsDisabled(false);
      }
    }
  }

  function getLectures() {
    setLoading(true);
    
    fetch(`/rest/videoupload/getlectures`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    }).then(response => {
      setLoading(false);
      if(response.ok && response.status === 200){
        return response.json()
      }
    }).then(data => {
      console.log(data);
      setLectures([
        ...lectures,
        ...data
      ]);
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    checkPaymentStatus();
    getLectures();
  }, []);

  return (
    <React.Fragment>
      <div className="statistics">
        <div className="landingpage">
          { window.innerWidth > 600 ? (
            <div className="top-menu-container" style={ showMenu ? { 
              display: "block",
              position: "fixed",
              width: "400px",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              right: "-20px",
              transition: "right 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "400px",
              zIndex: "1000",
              top: "0px",
              right: "-500px",
              transition: "right 250ms linear"
            }}>
            <Menu
              crossBtnListener={() => setShowMenu(false) }
            />
          </div>
          ) : (
            <div className="top-menu-container" style={ showMenu ? { 
              display: "block",
              position: "fixed",
              width: "100%",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              left: "0px",
              transition: "left 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "100%",
              zIndex: "1000",
              top: "0px",
              left: "calc(-100% - 100px)",
              transition: "left 250ms linear"
            }}>
            <Menu
              crossBtnListener={() => setShowMenu(false) }
            />
            </div>
          ) }
          <div className="heading">
            <div className="burger-container">
              <div className="langswitchers MuiButtonBase-root MuiButton-root MuiButton-text loginbutton">
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_no.svg"
                  alt=""
                  onClick={() => {
                    setLocale("no");
                    localStorage.setItem("language", "no");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_se.svg"
                  alt=""
                  onClick={() => {
                    setLocale("se");
                    localStorage.setItem("language", "se");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_gb.svg"
                  alt=""
                  onClick={() => {
                    setLocale("en");
                    localStorage.setItem("language", "en");
                  }}
                />
              </div>
              { _Auth.loggedIn() ? 
                <Button onClick={() => { _Auth.logout(); localStorage.clear(); } } className="loginbutton">
                  {t("statistics.main.logout")}
                </Button>
              :
                <Button component={Link} to="/login" className="loginbutton">
                  {t("statistics.main.login")}
                </Button>
              }
              { _Auth.loggedIn() ? 
                ""
              :
                <Button component={Link} to="/registration" className="loginbutton">
                  {t("statistics.main.newuser")}
                </Button>
              }
              <IconButton
                onClick={() => setShowMenu(true) }
                className="menubutton"
              >
                <img
                  className="burger"
                  src="/assets/images/burger_blue.svg"
                  alt=""
                />
              </IconButton>
            </div>
            <div className="biotail-container">
              <img
                onClick={() => history.push("/") }
                className="biotail-logo"
                style={{ cursor: "pointer" }}
                src="/assets/images/biotail.svg"
                alt=""
              />
            </div>
          </div>
          <div className="header" style={{ backgroundColor: "#2e3694" }}>
            <img
              className="headerImage"
              src="/assets/images/headerbilde_statistikk.jpg"
              alt=""
              style={{ marginTop: "-130px", opacity: "0.3" }}
            />
            <div className="description">
              <div className={"info-text-container"}>
                <h3>{t("lectures.main.title")}</h3>
              </div>
            </div>
          </div>

          <div className="lectures-container">
            { loading ?
              <div className="loading-container" style={{ margin: "auto" }}>
                <div className="loading">
                  &nbsp;
                  <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry" }}>
                    {t("lectures.main.loading")}
                  </Typography>
                </div>
              </div>
            : null }
            { !loading && lectures.length == 0 ?
              <div className={"info-text-container"} style={{ margin: "auto" }}>
                &nbsp;
                <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", textAlign: "center" }}>
                  {t("lectures.main.nolectures")}
                </Typography>
                <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", textAlign: "center" }}>
                  {t("lectures.main.checkloginsubscription")}
                </Typography>
                <Typography
                  className={"info-text"}
                  style={{
                    paddingBottom: "25px",
                    color: "blueberry",
                    textDecoration: "underline",
                    textAlign: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push({ pathname: '/products'}) }
                >
                  {t("lectures.main.subscribelink")}
                </Typography>
              </div>
            : 
              !loading && lectures.length > 0 ?
                <div className={"info-text-container"} style={{ margin: "auto" }}>
                  &nbsp;
                  <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry", textAlign: "center" }}>
                    {t("lectures.main.description")}
                  </Typography>
                </div>
              : null
            }
          </div>
          
          <div className="lectures-container">
            { lectures.map((lecture, index) => 
              <Card className={"info-card lecturecard"} onClick={() => {
                setSelectedLecture(lecture);
              }}>
                <card-content>
                  <div className="lecturecard-header">
                    <div className="card-title">
                      <h1>{lecture.title}</h1>
                    </div>
                  </div>
                  <div className="lecturecard-content">
                    <div className="card-image">
                      <img
                        className="cardimage"
                        src={lecture.imageLink != null ? ("https://" + lecture.imageLink) : ""}
                        alt=""
                      />
                    </div>
                    &nbsp;
                    <div className="card-text">
                      <p>{lecture.description}</p>
                    </div>
                  </div>
                </card-content>
              </Card>
            )}
          </div>

          { selectedLecture != null ? 
            <div
              className="lectureplayermodal"
              onClick={(event) => {
                // If the click is actually in the gray area, close the modal
                if (event.target.className == "lectureplayermodal") {
                  setSelectedLecture(null);
                }
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <div className="lectureplayercontent">
                <div className="lectureplayerheader">
                  <div className="card-title">
                    <Typography className={"info-text"} style={{ color: "blueberry" }}>
                      { selectedLecture != null ? selectedLecture.title : "" }
                    </Typography>
                  </div>
                  <div className="closebtn" onClick={() => setSelectedLecture(null) }>
                    X
                  </div>
                </div>
                <div className="lectureplayer">
                  <div className="lectureplayer-video">
                    <video
                      controls
                      // Making it harder to download or share the video
                      controlsList="nodownload"
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <source src={selectedLecture != null ? ("https://" + selectedLecture.videoLink) : ""} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          : null }

          <div className={"bottom-text"}>
            <div className={"collaborators"}>
              <div className={"collaborators-title"}>
                <img
                  className="b-image2"
                  src="/assets/images/B.svg"
                  alt=""
                />
                <br/>
                <p className="ownertext">
                  {t("statistics.footer.owner")} <a style={{ color: "white" }} target="_blank" href="http://aninova.no/">Aninova</a>
                </p>
              </div>
              <div className={"strek2 hide-on-desktop"}></div>
            </div>
            <div className={"contact"}>
              <Link className={"link"} to="/howdoesitwork" >{t("statistics.footer.about")}</Link> <br />
              <Link className={"link"} to="/gdpr" >{t("statistics.footer.cookies")}</Link> <br />
              <div className={"strek2"}></div>
              {t("statistics.footer.contact")} <a className={"link"} href="mailto:post@aninova.no" >post@aninova.no</a> {t("statistics.footer.or")} @biotail {t("statistics.footer.at")} <u><a style={{ color: "white" }} target="_blank" href="https://www.facebook.com/biotail/">Facebook</a></u>.
              <div className={"strek2"}></div>
              <div className={"developer-text"}>{t("statistics.footer.developer")} <a className={"link"} href="https://kongarthur.no" target="_blank">Kong Arthur</a> </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Lectures;
