import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

function Footer(props) {
    const t = useTranslate();

    useEffect(() => {
        
    }, [])

    return (
        <React.Fragment>
            <div className={"bottom-text"}>
                <div className={"collaborators"}>
                    <div className={"collaborators-title"}>
                        <img
                            className="b-image2"
                            src="/assets/images/B.svg"
                            alt=""
                        />
                        <br/>
                        <p className="ownertext">
                            {t("dogexplorer.footer.owner")} <a style={{ color: "white" }} target="_blank" href="http://aninova.no/">Aninova</a>
                        </p>
                    </div>
                    <div className={"strek2 hide-on-desktop"}></div>
                </div>
                <div className={"contact"}>
                    <Link className={"link"} to="/howdoesitwork" >{t("dogexplorer.footer.about")}</Link>
                    <br />
                    <Link className={"link"} to="/gdpr" >{t("dogexplorer.footer.cookies")}</Link>
                    <br />
                    <div className={"strek2"}></div>
                        {t("dogexplorer.footer.contact")} <a className={"link"} href="mailto:post@aninova.no" >post@aninova.no</a> {t("dogexplorer.footer.or")} <u><a style={{ color: "white" }} target="_blank" href="https://www.facebook.com/biotail/">Facebook</a></u>.
                    <div className={"strek2"}></div>
                    <div className={"developer-text"}>
                        {t("dogexplorer.footer.developedby")} <a className={"link"} href="https://kongarthur.no" target="_blank">Kong Arthur</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Footer;