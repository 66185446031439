import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

import { ExplorerContext } from "../../ExplorerContext";

import Card from "../cardTypes/Card";

function DogExplorerStatisticsEVA(props) {
    let history = useHistory();

    let explorer = useContext(ExplorerContext);

    useEffect(() => {
        
    }, [props.data])

    return (
        <React.Fragment>
            <div className="dogexplorer-statistics-eva">
                <div className="row">
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="number"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="birthyeardistribution"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="siblingsdistribution"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    {explorer.types.includes("eva") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="fcoefbirthyear"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="pci6birthyear"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="ebvrelationshipstand"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                        </React.Fragment>
                    : null }
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerStatisticsEVA;