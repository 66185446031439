import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-polyglot';

const useStyles = makeStyles({
  root: {
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    minHeight: "243px"
  },
  cardcontentselected: {
    boxShadow: "0px 0px 0px 8px #5cf79bb5 inset",
    minHeight: "243px"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "18px",
    paddingBottom: "6px",
  },
  pos: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  dateText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  cardcontent: {
    padding: "24px",
    paddingBottom: "66px !important",
  },
  price: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)",
    position: "absolute",
    bottom: "26px",
  },
  bottomSide: {
    width: "135px",
    height: "40px",
    borderRadius: "35px",
    //border: "solid 2px #40349d",
    float: "right",
    bottom: "18px",
    right: "36px",
    position: "absolute",
    padding: "9px 9px",
    textAlign: "right"
  },
  bottomSideChosen: {
    width: "135px",
    height: "40px",
    borderRadius: "35px",
    //border: "solid 2px #40349d",
    float: "right",
    bottom: "18px",
    right: "36px",
    position: "absolute",
    padding: "9px 9px",
    textAlign: "right",
    //backgroundColor: "#6cefa117"
  },
  bottomSideChosenRed: {
    width: "135px",
    height: "40px",
    borderRadius: "35px",
    //border: "solid 2px #40349d",
    float: "right",
    bottom: "18px",
    right: "36px",
    position: "absolute",
    padding: "9px 9px",
    textAlign: "right",
    //backgroundColor: "#eb0c0c"
  },
  topRight: {
    objectFit: "contain",
    transform: "rotate(360deg)",
    float: "right",
  },
  selectable: {
    cursor: "pointer",
  },
  avbestillbtn: {
    width: "105px",
    height: "40px",
    borderRadius: "35px",
    //border: "solid 2px #40349d",
    float: "right",
    bottom: "18px",
    right: "36px",
    position: "absolute",
    padding: "9px 9px",
    textAlign: "right",
    //backgroundColor: "#6cefa117"
    "&:hover": {
      cursor: "pointer",
    }
  }
});

const ProductCard = (props) => {
  const history = useHistory();
  const t = useTranslate();

  const classes = useStyles();
  const [id, setId] = useState([])
  const [active, setActive] = useState(true)
  const [validUntilMonth, setValidUntilMonth] = useState("")
  const [validUntilDay, setValidUntilDay] = useState("")
  const [validUntilYear, setValidUntilYear] = useState("")
  const [discounts, setDiscounts] = useState([])
  const [loading, setLoading] = useState(true)

  const [prodDiscount, setProdDiscount] = useState({})
  const [hasDiscount, setHasDiscount] = useState(false)

  const selected = props.data.checked;
  const owned = props.data.owned;
  const priceAsNumber = Number(props.data.price/100);

  const tempClass = selected ? "bottomSideChosen" : "bottomSide"
  const tempClassCancel = selected ? "bottomSideChosenRed" : "bottomSide"


  useEffect(() => {
    if(props.data != undefined)
    setId(props.data.id)
  }, [id])

  useEffect(() => {
    if(id != undefined) {
      productStatus()
      checkUserDiscounts()
    }
  },[])

  useEffect(() => {
    if(discounts) {
      discounts.map((dis) => {
        if(dis.product.id === props.data.id) {
          setProdDiscount(dis);
          setHasDiscount(true)
        }
      })
    }
  }, [discounts])

  const checkUserDiscounts = () => {
    fetch(`/rest/users/discounts`, {
      method:"GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        "Content-Type": "application/json"
      })
    })
    .then(response => {
      if(response.ok && response.status === 200) {
        return response.json()
        /*
        if(response != null) {
          return response.json()
        } else {
          return {discounts: []}
        }
        */
      }
    })
    .then(data => {
      setDiscounts(data.discounts)
    })
  }

    const removeProduct = (e) => {
      e.preventDefault();
      e.target.innerHTML = "Avbestiller ..";
      fetch(`/rest/deals/remove`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({"productIds" : [id]})
      })
      .then(data => {
        fetch(`/rest/nets/pay/byuser`, {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${localStorage.getItem("id_token")}`
          })
        })
        .then(data2 => {
          fetch(`/auth/oauth/token?grant_type=refresh_token&refresh_token=` + localStorage.getItem("refresh_token"), {
            method: "POST",
            headers: new Headers({
              Authorization: `Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ=`
            })
          }).then(response => {
            if(response.ok && response.status === 200)
            return response.json()
          }).then(json => {
            localStorage.setItem("id_token", json.access_token);
            localStorage.setItem("refresh_token", json.refresh_token);
          }).then(() => {
            window.location.reload();
          })
        })
      })
    }

    const reactivateProduct = (productId) => {
      fetch(`/rest/deals/product/reactivate/${productId}`, {
        method:"POST",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
          "Content-Type": "application/json"
        })
      })
      .then(delay => {
        window.location.reload();
      })
    }

    const productStatus = () => {
      fetch(`/rest/deals/product/active/${props.data.id}`, {
        method:"GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
          "Content-Type": "application/json"
        })
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if(data.active === "false"){
          setActive(false)
          translateValidUntil(data.validUntilMonth)
          setValidUntilDay(data.validUntilDay)
          setValidUntilYear(data.validUntilYear)
        } else {
          setActive(true)
        }
      })
    }

    const calculatePrice = (price, discount) => {
      let totalDiscount = 0;

      discount.map((d) => {
          totalDiscount += d.percentageOff;
      })

      if(totalDiscount > 100) {
        totalDiscount = 100
      }else if (totalDiscount < 0) {
        totalDiscount = 0;
      }

      let percentage = totalDiscount/100;

      let priceWithPercentage = price * percentage
      return (price - priceWithPercentage).toFixed(2);
    }

    const findTotalDiscount = (discount) => {
        let totalDiscountNumber = 0;
        discount.map((d) => {
          totalDiscountNumber += d.percentageOff;
        })
        if(totalDiscountNumber > 100) {
          totalDiscountNumber = 100;
        } else if (totalDiscountNumber < 0) {
          totalDiscountNumber = 0;
        }
        return totalDiscountNumber;
    }

    const translateValidUntil = (date) => {
      switch (date) {
        case "JANUARY":
          setValidUntilMonth("Januar")
          break;
        case "FEBRUARY":
          setValidUntilMonth("Februar")
          break;
        case "MARCH":
          setValidUntilMonth("Mars")
          break;
        case "APRIL":
          setValidUntilMonth("April")
          break;
        case "MAY":
          setValidUntilMonth("Mai")
          break;
        case "JUNE":
          setValidUntilMonth("Juni")
          break;
        case "JULY":
          setValidUntilMonth("Juli")
          break;
        case "AUGUST":
          setValidUntilMonth("August")
          break;
        case "SEPTEMBER":
          setValidUntilMonth("September")
          break;
        case "OCTOBER":
          setValidUntilMonth("Oktober")
          break;
        case "NOVEMBER":
          setValidUntilMonth("November")
          break;
        case "DESEMBER":
          setValidUntilMonth("Desember")
          break;

        default:

      }
    }

  return (
    <Card className={classes.root}>
      {owned ? <div className="ribbon ribbon-top-right"><span>{active ? t("purchase.productcard.subscribed") : t("purchase.productcard.cancelled")}</span></div> : ""}
      <CardContent className={`${classes.cardcontent} ${selected ? classes.cardcontentselected : ""} ${owned ? "" : classes.selectable}`}>
        <Typography className={classes.title} component="h3">
          {props.data.name}
        </Typography>
        {!active &&
          <Typography className={classes.dateText} color="textSecondary">
            {/* {`Abonnement går ut: ${validUntilDay}/${validUntilMonth}/${validUntilYear}`} */}
            {t("purchase.productcard.expirationdate") + validUntilDay + "/" + validUntilMonth + "/" + validUntilYear}
          </Typography>
        }
        <Typography className={classes.pos} color="textSecondary">
          {props.data.description}
        </Typography>
        {hasDiscount &&
          <Typography className={classes.dateText} component="p">
            {t("purchase.productcard.havediscount")} {findTotalDiscount(discounts)} % 
          </Typography>
        }
        {hasDiscount ?
          <Typography className={classes.price} component="p">
            {calculatePrice(priceAsNumber, discounts)} kr/mnd
          </Typography>
          :
          <Typography className={classes.price} component="p">
            {priceAsNumber} kr/mnd
          </Typography>
        }
        {
          owned ?
          <Typography
            className={classes.avbestillbtn}
            component="p"
            onClick={active ? removeProduct : () => reactivateProduct(props.data.id)}
            >
            { active ? t("purchase.productcard.cancel") : t("purchase.productcard.reactive")}
          </Typography>
          :
          <Typography className={classes[tempClass]} component="p">
            {selected ? t("purchase.productcard.selected") : t("purchase.productcard.clicktoselect")}
          </Typography>
        }
      </CardContent>
    </Card>
  );

}

export default ProductCard
