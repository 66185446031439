import React, { useEffect, useState } from "react";

import Youandyourdog from "./Youandyourdog/Youandyourdog.jsx";
import Currentexperience from "./Currentexperience/Currentexperience.jsx";
import Earlierexperience from "./Earlierexperience/Earlierexperience.jsx";
import Physicalmentalactivities from "./Physicalmentalactivities/Physicalmentalactivities.jsx";
import _ from "lodash";

function StatisticsBehaviorPhd(props) {
    const [youAndYourDogDataset, setYouAndYourDogDataset] = useState([]);
    const [currentexperienceDataset, setCurrentexperienceDataset] = useState([]);
    const [earlyexperienceDataset, setEarlyexperienceDataset] = useState([]);
    const [physicalmentalactivitiesDataset, setPhysicalmentalactivitiesDataset] = useState([]);

    useEffect(() => {
        if (props.breedTraits != undefined && props.breedTraits != null) {
            let breedTraits = props.breedTraits;
            if (!props.sexMale) {
                breedTraits = breedTraits.filter(x => x.dog.gender != "true");
            }
            if (!props.sexFemale) {
                breedTraits = breedTraits.filter(x => x.dog.gender != "false");
            }
            setYouAndYourDogDataset(breedTraits.filter(dog =>
                dog["selections"][0].categoryId == 77 ||
                dog["selections"][0].categoryId == 76 ||
                dog["selections"][0].categoryId == 78
            ));
            setCurrentexperienceDataset(breedTraits.filter(dog =>
                dog["selections"][0].categoryId == 73 ||
                dog["selections"][0].categoryId == 71 ||
                dog["selections"][0].categoryId == 75
            ));
            setEarlyexperienceDataset(breedTraits.filter(dog =>
                dog["selections"][0].categoryId == 72 ||
                dog["selections"][0].categoryId == 70 ||
                dog["selections"][0].categoryId == 74
            ));
            setPhysicalmentalactivitiesDataset(breedTraits.filter(dog =>
                dog["selections"][0].categoryId == 79 ||
                dog["selections"][0].categoryId == 80 ||
                dog["selections"][0].categoryId == 81
            ));
        }
    }, [props.breedTraits, props.sexMale, props.sexFemale]);

    return (
        <React.Fragment>
            {props.displaySubcategory == "youandyourdog" ?
                <Youandyourdog
                    myDogs={props.myDogs}
                    allBreedTraits={props.allBreedTraits}
                    displaySubcategory={props.displaySubcategory}
                    breedTraits={props.breedTraits}
                    sexMale={props.sexMale}
                    sexFemale={props.sexFemale}
                    selectedDogName={props.selectedDogName}
                    myDogId={props.myDogId}
                    youAndYourDogDataset={youAndYourDogDataset}
                ></Youandyourdog>
            : null}
            {props.displaySubcategory == "currentexperience" ?
                <Currentexperience
                    myDogs={props.myDogs}
                    allBreedTraits={props.allBreedTraits}
                    displaySubcategory={props.displaySubcategory}
                    breedTraits={props.breedTraits}
                    sexMale={props.sexMale}
                    sexFemale={props.sexFemale}
                    selectedDogName={props.selectedDogName}
                    myDogId={props.myDogId}
                    youAndYourDogDataset={youAndYourDogDataset}
                    currentexperienceDataset={currentexperienceDataset}
                ></Currentexperience>
            : null }
            {props.displaySubcategory == "earlierexperience" ?
                <Earlierexperience
                    myDogs={props.myDogs}
                    allBreedTraits={props.allBreedTraits}
                    displaySubcategory={props.displaySubcategory}
                    breedTraits={props.breedTraits}
                    sexMale={props.sexMale}
                    sexFemale={props.sexFemale}
                    selectedDogName={props.selectedDogName}
                    myDogId={props.myDogId}
                    youAndYourDogDataset={youAndYourDogDataset}
                    earlyexperienceDataset={earlyexperienceDataset}
                ></Earlierexperience>
            : null }
            {props.displaySubcategory == "physicalmentalactivities" ?
                <Physicalmentalactivities
                    myDogs={props.myDogs}
                    allBreedTraits={props.allBreedTraits}
                    displaySubcategory={props.displaySubcategory}
                    breedTraits={props.breedTraits}
                    sexMale={props.sexMale}
                    sexFemale={props.sexFemale}
                    selectedDogName={props.selectedDogName}
                    myDogId={props.myDogId}
                    youAndYourDogDataset={youAndYourDogDataset}
                    physicalmentalactivitiesDataset={physicalmentalactivitiesDataset}
                ></Physicalmentalactivities>
            : null }
        </React.Fragment>
    );
}

export default StatisticsBehaviorPhd;
