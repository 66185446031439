import React, {useState, useEffect} from "react";

import InputTextField from "../../common/InputTextField"
import { Redirect } from "react-router";

//Material UI components
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FormGroup, colors, Input, FormControl } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import Select from 'react-select';
import TextField from "@material-ui/core/TextField";

import NavBar from "../../common/Navbar";
import GDPR from "../gdpr/GDPR";
import CloseIcon from "@material-ui/icons/Close";

import Auth from "../../../Auth";
import {useStompMsgHandler} from "../../../StompMsgHandler";
import { Prompt } from 'react-router';
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useTranslate } from 'react-polyglot';
import LocaleHandler from '../../../LocaleHandler';

toast.configure()

window.STOMPCLIENT = null;
window.LOCATION_URL = `${window.location.protocol}//${
  window.location.hostname
  }`;

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    }
  })
};

const UserRegistration = () => {
  let _Auth = new Auth();
  let stompMsgHandler = useStompMsgHandler();
  const { locale } = React.useContext(LocaleHandler);
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [role, setRole] = useState("Hundeeier");
  const [roleNumber, setRoleNumber] = useState("");

  const [locking, setLocking] = useState(false);
  const t = useTranslate();

  const [roles, setRoles] = useState([
      { value: "Hundeeier", label: "Hundeeier" },
        { value: "Veterinær", label: "Veterinær" },
        { value: "Oppdretter", label: "Oppdretter" },
        { value: "Dommer", label: "Dommer" }
    ])
  const [showPassword, setShowPassword] = useState(false)
  const [userAgreementRead, setUserAgreementRead] = useState(false)
  const [redirectLogin, setRedirectLogin] = useState(false)
  const [redirectMyDogs, setRedirectMyDogs] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [check, setChecked] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [editDisabled, setEditDisabled] = useState(false)
  const rolle = t("registration.owner.label.role");
  const unSavedChanges = t("registration.notify.warning.unsavedchanges");

  useEffect(() => {
    if(email != "" || password != "" || firstName != "" || surName != "" || postCode != "" || role != "" || roleNumber != "") {
        setHasChanged(true)
    }
  },[])

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const validateForm = () => {
    return email.length > 0 && password.length > 8
  }

  const closeModal = () =>  {
    setShowModal(false)
  };

  const openModal = () => {
    setShowModal(true)
  };


  const handleCheckbox = (event) => {
    setUserAgreementRead(event.target.checked)
  };

  const handleEmail = (input) => {
    setEmail(input)
  }
  const handlePassword = (input) => {
      setPassword(input)
  }

  const handleFirstName = (input) => {
      setFirstName(input)
  }

  const handleSurName = (input) => {
      setSurName(input)
  }

  const handlePostCode = (input) => {
      setPostCode(input)
  }

  const handleRole = (input) => {
      setRole(input)
  }

  const handleRoleNumber = (input) => {
      setRoleNumber(input)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleSubmit = () => {
    //event.preventDefault();
    let user = {};
    user.email = email;
    user.password = password;
    user.firstName = firstName;
    user.lastName = surName;
    user.postCode = postCode;
    user.language = locale;
    user.role = role;
    user.roleNumber = roleNumber;
    user.token = null;
    user.dog = null;

    if (email == "" || password == "" || firstName == "" || surName == "") {
      notifyInfo(t("registration.notify.warning.missedfields"));
      return;
    }

    if(!email.includes("@")) {
      notifyError(t("registration.notify.warning.invalidemail"))
      return;
    }

    if (password == email) {
      notifyInfo(t("registration.notify.warning.validation"));
      return;
    }

    if (password.length < 8) {
      notifyInfo(t("registration.notify.warning.lengthofpaswd"));
      return;
    }

    if (userAgreementRead) {
      setLocking(true);
      user.email = user.email.toLowerCase();
      const headers = new Headers({
        "Content-Type": "application/json"
      });
      fetch("/rest/registration/", {
        headers: headers,
        method: "POST", // or 'PUT'
        body: JSON.stringify(user) // data can be `string` or {object}!
      })
        .then(res => {
          if (res.status == 409) {
            notifyInfo(t("registration.notify.warning.existuser"));
            setLocking(false);
            return Promise.reject();
          }
          if (res.status == 400) {
            notifyInfo(t("registration.notify.warning.unknownissue"));
            setLocking(false);
            return Promise.reject();
          }
          return res.json();
        })
        .then(response => {
          if (response.id != null) {
            login(response.username, user.password)
          } else {
            notifyInfo(t("registration.notify.warning.existuser"));
            setLocking(false);
          }
        })
    } else {
      notifyInfo(t("registration.notify.warning.acceptterms"));
    }
  }

  const login = (username, password) => {
   let user = {};
   user.email = username;
   user.password = password;

   const obj = {
     method: "POST",
     async: "true",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
       Authorization: "Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ="
     }
   };
   fetch(`/auth/oauth/token?grant_type=password&username=${username}&password=${password}`, obj)
   .then(response => response.json())
   .then(data => {
     searchPerson(data.access_token, data)
   })
  }

  const searchPerson = (theToken, data) => {
   fetch("/rest/users/me", {
     method: "GET",
     headers: new Headers({
       Authorization: `Bearer ${theToken}`
   })
   }).then(response => {
     if(response.ok && response.status === 200)
     return response.json()
   }).then(json => {
       _Auth.setToken(json, data.access_token, data.refresh_token)
   }).then(() => {
     // TODO: NEED STOMPHANDLER HERE???
     stompMsgHandler.initSocket();
     setRedirectMyDogs(true)
   })
 }

  const handleChangeSingle = (value) => {
    setRole(value.value)
  }

    if (redirectLogin) {
      return <Redirect to={"/login"} />;
    }
    if (redirectMyDogs) {
      return <Redirect to={"/mydogs"} />;
    }
    return (
      <React.Fragment>
        <NavBar />
          <Prompt
            when={ hasChanged }
            message={ unSavedChanges }
          />
        <div className="registration">
          <h1 className="main-headliner">Opprett Bruker</h1>
          <div className="text-wrapper">
            <Typography gutterBottom variant="h3">
              {t("registration.info.description")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
              {t("registration.info.access_dogresigtration")}
            </Typography>
          </div>
          <form className="registration-form">

            {/* form for registration will be a required field */}
            <FormGroup>
              {/* email inputfield */}
              <InputTextField
                id="email"
                value={email}
                label={t("registration.owner.label.email")}
                placeholder={t("registration.owner.placeholder.email")}
                onChange={e => handleEmail(e.target.value)}
                type="email"
                required
              />

              {/* password inputfield. this has states to toggle the visibility of the password in a form of "inputadornment iconbutton". will be a required field */}
              <FormControl required >
                <InputLabel htmlFor="password" classes={{ root: 'registration-text' }}>
                  {t("registration.choosepasswd")}
              </InputLabel>
                <Input
                  classes={{ root: 'registration-text' }}
                  id="password"
                  value={password}
                  label={t("registration.owner.label.password")}
                  placeholder={t("registration.owner.placeholder.password")}
                  onChange={e => handlePassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>


              {/* first name inputfield. this will be a required field */}
              <InputTextField
                inputProps={{ style: { color: "#000000" } }}
                InputLabelProps={{ classes: { root: "registration-text" } }}
                id="firstName"
                value={firstName}
                label={t("registration.owner.label.firstname")}
                placeholder={t("registration.owner.placeholder.firstname")}
                onChange={e => handleFirstName(e.target.value)}
                required
              />
              {/* surname inputfield. this will be a required field */}
              <InputTextField
                inputProps={{ style: { color: "#000000" } }}
                InputLabelProps={{ classes: { root: "registration-text" } }}
                id="surName"
                value={surName}
                label={t("registration.owner.label.lastname")}
                placeholder={t("registration.owner.placeholder.lastname")}
                onChange={e => handleSurName(e.target.value)}
                required
              />
              <InputTextField
                inputProps={{ style: { color: "#000000" } }}
                InputLabelProps={{ classes: { root: "registration-text" } }}
                id="postCode"
                value={postCode}
                label={t("registration.owner.label.postnumber")}
                placeholder={t("registration.owner.placeholder.postnumber")}
                onChange={e => handlePostCode(e.target.value)}
              />
              <Select
                className="registration-text"
                inputId="react-select-single"
                isDisabled={editDisabled}
                styles={selectStyle}
                TextFieldProps={{
                  label: rolle,
                  InputLabelProps: {
                    htmlFor: 'react-select-single',
                    shrink: true,
                  },
                }}
                options={roles.map(i => { return { value: i.value, label: t("registration.owner.roles." + i.label) } })}
                value={{ value: role, label: t("registration.owner.placeholder.role") + ": " + t("registration.owner.roles." + role)}}
                placeholder={t("registration.owner.placeholder.role")}
                onChange={handleChangeSingle}
              />
              {role != "Hundeeier" &&
                <InputTextField
                  inputProps={{ style: { color: "#000000" } }}
                  InputLabelProps={{ classes: { root: "registration-text" } }}
                  id="roleNumber"
                  value={roleNumber}
                  label={t("registration.owner.label.idnumber")}
                  onChange={e => handleRoleNumber(e.target.value)}
                />
              }

              <FormControl className="add-spacing">
                <Typography onClick={openModal} className="hover-pointer">
                  {t("registration.privacystatement.title")}
                </Typography>
              </FormControl>

              <FormControl className="add-spacing">
                <Divider />
              </FormControl>

              {/* checkbox for "jeg har lest og aksepterer personvernerkleringen" */}
              <FormControlLabel
                label={t("registration.privacystatement.info")}
                labelPlacement="start"
                control={
                  <Checkbox
                    classes={{ root: "registration-text" }}
                    checked={userAgreementRead}
                    onChange={handleCheckbox}
                    name="userAgreementRead"
                    value="userAgreementRead"
                  />
                }
              />
              <br />
              <div className="button-container">
                {/* button for "Opprett bruker og start registrering" onthign really to do here yet but making the button itself */}
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Add"
                  className="create-button"
                  disabled={locking}
                  onClick={() => handleSubmit()}
                >
                  {t("registration.title")}
              </Fab>
              </div>

            </FormGroup>
          </form>
        </div>

        <div className={"gdpr-modal"}>
        <Modal
          open={showModal}
          onClose={closeModal}
        >
          <div className={"modal-content"}>
            <div className={"gdpr-registration"}>
              <IconButton className={"gdpr-modal-cross-button"} aria-label="Close" onClick={closeModal}><CloseIcon/></IconButton>
              <GDPR />
            </div>
          </div>
        </Modal>
        </div>
      </React.Fragment>
    );
}



export default UserRegistration;
