import React, {useEffect, useState, useRef, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";
import Select from 'react-select';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Line } from 'react-chartjs-2';
import moment from 'moment';

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    }
  })
};

function CardTypeEbvGenTrendJakt(props) {
    let explorer = useContext(ExplorerContext);
    const t = useTranslate();

    let chartRef = useRef();

    const [activeData, setActiveData] = useState([]);

    const [data, setData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);

    const [ebvjaktno, setEbvjaktno] = useState(props.ebvStandJaktSelected  != undefined ? props.ebvStandJaktSelected : "1");

    const [options, setOptions] = useState([
        { value: "1", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest01") },
        { value: "2", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest02") },
        { value: "3", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest03") },
        { value: "4", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest04") },
        { value: "5", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest05") },
        { value: "6", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest06") },
        { value: "7", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest07") },
        { value: "8", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest08") },
        { value: "9", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest09") },
        { value: "10", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest10") },
        { value: "11", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest11") },
        { value: "12", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest12") },
    ]);

    //const [showAll, setShowAll] = useState(false);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/ebvjaktgentrend", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setData(json);
            setActiveData(json.find(i => i.key == ebvjaktno) != undefined ? json.find(i => i.key == ebvjaktno).data : []);
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        setActiveData(data.find(i => i.key == ebvjaktno) != undefined ? data.find(i => i.key == ebvjaktno).data : []);
    }, [ebvjaktno]);

    useEffect(() => {
        setEbvjaktno(props.selectValue);
    }, [props.selectValue]);

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-ebvjaktgentrend">
            <h1>{t("dogexplorer.statistics.cards.ebvgentrendjakt.title")}</h1>
            <Select
                className={"registration-text"}
                inputId="react-select-single"
                styles={selectStyle}
                TextFieldProps={{
                    label: 'Jakttest',
                    InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                    },
                }}
                options={options}
                value={options.find(i => i.value == ebvjaktno)}
                placeholder={ebvjaktno}
                onChange={(data) => {
                    setEbvjaktno(data.value);
                    if (props.selectChange != undefined) {
                        props.selectChange(data.value);
                    }
                }}
            />
            <div>
                <Line
                    ref={chartRef}
                    chartId="barchart1-1"
                    options={{
                        tooltips: {
                            intersect: false
                        },
                        scales: {
                            xAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.statistics.cards.ebvgentrendjakt.year")
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.statistics.cards.ebvgentrendjakt.average")
                                    }
                                }
                            ]
                        }
                    }}
                    data={{
                        labels: activeData != undefined ? activeData.filter(i => i.year != "").map(i => i.year) : [],
                        datasets: [
                            {
                                backgroundColor: "transparent",
                                pointBackgroundColor: "#382e92",
                                borderColor: "#382e92",
                                label: t("dogexplorer.statistics.cards.ebvgentrendjakt.average"),
                                data: activeData != undefined ? activeData.filter(i => i.year != "").map(i => i.avg) : []
                            }
                        ]
                    }}
                    maxWidth={50}
                    maxHeight={50}
                ></Line>
            </div>
        </Card>
    );
}

export default CardTypeEbvGenTrendJakt;