function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

let fetchHelperService = {
    fetchRetry: function fetchRetry(url, delay, tries, fetchOptions = {}) {
        function onError(err){
            let triesLeft = tries - 1;
            if(!triesLeft){
                throw err;
            }
            return wait(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions));
        }
        return fetch(url,fetchOptions).catch(onError);
    }
}

export default fetchHelperService;