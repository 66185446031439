import decode from "jwt-decode";


export default class Auth {
  constructor() {

  }

  componentDidCatch(error, info) {
    console.log(error)
    console.log(info);
  }


  loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  };

  isTokenExpired = token => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired.
        return true;
      } else return false;
    } catch (err) {
      console.log("expired check failed! - Auth.jsx");
      return false;
    }
  };

  setToken = (idToken, token, refresh) => {
    // Saves user token to localStorage

    localStorage.setItem("refresh_token", refresh);
    localStorage.setItem("id_token", token);
    localStorage.setItem("userid", idToken.id);
    localStorage.setItem("username", idToken.email);
    localStorage.setItem("firstName", idToken.firstName);
    localStorage.setItem("lastName", idToken.lastName);
    localStorage.setItem("language", idToken.language);
    localStorage.setItem("postCode", idToken.postCode);
  };

  getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  };

  logout = () => {
    // Clear user token and profile data from localStorage
    //localStorage.removeItem("id_token");
    //localStorage.removeItem("userid");
    //localStorage.removeItem("username");
    localStorage.clear();
  };

  getConfirm = () => {
    // Using jwt-decode npm package to decode the token
    let answer = decode(this.getToken());
    console.log("Recieved answer!");
    return answer;
  };

  fetch = (url, options = {}) => {
    // performs api calls sending the required authentication headers
    const headers = new Headers({
      //Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("id_token") != null ? "Bearer " + localStorage.getItem("id_token") : undefined
    });
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    /*try{
      headers["Authorization"] = "Bearer " + localStorage.getItem("id_token");
    } catch(error) {
      console.log(error);
      this.logout();
      throw error;
    }*/
    return fetch(url, { headers, ...options })
      .then(this._checkStatus)
  };


  _checkStatus = response => {
    // raises an error in case response status is not a success
    if ((response.status >= 200 && response.status < 300) || response.status == 409) {
      // Success status lies between 200 to 300
      return response;
    } else {
      if (response.status == 401) {
        this.logout();
        window.location = window.location.origin + "/login";
        return null;
      }
      var error = new Error(response.statusText);
      error.response = response;
      if (response.status == 301) {
        this.logout();
        window.location = window.location.origin + "/login";
        return null;
      }
      //throw error;
    }
  };
}
