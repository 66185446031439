import React from "react";
import { useTranslate } from 'react-polyglot';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import InfoDivider from "../../common/Divider";
import NavBar from "../../common/Navbar";

function Thanks(){
  const t = useTranslate();

    return (
      <React.Fragment>
        <NavBar />
        <div className="thanks-container">
          <Card classes={{ root: "thanks-card" }}>
            <CardContent classes={{ root: "thanks-card-content" }}>
              <div className="card-thanks-title-container">
                <img src="assets/images/card-thanks-illustration.svg" alt="" />
                <div className="card-thanks-title">
                  <Typography style={{ color: "white" }}>
                    {t("thanks.discount")}
                  </Typography>
                </div>
              </div>
              {/* <div className="rabatt-code-container">
                <Typography variant="body1">Rabattkode: Biotail0278</Typography>
                <Button classes={{ root: "redeem-button" }}>Løs inn</Button>
              </div> */}
            </CardContent>
          </Card>
          <Card classes={{ root: "card-thanks-shadow" }} />
        </div>
        <div className="thanks-description-container">
          <Typography variant="h3">{t("thanks.description1")}</Typography>
          <InfoDivider />
          <br />
          <Typography gutterBottom>
            {t("thanks.description2")}
          </Typography>

          <Typography>{t("thanks.saved")}</Typography>
          <div className="go-button-container">
            <Button classes={{ root: "go-button" }}>{t("thanks.mydogs")}</Button>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Thanks;
