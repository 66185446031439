import React, {useEffect, useState, useContext, useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Card } from "@material-ui/core";

import { Bar } from 'react-chartjs-2';

function CardTypesSexdistribution(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    let chartRef = useRef();

    const [male, setMale] = useState(0);
    const [female, setFemale] = useState(0);

    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/sexdistribution", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setMale(json.male);
            setFemale(json.female);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-sexdistribution">
            <h1>{t("dogexplorer.genderdistribution.description")}</h1>
            <Bar
                ref={chartRef}
                chartId="barchart1-1"
                options={{
                    onClick: (evt, data) => {
                        let gender = chartRef.current.chartInstance.getElementsAtXAxis(evt)[0]._index;
                        
                        let tempFilter = explorer.filters.filter(i => i.type != "male" && i.type != "female");

                        tempFilter.push({
                            type: "male",
                            stage: "pre",
                            operation: "eq",
                            value: gender == 0 ? true : false
                        });
                        tempFilter.push({
                            type: "female",
                            stage: "pre",
                            operation: "eq",
                            value: gender == 1 ? true : false
                        });

                        explorer.updateFilters(tempFilter);
                    },
                    tooltips: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.genderdistribution.gender")
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.genderdistribution.numberofdogs")
                                }
                            }
                        ]
                    }
                }}
                data={{
                    labels: [t("dogexplorer.genderdistribution.male"), t("dogexplorer.genderdistribution.female")],
                    datasets: [
                        {
                            backgroundColor: "#382e92",
                            label: t("dogexplorer.genderdistribution.number"),
                            data: [male, female]
                        }
                    ]
                }}
                maxWidth={50}
                maxHeight={50}
            ></Bar>
        </Card>
    );
}

export default CardTypesSexdistribution;