import React, {useEffect, useState, useContext, useRef} from "react";
import { Link, useHistory } from "react-router-dom";

import { ExplorerContext } from "../../../ExplorerContext";

import fetchHelperService from "../../../../../../services/fetchHelper";

import { Card } from "@material-ui/core";

import { Bar } from 'react-chartjs-2';

function CardTypeJaktpovePart(props) {
    let history = useHistory();

    let explorer = useContext(ExplorerContext);

    let chartRef = useRef();

    const [data, setData] = useState([]);

    const [appliedFilters, setAppliedFilters] = useState([]);

    const delnavn = {
        1: "Tempo",
        2: "Søk",
        3: "Finne elg",
        4: "Holde elg opptatt",
        5: "Losarbeidets kvalitet",
        6: "Påhengelighet",
        7: "Evne til å stille støkt elg",
        8: "Lostid",
        9: "Losmålets hørbarhet",
        10: "Bruken av målet",
        11: "Samarbeid",
        12: "Lydighet",
        13: "Del 13"
    }

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/jaktprovepart/" + props.partnum, 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setData(json);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className={"dogexplorer-card-jaktprovepart" + props.partnum}>
            <h1>Poengfordeling - { delnavn[props.partnum] }</h1>
            <Bar
                ref={chartRef}
                chartId="barchart1-1"
                options={{
                    onClick: (evt, data) => {
                        
                    },
                    tooltips: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Poeng"
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: "Antall prøver"
                                }
                            }
                        ]
                    }
                }}
                data={{
                    labels: data.map(i => i.key),
                    datasets: [
                        {
                            backgroundColor: "#382e92",
                            label: "Antall",
                            data: data.map(i => i.value),
                        }
                    ]
                }}
                maxWidth={50}
                maxHeight={50}
            ></Bar>
        </Card>
    );
}

export default CardTypeJaktpovePart;