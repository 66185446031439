import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import { Radar, Bar } from 'react-chartjs-2';

import Card from "@material-ui/core/Card";
import StatisticsBehaviorPhd from "./StatisticsBehaviorPhd/StatisticsBehaviorPhd";

import Auth from "../../../../Auth";
import { useTranslate } from "react-polyglot";


function StatisticsBehavior(props) {
  const _Auth = new Auth();

  const t = useTranslate();

  const [mydogTraits, setMydogTraits] = useState(null);
  const [breedTraits, setBreedTraits] = useState(null);
  const [allBreedTraits, setAllBreedTraits] = useState(null);
  const [allAllBreedTraits, setAllAllBreedTraits] = useState(null);
  const [myDogId, setMyDogId] = useState(0);
  const [breedId, setBreedId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [displaySubcategory, setDisplaySubcategory] = useState("phd|youandyourdog");

  function getDataBlock(selectionId) {
    return {
      label: t("statistics.areas.behavior.otherdogs"),
      backgroundColor: mydogTraits != null ? [
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 1 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 2 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 3 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 4 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 5 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 6 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 7 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 8 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 9 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderValue"] == 10 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)"
      ] : "rgba(56, 46, 146, 0.3)",
      data: breedTraits != null ? [
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 1).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 2).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 3).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 4).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 5).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 6).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 7).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 8).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 9).length,
        breedTraits.filter(dog => dog["selections"].length > 0).map(selection => selection["selections"][selectionId] != undefined && selection["selections"][selectionId].value != undefined && JSON.parse(selection["selections"][selectionId].value) != null ? JSON.parse(selection["selections"][selectionId].value)["sliderValue"] : 0).filter(item => item == 10).length
      ] : []
    };
  }

  function getTraitCardPart(titleText, selectionId) {
    return(
      <div>
        <div className={"info-card-header"}>
          <div className={"info-card-text-header"}>
            {titleText}
          </div>
        </div>
        <div className={"info-card-body"}>
          <Bar
            chartId="barchart2-1"
            data={{
              labels: [1,2,3,4,5,6,7,8,9,10],
              datasets: [
                {
                  ...getDataBlock(selectionId)
                },
                {
                  label: t("statistics.areas.behavior.yourdog"),
                  backgroundColor: "rgba(255, 139, 18, 0.3)",
                  data: []
                }
              ]
            }}
            options={{
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      zeroLineColor: ["#EEEEEE"],
                      color: "#EEEEEE",
                      lineWidth: 2,
                      zeroLineWidth: 2,
                      drawTicks: true,
                      tickMarkLength: 8,
                    },
                    scaleLabel: {
                      display: true,
                      fontSize: 18,
                      labelString: t("statistics.areas.behavior.behavior_degree")
                    },
                    ticks: {
                        fontColor: "#382e92",
                        fontSize: 14,
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      zeroLineColor: ["#382e92"],
                      lineWidth: 2,
                      zeroLineWidth: 2,
                      drawTicks: true,
                      tickMarkLength: 8,
                    },
                    scaleLabel: {
                      display: true,
                      fontSize: 18,
                      labelString: t("statistics.areas.behavior.number_answers")
                    },
                    ticks: {
                      fontColor: "#382e92",
                      fontSize: 14
                    }
                  }
                ]
              }
            }}
            maxWidth={50}
            maxHeight={50}
            redraw={true}
          ></Bar>
        </div>
      </div>
    );
  }

  function getAmountAtSelection(selectionIndex) {
    let tempbreedTraits = breedTraits.filter(dog => dog["selections"].length > 0);
    let slidervalues = tempbreedTraits.map(dog => dog["selections"][selectionIndex] != undefined && dog["selections"][selectionIndex].value != undefined && JSON.parse(dog["selections"][selectionIndex].value) != null ? JSON.parse(dog["selections"][selectionIndex].value)["sliderValue"] : 0);
    let neSlidervalues = slidervalues.filter(item => item != 0 && !isNaN(item));
    let totalSlidervalues = neSlidervalues.reduce((a,b) => a + b, 0);
    let amountSlidervalues = neSlidervalues.length;
    return totalSlidervalues / amountSlidervalues;
  }

  useEffect(() => {
    if (props.loadingStart != undefined) props.loadingStart();
    setLoading(true);
    _Auth.fetch("/rest/breedwiz/forbreed/" + "99999999")
      .then(response => response.json())
      .then(data => {
        data = data.filter(dog => dog["selections"].length > 0);
        setAllBreedTraits(data);
        setAllAllBreedTraits(data);
        data = data.filter(dog => dog["selections"][0].categoryId == 2);
        setBreedTraits(data.filter(dog => dog["_id"] != props.myDogId && dog != null));
        setBreedId(props.breedId);
        if (props.loadingEnd != undefined) props.loadingEnd();
        setLoading(false);
      })
      .catch(error => console.error("Error:", error));
  }, []);

  useEffect(() => {
    if (props.selectedBreedId != undefined && props.selectedBreedId != "") {
      if (props.loadingStart != undefined) props.loadingStart();
      _Auth.fetch("/rest/breedwiz/forbreed/" + props.selectedBreedId)
        .then(response => response.json())
        .then(data => {
          data = data.filter(dog => dog["selections"].length > 0);
          setAllBreedTraits(data);
          data = data.filter(dog => dog["selections"][0].categoryId == 2);
          setBreedTraits(data.filter(dog => dog["_id"] != props.myDogId && dog != null));
          setBreedId(props.breedId);
          if (props.loadingEnd != undefined) props.loadingEnd();
        })
        .catch(error => console.error("Error:", error));
    }
    if (props.myDogId != undefined) {
      _Auth.fetch("/rest/breedwiz/fordog/" + props.myDogId)
        .then(response => response.json())
        .then(data => {
          let behaviorMyDog = null;
          data.forEach(item => {
            if (item.selections.length > 0) {
              if (item.selections[0]["categoryId"] == 2) {
                behaviorMyDog = item;
              }
            }
          });
          
          if (behaviorMyDog != null) {
            setMydogTraits(behaviorMyDog);
            setMyDogId(props.myDogId);
          }
        })
        .catch(error => console.error("Error:", error));
    }
  }, [props.selectedBreedId, props.myDogId]);

  return (
    <React.Fragment>
        <div>
            <div className="subcategoriescontainer">
              <div className="row subcategories">
                <hr/>
                {/* <div className="col-md-3">
                  <Card className={""} style={{
                    cursor: "pointer",
                    border: displaySubcategory == "general" ? "3px solid" : "none",
                  }} onClick={(evt) => setDisplaySubcategory("general") }>
                    <card-content>
                      <h1>{t("statistics.behavior.sub_general.button.title")}</h1>
                    </card-content>
                  </Card>
                </div> */}
                <div className="col-md-3">
                  <Card className={""} style={{
                    cursor: "pointer",
                    border: displaySubcategory == "phd|youandyourdog" ? "3px solid" : "none",
                  }} onClick={(evt) => !loading ? setDisplaySubcategory("phd|youandyourdog") : null }>
                    <card-content>
                      {!loading ?
                        <h1>{t("statistics.behavior.sub_phd_youandyourdog.button.title")}</h1>
                      :
                        <h1>{t("statistics.main.filters.loadingdata")}</h1>
                      }
                    </card-content>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className={""} style={{
                    cursor: "pointer",
                    border: displaySubcategory == "phd|currentexperience" ? "3px solid" : "none",
                  }} onClick={(evt) => !loading ? setDisplaySubcategory("phd|currentexperience") : null }>
                    <card-content>
                      {!loading ?
                        <h1>{t("statistics.behavior.sub_phd_currentexperience.button.title")}</h1>
                      :
                        <h1>{t("statistics.main.filters.loadingdata")}</h1>
                      }
                    </card-content>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className={""} style={{
                    cursor: "pointer",
                    border: displaySubcategory == "phd|earlierexperience" ? "3px solid" : "none",
                  }} onClick={(evt) => !loading ? setDisplaySubcategory("phd|earlierexperience") : null }>
                    <card-content>
                      {!loading ?
                        <h1>{t("statistics.behavior.sub_phd_earlierexperience.button.title")}</h1>
                      :
                        <h1>{t("statistics.main.filters.loadingdata")}</h1>
                      }
                    </card-content>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className={""} style={{
                    cursor: "pointer",
                    border: displaySubcategory == "phd|physicalmentalactivities" ? "3px solid" : "none",
                  }} onClick={(evt) => !loading ? setDisplaySubcategory("phd|physicalmentalactivities") : null }>
                    <card-content>
                      {!loading ?
                        <h1>{t("statistics.behavior.sub_phd_physicalmentalactivities.button.title")}</h1>
                      :
                        <h1>{t("statistics.main.filters.loadingdata")}</h1>
                      }
                    </card-content>
                  </Card>
                </div>
              </div>
            </div>
            { displaySubcategory.split("|")[0] == "phd" && !loading ?
              <div className="subcategory subcategory-general">
                <StatisticsBehaviorPhd
                  breedTraits={allBreedTraits}
                  allBreedTraits={allAllBreedTraits}
                  myDogId={props.myDogId}
                  myDogs={props.myDogs}
                  allDogs={props.allDogs}
                  selectedDogName={props.selectedDogName}
                  displaySubcategory={displaySubcategory.split("|")[1]}
                  sexMale={props.sexMale}
                  sexFemale={props.sexFemale}
                ></StatisticsBehaviorPhd>
              </div>
            : null }
            { displaySubcategory == "general" && !loading ? 
              <div className="subcategory subcategory-general">
                <Card className={"info-card center"}>
                  <card-content class="behaviorCard">
                    <div className={"info-card-content"}>
                      <div className={"info-card-header"}>
                        <div className={"info-card-text-header"}>
                        {t("statistics.areas.behavior.title")}
                        </div>
                      </div>
                      <hr/>
                      <Typography classes={{ root: "text phoneonly" }} style={{paddingTop: "50px", paddingLeft: "50px"}}>
                        {t("statistics.areas.behavior.phone1")}<br/>
                        {t("statistics.areas.behavior.phone2")}
                        <br/>
                        <br/>
                      </Typography>
                      <div className={"info-card-body"}>
                        <Radar
                          chartId="chart3"
                          options= {{
                            legend: {
                              labels: {
                                fontSize: 16
                              }
                            },
                            scale: {
                              pointLabels: {
                                fontSize: 14
                              },
                              ticks: {
                                min: 0,
                                max: 10,
                              }
                            }
                          }}
                          data={{
                            labels: [
                              t("statistics.areas.behavior.labels.label1"),
                              t("statistics.areas.behavior.labels.label2"),
                              t("statistics.areas.behavior.labels.label3"),
                              t("statistics.areas.behavior.labels.label4"),
                              t("statistics.areas.behavior.labels.label5"),
                              t("statistics.areas.behavior.labels.label6"),
                              t("statistics.areas.behavior.labels.label7"),
                              t("statistics.areas.behavior.labels.label8"),
                              t("statistics.areas.behavior.labels.label9"),
                              t("statistics.areas.behavior.labels.label10"),
                              t("statistics.areas.behavior.labels.label11"),
                              t("statistics.areas.behavior.labels.label12")
                            ],
                            datasets: [
                              {
                                label: t("statistics.areas.behavior.raceavg"),
                                data: breedTraits != null ? 
                                  [
                                    getAmountAtSelection(0),
                                    getAmountAtSelection(1),
                                    getAmountAtSelection(2),
                                    getAmountAtSelection(3),
                                    getAmountAtSelection(4),
                                    getAmountAtSelection(5),
                                    getAmountAtSelection(6),
                                    getAmountAtSelection(7),
                                    getAmountAtSelection(8),
                                    getAmountAtSelection(9),
                                    getAmountAtSelection(10),
                                    getAmountAtSelection(11),
                                  ] : [],
                                backgroundColor: "rgba(56, 46, 146, 0.3)"
                              },
                              {
                                label: t("statistics.areas.behavior.yourdog"),
                                data: (mydogTraits != null && mydogTraits["selections"].length > 0) ?
                                  [
                                    mydogTraits["selections"][0].value != undefined && JSON.parse(mydogTraits["selections"][0].value) != null ? JSON.parse(mydogTraits["selections"][0].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][1].value != undefined && JSON.parse(mydogTraits["selections"][1].value) != null ? JSON.parse(mydogTraits["selections"][1].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][2].value != undefined && JSON.parse(mydogTraits["selections"][2].value) != null ? JSON.parse(mydogTraits["selections"][2].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][3].value != undefined && JSON.parse(mydogTraits["selections"][3].value) != null ? JSON.parse(mydogTraits["selections"][3].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][4].value != undefined && JSON.parse(mydogTraits["selections"][4].value) != null ? JSON.parse(mydogTraits["selections"][4].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][5].value != undefined && JSON.parse(mydogTraits["selections"][5].value) != null ? JSON.parse(mydogTraits["selections"][5].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][6].value != undefined && JSON.parse(mydogTraits["selections"][6].value) != null ? JSON.parse(mydogTraits["selections"][6].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][7].value != undefined && JSON.parse(mydogTraits["selections"][7].value) != null ? JSON.parse(mydogTraits["selections"][7].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][8].value != undefined && JSON.parse(mydogTraits["selections"][8].value) != null ? JSON.parse(mydogTraits["selections"][8].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][9].value != undefined && JSON.parse(mydogTraits["selections"][9].value) != null ? JSON.parse(mydogTraits["selections"][9].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][10].value != undefined && JSON.parse(mydogTraits["selections"][10].value) != null ? JSON.parse(mydogTraits["selections"][10].value)["sliderValue"] : 0,
                                    mydogTraits["selections"][11].value != undefined && JSON.parse(mydogTraits["selections"][11].value) != null ? JSON.parse(mydogTraits["selections"][11].value)["sliderValue"] : 0
                                  ] : [],
                                backgroundColor: "rgba(255, 139, 18, 0.3)"
                              }
                            ]
                          }}
                          maxWidth={50}
                          maxHeight={50}
                          redraw={true}
                        ></Radar>
                      </div>
                    </div>
                  </card-content>
                </Card>
                <Card className={"info-card center"}>
                  <card-content>
                    <div className={"info-card-content"}>
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label1"), 0) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label2"), 1) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label3"), 2) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label4"), 3) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label5"), 4) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label6"), 5) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label7"), 6) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label8"), 7) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label9"), 8) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label10"), 9) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label11"), 10) }
                      { getTraitCardPart(t("statistics.areas.behavior.labels.label12"), 11) }
                    </div>
                  </card-content>
                </Card>
              </div>
            : null }
        </div>
    </React.Fragment>);
}

export default StatisticsBehavior;
