import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import { Radar, Bar, Line } from 'react-chartjs-2';

import Card from "@material-ui/core/Card";

import Auth from "../../../../Auth";
import { useTranslate } from "react-polyglot";

function StatisticsExterior(props) {
  const _Auth = new Auth();
  const t = useTranslate();

  const [mydogTraits, setMydogTraits] = useState(null);
  const [breedTraits, setBreedTraits] = useState(null);
  const [myDogId, setMyDogId] = useState(0);
  const [breedId, setBreedId] = useState(0);
  const [images, setImages] = useState([]);

  function tooltipModelFunction(tooltipModel, chartElement, traitId) {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');
    
    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div></div>';
        document.body.appendChild(tooltipEl);
    }
  
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }
  
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
  
    // Set Text
    if (tooltipModel.body) {
        var innerHtml = '<img src="' + images[traitId].traitValues[tooltipModel.dataPoints[0].label].image.imageUrl + '" style="width: 200px; height: 200px;">';
        var tableRoot = tooltipEl.querySelector('div');
        tableRoot.innerHTML = innerHtml;
    }
  
    // `this` will be the overall tooltip
    var position = chartElement._chart.canvas.getBoundingClientRect();
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 10 + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.zIndex = '1000';
    tooltipEl.style.backgroundColor = 'white';
    tooltipEl.style.border = '1px solid #d0d0d0';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.boxShadow = '0px 0px 20px #0000001f';
    tooltipEl.style.padding = '12px';
  }
  
  function getDataBlock(selectionId) {
    return {
      label: t("statistics.areas.exterior.otherdogs"),
      backgroundColor: mydogTraits != null ? [
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderSelected"] == 6 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderSelected"] == 7 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderSelected"] == 8 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderSelected"] == 9 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)",
        mydogTraits["selections"][selectionId].value != undefined && JSON.parse(mydogTraits["selections"][selectionId].value) != null && JSON.parse(mydogTraits["selections"][selectionId].value)["sliderSelected"] == 10 ? "rgba(255, 139, 18, 0.3)" : "rgba(56, 46, 146, 0.3)"
      ] : "rgba(56, 46, 146, 0.3)",
      data: breedTraits != null ? [
        breedTraits.filter(dog => dog["selections"].length > 0 && dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null).map(selection => JSON.parse(selection["selections"][selectionId].value)["sliderSelected"]).filter(item => item == 6).length,
        breedTraits.filter(dog => dog["selections"].length > 0 && dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null).map(selection => JSON.parse(selection["selections"][selectionId].value)["sliderSelected"]).filter(item => item == 7).length,
        breedTraits.filter(dog => dog["selections"].length > 0 && dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null).map(selection => JSON.parse(selection["selections"][selectionId].value)["sliderSelected"]).filter(item => item == 8).length,
        breedTraits.filter(dog => dog["selections"].length > 0 && dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null).map(selection => JSON.parse(selection["selections"][selectionId].value)["sliderSelected"]).filter(item => item == 9).length,
        breedTraits.filter(dog => dog["selections"].length > 0 && dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null).map(selection => JSON.parse(selection["selections"][selectionId].value)["sliderSelected"]).filter(item => item == 10).length
      ] : []
    };
  }

  function GetTraitsByAge(traitid) {
    if (breedTraits != null) {
      return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((age) => {
        let dogsAtAge = props.dogs.filter(dog => new Date(dog["birthDate"]).getFullYear() == (new Date().getFullYear() - age + 1)).map(dog => dog["id"]);
        let traitsAtAge = breedTraits.filter(trait => dogsAtAge.includes(trait["dogId"]) && (trait["selections"].length > 0));
        let traitSelectionsForId = traitsAtAge.map(trait => {
          let foundTrait = trait["selections"].find(selection => 
            selection["traitId"] == traitid &&
            selection["value"] != undefined &&
            JSON.parse(selection["value"]) != null
          );
          if (foundTrait != undefined && foundTrait != null) {
            return JSON.parse(foundTrait.value)["sliderSelected"];
          } else {
            return null;
          }
        }).filter(traitValue => 
          traitValue != null &&
          traitValue != 0
        );

        let sum = traitSelectionsForId.reduce((a, b) => (a - 5) + b, 0);
        let avg = (sum / traitSelectionsForId.length) || 0;

        if (traitSelectionsForId.length == 0) {
          avg = null; // Spanning gaps where value is missing
        }

        return {
          x: age,
          y: avg
        }
      });
    } else {
      return []
    }
  }

  function getTraitCardPart(titleText, selectionId) {
    return(
      <div>
        <div className={"info-card-header"}>
          <div className={"info-card-text-header"}>
            {titleText} {breedTraits != null ? <span className='titleannotation'>({t("statistics.areas.exterior.answers")} {breedTraits.filter(dog => {
              if (dog["selections"].length < 1) return false;
              return true;
            }).map(dog => {
              if (dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null) {
                return JSON.parse(dog["selections"][selectionId].value)["sliderSelected"];
              } else {
                return 0;
              }
            }).filter(item => item != 0).length})</span> : ""}
          </div>
        </div>
        <div className={"info-card-body"}>
          <Typography classes={{ root: "text phoneonly" }} style={{paddingTop: "50px", paddingLeft: "50px"}}>
            {t("statistics.areas.exterior.phone1")}<br/>
            {t("statistics.areas.exterior.phone2")}
            <br/>
            <br/>
          </Typography>
          <div style={{ overflow: "auto" }}>
            <div className="graphcontainer left">
              <Bar
                chartId="barchart1-1"
                options={{
                  tooltips: {
                    enabled: false,
                    custom: function (model) { tooltipModelFunction(model, this, selectionId) }
                  },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          fontSize: 18,
                          labelString: t("statistics.areas.exterior.current_result")
                        }
                      }
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          fontSize: 18,
                          labelString: t("statistics.areas.exterior.number_answers")
                        }
                      }
                    ]
                  }
                }}
                data={{
                  labels: [1,2,3,4,5],
                  datasets: [
                    {
                      ...getDataBlock(selectionId)
                    },
                    {
                      label: t("statistics.areas.exterior.yourdog"),
                      backgroundColor: "rgba(255, 139, 18, 0.3)",
                      data: []
                    }
                  ]
                }}
                maxWidth={50}
                maxHeight={50}
                redraw={true}
              ></Bar>
            </div>
            <div className="graphcontainer">
              <Line
                data={{
                  labels: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20",
                  ],
                  datasets: [
                    {
                      label: t("statistics.areas.exterior.all_dogs_in_race"),
                      backgroundColor: "rgba(255, 139, 18, 0.2)",
                      borderColor: "rgba(255, 139, 18, 0.4)",
                      data: [
                        ...GetTraitsByAge(selectionId + 1)
                      ]
                    }
                  ]
                }}
                options={{
                  spanGaps: true,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          max: 6
                        }
                      }
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          fontSize: 18,
                          labelString: t("statistics.areas.exterior.development_over_time")
                        }
                      }
                    ]
                  }
                }}
              ></Line>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (props.selectedBreedId != undefined && props.selectedBreedId != "") {
      _Auth.fetch("/rest/breedwiz/forbreed/" + props.selectedBreedId)
        .then(response => response.json())
        .then(data => {
          data.forEach(dog => {
            dog["selections"] = dog["selections"].filter(selection => selection["technicalTypeId"] == 1 || selection["technicalTypeId"] == 0);
          });
          setBreedTraits(data.filter(dog => dog["_id"] != props.myDogId));
          setBreedId(props.breedId);
        })
        .catch(error => console.error("Error:", error));
      // Fetch the actual wiz to display images
      _Auth.fetch("/rest/breedwiz/" + props.selectedBreedId)
        .then(response => response.json())
        .then(data => {
          setImages(data.traits.filter(item => item.technicalTypeId == 1).map((item) => { return {traitValues: item.traitValues}; }));
        })
        .catch(error => console.error("Error:", error));
    }
    if (props.myDogId != undefined) {
      _Auth.fetch("/rest/breedwiz/fordog/" + props.myDogId)
        .then(response => response.json())
        .then(data => {
          let exteriorMyDog = null;
          data.forEach(item => {
            if (item.selections.length > 0) {
              if (item.selections[0]["technicalTypeId"] == 0 || item.selections[0]["technicalTypeId"] == 1) {
                exteriorMyDog = item;
              }
            }
          });
          setMydogTraits(exteriorMyDog);
          setMyDogId(props.myDogId);
        })
        .catch(error => console.error("Error:", error));
    }
  }, [props.selectedBreedId, props.myDogId]);

  return (
    <React.Fragment>
      <div>
        <Card className={"info-card center"}>
          <card-content>
            <div className={"info-card-content"}>

              {getTraitCardPart(t("statistics.areas.exterior.cardparts.part1") + " ", 0)}
              <hr />
              {getTraitCardPart(t("statistics.areas.exterior.cardparts.part2") + " ", 1)}
              <hr />
              {getTraitCardPart(t("statistics.areas.exterior.cardparts.part3") + " ", 2)}
              <hr />
              {getTraitCardPart(t("statistics.areas.exterior.cardparts.part4") + " ", 3)}
              <hr />
              {getTraitCardPart(t("statistics.areas.exterior.cardparts.part5") + " ", 4)}
              <hr />

            </div>
          </card-content>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default StatisticsExterior;
