import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Menu from "../views/menu/Menu";
import LocaleHandler from '../../LocaleHandler';
import ReactFlagsSelect from 'react-flags-select';

function Navbar(props) {
  const { setLocale, locale } = React.useContext(LocaleHandler);

  const [showMenu, setShowMenu] = useState(false);

  function showMenuBtn() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  return (
    <React.Fragment>
      <nav className="navbar">
        <IconButton
          className="logo"
          component={Link}
          to="/"
        >
          <img className="burger" src="/assets/images/logo.svg" alt="" />
        </IconButton>
        <div>
          <div className="flagselector">
            <ReactFlagsSelect
              selected={locale == 'en' ? 'GB' : locale == 'no' ? 'NO' : 'SE'}
              onSelect={(val) => {
                setLocale(val == 'GB' ? 'en' : val == 'NO' ? 'no' : 'se');
                localStorage.setItem('language', val == 'GB' ? 'en' : val == 'NO' ? 'no' : 'se');
              }}
              countries={["GB", "SE", "NO"]}
              blacklistCountries={false}
              showOptionLabel={false}
              showSelectedLabel={false}
            />
          </div>
          <IconButton
            classes={{ root: "icon-button" }}
            onClick={ showMenuBtn }
          >
            <img className="burger" src="/assets/images/burger.svg" alt="" />
          </IconButton>
        </div>
      </nav>
      { window.innerWidth > 600 ? (
        <div className="top-menu-container" style={ showMenu ? { 
          display: "block",
          position: "fixed",
          width: "400px",
          height: "auto",
          zIndex: "1000",
          top: "0px",
          right: "-20px",
          transition: "right 250ms linear"
        } : {
          display: "block",
          position: "fixed",
          width: "400px",
          zIndex: "1000",
          top: "0px",
          right: "-500px",
          transition: "right 250ms linear"
        }}>
        <Menu
          crossBtnListener={ closeMenu }
        />
      </div>
      ) : (
        <div className="top-menu-container" style={ showMenu ? { 
          display: "block",
          position: "fixed",
          width: "100%",
          height: "auto",
          zIndex: "1000",
          top: "0px",
          left: "0px",
          transition: "left 250ms linear"
        } : {
          display: "block",
          position: "fixed",
          width: "100%",
          zIndex: "1000",
          top: "0px",
          left: "calc(-100% - 100px)",
          transition: "left 250ms linear"
        }}>
        <Menu
          crossBtnListener={ closeMenu }
        />
        </div>
      ) }
    </React.Fragment>
  );
}

export default Navbar;
