import React, { useState, useEffect } from "react";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslate } from "react-polyglot";

function ProgressMobileStepper(props) {
  const t = useTranslate();

  const [activeStep, setActiveStep] = useState(0);

  function activeStepChange(i) {
    setActiveStep(i);
    props.activeListener(i);
  }

  function handleNext() {
    activeStepChange(activeStep + 1);
    if ((activeStep == (props.length - 2) && !props.noFinish) && props.onComplete != undefined) {
      props.onComplete();
    }
  }

  function handleBack() {
    activeStepChange(activeStep - 1);
  }

  useEffect(() => {
    if (props.gotoLastStep != 0) {
      setActiveStep(props.length - 2);
    }
  }, [props.gotoLastStep]);
  
  return (
    <React.Fragment>
      <MobileStepper
        classes={{root: "stepper"}}
        style={{
          display: (activeStep == (props.length - 1) && !props.noFinish) ? "none" : "",
          zIndex: 10
        }}
        variant="dots"
        steps={ props.length - 1 }
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === props.length - 1}>
            { (activeStep == (props.length - 2) && !props.noFinish) ? t("registrationwizard.stepper.complete") : t("registrationwizard.stepper.next") }
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            {t("registrationwizard.stepper.previous")}
          </Button>
        }
      />
      {props.incompleteSteps != undefined ?
        <style>
          {props.incompleteSteps.filter(i => i <= activeStep).map(i => {
            return `
              .MuiMobileStepper-dot:nth-child(${i}) {
                background-color: transparent;
                border: 1px solid darkgray;
              }
            `;
          }).join("")}
        </style>
      : null}
    </React.Fragment>
  );
}

export default ProgressMobileStepper;