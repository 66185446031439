import React, { useState, useEffect } from 'react';

import TaskCard from "../TaskCard";
import { TextField } from '@material-ui/core';

import { useTranslate } from 'react-polyglot';

function MeasureWizPage(props) {
    const t = useTranslate();

    const [measurementValue, setMeasurementValue] = useState("");

    function triggerPageUpdated(e) {
        let inputText = e.target.value.replace(",", ".");
        if (!isNaN(inputText) || inputText.charAt(inputText.length - 1) == ".") {
            setMeasurementValue(inputText);
            props.pageUpdatedCallback({
                ...props.trait,
                value: {measurementValue: inputText}
            });
        }
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        try {
            let value = JSON.parse(props.trait.value);
            setMeasurementValue(value.measurementValue);
        } catch (ex) {
            console.debug("no resume value");
        }
    }, []);

    return(
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    taskHeader={stringReplacer(props.trait.traitDescription)}
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="measuring-content-wrapper">
                            <img alt="" src={props.trait.image.imageUrl} style={{maxWidth: "400px", maxHeight: "400px"}} width="100%" maxWidth="400px" maxHeight="100%"/>
                            <div className="measuring-textfield">
                                <TextField
                                    id={props.trait.traidId}
                                    style={{width: "80%"}}
                                    label={ (props.trait.unit == "kg" ? t("registrationwizard.measuring.label.weight") : t("registrationwizard.measuring.label.goal")) + ` i ${props.trait.unit}`}
                                    type="text"
                                    placeholder={props.trait.unit}
                                    value={measurementValue}
                                    onChange={triggerPageUpdated}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );

}

export default MeasureWizPage
