import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";

class InputTextField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <TextField
        inputProps={{ style: { color: "#4b4f63" } }}
        InputLabelProps={{ classes: { root: "registration-text" } }}
        id={this.props.id}
        value={this.props.value}
        label={this.props.label}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        required={this.props.required}
        type={this.props.type}
        {...this.props}
      />
    );
  }
}
InputTextField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string
};

export default InputTextField;
