import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../ExplorerContext";

import Button from "@material-ui/core/Button";

function DogExplorerGallery(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [dogs, setDogs] = useState([]);
    const [loading, setLoading] = useState(false);

    function applyDogidFilter(regnumber) {
        let tempFilter = explorer.filters.filter(i => i.type != "regnumber");
        tempFilter.push({
            type: "regnumber",
            stage: "pre",
            operation: "eq",
            value: regnumber
        });
        explorer.updateFilters(tempFilter);
        //props.onFilterchange(tempFilter);
        if (props.requestTabChange != undefined) {
            props.requestTabChange(2);
        }
        //fetchDogs()
    }

    function fetchDogs() {
        setLoading(true);
        //if (props.onChangeStart != undefined) props.onChangeStart();
        fetch("/dataapi/api/dogs/filter/gallery/", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: "generelt",
                skip: dogs.length,
                amount: 25
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            let prevDogs = dogs;
            prevDogs = prevDogs.concat(json);
            setDogs(prevDogs);
            setLoading(false);
            //if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        setDogs([]);
    }, [explorer.filters])

    useEffect(() => {
        if (dogs.length == 0) {
            fetchDogs();
        }
    }, [dogs])

    return (
        <React.Fragment>
            <div className="dogexplorer-gallery">
                {dogs.map((dog, key) => 
                    <div key={key} className="dogexplorer-gallery-dog">
                        <div className="imagecontainer" onClick={() => applyDogidFilter(dog.regnumber)}>
                            <img src="/assets/images/doggoplaceholder.svg" />
                        </div>
                        <div className="titlecontainer">
                            <p>&nbsp;{dog.name != null && dog.name != undefined ? dog.name : dog.regnumber}</p>
                        </div>
                        <hr/>
                        <div className="textcontainer">
                            <p>&nbsp;{dog.birthdate != "NaN.NaN.NaN" ? dog.birthdate : ""}</p>
                        </div>
                    </div>
                )}
                <div style={{width: "100%", overflow: "auto"}}>
                    <Button
                        style={{ width: "auto" }}
                        classes={{ root: "edit-button" }}
                        disabled={loading}
                        onClick={() => fetchDogs()}
                    >
                        {loading ? t("dogexplorer.gallery.fetchdog") : t("dogexplorer.gallery.fetchdogs")}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerGallery;