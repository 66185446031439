import React from "react";
import { CSSGrid, makeResponsive } from 'react-stonecutter';

import Grid from "./Grid";
import GalleryHeader from "./GalleryHeader";
import Stepper from "../../common/Stepper";

import Fab from '@material-ui/core/Fab';
import Autorenew from '@material-ui/icons/Autorenew';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import Auth from "../../../Auth";

const CSSGridResponsive = makeResponsive(CSSGrid, {
  maxWidth: 2800,
  minPadding: 100
});

class Gallery extends React.Component {
  _Auth = new Auth();

  state = {
    redirect: false,
    autoupdate: false,

    galleryItems: [],
    breedsList: [],
    pagesize: 5,
    numPages: 10,
    currentPage: 0,
    sorting: "NEW",
    selectedBreed: 99999,
    isLoading: false,
    isEmpty: false,
    didTryAgain: false
  }

  constructor(props) {
      super(props);

      this.breedSelectionChanged = this.breedSelectionChanged.bind(this);
      this.sortingSelectionChanged = this.sortingSelectionChanged.bind(this);
      this.toggleAutoupdate = this.toggleAutoupdate.bind(this);
      this.stepperChanged = this.stepperChanged.bind(this);
  }

  getBreeds() {
    let that = this;
    this._Auth.fetch("/rest/breedwiz/breeds/")
      .then(response => response.json())
      .then(data => {
        this.setState({
          breedsList: data
        });
      })
      .catch(error => console.error("Error:", error));
  }

  toggleAutoupdate() {
    this.setState({ autoupdate: !this.state.autoupdate });
  }

  getGalleryPage(breedId, page, sorting, pageSize) {
    if (pageSize == undefined) {
      pageSize = this.state.pageSize;
    }
      this._Auth.fetch("/rest/dogs/filter", {
        method: "POST",
        body: JSON.stringify({
          "publicOnly": true,
          "breedId": breedId,
          "filter": sorting,
          "page": page,
          "pageSize": pageSize
        })
      })
        .then(res => res.json())
        .then(response => {
          let newImages = response.map(item => {
            return {
              id: item.dog.id,
              animalName: item.dog.name,
              imageSrc: ((item.dog.pictures[0] != undefined) ? item.dog.pictures[0].imageUrl : ""),
              ownerName: item.ownerName,
              animalId: item.dog.animalId
            }
          });

          if (newImages.length < 1) {
            this.setState({isEmpty: true});
            return;
          }

          let images = this.state.galleryItems;

          newImages.forEach(image =>{
            images.push(image);
          });

          this.setState({galleryItems: images, isLoading: false});
       
          
          const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
          const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        
          const scrolled = winScroll / height;
          const winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
          const docheight = this.getDocHeight();

          // If there is no scroll-bar, download again. (do until there is)
          if (Number.isNaN(scrolled) || docheight < winheight + 200) {
            this.setState({currentPage: this.state.currentPage + 1, isLoading: true})
            this.getGalleryPage(this.state.selectedBreed, this.state.currentPage, this.state.sorting);
          } 
           else if (this.state.galleryItems.length < 10 && !this.state.didTryAgain) {
             this.setState({didTryAgain: true, currentPage: this.state.currentPage + 1, isLoading: true});
            this.getGalleryPage(this.state.selectedBreed, this.state.currentPage, this.state.sorting);
          }
          
          
     

        })
        .catch(error => console.error("Error:", error));
  }

  getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    )
  }

  breedSelectionChanged(value) {
    this.setState({ didTryAgain: false, selectedBreed: value, isEmpty: false, currentPage: 0, galleryItems: [] });
    this.getGalleryPage(value, 0, this.state.sorting);
  }

  sortingSelectionChanged(value) {
    this.setState({didTryAgain: false, sorting: value, isEmpty: false, currentPage: 0, galleryItems: [] });
    this.getGalleryPage(this.state.selectedBreed, 0, value);
  }

  stepperChanged(value) {
    this.setState({ currentPage: value, isEmpty: false });
    this.getGalleryPage(this.state.selectedBreed, value, this.state.sorting);
  }

  componentDidMount() {
    var autoUpdater = setInterval(() => {
      if (this.state.autoupdate) {
        this.setState({galleryItems: [], currentPage: 0})
        this.getGalleryPage(this.state.selectedBreed, this.state.currentPage, this.state.sorting);
      }
    }, 30000);

    this.getBreeds();
    window.addEventListener('scroll', this.listenToScroll)

    let numItems = Math.floor(((window.innerWidth || (document.documentElement || document.body).clientWidth) - 100) / 330);

    if (numItems < 1) {
      numItems = 2;
    }
    this.setState({pageSize: numItems});

    
    this.getGalleryPage(this.state.selectedBreed, 0, "NEW", numItems);


  }

  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
  
    const scrolled = winScroll / height;
  
    if (scrolled > 0.75 && !this.state.isLoading && !this.state.isEmpty) {
      this.setState({currentPage: this.state.currentPage + 1, isLoading: true})
      this.getGalleryPage(this.state.selectedBreed, this.state.currentPage, this.state.sorting);
    }
  }

  scrollToTop = () => {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
      <div style={{ position: "absolute", left: "0px", width: "100%", padding: "12px" }}>
        <GalleryHeader
          breedsList={ this.state.breedsList }
          breedChangeListener={ this.breedSelectionChanged }
          sortingChangeListener={ this.sortingSelectionChanged }
        />
        <div className="gallery-container">
          <Grid gridResponsive={ CSSGridResponsive } items={ this.state.galleryItems } />
        </div>
        <div className="gallery-stepper-container">
          <Stepper
            activeListener={ this.stepperChanged }
            noFinish={ true }
            length={ this.state.numPages }
          />
        </div>
        <div className="gallery-reapeatbtn">
          <Fab
            color="primary"
            onClick={ this.toggleAutoupdate }
            size="medium"
            className={ this.state.autoupdate ? "enabled" : "" }
            aria-label="Autorenew"
          >
            <Autorenew />
          </Fab>
          <Fab
            color="primary"
            onClick={ this.scrollToTop }
            size="medium"
            className=""
            aria-label="gotoTop"
            style={{
              marginLeft: "10px"
            }}
          >
            <KeyboardArrowUp />
          </Fab>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  //galleryItems: PropTypes.array
};

export default Gallery;
