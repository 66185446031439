import React from "react";
import { useTranslate } from 'react-polyglot';


import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import NavBar from "../../common/Navbar";

function HowDoesItWork(){
  const t = useTranslate();

  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <NavBar />
      <img className="how-doggo-pic" src="/assets/images/doggo2.png" alt="" />
      <div className="how">
        <Typography gutterBottom variant="h3">
          {t("howdoesitwork.aboutBiotail")}
        </Typography>
        <Divider className="divider" />
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.description")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h3">
          {t("howdoesitwork.background")}
        </Typography>
        <Divider className="divider" />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.documentation")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.documentationdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.longterm")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.longtermdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.all")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.alldescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.breeders")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.breedersdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h3">
          {t("howdoesitwork.ourvalues")}​
        </Typography>
        <Divider className="divider" />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.exploring")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.exploringdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.interact")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.exploringdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.brave")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.bravedescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h5">
          {t("howdoesitwork.honest")}
        </Typography>
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.honestdescription")}
        </Typography>
        <br />
        <br />
        <Typography gutterBottom variant="h3">
          {t("howdoesitwork.who")}​
        </Typography>
        <Divider className="divider" />
        <Typography gutterBottom variant="body1">
          {t("howdoesitwork.contact")} <a href="mailto: post@aninova.no">post@aninova.no</a> {t("howdoesitwork.or")} <a href="tel: 99544702">99544702</a>

          <br />
          {t("howdoesitwork.moreabout")} <a href="http://www.aninova.no" target="blank">Aninova</a> {t("howdoesitwork.and")} <a href="https://www.heidner.no" target="blank">Heidner</a>
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default HowDoesItWork;
