import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../../ExplorerContext";

import { Card } from "@material-ui/core";
import InputTextField from "../../../../common/InputTextField";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';

import fetchHelperService from "../../../../../services/fetchHelper";

import { Line } from 'react-chartjs-2';

function CardTypeHDResultBirthyear(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    const [data, setData] = useState();

    const [appliedFilters, setAppliedFilters] = useState([]);

    const [filterMin, setFilterMin] = useState("");
    const [filterMax, setFilterMax] = useState("");

    const [filteringOpen, setFilteringOpen] = useState(false);

    const [showAll, setShowAll] = useState(false);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/hdresultbirthyear", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setData(json);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-hdresultbirthyear">
            <h1>{t("dogexplorer.profile.cardtypes.HDresultbirthyear.description")}</h1>
            <Line
                chartId="barchart1-1"
                options={{
                    tooltips: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.HDresultbirthyear.yearofbirth"),
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.HDresultbirthyear.result")
                                }
                            }
                        ]
                    }
                }}
                data={{
                    labels: data != undefined ? data.labels : [],
                    datasets: data != undefined ? data.datasets.map((i, key) => {
                        let gtfilter = explorer.filters.find(i => i.type == "hdscore" && i.operation == "gt");
                        let ltfilter = explorer.filters.find(i => i.type == "hdscore" && i.operation == "lt");
                        let gtfiltervalue = gtfilter != undefined && !showAll ? gtfilter.value : "E";
                        let ltfiltervalue = ltfilter != undefined && !showAll ? ltfilter.value : "A";
                        return {
                            backgroundColor: "transparent",
                            pointBackgroundColor: key == 0 ? "#68DB5A"
                                : key == 1 ? "#A6F65D"
                                : key == 2 ? "#FFE144"
                                : key == 3 ? "#F89D27"
                                : key == 4 ? "#FF2F45"
                                : [],
                            borderColor: key == 0 ? "#68DB5A"
                                : key == 1 ? "#A6F65D"
                                : key == 2 ? "#FFE144"
                                : key == 3 ? "#F89D27"
                                : key == 4 ? "#FF2F45"
                                : [],
                            pointRadius: 2,
                            label: key == 0 ? "A"
                                : key == 1 ? "B"
                                : key == 2 ? "C"
                                : key == 3 ? "D"
                                : key == 4 ? "E"
                                : [],
                            data: 
                                key == 0 && ["A","B","C","D","E"].includes(gtfiltervalue) && ["A"].includes(ltfiltervalue) ? i
                                : key == 1 && ["B","C","D","E"].includes(gtfiltervalue) && ["A","B"].includes(ltfiltervalue) ? i
                                : key == 2 && ["C","D","E"].includes(gtfiltervalue) && ["A","B","C"].includes(ltfiltervalue) ? i
                                : key == 3 && ["D","E"].includes(gtfiltervalue) && ["A","B","C","D"].includes(ltfiltervalue) ? i
                                : key == 4 && ["E"].includes(gtfiltervalue) && ["A","B","C","D","E"].includes(ltfiltervalue) ? i
                                : []
                        };
                    }) : []
                }}
                maxWidth={50}
                maxHeight={50}
                redraw={true}
            ></Line>
            <p onClick={() => setFilteringOpen(!filteringOpen)} style={{ cursor: "pointer" }}>Filter <FilterListIcon></FilterListIcon></p>
            
            <div style={ filteringOpen ? { height: "auto" } : { height: "0px", overflow: "hidden" }}>
                <FormControl style={{
                    maxWidth: "50%",
                    width: "140px"
                }}>
                    <InputLabel id="filtermin">{t("dogexplorer.profile.cardtypes.HDresultbirthyear.resultover")}</InputLabel>
                    <Select
                        id="filtermin"
                        labelId="filtermin"
                        value={filterMin}
                        onChange={(evt) => setFilterMin(evt.target.value)}
                    >
                        <MenuItem value={""}>{t("dogexplorer.profile.cardtypes.HDresultbirthyear.selectresult")}</MenuItem>
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                        <MenuItem value={"D"}>D</MenuItem>
                        <MenuItem value={"E"}>E</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{
                    maxWidth: "50%",
                    width: "140px"
                }}>
                    <InputLabel id="filtermin">{t("dogexplorer.profile.cardtypes.HDresultbirthyear.resultunder")}</InputLabel>
                    <Select
                        id="filtermax"
                        labelId="filterax"
                        value={filterMax}
                        onChange={(evt) => setFilterMax(evt.target.value)}
                    >
                        <MenuItem value={""}>{t("dogexplorer.profile.cardtypes.HDresultbirthyear.selectresult")}</MenuItem>
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                        <MenuItem value={"D"}>D</MenuItem>
                        <MenuItem value={"E"}>E</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    style={{ width: "auto" }}
                    classes={{ root: "edit-button" }}
                    onClick={() => {
                        let filters = explorer.filters;

                        filters = filters.filter(i => i.type != "hdscore");

                        if (filterMin != "") {
                            filters.push({ type: "hdscore", stage: "pre", operation: "gt", value: filterMin });
                        }
                        if (filterMax != "") {
                            filters.push({ type: "hdscore", stage: "pre", operation: "lt", value: filterMax });
                        }

                        explorer.updateFilters(filters);
                    }}
                >
                    {t("dogexplorer.profile.cardtypes.HDresultbirthyear.addfilter")}
                </Button>
                <div style={{width: "100%"}}>
                    <FormControlLabel
                        control={<Checkbox checked={Boolean(showAll)} onChange={(evt) => setShowAll(evt.target.checked)} />}
                        label={t("dogexplorer.profile.cardtypes.HDresultbirthyear.showallresults")}
                    />
                </div>
            </div>
        </Card>
    );
}

export default CardTypeHDResultBirthyear;