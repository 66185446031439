import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";

function ProfilecardGeneral(props) {
    let history = useHistory();
    const t = useTranslate();

    const [evaExams, setEvaExams] = useState();
    const [jaktproveExams, setJaktproveExams] = useState();
    const [helseExams, setHelseExams] = useState();

    useEffect(() => {
        if (props.dog.examinations != undefined) {
            let foundEvas = props.dog.examinations.filter(e => e.type == "eva");
            setEvaExams(foundEvas);
            let foundJaktprover = props.dog.examinations.filter(e => e.type == "jaktprove");
            if (foundJaktprover.length == 0) foundJaktprover = props.dog.examinations.filter(e => e.type == "jaktprovespor");
            setJaktproveExams(foundJaktprover);
            let foundHelse = props.dog.examinations.filter(e => e.type == "helse");
            setHelseExams(foundHelse);
        }
    }, [props.dog]);

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            <Card className="dogexplorer-profilecard-general">
                <h1>{t("dogexplorer.profile.cardtypes.general.about")}</h1>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.registrationnumber")}</td>
                            <td>{props.dog.regnumber}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.gender")}</td>
                            <td>{props.dog.sex == "1" ? "Hannhund" : "Tispe"}</td>
                        </tr>
                        {evaExams != undefined && evaExams.length > 0 ?
                            <React.Fragment>
                                <tr>
                                    <td style={{ fontWeight: "600" }}>PCI6</td>
                                    <td>{evaExams[0].pci6.replace(",", ".")}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.fCoefficient")}</td>
                                    <td>{evaExams[0].fCoefficient.replace(",", ".")}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.relationshipvalue")}</td>
                                    <td>{evaExams.find(e => e.relationshipStand != "" && e.relationshipStand != 0) != undefined ? evaExams.find(e => e.relationshipStand != "" && e.relationshipStand != 0).relationshipStand.toString().replace(",", ".") : ""}</td>
                                </tr>
                            </React.Fragment>
                        : null}
                        {jaktproveExams != undefined ?
                            <React.Fragment>
                                <tr>
                                    <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.huntingtrials")}</td>
                                    <td>{jaktproveExams.length}</td>
                                </tr>
                            </React.Fragment>
                        : null}
                        {helseExams != undefined ?
                            <React.Fragment>
                                <tr>
                                    <td style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.general.hipdysplasia")}</td>
                                    <td>{helseExams.map(i => i.scale).filter(i => i != "U").join(", ").length > 0 ? helseExams.map(i => i.scale).filter(i => i != "U").join(", ") : t("dogexplorer.profile.cardtypes.general.nottested")}</td>
                                </tr>
                            </React.Fragment>
                        : null}
                    </tbody>
                </table>
            </Card>
        </div>
    );
}

export default ProfilecardGeneral;