import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { Radar } from 'react-chartjs-2';
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";

function ProfilecardHuntingWeb(props) {
    let history = useHistory();
    const t = useTranslate();

    const [jaktproveExams, setJaktproveExams] = useState();

    useEffect(() => {
        if (props.dog.examinations != undefined) {
            let foundJaktprover = props.dog.examinations.filter(e => e.type == "jaktprove");
            setJaktproveExams(foundJaktprover);
        }
    }, [props.dog]);

    function getAverageResults() {
        let testparts = JSON.parse(JSON.stringify(jaktproveExams[0].testparts));

        testparts.forEach((part, key) => {
            let partSum = jaktproveExams.map(exam => Number(exam.testparts[key].data)).filter(i => i != 0).reduce((i, j) => i + j, 0);
            let amountParts = jaktproveExams.map(exam => Number(exam.testparts[key].data)).filter(i => i != 0).length;
            part.data = amountParts != 0 ? (partSum / amountParts) : 0;
        });

        return testparts;
    }

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            {jaktproveExams != undefined ? 
                <Card className="dogexplorer-profilecard-huntingweb">
                    <h1>{t("dogexplorer.profile.cardtypes.huntingweb.description")}</h1>
                    <Radar
                        chartId="chart1"
                        options= {{
                            legend: {
                                labels: {
                                    fontSize: 16
                                }
                            },
                            scale: {
                                pointLabels: {
                                    fontSize: 14
                                },
                                ticks: {
                                    min: 0,
                                    max: 10,
                                }
                            }
                        }}
                        data={{
                            labels: getAverageResults().map(i => i.part),
                            datasets: [
                                {
                                    label: t("dogexplorer.profile.huntingweb"),
                                    data: getAverageResults().map(i => i.data),
                                    backgroundColor: "rgba(56, 46, 146, 0.3)"
                                },
                            ]
                        }}
                        maxWidth={50}
                        maxHeight={50}
                        redraw={true}
                    ></Radar>
                </Card>
            : null}
        </div>
    );
}

export default ProfilecardHuntingWeb;