import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Redirect, useHistory } from 'react-router';

import ReactGA from "react-ga";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Menu from "../menu/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';

import StatisticsWeight from './statisticspages/StatisticsWeight';
import StatisticsExterior from './statisticspages/StatisticsExterior';
import StatisticsMeasurement from './statisticspages/StatisticsMeasurement';
import StatisticsBehavior from './statisticspages/StatisticsBehavior';

import NavBar from "../../common/Navbar";

import Auth from "../../../Auth";
import JwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useTranslate } from "react-polyglot";
import LocaleHandler from '../../../LocaleHandler';

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    }
  })
};

const NormalRoute = ({isMobile, component: Component, props, ...rest }) => {
  ReactGA.pageview(window.location.pathname);
  return (
  <Route {...rest} render={(props2) => (
    <div className={isMobile ? "mobile" : ""}><Component {...props} {...props2} /></div>
  )} />
)}

function Statistics(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const { setLocale } = React.useContext(LocaleHandler);

  let chartReference = {};

  const [myDogId, setMyDogId] = useState(props.location.state != undefined ? props.location.state.dbId : "0");
  const [breedId, setBreedId] = useState(props.location.state != undefined ? props.location.state.breedId : "0");
  const [dogs, setDogs] = useState([]);
  const [myDog, setMyDog] = useState({ name: t("statistics.main.loading") });
  const [myDogs, setMyDogs] = useState([]);
  const [myDogsLabels, setMyDogsLabels] = useState([]);
  const [myDogWeights, setMyDogWeights] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [config, setConfig] = useState(false);
  const [selectedBreedName, setSelectedBreedName] = useState("");
  const [selectedBreedId, setSelectedBreedId] = useState(props.location.state != undefined ? props.location.state.breedId : "");
  const [selectedDogName, setSelectedDogName] = useState("");
  const [manualSex, setManualSex] = useState(false);
  const [sexMale, setSexMale] = useState(true);
  const [sexFemale, setSexFemale] = useState(true);
  const [ageFrom, setAgeFrom] = useState(0);
  const [ageTo, setAgeTo] = useState(99);
  const [statisticsDisabled, setStatisticsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [allDogs, setAllDogs] = useState([]);
  
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSubData, setLoadingSubData] = useState(false);

  function handleChangeBreed(data) {
    setLoadingData(true);
    setSelectedBreedId(data.value.toString());
    setBreedId(data.value.toString());
    setSelectedBreedName(data.label);
    if (data.value.toString() != "99999999") {
      setMyDogId("0");
      setSelectedDogName(t("statistics.main.yourdogs"));
    }
    setSexFemale(true);
    setSexMale(true);
  }

  function handleChangeDog(data) {
    setMyDogId(data.value.toString());
    setSelectedDogName(data.label);
    setBreedId(myDogs.filter(dog => dog["id"] == data.value)[0]["breedId"].toString());
    setSelectedBreedId(myDogs.filter(dog => dog["id"] == data.value)[0]["breedId"].toString());
  }

  // function checkPaymentStatus() {
  //   let token = localStorage.getItem("id_token");
  //   if (token != undefined) {
  //     let decodedToken = JwtDecode(token);
  //     if (decodedToken.authorities != undefined && decodedToken.authorities.some(item => item == "ROLE_P1") ) {
  //       setStatisticsDisabled(false);
  //     }
  //   }
  // }

  function loadBreedData(breedId, filterload = false) {
    _Auth.fetch("/rest/dogs/breedid/" + breedId)
      .then(response => response.json())
      .then(data => {
        let myDog = data.filter(dog => dog["id"] == myDogId) != null ? data.filter(dog => dog["id"] == myDogId)[0] : null;
        setAllDogs(data);
        if (myDog != undefined) {
          if (selectedDogName != myDog["name"]) {
            setSelectedBreedName(myDog["breedName"]);
            setSelectedDogName(myDog["name"]);
            setSelectedBreedId(myDog["breedId"].toString());
          }
          setMyDog(myDog);

          if (!manualSex) {
            if (!filterload) {
              if (!sexMale) {
                data = data.filter(dog => dog["gender"] != "true");
              }
              if (!sexFemale) {
                data = data.filter(dog => dog["gender"] != "false");
              }
              setWeightData(data);

            } else {
              if (!sexMale) {
                data = data.filter(dog => dog["gender"] != "true");
              }
              if (!sexFemale) {
                data = data.filter(dog => dog["gender"] != "false");
              }
              setWeightData(data);
              
            }
          } else {
            setWeightData(data);
          }
        } else {
          setMyDogWeights([]);
          setMyDog(undefined);

          if (!sexMale) {
            data = data.filter(dog => dog["gender"] != "true");
          }
          if (!sexFemale) {
            data = data.filter(dog => dog["gender"] != "false");
          }
          setWeightData(data);
        }
        setLoadingData(false);
      })
      .catch(error => console.error("Error:", error));
  }

  //let scrollToStatisticsRef = () => window.scrollTo(0, this.statisticsRef.current.offsetTop)

  //cardStyle = {
  //  padding: "20px"
  //}

  useEffect(() => {
    //checkPaymentStatus();

    setSelectedDogName(t("statistics.main.yourdogs"));
    let userid = localStorage.getItem("userid");

    _Auth.fetch("/rest/dogs/owner/" + userid)
      .then(response => response.json())
      .then(data => {
        setMyDogsLabels(data.map(dog => { return { label: dog["name"], value: dog["id"] }}));
        setMyDogs(data);

        if (data.length == 1) {
          //setMyDogId(data[0]["id"].toString());
          //setMyDog(data[0]);
          //setBreedId(data[0]["breedId"]);
          //setSelectedDogName(data[0]["name"]);
        }
      })
      .catch(error => console.error("Error:", error));

    if (breedId == 0) {
      setSelectedBreedName(t("statistics.main.selectrace"));
      setSexMale(true);
      setSexFemale(true);
    }

    loadBreedData(breedId);

    _Auth.fetch("/rest/breedwiz/breeds/")
      .then(response => response.json())
      .then(data => {
        var breeds = [];
        for (const item of data) {
          breeds.push({ value: item.breedId, label: t("breeds.list." + item.breedId) });
        }

        setBreeds(breeds);
      })
      .catch(error => console.error("Error:", error));
  }, []);

  useEffect(() => {
    loadBreedData(breedId, true);
  }, [myDogId, selectedBreedId]);

  useEffect(() => {
    loadBreedData(selectedBreedId, true);
  }, [sexMale, sexFemale, ageFrom, ageTo]);

  return (
    !loading ?
      <React.Fragment>
        <div className="statistics">
          <div className="landingpage">
            { window.innerWidth > 600 ? (
              <div className="top-menu-container" style={ showMenu ? { 
                display: "block",
                position: "fixed",
                width: "400px",
                height: "auto",
                zIndex: "1000",
                top: "0px",
                right: "-20px",
                transition: "right 250ms linear"
              } : {
                display: "block",
                position: "fixed",
                width: "400px",
                zIndex: "1000",
                top: "0px",
                right: "-500px",
                transition: "right 250ms linear"
              }}>
              <Menu
                crossBtnListener={() => setShowMenu(false) }
              />
            </div>
            ) : (
              <div className="top-menu-container" style={ showMenu ? { 
                display: "block",
                position: "fixed",
                width: "100%",
                height: "auto",
                zIndex: "1000",
                top: "0px",
                left: "0px",
                transition: "left 250ms linear"
              } : {
                display: "block",
                position: "fixed",
                width: "100%",
                zIndex: "1000",
                top: "0px",
                left: "calc(-100% - 100px)",
                transition: "left 250ms linear"
              }}>
              <Menu
                crossBtnListener={() => setShowMenu(false) }
              />
              </div>
            ) }
            <div className="heading">
              <div className="burger-container">
                <div className="langswitchers MuiButtonBase-root MuiButton-root MuiButton-text loginbutton">
                  <img
                    width="30px"
                    className="language-icon"
                    src="/assets/images/flag_no.svg"
                    alt=""
                    onClick={() => {
                      setLocale("no");
                      localStorage.setItem("language", "no");
                    }}
                  />
                  <img
                    width="30px"
                    className="language-icon"
                    src="/assets/images/flag_se.svg"
                    alt=""
                    onClick={() => {
                      setLocale("se");
                      localStorage.setItem("language", "se");
                    }}
                  />
                  <img
                    width="30px"
                    className="language-icon"
                    src="/assets/images/flag_gb.svg"
                    alt=""
                    onClick={() => {
                      setLocale("en");
                      localStorage.setItem("language", "en");
                    }}
                  />
                </div>
                { _Auth.loggedIn() ? 
                  <Button onClick={() => { _Auth.logout(); localStorage.clear(); } } className="loginbutton">
                    {t("statistics.main.logout")}
                  </Button>
                :
                  <Button component={Link} to="/login" className="loginbutton">
                    {t("statistics.main.login")}
                  </Button>
                }
                { _Auth.loggedIn() ? 
                  ""
                :
                  <Button component={Link} to="/registration" className="loginbutton">
                    {t("statistics.main.newuser")}
                  </Button>
                }
                <IconButton
                  onClick={() => setShowMenu(true) }
                  className="menubutton"
                >
                  <img
                    className="burger"
                    src="/assets/images/burger_blue.svg"
                    alt=""
                  />
                </IconButton>
              </div>
              <div className="biotail-container">
                <img
                  onClick={() => history.push("/") }
                  className="biotail-logo"
                  style={{ cursor: "pointer" }}
                  src="/assets/images/biotail.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="header" style={{ backgroundColor: "#2e3694" }}>
              <img
                className="headerImage"
                src="/assets/images/headerbilde_statistikk.jpg"
                alt=""
                style={{ marginTop: "-130px", opacity: "0.3" }}
              />
              <div className="description">
                <div className={"info-text-container"}>
                  <h3>{t("statistics.main.title")}</h3>
                </div>
              </div>
            </div>
            
            <div className="statistics-diclaimer">
              <div className={"info-text-container"}>
                <Typography className={"info-text"} style={{ paddingBottom: "25px", color: "blueberry" }}>
                  {t("statistics.main.description1")}
                  <br/><br/>
                  {t("statistics.main.description2")}
                  <br/><br/>
                  {t("statistics.main.description3")}
                  <br/>{t("statistics.main.description4")} <a href="mailto:post@aninova.no">post@aninova.no</a>.
                  <br/><br/>
                  <a href="#" onClick={() => history.push("/status") }>{t("statistics.main.check_statistics_status")}</a>
                </Typography>
              </div>
            </div>

            <div className="cardmenucontainer">
              <div className="row">
                <div className="col-md-3">
                  <Card className={"info-card menucard"} onClick={() => history.push({ pathname: '/statistics/weight' })}>
                    <card-content>
                      <div className={"info-card-content " + (props.location.pathname.includes("weight") ? "selected" : "")}>
                        <div className={"info-card-body"}>
                          <img
                            className="menucard-main-image"
                            src="/assets/images/icon_statistikk.svg"
                            alt=""
                          />
                          <h1>{t("statistics.main.cards.weight.title")}</h1>
                          <p>{t("statistics.main.cards.weight.description")}</p>
                        </div>
                      </div>
                    </card-content>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className={"info-card menucard"} onClick={() => { history.push({ pathname: '/statistics/exterior' }) }}>
                    <card-content>
                      <div className={"info-card-content " + (props.location.pathname.includes("exterior") ? "selected" : "")}>
                        <div className={"info-card-body"}>
                          <img
                            className="menucard-main-image"
                            src="/assets/images/icon_statistikk.svg"
                            alt=""
                          />
                          {/* <Tooltip title="Kjøp tilgang til utvidet statistikk!" aria-label="add">
                            <div className="menucardlock">
                              <img
                                className="lockimage"
                                src="/assets/images/icon_lock_gray.svg"
                                alt=""
                                onClick={() => history.push({ pathname: '/products'})}
                              />
                            </div>
                          </Tooltip> */}
                          <h1>{t("statistics.main.cards.exterior.title")}</h1>
                          <p>{t("statistics.main.cards.exterior.description")}</p>
                        </div>
                      </div>
                    </card-content>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className={"info-card menucard"} onClick={() => { history.push({ pathname: '/statistics/measure' }) }}>
                    <card-content>
                      <div className={"info-card-content " + (props.location.pathname.includes("measure") ? "selected" : "")}>
                        <div className={"info-card-body"}>
                          <img
                            className="menucard-main-image"
                            src="/assets/images/icon_statistikk.svg"
                            alt=""
                          />
                          {/* <Tooltip title="Kjøp tilgang til utvidet statistikk!" aria-label="add">
                            <div className="menucardlock">
                              <img
                                className="lockimage"
                                src="/assets/images/icon_lock_gray.svg"
                                alt=""
                                onClick={() => history.push({ pathname: '/products'})}
                              />
                            </div>
                          </Tooltip> */}
                          <h1>{t("statistics.main.cards.measurements.title")}</h1>
                          <p>{t("statistics.main.cards.measurements.description")}</p>
                        </div>
                      </div>
                    </card-content>
                  </Card>
                </div>
                {/*<div className="col-md-3">
                  <Card className={"info-card menucard" + (statisticsDisabled ? " disabled" : "")} onClick={() => { if(!statisticsDisabled) {history.push({ pathname: '/statistics/behavior' })} else { history.push({ pathname: '/products'}) } }}>
                    <card-content>
                      <div className={"info-card-content " + (props.location.pathname.includes("behavior") ? "selected" : "")}>
                        <div className={"info-card-body"}>
                          <img
                            className="menucard-main-image"
                            src="/assets/images/icon_statistikk.svg"
                            alt=""
                          />
                          <Tooltip title="Kjøp tilgang til utvidet statistikk!" aria-label="add">
                            <div className="menucardlock">
                              <img
                                className="lockimage"
                                src="/assets/images/icon_lock_gray.svg"
                                alt=""
                                onClick={() => history.push({ pathname: '/products'})}
                              />
                            </div>
                          </Tooltip>
                          <h1>{t("statistics.main.cards.behavior.title")}</h1>
                          <p>{t("statistics.main.cards.behavior.description")}</p>
                        </div>
                      </div>
                    </card-content>
                  </Card>
                </div>*/}
                <div className="col-md-3">
                  <Card className={"info-card menucard"} onClick={() => {
                    if (selectedBreedId == "") {
                      handleChangeBreed({label: t("breeds.list.99999999"), value: "99999999"});
                    }
                    history.push({ pathname: '/statistics/behavior' });
                  }}>
                    <card-content>
                      <div className={"info-card-content " + (props.location.pathname.includes("behavior") ? "selected" : "")}>
                        <div className={"info-card-body"}>
                          <img
                            className="menucard-main-image"
                            src="/assets/images/icon_statistikk.svg"
                            alt=""
                          />
                          <h1>{t("statistics.main.cards.behavior.title")}</h1>
                          <p>{t("statistics.main.cards.behavior.description")}</p>
                        </div>
                      </div>
                    </card-content>
                  </Card>
                </div>
              </div>
            </div>

            <div className="filterArea">
              <Card className={"info-card center"}>
                <card-content>
                  <div className={"info-card-content"}>
                    <div className={"info-card-header"}>
                      <div className={"info-card-text-header"}>
                        {t("statistics.main.filters.title")}
                      </div>
                    </div>
                    <div className={"info-card-body"}>
                      <div className="helpertext">
                        <p>{t("statistics.main.filters.description1")}</p>
                      </div>
                      <div className="filterinputs">
                        <Select
                          className="registration-text"
                          inputId="react-select-single"
                          styles={selectStyle}
                          TextFieldProps={{
                            label: t("statistics.main.filters.race"),
                            InputLabelProps: {
                              htmlFor: 'react-select-single',
                              shrink: true,
                            },
                          }}
                          value={selectedBreedId != "" ? { value: selectedBreedId, label: t("breeds.list." + selectedBreedId) } : null}
                          placeholder={selectedBreedName}
                          options={
                            [
                              { value: "99999999", label: t("breeds.list.99999999") },
                              ...(breeds.map(b => { return { ...b, label: t("breeds.list." + b.value) } }))
                            ]
                          }
                          onChange={handleChangeBreed}
                        />
                        <Select
                          className="registration-text"
                          inputId="react-select-single"
                          styles={selectStyle}
                          TextFieldProps={{
                            label: t("statistics.main.filters.dog"),
                            InputLabelProps: {
                              htmlFor: 'react-select-single',
                              shrink: true,
                            },
                          }}
                          value={myDogId}
                          placeholder={selectedDogName}
                          options={myDogsLabels}
                          onChange={handleChangeDog}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={Boolean(sexMale)} onChange={evt => setSexMale(evt.target.checked)} />}
                          label={t("statistics.main.filters.male")}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={Boolean(sexFemale)} onChange={evt => setSexFemale(evt.target.checked)} />}
                          label={t("statistics.main.filters.female")}
                        />
                      </div>
                      <div className="helpertext">
                        {!loadingData && !loadingSubData ?
                          <p>{t("statistics.main.filters.description2")} { weightData.length } { weightData.length == 1 ? t("statistics.main.filters.dog_single") : t("statistics.main.filters.dog_multiple") }</p>
                        :
                          <p>{t("statistics.main.filters.loadingdata")}</p>
                        }
                      </div>
                    </div>
                  </div>
                </card-content>
              </Card>
            </div>
            
            <Switch>
              <NormalRoute path="/statistics/weight" isMobile={false} props={ {selectedBreedId: selectedBreedId, sexMale: sexMale, sexFemale: sexFemale, myDogId: myDogId, myDog: myDog, weightData: weightData} } component={StatisticsWeight} />
              <NormalRoute path="/statistics/exterior" isMobile={false} props={ {selectedBreedId: selectedBreedId, sexMale: sexMale, sexFemale: sexFemale, myDogId: myDogId, myDog: myDog, dogs: weightData} } component={StatisticsExterior} />
              <NormalRoute path="/statistics/measure" isMobile={false} props={ {selectedBreedId: selectedBreedId, sexMale: sexMale, sexFemale: sexFemale, myDogId: myDogId, myDog: myDog, dogs: weightData} } component={StatisticsMeasurement} />
              <NormalRoute path="/statistics/behavior" isMobile={false} props={ {
                selectedBreedId: selectedBreedId,
                sexMale: sexMale,
                sexFemale: sexFemale,
                myDogId: myDogId,
                myDog: myDog,
                myDogs: myDogs,
                selectedDogName: selectedDogName,
                allDogs: allDogs,
                loadingStart: () => setLoadingSubData(true),
                loadingEnd: () => setLoadingSubData(false),
              } } component={StatisticsBehavior} />
            </Switch>

            <div className={"bottom-text"}>
              <div className={"collaborators"}>
                <div className={"collaborators-title"}>
                  <img
                    className="b-image2"
                    src="/assets/images/B.svg"
                    alt=""
                  />
                  <br/>
                  <p className="ownertext">
                    {t("statistics.footer.owner")} <a style={{ color: "white" }} target="_blank" href="http://aninova.no/">Aninova</a>
                  </p>
                </div>
                <div className={"strek2 hide-on-desktop"}></div>
              </div>
              <div className={"contact"}>
                <Link className={"link"} to="/howdoesitwork" >{t("statistics.footer.about")}</Link> <br />
                <Link className={"link"} to="/gdpr" >{t("statistics.footer.cookies")}</Link> <br />
                <div className={"strek2"}></div>
                {t("statistics.footer.contact")} <a className={"link"} href="mailto:post@aninova.no" >post@aninova.no</a> {t("statistics.footer.or")} @biotail {t("statistics.footer.at")} <u><a style={{ color: "white" }} target="_blank" href="https://www.facebook.com/biotail/">Facebook</a></u>.
                <div className={"strek2"}></div>
                <div className={"developer-text"}>{t("statistics.footer.developer")} <a className={"link"} href="https://kongarthur.no" target="_blank">Kong Arthur</a> </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    :
      <React.Fragment>
        <NavBar />
        <Typography gutterBottom variant="h3" className="loading-text">
          {t("statistics.main.loading")}
        </Typography>
      </React.Fragment>
  );
}

export default Statistics;
