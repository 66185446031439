import React, {useEffect, useState, useRef, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import Tree from 'react-tree-graph';

import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import "./ProfilecardFamilytree.css";

import { ExplorerContext } from "../../ExplorerContext";

function ProfilecardFamilytree(props) {

    let explorer = useContext(ExplorerContext);
    const t = useTranslate();

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    function fetchData(regnumber = props.dog.regnumber) {
        setLoading(true);
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetch("/dataapi/api/dogs/filter/carddata/familytree", {
            method: "POST",
            body: JSON.stringify({
                regnumber
            }),
            headers: new Headers({
                "Content-Type": "application/json"
            })
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setData(json);
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        explorer.addFilterChangeListener("familytreereloader", (filters) => {
            let regnumberfilter = filters.find(filter => filter.type == "regnumber");
            if (regnumberfilter != undefined) {
                let regnumber = regnumberfilter.value;
                fetchData(regnumber);
            }
        });
    }, []);

    useEffect(() => {
        if (props.dog != undefined && data == undefined) {
            fetchData();
        }
    }, [props.dog]);

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            <Card className="dogexplorer-profilecard-familytree">
                <h1>{t("dogexplorer.profile.cardtypes.familytree.title")}</h1>
                <p>{t("dogexplorer.profile.cardtypes.familytree.description")}</p>
                <div className={"loading " + (!loading ? "hidden" : "")}>
                    <p>{t("dogexplorer.profile.cardtypes.familytree.loading")}</p>
                </div>
                <Button
                    //key={"button" + (data != undefined ? data._id : "a")}
                    style={{ width: "auto" }}
                    classes={{ root: "edit-button" }}
                    onClick={() => {
                        explorer.goBack();
                    }}
                >
                    {t("dogexplorer.profile.cardtypes.familytree.back")}
                </Button>
                {data != undefined ?
                    <Tree
                        key={data._id}
                        data={data}
                        labelProp={"name"}
                        height={1500}
                        width={1000}
                        //animated
                        pathFunc={(x1, y1, x2, y2) => `M${y1},${x1} ${y1},${x2} ${y2},${x2}`}// ${y2},${x2}'}
                        svgProps={{
                            viewBox: "-20 0 1030 1500",
                            style: { width: "100%" },
                            className: 'custom',
                            width: "100%"
                        }}
                        nodeShape="image"
                        nodeProps={{
                            height: 40,
                            width: 40,
                            x: -30,
                            onClick: (evt, nodeKey) => {
                                let tempFilter = explorer.filters.filter(i => i.type != "regnumber");

                                tempFilter.push({
                                    type: "regnumber",
                                    stage: "pre",
                                    operation: "eq",
                                    value: nodeKey
                                });

                                explorer.updateFilters(JSON.parse(JSON.stringify(tempFilter)));
                            },
                            href: '/assets/images/doggoplaceholder_round.svg'
                        }}
                    />
                : null}
            </Card>
        </div>
    );
}

export default ProfilecardFamilytree;