import React, {useEffect, useState, useContext, useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Card } from "@material-ui/core";

import { Bar } from 'react-chartjs-2';

function CardTypeJaktproveSumYear(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    let chartRef = useRef();

    const [data, setData] = useState([]);

    const [appliedFilters, setAppliedFilters] = useState([]);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/jaktprovesumyear", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            setData(json);
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-jaktprovesumyear">
            <h1>{t("dogexplorer.profile.cardtypes.huntingprovesumyear.description")}</h1>
            <Bar
                ref={chartRef}
                chartId="barchart1-1"
                options={{
                    onClick: (evt, data) => {
                        
                    },
                    tooltips: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.huntingprovesumyear.year"),
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    max: 100,
                                    min: 0
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: t("dogexplorer.profile.cardtypes.huntingprovesumyear.point")
                                }
                            }
                        ]
                    }
                }}
                data={{
                    labels: Object.keys(data).filter(key => key >= 1990).map(key => key),
                    datasets: [
                        {
                            backgroundColor: "#382e92",
                            label: t("dogexplorer.profile.cardtypes.huntingprovesumyear.average"),
                            data: Object.keys(data).filter(key => key >= 1990).map(key => data[key])
                        }
                    ]
                }}
                maxWidth={50}
                maxHeight={50}
            ></Bar>
        </Card>
    );
}

export default CardTypeJaktproveSumYear;