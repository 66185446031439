import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import IconButton from "@material-ui/core/IconButton";
import TaskCard from "../TaskCard";
import ImageMeasure from '../../ImageMeasure';
import ImageResizerService from "../../../../services/ImageResizer";
import html2canvas from 'html2canvas';
import blueimp from 'blueimp-load-image';

import { useTranslate } from 'react-polyglot';

function ProportionWizPage(props) {
    const t = useTranslate();

    const [open, setOpen] = useState();
    const [proportionItems, setProportionItems] = useState(null);

    const [traitPicture, setTraitPicture] = useState(null);
    const [headProportion, setHeadProportion] = useState(0);
    const [partialPercentage, setPartialPercentage] = useState(0);
    const [rotation, setRotation] = useState(props.trait.rotation != undefined ? props.trait.rotation : 0);
    const [defaultRotation, setDefaultRotation] = useState(props.trait.rotation != undefined ? props.trait.rotation : 0);
    const [position, setPosition] = useState({left: 0, top: 0});
    const [size, setSize] = useState({width: 0, height: 0});
    const [line, setLine] = useState(0);

    const [showComponent, setShowComponent] = useState(false);
    const [photoTaken, setPhotoTaken] = useState(false);
    const [boundry, setBoundry] = useState([]);
    const [width, setWidth] = useState(120);
    const [height, setHeight] = useState(120);
    const [imgProp, setImgProp] = useState(0);
    const [direction, setDirection] = useState("left");

    const fileInput = useRef(null);
    const imagecontainer = useRef(null);
    const container = useRef(null);
    const imagecapture = useRef(null);

    function onResizeWindow(evt) {
        if (imgProp > 0) {
            let imgWidth = imagecontainer.current.offsetWidth;
            setHeight(imgWidth * imgProp);
        }
    }

    function toDataURL(img) {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL();
        return dataURL;
    }

    function onTakePhoto(dataUri) {
        //var reader = new FileReader();
        //reader.readAsBinaryString(dataUri.target.files[0]);

        blueimp(dataUri.target.files[0], { maxWidth: 1000 }).then(image => {
            let base64 = toDataURL(image.image);

            ImageResizerService.resizeForUpload(base64, 1000, 1000, 0.75).then(image => {
                ImageResizerService.getImageSize(image).then(data => {
                    let imgWidth = imagecontainer.current.offsetWidth;

                    setBoundry([data.height / 2, data.width / 2, -data.height / 2, -data.width / 2]);
                    setWidth(data.width);
                    setHeight(imgWidth * (data.height / data.width));
                    setImgProp(data.height / data.width);
                    setTraitPicture(image);
                })
            })
        });

        /*reader.onload = function () {
            let result = btoa(reader.result);
            ImageResizerService.resizeForUpload("data:application/octet-stream;base64," + result, 1000, 1000, 0.75).then(image => {

                ImageResizerService.getImageSize(image).then(data => {
                    let imgWidth = imagecontainer.current.offsetWidth;

                    setBoundry([data.height / 2, data.width / 2, -data.height / 2, -data.width / 2]);
                    setWidth(data.width);
                    setHeight(imgWidth * (data.height / data.width));
                    setImgProp(data.height / data.width);
                    setTraitPicture(image);
                })
            })
        };*/
    }

    function onCameraButtonClick() {
        fileInput.current.click();
    }

    function triggerPageUpdated(head, partial, traitPicture, rotation, position, size, direction, line) {
        html2canvas(imagecapture.current, {
            scrollY: -window.scrollY
        }).then(canvas => {
            setHeadProportion(head);
            setPartialPercentage(partial);
            setTraitPicture(traitPicture);
            setRotation(rotation);
            setPosition(position);
            setSize(size);
            setDirection(direction);
            setLine(line);
            
            props.pageUpdatedCallback({
                ...props.trait,
                value: {
                    proportionType: defaultRotation,
                    headProportion: head,
                    partialPercentage: partial,
                    rotation: rotation,
                    position: { top: position.top / imagecapture.current.offsetHeight, left: position.left / imagecapture.current.offsetWidth},
                    size: { width: size.width / imagecapture.current.offsetWidth, height: size.height / imagecapture.current.offsetHeight},
                    direction: direction,
                    line: line / imagecapture.current.offsetWidth,
                    picture: traitPicture,
                    capture: canvas.toDataURL()
                }
            });
        });
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        window.addEventListener("resize", onResizeWindow);
        if (props.trait.value != undefined && imagecontainer.current != null) {
            container.current.style = "display: block; margin-bottom: 120px";
            try {
                let value = JSON.parse(props.trait.value);
    
                ImageResizerService.getImageSize(value.picture).then(data => {
                    let imgWidth = imagecontainer.current.offsetWidth;

                    setHeadProportion(value.headProportion);
                    setPartialPercentage(value.partialPercentage);
                    setRotation(value.rotation);
                    setPosition({ top: value.position.top * (imgWidth * (data.height / data.width)), left: value.position.left * imagecapture.current.offsetWidth});
                    setSize({ width: value.size.width * imagecapture.current.offsetWidth, height: value.size.height * (imgWidth * (data.height / data.width))});
                    setDirection(value.direction);
                    setLine(value.line * imagecapture.current.offsetWidth);
    
                    setBoundry([data.height / 2, data.width / 2, -data.height / 2, -data.width / 2]);
                    setWidth(data.width);
                    setHeight(imgWidth * (data.height / data.width));
                    setImgProp(data.height / data.width);
                    setTraitPicture(value.picture);
                    container.current.style = props.display ? "display: block; margin-bottom: 120px" : "display: none; margin-bottom: 120px";
                })
            } catch (ex) {
                console.debug("no resume value");
            }
        }
    }, []);

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }} ref={container}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    taskHeader={props.trait.traitName}
                    //taskBody="I denne registreringen skal vi tallfeste hundens snutelengde i forhold til skallelengden. Tallet kommer ut som et forholdstall i sertifikatet som lages ved avsluttet registrering.\nDu trenger et bilde av hundens hode fra siden. Roter og plasser boksen slik som i instruksjonsvideoen. Videoen finner du ved å trykke på (i) ikonet. Husk at den grønne streken skal være på tuppen av hundens snute, og den røde på hundens bakhode."
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    taskHelpText=""
                    noInfo
                    taskInfoHeader={"Instruksjonsvideo"}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className='dog-proportion-explainimage' >
                            <div>
                                <img src={props.trait.imageUrl} />
                            </div>
                        </div>
                    }
                />

                <TaskCard
                    taskCounter={"Egenskap: " + props.trait.traitName}
                    taskHeader={props.trait.traitInfoName}
                    taskBody={
                        defaultRotation == 90 ?
                            t("registrationwizard.proportion.defaultrotation.description1")
                        :
                        t("registrationwizard.proportion.defaultrotation.description2")
                    }
                    style={{overflow: "visible"}}
                    taskInfoHeader={"Instruksjonsvideo"}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    //taskInfoHeader="Tips til fotografering"
                    //taskInfoBody="Sørg for at bildene taes i gode lysforhold. 
        //Du kan gjerne være utendørs, dette er ofte lettere for hunden. Du kan også få hjelp av noen til å holde hunden rolig, et bånd kan være kjekt i en slik situasjon.
//
        //Belønn gjerne med godbiter mellom hvert bilde, dette gjør at hunden holder fokus lengre enn normalt."
                    children={
                        <div className="picture-button-container" ref={imagecontainer} >
                            { defaultRotation != 90 ?
                                <div className="measureflipper">
                                    <h5>{t("registrationwizard.proportion.direction")}</h5>
                                    <div className={direction == "left" ? "selected" : ""} onClick={() => triggerPageUpdated(headProportion, partialPercentage, traitPicture, rotation, position, size, "left", line)}>
                                        <p>{t("registrationwizard.proportion.left")}</p>
                                    </div>
                                    <div className={direction == "right" ? "selected" : ""} onClick={() => triggerPageUpdated(headProportion, partialPercentage, traitPicture, rotation, position, size, "right", line)}>
                                        <p>{t("registrationwizard.proportion.right")}</p>
                                    </div>
                                </div>
                            : null }
                            <div className="dog-features-img-container" ref={imagecapture} style={{
                                width: "100%",
                                margin: "auto",
                                marginTop: "40px",
                                background: "unset",
                                height: "fit-content"
                            }} id="imagecontainer">
                                {traitPicture != undefined ?
                                    <div className="testcontainer" style={{ width: "100%", height: height }}>
                                        <ImageMeasure
                                            frameStyle={{
                                                backgroundImage: 'url("' + traitPicture + '")'
                                            }}
                                            direction={direction}
                                            startrotation={rotation != 0 ? rotation : props.trait.orientation}
                                            defaultrotation={defaultRotation != 0 ? defaultRotation : props.trait.orientation}
                                            lineStart={line != 0 ? line : undefined}
                                            onChange={(p, s, r, l) => { console.log({p,s,r,l}); triggerPageUpdated(s.width / s.height, (l / s.width) * 100, traitPicture, r, p, s, direction, l); }}
                                            //onLineMove={(val) => { triggerPageUpdated(headProportion, val, traitPicture, rotation, position, size, direction, line); } }
                                            //onBoxResize={(val) => { triggerPageUpdated(val, partialPercentage, traitPicture, rotation, position, size, direction, line); } }
                                            boxStartLeft={(imagecontainer.current.offsetWidth / 4)}
                                            boxStartTop={((imagecontainer.current.offsetWidth * imgProp) / 4)}
                                            boxStartWidth={(imagecontainer.current.offsetWidth / 2)}
                                            boxStartHeight={((imagecontainer.current.offsetWidth * imgProp) / 2)}
                                            defaultboxStartLeft={position.left != 0 ? position.left : undefined}
                                            defaultboxStartTop={position.top != 0 ? position.top : undefined}
                                            defaultboxStartWidth={size.width != 0 ? size.width : undefined}
                                            defaultboxStartHeight={size.height != 0 ? size.height : undefined}
                                        ></ImageMeasure>
                                    </div>
                                : null}
                            </div>

                            {/*<div>
                                <p>Høyde / bredde: {headProportion != null ? headProportion.toFixed(2) : ""}</p>
                                <p>Andel mellom grønn og blå: {(partialPercentage * 100).toFixed(2)}%</p>
                            </div>*/}

                            {showComponent ?
                                ""
                                :
                                <div className="button-placement">
                                    <IconButton onClick={() => onCameraButtonClick()}>
                                        <img src="/assets/images/tabildeknapp.svg" alt="" />
                                        <div style={{ position: "absolute" }}>
                                            <img style={{ verticalAlign: "inherit" }} src="/assets/images/photo_icon.svg" alt="" />
                                        </div>
                                    </IconButton>
                                    <input onChange={onTakePhoto} ref={fileInput} style={{ display: "none" }} id="file-input" type="file" name="myImage" accept="image/*" />
                                </div>
                            }
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

ProportionWizPage.propTypes = {
    trait: PropTypes.object,
    pageUpdatedCallback: PropTypes.func,
    display: PropTypes.bool
};

export default ProportionWizPage;