import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'table',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%"
    },
    item:{
        position: "fixed",
        top: "50%",
        left: "48%",
        transform: "translate(-50%, -50%)"
    }
  }));

const Loader = () => {

    const classes = useStyles();

    return (
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: "100vh"}}
        >
            <Grid item xs={3}> 
                <CircularProgress  />
            </Grid>
        </Grid>
    );


}

export default Loader