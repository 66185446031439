import React, {useState, useRef, useEffect} from 'react';

import IconButton from "@material-ui/core/IconButton";
import TaskCard from "../TaskCard";
import SimpleSlider from '../../SimpleSlider';
import ImageResizerService from "../../../../services/ImageResizer";

import { useTranslate } from 'react-polyglot';

function FeatureWizPage(props) {
  const t = useTranslate();

  const [sliderItems, setSliderItems] = useState([]);
  const [sliderSelected, setSliderSelected] = useState();
  const [traitPicture, setTraitPicture] = useState(null);

  const fileInput = useRef();

  useEffect(() => {
    let traitValues = props.trait.traitValues.map((trait) => { return {
      id: trait.traitId.toString(),
      value: trait.validId.toString(),
      sliderPicture: trait.image.imageUrl
    }});
    setSliderItems(traitValues);

    try {
      let value = JSON.parse(props.trait.value);
      setSliderSelected(value.sliderSelected);
      setTraitPicture(value.traitPicture);
    } catch (ex) {
      console.debug("no resume value");
    }
  }, []);

  const handleSliderSelects = (value) => {
    setSliderSelected(parseInt(value));
    triggerPageUpdated(traitPicture, parseInt(value));
  }

  function onTakePhoto(dataUri) {
    var reader = new FileReader();
    reader.readAsBinaryString(dataUri.target.files[0]);

    reader.onload = function() {
      let result = btoa(reader.result);

      ImageResizerService.resizeForUpload("data:application/octet-stream;base64," + result, 500, 500, 0.75).then(image => {
        ImageResizerService.getImageSize(image).then( data => {
          setTraitPicture(image);
          triggerPageUpdated(image, sliderSelected);
        });
      });
    }
  }

  function triggerPageUpdated(picture = null, sliderSelected = undefined) {
    props.pageUpdatedCallback({
      ...props.trait,
      value: {
        sliderSelected: sliderSelected,
        sliderPicture: sliderItems.find(item => item.value == sliderSelected) != undefined ? sliderItems.find(item => item.value == sliderSelected).sliderPicture : null,
        traitPicture: picture == null ? traitPicture : picture
      }
    });
  }

  function stringReplacer(str) {
      str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
      str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
      str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
      str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
      return str;
  }

  return (
    <React.Fragment>
      <div style={{ display: props.display ? "block" : "none" }}>
        <TaskCard className="feature-card" 
          taskCounter={props.trait.traitName}
          taskHeader={stringReplacer(props.trait.traitDescription)}
          taskBody=""
          noInfo={props.trait.traitInfoName == "" ? true : undefined}
          taskHelpText={t("registrationwizard.feature.trait.helptext")}
          taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
          taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
          children={
            <div className='dog-features-form' >
              <SimpleSlider
                sliderArray={ sliderItems }
                selectorButton={ true }
                selectedItem={ sliderSelected }
                selectorButtonCallback={ handleSliderSelects }
              >
              </SimpleSlider>
            </div>
          }
        />

        <TaskCard
          taskCounter={ props.trait.traitName }
          taskHeader={t("registrationwizard.feature.trait.header")}
          taskBody={t("registrationwizard.feature.trait.body")}
          taskInfoHeader={t("registrationwizard.feature.trait.infoheader")}
          taskInfoBody={t("registrationwizard.feature.trait.infobody")}
          children={
            <div className="picture-button-container">
              <div className="dog-features-img-container">
                {traitPicture ?
                  <img alt="" src={traitPicture}/>:
                null}
              </div>

              <div className="button-placement">
                <IconButton  onClick={() => fileInput.current.click()}>
                  <img src="/assets/images/tabildeknapp.svg" alt="" />
                  <div style={{ position: "absolute" }}>
                    <img style={{verticalAlign: "inherit"}} src="/assets/images/photo_icon.svg" alt="" />
                  </div>
                </IconButton>
                <input onChange={onTakePhoto} ref={fileInput} style={{display: "none"}} id="file-input" type="file" name="myImage" accept="image/*" />
              </div>
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
}

export default FeatureWizPage;