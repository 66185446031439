import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

import { ExplorerContext } from "../../ExplorerContext";

import Card from "../cardTypes/Card";

function DogExplorerStatisticsHealth(props) {
    let history = useHistory();

    let explorer = useContext(ExplorerContext);

    useEffect(() => {
        
    }, [props.data])

    return (
        <React.Fragment>
            <div className="dogexplorer-statistics-health">
                <div className="row">
                    <div className="col col-xl-4 col-lg-6 col-sm-12">
                        <Card
                            type="number"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    {explorer.types.includes("helse") ?
                        <React.Fragment>
                            <div className="col col-xl-4 col-lg-6 col-sm-12">
                                <Card
                                    type="numberhealth"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                        </React.Fragment>
                    : null }
                    {/*explorer.types.includes("biotailweight") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="biotailweightage"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                        </React.Fragment>
                    : null */}
                    {explorer.types.includes("helse") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="examinationagedistribution"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="hdresultbirthyear"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="hdregistrationsyear"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                        </React.Fragment>
                    : null }
                    {explorer.types.includes("ebvstandjakt") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="ebvstandhelse"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="ebvhdgentrend"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                        </React.Fragment>
                    : null}
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerStatisticsHealth;