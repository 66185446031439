import React, {useEffect, useState} from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Auth from "../../../../Auth";
import { useTranslate } from 'react-polyglot';

function Admin(props) {
    let _Auth = new Auth();
    const t = useTranslate();

    const [type, setType] = useState("");
    const [file, setFile] = useState();

    async function startUpload() {
        let base64file = await toBase64(file);
        fetch(`/dataapi/api/fileimport/`, {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem("id_token")}`,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                type,
                file: base64file
            })
        }).then(res => {
            
        })
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    useEffect(() => {
        
    }, [])

    return (
        <React.Fragment>
            <div>
                <Typography gutterBottom variant="h6">
                    {t("admin.fileimport.upload")}
                </Typography>
                <p>{t("admin.fileimport.description")}</p>
                <select onChange={(evt) => setType(evt.target.value)}>
                    <option value="">{t("admin.fileimport.options.default")}</option>
                    <option value="jaktprove">{t("admin.fileimport.options.hunting")}</option>
                    <option value="jaktprovespor">{t("admin.fileimport.options.tracks")}</option>
                    <option value="individ">{t("admin.fileimport.options.individuals")}</option>
                    <option value="helse">{t("admin.fileimport.options.health")}</option>
                    <option value="eva">{t("admin.fileimport.options.eva")}</option>
                    <option value="ebvstandhelse">{t("admin.fileimport.options.ebv.health")}</option>
                    <option value="ebvstandjakt">{t("admin.fileimport.options.ebv.hunting")}</option>
                </select>
                <input type="file" onChange={(evt) => { setFile(evt.target.files[0]); console.log(evt.target.files); }}></input>
                <Button
                    className="savegoalsbtn"
                    onClick={ (evt) => startUpload() }
                    style={{
                        backgroundColor: "#2bb919",
                        color: "white",
                        padding: "6px",
                    }}
                >
                    {t("admin.uploading")}
                </Button>
            </div>
        </React.Fragment>
    );
}

export default Admin;