import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";

function ProfilecardHuntingResultsTable(props) {
    let history = useHistory();
    const t = useTranslate();

    const [jaktproveExams, setJaktproveExams] = useState();

    useEffect(() => {
        if (props.dog.examinations != undefined) {
            let foundJaktprove = props.dog.examinations.filter(e => e.type == "jaktprove");
            setJaktproveExams(foundJaktprove);
        }
    }, [props.dog]);

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            <Card className="dogexplorer-profilecard-huntingresults">
                <h1>{t("dogexplorer.profile.cardtypes.huntingresults.huntingtrails")}</h1>
                {jaktproveExams != undefined ? 
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>{t("dogexplorer.profile.cardtypes.huntingresults.exercise")}</th>
                                {jaktproveExams.map((i, key) => 
                                    <th key={key}>{i["testdate"]}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {jaktproveExams[0]["testparts"].map((i, partkey) => 
                                <tr key={partkey}>
                                    <td>{i["part"]}</td>
                                    {jaktproveExams.map((j, datakey) => 
                                        <td key={datakey}>{j["testparts"][partkey]["data"]}</td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                : null}
            </Card>
        </div>
    );
}

export default ProfilecardHuntingResultsTable;