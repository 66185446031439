import React, { Component, useState, useEffect } from "react";
import history from "./history"

import Stomp from "stompjs";
import SockJS from "sockjs-client";
import "./App.css";


import Login from "./components/views/login/Login";
import LandingPage from "./components/views/landingpage/Landingpage";
import GDPR from "./components/views/gdpr/GDPR";
import Menu from "./components/views/menu/Menu";
import CommonlyAsked from "./components/views/commonlyAsked/CommonlyAsked";
import HowDoesItWork from "./components/views/howdoesitwork/HowDoesItWork";
import Forsikringinfo from "./components/views/forsikringinfo/Forsikringinfo";
import Error from "./components/views/error/Error";
import Registration from "./components/views/registration/Registration";
import DogRegistration from "./components/views/registration/DogRegistration";
import MySettings from "./components/views/settings/MySettings";
import Thanks from "./components/views/thanks/Thanks";
import MyDogs from "./components/views/myDogs/myDogs";
import DogExplorerWrapper from "./components/views/dogExplorer/DogExplorer";
import MyDog from "./components/views/mydog/MyDog";
import Summary from "./components/views/summary/summary";
import Admin from "./components/views/admin/Admin";
import Docs from "./components/views/docs/Docs";
import Footer from "./components/common/Footer";
import NavBar from "./components/common/Navbar";
import DogDetails from "./components/common/DogDetails";
import DogRegistrationWizard from "./components/views/RegisterFeatures/DogRegistrationWizard";
import AnonymousRegistrationWizard from "./components/views/RegisterFeatures/AnonymousRegistrationWizard";
import Gallery from "./components/views/gallery/Gallery";
import { Redirect } from 'react-router';
import ReactGA from "react-ga";
import ResetPassword from "./components/views/resetPassword/ResetPassword";
import Statistics from "./components/views/statistics/Statistics";
import Lectures from "./components/views/lectures/lectures";
import Status from "./components/views/statistics/Status";
import ProductPage from "./components/views/purchase/ProductPage";
import PaymentHistory from "./components/views/purchase/PaymentHistory";
import Loader from "./components/common/Loader";

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { MyContext } from "./components/context/MyContext";

import Purchase from "./components/views/purchase/Purchase";

import Auth from "./Auth";
import {ProvideStompMsgHandler, useStompMsgHandler} from "./StompMsgHandler";
import {Helmet} from "react-helmet";

import { I18n } from 'react-polyglot';
import languages from "./components/Assets/Language/Langloader";
import LocaleHandler from "./LocaleHandler";

const _Auth = new Auth();


const NavbarLayout = props => {
  return (
    <div>
      {props.navBar ? React.createElement(props.navBar) : null}
      {props.children}
    </div>
  );
};

const _navbar = () => {
  const { hide } = this.state;
  if (hide) return null;
  return <NavBar />;
};

//what is happening here?
//first of all we will always display a navbar and a Footer wrapped inside the browserRouter container
// then we have a Switch component which lets us go to one of the Routes.
//by default the standard address will send you to the Loginscreen (for now)
//any other addresses is easy to access with buttons routing to the path given below.
//finally, if there should be a path we do not know about. we will route to the errorscreen

const PrivateRoute = ({isMobile, component: Component, ...rest }) => {
  ReactGA.pageview(window.location.pathname);
  return(
  <Route {...rest} render={(props) => (
    _Auth.loggedIn()
      ? <div className={isMobile ? "mobile" : ""}><Component {...props} /></div>
      : history.push("/login?redirect=" + window.location.pathname + window.location.search)
  )} />
)}

const NormalRoute = ({isMobile, component: Component, ...rest }) => {
  ReactGA.pageview(window.location.pathname);
  return (
  <Route {...rest} render={(props) => (
    <div className={isMobile ? "mobile" : ""}><Component {...props} /></div>
  )} />
)}

const ApplicationContext = React.createContext('application');

const App = () => {

  let stompMsgHandler = useStompMsgHandler();

  let localeTemp = "no";
  if (localStorage.getItem("language") != null) {
      localeTemp = localStorage.getItem("language");
  }

  let languageTemp: any = {};
  languageTemp = languages(localeTemp);
  
  const [locale, setLocale] = React.useState(localeTemp);
  const [messages, setMessages] = React.useState(languageTemp);

  const [showNavBar, setShowNavBar] = useState(true);
  const [terminalUrl, setTerminalUrl] = useState("");
  const [username, setUsername] = useState("");


  useEffect (() => {
    if(window.location.href.indexOf("localhost") == -1) {
      ReactGA.initialize("UA-90332432-2", {
        debug: false,
        titleCase: false
      });
    }
    checkValidity()
  }, []);

  useEffect (() => {
    if (localStorage.getItem("language") != null) {
      let localLanguage = localStorage.getItem("language");
      setLocale(localLanguage.toLowerCase());
      setMessages(languages(locale));
    }
  }, [locale]);

  const checkValidity = () => {
    if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == undefined) {
      fetch("/rest/users/me", {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
      }).then(response => {
        if(response.status === 401) {
          stompMsgHandler.handleLogout()
          _Auth.logout()
          /*
          localStorage.clear()
          history.push("/login")
          */
        }
      })
    }
  }

  const hideNavBar = () => {
    setShowNavBar(false);
  };

  const updateValue = username2 => {
    setUsername(username2)

  };


  const changeSource = (url) => {
    console.log(`Found URL: ${url}`);
    setTerminalUrl(url)
  }

  const { Provider } = MyContext;
    return (
      <Provider
        value={{
          updateValue: updateValue
        }}
      >
        <LocaleHandler.Provider value={{locale, setLocale}}>
          <I18n locale={locale} messages={messages}>
            <div className="everything">
              {window.location.href.indexOf("localhost") == -1 ?
                <Helmet>
                  <script>{`
                    function loadScript(c,b) {
                      var a = document.createElement("script");
                      a.type = "text/javascript";
                      a.readyState ? a.onreadystatechange = function() {
                          if("loaded" == a.readyState || "complete" == a.readyState) a.onreadystatechange = null, b()
                      } : a.onload = function() {
                          b()
                      };
                      a.src = c;
                      document.getElementsByTagName("head")[0].appendChild(a)
                    };

                    loadScript("https://prod.api.adline.com/api/cpa?key=UID-ZRqgrt8x6DSwHoNRGbU3", function(){});
                  `}</script>
                </Helmet>
              : null}
              <BrowserRouter>
                <ProvideStompMsgHandler >
                  <Switch history={history}>
                    <NormalRoute path="/" exact isMobile={false} component={LandingPage} />
                    <PrivateRoute path="/purchase" component={Purchase} />
                    <NormalRoute path="/login" isMobile={true} component={Login} />
                    <NormalRoute path="/menu"  component={Menu} />
                    <NormalRoute path="/gdpr" isMobile={true} component={GDPR} />
                    <PrivateRoute path="/dogfeatureregistration" isMobile={true} component={DogRegistrationWizard} />
                    <NormalRoute path="/study/:qlink" isMobile={true} component={AnonymousRegistrationWizard} />
                    <PrivateRoute path="/dogdetails" isMobile={true} component={DogDetails} />
                    <NormalRoute path="/faq" isMobile={true} component={CommonlyAsked} />
                    <PrivateRoute path="/mydogs" isMobile={true} component={MyDogs} />
                    <NormalRoute path="/howdoesitwork" isMobile={true} component={HowDoesItWork} />
                    <NormalRoute path="/fordeler" isMobile={true} component={Forsikringinfo} />
                    <PrivateRoute path="/summary" isMobile={true} component={Summary} />
                    <PrivateRoute path="/thanks" isMobile={true} component={Thanks} />
                    <PrivateRoute path="/mydog" isMobile={true} component={MyDog} />
                    <NormalRoute path="/registration" isMobile={true} component={Registration} />
                    <NormalRoute path="/gallery" isMobile={false} component={Gallery} />
                    <PrivateRoute path="/dogregistration" isMobile={true} component={DogRegistration} />
                    <PrivateRoute path="/mysettings" isMobile={true} component={MySettings} />
                    <NormalRoute path="/admin-tu5ng8ub34ng96y7n49hnj" isMobile={false} component={Admin} />
                    <NormalRoute path="/reset" isMobile={true} component={ResetPassword} />
                    <NormalRoute path="/statistics" isMobile={false} component={Statistics} />
                    <NormalRoute path="/lectures" isMobile={false} component={Lectures} />
                    <NormalRoute path="/status" isMobile={false} component={Status} />
                    <PrivateRoute path="/products" isMobile={true} component={ProductPage} />
                    <PrivateRoute path="/paymenthistory" isMobile={true} component={PaymentHistory} />
                    <NormalRoute path="/dogexplorer" isMobile={false} component={DogExplorerWrapper} />
                    <NormalRoute path="/loader" isMobile={false} component={Loader} />
                    <NormalRoute path="/doc/:docid" isMobile={false} component={Docs} />
                    <NormalRoute component={Error} />
                  </Switch>
                </ProvideStompMsgHandler>
                <Footer />
              </BrowserRouter>
            </div>
          </I18n>
        </LocaleHandler.Provider>
      </Provider>
    );
  }


export default App;
