import React, {useState} from "react";
import { Redirect } from "react-router";
import {useHistory} from "react-router-dom";

import Auth from "../../../Auth";
import {useStompMsgHandler} from "../../../StompMsgHandler";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import NavBar from "../../common/Navbar";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from 'react-polyglot';
import LocaleHandler from "../../../LocaleHandler";

toast.configure()

const Login = () => {
  let _Auth = new Auth();
  let history = useHistory();
  let stompMsgHandler = useStompMsgHandler();
  const { setLocale } = React.useContext(LocaleHandler);
  const t = useTranslate();

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER
    })
  }

  // Initial states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSendingEmail, setShowSendingEmail] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  // StateChangeHandlers
  // const handleEmailInput = e => {
  //   setEmail({email: e.target.value});
  // };

  // const handlePasswordInput = e => {
  //   setPassword({password: e.target.value})
  // }

  // const handleShowForgotPassword = () => setShowForgotPassword(!showForgotPassword);

  // const handleRedirect = () => setRedirect(!redirect);

  // const handleShowSuccess = () => setShowSuccess(!showSuccess);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = (json) => {
    setLocale(json["language"]);
    localStorage.setItem("language", json["language"]);
    // if url contains a redirect, redirect to that url
    if (window.location.href.includes("redirect=")) {
      let redirectUrl = window.location.href.split("redirect=")[1];
      history.push(redirectUrl);
    } else {
      history.push("/mydogs");
    }
  }

  const handleKeyPress = (event) => {
    if(event.key == 'Enter'){
      login();
    }
  }
/*
 const login = async () => {
   const obj = {
     method: "POST",
     async: "true",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
       Authorization: "Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ="
     }
   };
   await fetch(`http://localhost:9999/oauth/token?grant_type=password&username=${email}&password=${password}`, obj)
   .then(response => {
     if(response.ok && response.status === 200) {
       return response.json()
       .then(data => {
         searchPerson(data.access_token, data)
       })
     } else {
       notifyInfo("Feil brukernavn eller passord")
     }
   })
 }
*/
  const login = async () => {
    const obj = {
      method: "POST",
      async: "true",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ="
      }
    };
    await fetch(`/auth/oauth/token?grant_type=password&username=${email}&password=${password}`, obj)
    .then(response => {
      if(response.ok) {
        return response.json()
      } else {
        notifyInfo(t("login.notify.warning.incorrectpasswd"))
      }
    })
    .then(data => {
      searchPerson(data.access_token, data);
    })
    .catch(error => setError(error))
  }

  const searchPerson = (theToken, data) => {
    fetch("/rest/users/me", {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${theToken}`
    })
    }).then(response => {
      if(response.ok && response.status === 200)
      return response.json()
    }).then(json => {
        _Auth.setToken(json, data.access_token, data.refresh_token);
        return json;
    }).then((json) => {
      stompMsgHandler.initSocket();
      handleLogin(json);
    })
  }

  const forgotPassword = async () => {
    if (email == "") {
      notifyInfo(t("login.notify.warning.forgotpaswd"));
      return;
    }
    setShowSendingEmail(true);
    // try {
      _Auth.fetch("/rest/resetPassword/forgot?email="+email, {method: "POST"})
      .then(response => {
       if (response.status === 201) {
          setShowSuccess({showSuccess: true});
          setShowSendingEmail(false);
          setShowForgotPassword({showForgotPassword: false});
        }
      }).catch(error => {
        notifyInfo(t("login.notify.warning.invaliduser"));
      })
  }

    // if (redirect) {
    //   return <Redirect to={"/mydogs"} />;
    // }
    
  return (
    <React.Fragment>
      <NavBar />
      <div className="login">
        <Typography
          classes={{ root: "title" }}
          variant="h3"
          gutterBottom
        >
          {t("login.title")}
        </Typography>

        {showSuccess &&
          <Typography> {t("login.notify.success.emailsent")} </Typography>
        }

        {!showSuccess && showSendingEmail ?
          <Typography> {t("login.notify.success.emailsending")} </Typography>
        : null}


        <FormControl>
          <InputLabel classes={{ root: "label" }} htmlFor="email">
          {t("login.input.email")}
          </InputLabel>
          <Input
            classes={{
              underline: "underline",
              input: "input"
            }}
            id="email"
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </FormControl>

        <FormControl>
          <InputLabel
            classes={{ root: "label" }}
            htmlFor="adornment-password"
          >
            {t("login.input.password")}
          </InputLabel>
          <Input
            classes={{
              underline: "underline",
              input: "input"
            }}
            id="adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={event => setPassword(event.target.value)}
            onKeyPress={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  value={showPassword}
                  onClick={handleShowPassword}
                  style={{ color: "#382e92" }}
                >
                  {showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        {showForgotPassword &&
        <div className="password-forgotten-container" >
          <Tooltip title={t("login.tooltip.forgotpwd")} placement="top">
            <Typography onClick={() => {
              if (!showSuccess && !showSendingEmail) {
                forgotPassword();
              }
            }} classes={{ root: "password-forgotten" }}>
              {t("login.button.forgotpwd")}
            </Typography>
          </Tooltip>
        </div>
        }

        <div className="button-container">
          <Button onClick={ login } classes={{ root: "button" }}>
            {t("login.button.login")}
          </Button>
          <Button onClick={() => history.push("/registration")} classes={{ root: "button" }}>
            {t("login.button.registration")}
          </Button>
        </div>
        
      </div>
    </React.Fragment>
  );
}

export default Login;
