import React, { useState, useEffect } from 'react';

import TaskCard from "../TaskCard";
import { TextField } from '@material-ui/core';

import { useTranslate } from 'react-polyglot';

function DummytextWizPage(props) {
    const t = useTranslate();

    const [date, setDate] = useState("");

    function triggerPageUpdated(e) {
        setDate(e.target.value);

        props.pageUpdatedCallback({
            ...props.trait,
            value: { date: e.target.value }
        });
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        try {
            let value = JSON.parse(props.trait.value);
            setDate(value.date);
        } catch (ex) {
            console.debug("no resume value");
        }
    }, []);

    return(
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskHeader={""}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="dummytext-content-wrapper">
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );

}

export default DummytextWizPage
