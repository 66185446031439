import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import { Card } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';

import _ from "lodash";

import { ExplorerContext } from "../../ExplorerContext";

function ProfilecardChildren(props) {
    let history = useHistory();
    let explorer = useContext(ExplorerContext);
    const t = useTranslate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    function fetchData(regnumber = props.dog.regnumber) {
        setLoading(true);
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetch("/dataapi/api/dogs/filter/carddata/children", {
            method: "POST",
            body: JSON.stringify({
                regnumber
            }),
            headers: new Headers({
                "Content-Type": "application/json"
            })
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            let years = _.sortBy(_.uniq(json.map(dog => dog.birthdate.split(".")[2])));
            let dogsYear = [];
            years.forEach(year => {
                dogsYear.push({
                    year,
                    dogs: json.filter(dog => dog.birthdate.split(".")[2] == year)
                });
            });
            setData(dogsYear);
        }).catch(error => console.error("Error:", error));
    }

    function gotoDog(regnumber) {
        let tempFilter = explorer.filters.filter(i => i.type != "regnumber");

        tempFilter.push({
            type: "regnumber",
            stage: "pre",
            operation: "eq",
            value: regnumber
        });

        explorer.updateFilters(JSON.parse(JSON.stringify(tempFilter)));
    }

    useEffect(() => {
        fetchData();

        explorer.addFilterChangeListener("childrenreloader", (filters) => {
            let regnumberfilter = filters.find(filter => filter.type == "regnumber");
            if (regnumberfilter != undefined) {
                let regnumber = regnumberfilter.value;
                fetchData(regnumber);
            }
        });
    }, []);

    useEffect(() => {
        let regnumberfilter = explorer.filters.find(filter => filter.type == "regnumber");
        if (regnumberfilter != undefined) {
            let regnumber = regnumberfilter.value;
            fetchData(regnumber);
        }
    }, [props.dog]);

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            <Card className="dogexplorer-profilecard-children">
                <h1>{t("dogexplorer.profile.cardtypes.children.offspring")}</h1>
                <div className={"loading " + (!loading ? "hidden" : "")}>
                    <p>{t("dogexplorer.profile.cardtypes.children.loading")}</p>
                </div>
                {data.map(yearDogs => 
                    <div style={{ overflow: "auto" }}>
                        <h3 style={{ textAlign: "center", fontSize: "20px", fontWeight: "600" }}>
                            {t("dogexplorer.profile.cardtypes.children.yearofbirth")} { yearDogs.year }
                        </h3>
                        <div style={{ overflow: "auto" }}>
                            <div style={{ width: "50%", float: "left" }}>
                                <p style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.children.maledogs")}</p>
                                <table>
                                    <tbody>
                                        {_.orderBy(yearDogs.dogs.filter(dog => dog.sex == 1), ["regnumber"] ).map(dog => 
                                            <tr>
                                                <td style={{ cursor: "pointer" }} onClick={() => gotoDog(dog.regnumber)}>{dog.regnumber}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ width: "50%", float: "left" }}>
                                <p style={{ fontWeight: "600" }}>{t("dogexplorer.profile.cardtypes.children.female")}</p>
                                <table>
                                    <tbody>
                                        {_.orderBy(yearDogs.dogs.filter(dog => dog.sex == 2), ["regnumber"] ).map(dog => 
                                            <tr>
                                                <td style={{ cursor: "pointer" }} onClick={() => gotoDog(dog.regnumber)}>{dog.regnumber}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Divider classes={{ root: "divider" }} />
                    </div>
                )}
                {data.length == 0 ?
                    <p>{t("dogexplorer.profile.cardtypes.children.nooffspring")}</p>
                : null}
            </Card>
        </div>
    );
}

export default ProfilecardChildren;