import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

import { ExplorerContext } from "../../ExplorerContext";

import Card from "../cardTypes/Card";

function DogExplorerStatisticsJaktprove(props) {
    let history = useHistory();

    let explorer = useContext(ExplorerContext);

    const [ebvStandJaktSelected, setEbvStandJaktSelected] = useState("1");

    useEffect(() => {
        
    }, [props.data])

    return (
        <React.Fragment>
            <div className="dogexplorer-statistics-jaktprove">
                <div className="row">
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="number"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    {explorer.types.includes("jaktprovespor") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="numberjaktprovespor"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            {explorer.types.includes("ebvstandjakt") ?
                                <React.Fragment>
                                    <div className="col col-lg-6 col-sm-12">
                                        <Card
                                            type="ebvstandjakt"
                                            examtype={props.type}
                                            loading={props.loading}
                                            ebvStandJaktSelected={ebvStandJaktSelected}
                                            ebvStandJaktChange={val => {
                                                setEbvStandJaktSelected(val);
                                            }}
                                        ></Card>
                                    </div>
                                    <div className="col col-lg-6 col-sm-12">
                                        <Card
                                            type="ebvjaktgentrend"
                                            examtype={props.type}
                                            loading={props.loading}
                                            ebvStandJaktSelected={ebvStandJaktSelected}
                                            ebvStandJaktChange={val => {
                                                setEbvStandJaktSelected(val);
                                            }}
                                        ></Card>
                                    </div>
                                </React.Fragment>
                            : null}
                            {[1,2,3,4,5,6,7,8,9,10].map(i => 
                                <div className="col col-xl-4 col-lg-6 col-sm-12">
                                    <Card
                                        type="jaktprovesporpart"
                                        examtype={props.type}
                                        loading={props.loading}
                                        partnum={i}
                                    ></Card>
                                </div>
                            )}
                        </React.Fragment>
                    : null }
                    {explorer.types.includes("jaktprove") ?
                        <React.Fragment>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="numberjaktprove"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="jaktprovesum"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            <div className="col col-lg-6 col-sm-12">
                                <Card
                                    type="jaktprovesumyear"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            {explorer.types.includes("ebvstandjakt") ?
                                <React.Fragment>
                                    <div className="col col-lg-6 col-sm-12">
                                        <Card
                                            type="ebvstandjakt"
                                            examtype={props.type}
                                            loading={props.loading}
                                            ebvStandJaktSelected={ebvStandJaktSelected}
                                            ebvStandJaktChange={val => {
                                                setEbvStandJaktSelected(val);
                                            }}
                                        ></Card>
                                    </div>
                                    <div className="col col-lg-6 col-sm-12">
                                        <Card
                                            type="ebvjaktgentrend"
                                            examtype={props.type}
                                            loading={props.loading}
                                            ebvStandJaktSelected={ebvStandJaktSelected}
                                            ebvStandJaktChange={val => {
                                                setEbvStandJaktSelected(val);
                                            }}
                                        ></Card>
                                    </div>
                                </React.Fragment>
                            : null}
                            <div className="col col-lg-12">
                                <Card
                                    type="jaktprovepointsage"
                                    examtype={props.type}
                                    loading={props.loading}
                                ></Card>
                            </div>
                            {[1,2,3,4,5,6,7,8,9,10,11,12,13].map(i => 
                                <div className="col col-xl-4 col-lg-6 col-sm-12">
                                    <Card
                                        type="jaktprovepart"
                                        examtype={props.type}
                                        loading={props.loading}
                                        partnum={i}
                                    ></Card>
                                </div>
                            )}
                        </React.Fragment>
                    : null}
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerStatisticsJaktprove;