import React, { useState, useEffect, useContext, createContext } from "react";
import {useHistory} from "react-router-dom"
import { Client, Message } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure()

const stompMsgHandlerContext = createContext({
    handleMsg: (msg) => {},
    STOMPCLIENT: {},
    initSocket: () => {},
    handleLogout: () => {},
    connected: false
});

export function ProvideStompMsgHandler({ children }) {
    const handleMsg = useProvideStompMsgHandler();
    return <stompMsgHandlerContext.Provider value={handleMsg}>{children}</stompMsgHandlerContext.Provider>;
}

export const useStompMsgHandler = () => {
    return useContext(stompMsgHandlerContext);
};

const useProvideStompMsgHandler = () => {

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const history = useHistory();
  const [STOMPCLIENT, setStompClient] = useState(null);
  const [connected, setConnected] = useState(false);

  const [publicChannel, setPublicChannel] = useState(null)
  const [privateChannel, setPrivateChannel] = useState(null)

  const cleanUpDeals = () => {
    console.log("Running cleanup after error or intentional close");
    fetch("/rest/nets/failed/cleanup", {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
  }

  const randomClientSession = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * InitSocket connects the websocket and handles all actions from @Stomp/stompjs regarding
 * disconnects, reconnects, heartbeats and logging...
 */
  const initSocket = () => {
    console.log("socketConnected", connected);
    if (!connected) {
      const tempStompClient = new Client({
        // Comment this in to recieve debug messages from the stomp client
        
        // debug: function (str) {
        //   console.debug(str);
        // },
        
        connectHeaders: {
          "client-session": randomClientSession()
        },
        reconnectDelay: 0,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      });

      tempStompClient.webSocketFactory = () => new SockJS(`/socket/ws?access_token=${localStorage.getItem("id_token")}`);

      tempStompClient.onConnect = (frame) => {
        if (STOMPCLIENT == null) {
          setStompClient(tempStompClient);
        }
        setConnected(true);
        console.debug(frame);
        setPublicChannel(tempStompClient.subscribe("/topic/public", greeting => {
          const msg = JSON.parse(greeting.body).message;
          console.debug(msg);
          handleMsg(msg);
          greeting.ack();
        }, {ack: 'client'}));

        setPrivateChannel(tempStompClient.subscribe("/user/queue/private", message => {
          const msg = JSON.parse(message.body);
          console.debug(msg);
          handleMsg(msg);
          message.ack();
        }, {ack: 'client'}));
      };

      tempStompClient.onStompError = (err) => {
        console.log(err);
      }

      tempStompClient.onDisconnect = (frame) => {
        tempStompClient.deactivate()

        setStompClient(null)
      }

      tempStompClient.activate();
    }
  }

  useEffect(()=> {
    initSocket();
  }, []);

/**
 * The handleMsg function handles all the messages recieved from the broker (RabbitMQ) and
 * handles the action required.
 * prefix: no.kongarthur.applicationplatform.resourceserver.messaging.message.
 * messageType: MsgRegistrationCreated
 * @param {*} msg recieved via websocket connection (browser --> NetsModule --> RabbitMQ)
 */
    const handleMsg = (msg) => {
        console.log(msg.messageType);
         const messageType = msg.messageType;
         switch (messageType) {
           case "no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgRegistrationCreated":
              history.push({pathname: "/purchase", state: {term: msg.terminalUrl} })
             break;
           case "no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgCardUpdateCreated":
              history.push({pathname: "/purchase", state: {term: msg.terminalUrl} })
             break;
           case "no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgVerifyResponse":
             history.push("/loader")
              fetch(`/rest/nets/pay/byuser`, {
                method: "POST",
                headers: new Headers({
                  Authorization: `Bearer ${localStorage.getItem("id_token")}`
                })
              })
              .then(data2 => {
                fetch(`/auth/oauth/token?grant_type=refresh_token&refresh_token=` + localStorage.getItem("refresh_token"), {
                  method: "POST",
                  headers: new Headers({
                    Authorization: `Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ=`
                  })
                }).then(response => {
                  if(response.ok && response.status === 200)
                  return response.json()
                }).then(json => {
                  localStorage.setItem("id_token", json.access_token);
                  localStorage.setItem("refresh_token", json.refresh_token);
                }).then(() => {
                  history.push({pathname:"/products" })
                })
              })
             break;
           case "no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgPaymentError":
              if(msg.errorDetails.exceptionMessage === "VERIFY is not allowed when transaction is in Cancel state"){
                if(msg.errorMessage === "Error from Nets:MsgRegisterRecurringError") {
                  console.log(msg.errorMessage);
                   cleanUpDeals();
                   history.push("/products")
                   notifyInfo("Kansellerte kjøp")
                } else if (msg.errorMessage === "Error from Nets:MsgChangeCardError") {
                  console.log(msg.errorMessage);
                  history.push("/mysettings")
                  notifyInfo("Kansellert, nytt kort ikke registrert")
                }
              }else{
                cleanUpDeals();
                history.push("/error");
                notifyError("Error in payment")
              }
            break;
           case "no.kongarthur.applicationplatform.resourceserver.messaging.message.MsgCardChangedResponse":
              history.push("/mysettings")
            break;
           default:
            break;
    }
  }

  const handleLogout = () => {
    if(STOMPCLIENT != null) {
      STOMPCLIENT.deactivate();
      if(publicChannel != null && privateChannel != null) {
        publicChannel.unsubscribe()
        privateChannel.unsubscribe()
      } else {
        console.log("User not subscribed to channels, settings connection to false")
      }
    } else {
      console.log("Socket not connected, setting connection to false")
    }
    setConnected(false);
  }

    return {handleMsg, initSocket, STOMPCLIENT, handleLogout, connected};
}
