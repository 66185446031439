import React from 'react';
import { useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

//MATERIAL UI
import { Typography } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Fab from "@material-ui/core/Fab";

function WizFinishPage(props) {
  const history = useHistory();
  const t = useTranslate();

  function gotoMyDog() {
    history.push({pathname: "/mydog", state: { dbId: props.dogId }});
  }

  function gotoStatistics() {
    history.push("/statistics");
  }

  function gotoQuestionnaires() {
    props.goBack();
  }

  return (
    <React.Fragment>
      <div style={{ display: props.display ? "block" : "none" }}>
        <div className="certificate-shadow-card">
          <Card classes={{ root: "certificate-card" }}>
            <CardContent classes={{ root: "certificate-card-content" }}>
              <div >
                <Typography style={{ color: "white" }}>
                  {props.edited && props.categoryId == "1" ? t("registrationwizard.wizfinishpage.discount") : ""}
                </Typography>

                <img style={{ display: props.edited ? "block" : "none" }} 
                  src="/assets/images/alternativ.svg"
                  alt=""
                  className="certificate-card-image"
                />

              </div>
              {/* <div className="certificate-bottom-container">
                <Typography>Rabattkode: Biotail0278</Typography>
                <Button 
                  classes={{ root: "edit-button" }}>
                  Løs inn
                </Button>
              </div> */}
              <div className="wizfinished-text-container">
                <Typography gutterBottom variant="h3">
                  {props.edited ? t("registrationwizard.wizfinishpage.registration.succeed") : t("registrationwizard.wizfinishpage.registration.failed")}
                </Typography>
                <Divider className="divider" />
                <Typography gutterBottom variant="body1">
                  {props.edited ?
                    t("registrationwizard.wizfinishpage.completed") + " " + props.animalName + t("registrationwizard.wizfinishpage.profile")
                    :
                    t("registrationwizard.wizfinishpage.incompleted")
                  }
                </Typography>
                <br />
                <Typography gutterBottom variant="body1">
                  {props.edited ?
                    t("registrationwizard.wizfinishpage.description1") +
                    t("registrationwizard.wizfinishpage.description2") + " " + props.animalName + " " + t("registrationwizard.wizfinishpage.description3") + " " +
                    t("registrationwizard.wizfinishpage.description4")
                    :
                    t("registrationwizard.wizfinishpage.description5")
                  }
                </Typography>
              </div>

              <div style={{ paddingBottom: "25px" }} className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Min hund"
                  className="add-button"
                  onClick={() => gotoQuestionnaires()}
                >
                  {t("registrationwizard.wizfinishpage.backtoquestionnaires")}
                </Fab>
                <br/>
                <br/>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Min hund"
                  className="add-button"
                  onClick={() => gotoStatistics()}
                >
                  {t("registrationwizard.wizfinishpage.backtostatistics")}
                </Fab>
                <br/>
                <br/>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Min hund"
                  className="add-button"
                  onClick={() => gotoMyDog()}
                >
                  {t("registrationwizard.wizfinishpage.backtomydog")}
                </Fab>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WizFinishPage;