import React from "react";

import NavBar from "../../common/Navbar";
import { MyContext } from "../../context/MyContext";

import Faq from "../../common/Faq";

class CommonlyAsked extends React.Component {
  render() {
    window.scrollTo(0, 0);
    const { Consumer } = MyContext;
    return (
      <Consumer>
        {context => (
          <React.Fragment>
            <NavBar />
            <div className="gdpr-commonly">
              <Faq />
            </div>
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}

export default CommonlyAsked;
