import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import NavBar from "../../common/Navbar";

import HistoryCard from "./HistoryCard"
import { useTranslate } from 'react-polyglot';

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  },
  title: {
    width: "232px",
    height: "28px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "8px",
    lineHeight: "20px"
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  }
})

const PaymentHistory = (props) => {
  const classes = useStyles();
  const [payments, setPayments] = useState([]);
  const [hasPayments, setHasPayments] = useState(false)
  const t = useTranslate();


    useEffect(() => {
      if(props.history.location.state.payments!== undefined) {
        setPayments(props.history.location.state.payments)
        setHasPayments(true)
      }
    }, [payments])

  return (
      <React.Fragment>
      <NavBar />
      {hasPayments ?
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("purchase.paymenthistory.title")}
            </Typography>
            <Typography className={classes.text} color="textSecondary">
              {t("purchase.paymenthistory.description")}
            </Typography>
            &nbsp;
            {payments.map((item, i) =>
              item.history.map((his, key) =>
                <HistoryCard key={key} paymentHistory={his}> </HistoryCard>
              )
            )}
          </CardContent>
        </Card>
        :
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("purchase.paymenthistory.title")}
            </Typography>
            <Typography className={classes.text} color="textSecondary">
              {t("purchase.paymenthistory.nohistory")}
            </Typography>
          </CardContent>
        </Card>
      }
      </React.Fragment>
  )
}

export default PaymentHistory;
