import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavBar from "../../common/Navbar";
import Stepper from "../../common/Stepper";
import WizGroup from "../../common/RegistrationWizard/WizGroup";
import InputDatePicker from "../../common/InputDatePicker";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Fab from "@material-ui/core/Fab";
import { FormGroup, colors, Input, FormControl } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputTextField from "../../common/InputTextField";
import Modal from '@material-ui/core/Modal';
import CloseIcon from "@material-ui/icons/Close";
import GDPR from "../gdpr/GDPR";

import { Typography } from '@material-ui/core';
import { Prompt } from 'react-router';
import { useTranslate } from 'react-polyglot';
import Select from 'react-select';
import LocaleHandler from '../../../LocaleHandler';
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import {useStompMsgHandler} from "../../../StompMsgHandler";

import Auth from "../../../Auth";

import "./DogRegistrationWizard.scss";
import "./AnonymousRegistrationWizard.css";

toast.configure();

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
            border: state.isFocused ? 0 : 0
        }
    })
};

function AnonymousRegistrationWizard(props) {
    const _Auth = new Auth();
    const history = useHistory();
    const { locale, setLocale } = React.useContext(LocaleHandler);
    let stompMsgHandler = useStompMsgHandler();
    
    //const dogId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("dogId=") !== -1).split("=")[1];
    //const categoryId = window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1) != undefined ? window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1] : "";
    const t = useTranslate();
  
    const [breedWiz, setBreedWiz] = useState({});
    const [breedWizCategory, setBreedWizCategory] = useState({});
    const [breedWizId, setBreedWizId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [typeId, setTypeId] = useState("");
    const [loadingError, setLoadingError] = useState(false);
    const [visiblePage, setVisiblePage] = useState(0);
    const [edited, setEdited] = useState(false);
    const [changes, setChanges] = useState(false);
    const [infoVisible, setInfoVisible] = useState(true);
    const [dogId, setDogId] = useState("");
    
    const [breeds, setBreeds] = useState([]);

    const [dynSubCategory, setDynSubCategory] = useState();

    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const [selectedBreed, setSelectedBreed] = useState("");
    const [selectedBirthdate, setSelectedBirthdate] = useState("");
    const [selectedGender, setSelectedGender] = useState("");

    const [roles, setRoles] = useState([
        { value: "Hundeeier", label: "Hundeeier" },
        { value: "Veterinær", label: "Veterinær" },
        { value: "Oppdretter", label: "Oppdretter" },
        { value: "Dommer", label: "Dommer" }
    ]);
    const [email, handleEmail] = useState("");
    const [password, handlePassword] = useState("");
    const [firstName, handleFirstName] = useState("");
    const [surName, handleSurName] = useState("");
    const [postCode, handlePostCode] = useState("");
    const [userAgreementRead, handleCheckbox] = useState(false);
    const [role, setRole] = useState("Hundeeier");
    const [roleNumber, handleRoleNumber] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [locking, setLocking] = useState(false);
    const [dogName, setDogName] = useState("");
    const [dogAlias, setDogAlias] = useState("");
    const [dogWeight, setDogWeight] = useState("");
    const [dogWeightDate, setDogWeightDate] = useState("");

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const openModal = () => {
        setShowModal(true)
    };
    const closeModal = () =>  {
        setShowModal(false)
    };

    const handleChangeSingle = (value) => {
        setRole(value.value);
    }
  
    const [lastStep, setLastStep] = useState(0);
  
    const [language, setLanguage] = useState(localStorage.getItem("language") != undefined ? localStorage.getItem("language") : "no");
    const [languageOptions, setLanguageOptions] = useState([
        {label: "Norwegian (Norsk)", value: "no"},
        {label: "Swedish (Svenska)", value: "se"},
        {label: "English (English)", value: "en"},
    ]);
  
    const wizardPageUpdated = (traitData) => {
        let tempBreedWizCategory = breedWizCategory;
        let traitFound = getWizardFromBreedWizCategory(tempBreedWizCategory).traits.find(item => item.traitId == traitData.traitId);
        if (traitFound != undefined) {
            traitFound.value = traitData.value;
            setBreedWizCategory(JSON.parse(JSON.stringify(tempBreedWizCategory)));
            setEdited(true);
            setChanges(true);
        }
    }

    function fetchBreedsData(){
        _Auth.fetch("/rest/breedwiz/breeds/").then(response => response.json()).then(data => {
            // slett lista med raser
            var breeds = [];
            for (const item of data) {
                breeds.push({ value: item.breedId, label: item.breedId });
            }
            setBreeds(breeds);
            setLoading(false);
        }).catch(error => console.error("Error:", error));
    }

    function setLanguageCorrectly() {
        switch (props.match.params.qlink) {
            case "78":
            case "75":
            case "74":
            case "81":
            case "92":
                setLanguage("se");
                setLocale("se");
                localStorage.setItem("language", "se");
                break;
            case "2":
            case "56":
            case "53":
            case "54":
            case "55":
            case "65":
            case "73":
            case "72":
            case "77":
            case "80":
            case "90":
                setLanguage("no");
                setLocale("no");
                localStorage.setItem("language", "no");
                break;
            case "60":
            case "62":
            case "63":
            case "64":
            case "66":
            case "76":
            case "71":
            case "70":
            case "79":
            case "91":
                setLanguage("en");
                setLocale("en");
                localStorage.setItem("language", "en");
                break;
            default:
                setLanguage("en");
                setLocale("en");
                localStorage.setItem("language", "en");
                break;
        }
    }

    function getInfo(lang = language) {
        let breedId = selectedBreed;
        _Auth.fetch("/rest/breedwiz/dynamic/" + breedId + "?lang=" + lang).then(response => response.json()).then(data => {
            setBreedWiz(data);
            setBreedWizCategory(data.categories.find(category => category.categoryId == 2));
            // setBreedWizCategory(data.categories.find(category => category.categoryId == (props.location.state != undefined ? props.location.state.featuresCategory : window.location.search.split("?")[1].split("&").find(item => item.indexOf("categoryId=") !== -1).split("=")[1])));
            setLoading(false);
            setDynSubCategory(data.categories.find(category => category.categoryId == 2).dynSubCategories.find(subCategory => subCategory.dynWizards.some(wiz => wiz.wizardId == props.match.params.qlink)));
        }).catch(error => {
            console.error("Error:", error);
            setLoadingError(true);
        });
    }

    function createDog() {
        _Auth.fetch("/rest/dogs", {
            method: "POST", // or 'PUT'
            body: JSON.stringify({
                animalId: "",
                animalAlias: "",
                birthDate: selectedBirthdate,
                gender: selectedGender,
                name: "",
                ownerId: "",
                breedId: selectedBreed,
                weight: undefined,
                weights: [],
                pictures: [],
                breedName: "",
                notHavingBaby: false,
                animalIdFather: "",
                animalIdMother: "",
                approvedForPublicDisplay: true
            })
        }).then(res => {
            return res.json();
        }).then(response => {
            setDogId(response.id);
        }).catch(error => {
            console.error("Error:", error);
        });
    }
  
    function getWizardFromBreedWizCategory(inputBreedWizCategory, type = props.match.params.qlink) {
        let subcategory = inputBreedWizCategory.dynSubCategories.find(subCategory => subCategory.dynWizards.some(wiz => wiz.wizardId == type));
        return subcategory.dynWizards.find(wiz => wiz.wizardId == type);
    }
  
    function getIncompleteSteps() {
        let incompleteSteps = [];
        
        let questions = getWizardFromBreedWizCategory(breedWizCategory).traits.filter(i => i.technicalTypeId != 11 && i.technicalTypeId != 9);
        let groupings = getWizardFromBreedWizCategory(breedWizCategory).groupings;
    
        for (let question of questions) {
            if (
                question.value == null ||
                question.value == undefined ||
                question.value.sliderValue === "" ||
                question.value.sliderValue === null
            ) {
                for (let i = 0; i < groupings.length; i++) {
                    if (groupings[i].questions.includes(question.traitId)) {
                        incompleteSteps.push(i + 1);
                    }
                }
            }
        }
    
        return incompleteSteps;
    }
  
    function stepperChanged(value) {
        if (value == getWizardFromBreedWizCategory(breedWizCategory).groupings.length) {
            let incompleteSteps = [];
        
            let questions = getWizardFromBreedWizCategory(breedWizCategory).traits.filter(i => i.technicalTypeId != 11 && i.technicalTypeId != 9);
            let groupings = getWizardFromBreedWizCategory(breedWizCategory).groupings;
    
            for (let question of questions) {
                if (
                    question.value == null ||
                    question.value == undefined ||
                    question.value.sliderValue === "" ||
                    question.value.sliderValue === null
                ) {
                    for (let i = 0; i < groupings.length; i++) {
                        if (groupings[i].questions.includes(question.traitId)) {
                            incompleteSteps.push(i + 1);
                        }
                    }
                }
            }
    
            let hasUnanswered = false;
    
            if (incompleteSteps.length > 0) {
                hasUnanswered = true;
            }
    
            if (hasUnanswered) {
                let answer = window.confirm(t("registerfeatures.prompt.unanswered_questions"));
                if (!answer) {
                    setLastStep(lastStep + 1);
                    return;
                } else {
                    saveTraits(true);
                    setVisiblePage(value);
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 300)
                }
            } else {
                saveTraits(true);
                setVisiblePage(value);
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 300)
            }
        } else {
            saveTraits(false);
            setVisiblePage(value);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300)
        }
    }
  
    function onCloseInfo() {
        createDog();
        setInfoVisible(false);
        setTypeId(props.match.params.qlink);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300)
    }
  
    function saveTraits(completed = false) {
        let device = window.WURFL.complete_device_name;
        let form = window.WURFL.form_factor;
        let mobile = window.WURFL.is_mobile;
    
        if (device.includes("Apple") && !device.includes("iPhone") && navigator.maxTouchPoints > 0) {
            form = "Tablet";
            mobile = "true";
        }
    
        _Auth.fetch("/rest/breedwiz/", {
            method: "PUT",
            body: JSON.stringify({
                complete: completed ? completed : undefined,
                dogId: dogId,
                id: breedWizId != null ? breedWizId : null,
                deviceId: "device:" + device + "|form:" + form + "|mobile:" + mobile,
                selections: getWizardFromBreedWizCategory(breedWizCategory).traits.map(item => {
                    return {
                        imageUrl: item.answerTraitPicture,
                        traitValueImageUrl: item.answerTraitValuePicture,
                        traitName: item.traitName,
                        traitDescription: item.traitDescription,
                        traitId: item.traitId,
                        categoryId: item.categoryId,
                        technicalTypeId: item.technicalTypeId,
                        value: typeof item.value != "string" ? JSON.stringify(item.value) : item.value
                    }
                })
            })
        })
        .then(res => {
            return res.json();
        })
        .then(function (response) {
            if (response.id !== undefined) {
                setBreedWizId(response.id);
            }
            setChanges(false);
        })
        .catch(error => console.error("Error:", error));
    }

    const notifyInfo = (text) => {
        toast.info(text, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    const notifyError = (text) => {
        toast.error(text, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
        });
    }

    const handleSubmit = () => {
        //event.preventDefault();
        let user = {};
        user.email = email;
        user.password = password;
        user.firstName = firstName;
        user.lastName = surName;
        user.postCode = postCode;
        user.language = locale;
        user.role = role;
        user.roleNumber = roleNumber;
        user.token = null;
        user.dog = null;
        user.dogId = dogId;
        user.dogName = dogName;
        user.dogAlias = dogAlias;
        user.dogWeight = dogWeight;
        user.dogWeightDate = dogWeightDate;
    
        if (email == "" || password == "" || firstName == "" || surName == "") {
            notifyInfo(t("registration.notify.warning.missedfields"));
            return;
        }
    
        if(!email.includes("@")) {
            notifyError(t("registration.notify.warning.invalidemail"))
            return;
        }
    
        if (password == email) {
            notifyInfo(t("registration.notify.warning.validation"));
            return;
        }
    
        if (password.length < 8) {
            notifyInfo(t("registration.notify.warning.lengthofpaswd"));
            return;
        }
    
        if (userAgreementRead) {
            setLocking(true);
            user.email = user.email.toLowerCase();
            const headers = new Headers({
                "Content-Type": "application/json"
            });
            fetch("/rest/registration/withdog", {
                headers: headers,
                method: "POST", // or 'PUT'
                body: JSON.stringify(user) // data can be `string` or {object}!
            }).then(res => {
                if (res.status == 409) {
                    notifyInfo(t("registration.notify.warning.existuser"));
                    setLocking(false);
                    return Promise.reject();
                }
                if (res.status == 400) {
                    notifyInfo(t("registration.notify.warning.unknownissue"));
                    setLocking(false);
                    return Promise.reject();
                }
                return res.json();
            })
            .then(response => {
                if (response.id != null) {
                    login(response.username, user.password)
                } else {
                    notifyInfo(t("registration.notify.warning.existuser"));
                    setLocking(false);
                }
            })
        } else {
            notifyInfo(t("registration.notify.warning.acceptterms"));
        }
    }

    const login = (username, password) => {
        let user = {};
        user.email = username;
        user.password = password;
     
        const obj = {
            method: "POST",
            async: "true",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Basic S29uZ0FydGh1ckF1dGhvcml6YXRpb246RGV0dGUgZXIgZXR0IHBhc3NvcmQ="
            }
        };
        fetch(`/auth/oauth/token?grant_type=password&username=${username}&password=${password}`, obj)
            .then(response => response.json())
            .then(data => {
                searchPerson(data.access_token, data);
            });
    }

    const searchPerson = (theToken, data) => {
        fetch("/rest/users/me", {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${theToken}`
            })
        }).then(response => {
            if(response.ok && response.status === 200)
            return response.json()
        }).then(json => {
            _Auth.setToken(json, data.access_token, data.refresh_token)
        }).then(() => {
            // TODO: NEED STOMPHANDLER HERE???
            stompMsgHandler.initSocket();
            history.push("/mydogs");
        })
    }
    
    useEffect(() => {
        if (selectedBreed != "") {
            getInfo();
        }
    }, [selectedBreed]);
    
    useEffect(() => {
        setLanguageCorrectly();
        fetchBreedsData();
    }, []);
  
    useEffect(() => {
        if (selectedBreed != "") {
            getInfo();
        }
    }, [language]);

    useEffect(() => {
        // This could be used to select the corresponding survey for the new language
        //setLanguage(locale);
    }, [locale])

    return <div style={{padding: "20px"}}>
        { loadingError ?
            <React.Fragment>
                <NavBar />
                <Typography gutterBottom variant="h3" className="breedwiz-loading-text">
                    {t("registerfeatures.prompt.error")}<br />
                    {t("registerfeatures.prompt.foundnodata")}
                </Typography>
            </React.Fragment>
        : null}
        { !loading ?
            <React.Fragment>
                <Prompt
                    when={breedWiz.categories && changes}
                    message={t("registerfeatures.prompt.changes")}
                />
                <NavBar />
                { _Auth.loggedIn() ?
                    <React.Fragment>
                        <div className="wizinfocontainer">
                            <div>
                                <Typography gutterBottom variant="h3">
                                    {t("registrationwizard_anonymous.wizinfopage_loggedin.title")}
                                </Typography>
                                <Divider className="divider" />
                                &nbsp;
                                <Typography gutterBottom variant="body1">
                                    {t("registrationwizard_anonymous.wizinfopage_loggedin.description")}
                                </Typography>
                                &nbsp;
                                <div style={{ width: "100%", height: "30px" }}></div>
                                <Fab
                                    style={{ width: "100%" }}
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    aria-label={t("registrationwizard_anonymous.wizinfopage_loggedin.mydogsbtn")}
                                    className="add-button"
                                    onClick={() => history.push("/mydogs")}
                                >
                                    {t("registrationwizard_anonymous.wizinfopage_loggedin.mydogsbtn")}
                                </Fab>
                            </div>
                        </div>
                    </React.Fragment>
                : null}
                { infoVisible && !_Auth.loggedIn() ?
                    <React.Fragment>
                        <div className="wizinfocontainer">
                            <div>
                                <Typography gutterBottom variant="h3">
                                    {t("registrationwizard_anonymous.wizinfopage.title")}
                                </Typography>
                                <Divider className="divider" />
                                &nbsp;
                                <Typography gutterBottom variant="body1">
                                    {t("registrationwizard_anonymous.wizinfopage.description")}
                                </Typography>
                                &nbsp;
                                <div style={{ width: "100%", height: "30px" }}></div>
                            </div>
                            <div style={{
                                maxWidth: "300px",
                                margin: "0 auto"
                            }}>
                                <Select
                                    className="registration-text breedselector"
                                    inputId="react-select-single"
                                    styles={selectStyle}
                                    TextFieldProps={{
                                        label: t("dogregistration.label.race"),
                                        InputLabelProps: {
                                            htmlFor: 'react-select-single',
                                            shrink: true,
                                        },
                                    }}
                                    options={breeds.map(i => { return { value: i.value, label: t("breeds.list." + i.label) } })}
                                    placeholder={t("dogregistration.placeholder.race")}
                                    onChange={event => setSelectedBreed(event.value)}
                                />
                            </div>
                            <div style={{ width: "100%", height: "60px" }}></div>
                            { selectedBreed != "" && dynSubCategory != undefined ?
                                <div style={{
                                    maxWidth: "500px",
                                    margin: "0 auto"
                                }}>
                                    <Typography gutterBottom variant="h3" style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                    }}>
                                        {t("registrationwizard_anonymous.wizinfopage.title2")}
                                    </Typography>
                                    <Typography gutterBottom variant="h3" style={{
                                        fontSize: "26px",
                                        fontWeight: "600",
                                    }}>
                                        {(dynSubCategory.name)}
                                        { dynSubCategory.warning != undefined && dynSubCategory.warning != "" ?
                                        <span style={{
                                            color: "red",
                                        }}> ({dynSubCategory.warning})</span>
                                        : "" }
                                    </Typography>
                                    <Typography gutterBottom variant="body1" dangerouslySetInnerHTML={{ __html: dynSubCategory.anonymousDescription != undefined ? dynSubCategory.anonymousDescription.replaceAll("\n", "</br>") : "" }}></Typography>
                                    { dynSubCategory.introvideo != undefined && dynSubCategory.introvideo != "" ?
                                        <div style={{
                                            textAlign: "center",
                                        }}>
                                            <video
                                                width="320"
                                                height="200"
                                                controls
                                            >
                                                <source src={dynSubCategory.introvideo} type="video/mp4" />
                                            </video>
                                        </div>
                                    : null }
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    <div style={{
                                        maxWidth: "300px",
                                        margin: "0 auto"
                                    }}>
                                        <InputDatePicker
                                            className="fullwidth"
                                            inputProps={{ style: { color: "#000000" } }}
                                            InputLabelProps={{
                                                // classes: { root: "registration-text" },
                                                shrink: true
                                            }}
                                            id="animalBirthdate"
                                            label={t("dogregistration.label.birthdate")}
                                            value={selectedBirthdate}
                                            onChange={event => setSelectedBirthdate(event.target.value)}
                                            required
                                        />
                                        <div style={{ width: "100%", height: "30px" }}></div>
                                        <FormControl label={t("registrationwizard_anonymous.wizinfopage.doggender")}>
                                            <p className="dog-gender-header">{t("registrationwizard_anonymous.wizinfopage.doggender")}</p>
                                            <div className="dog-gender-container">
                                                <Button
                                                    id="animalGenderFemale"
                                                    onClick={() => {
                                                        setSelectedGender("false");
                                                    }}
                                                    variant={selectedGender === "false" ? "contained" : "outlined"}
                                                    size="medium"
                                                    color="primary"
                                                    className="female-button"
                                                >
                                                    {t("dogregistration.gender.female")}
                                                </Button>
                                                <Button
                                                    id="animalGenderMale"
                                                    onClick={() => {
                                                        setSelectedGender("true");
                                                    }}
                                                    variant={selectedGender === "true" ? "contained" : "outlined"}
                                                    size="medium"
                                                    color="primary"
                                                    className="male-button"
                                                >
                                                    {t("dogregistration.gender.male")}
                                                </Button>
                                            </div>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    <Divider className="divider" />
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    { dynSubCategory != undefined ?
                                        dynSubCategory.anonymousConditionsLink != undefined && dynSubCategory.anonymousConditionsLink != "" ?
                                            <>
                                                <Typography gutterBottom variant="body1" dangerouslySetInnerHTML={{ __html: dynSubCategory.anonymousConditionsText }}></Typography>
                                                <FormControlLabel
                                                    className="checkboxcontrol"
                                                    value="bottom"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(e, checked) => {
                                                                setAcceptedTerms(checked);
                                                            }}
                                                        />
                                                    }
                                                    label={dynSubCategory.conditionsText}
                                                    labelPlacement="end"
                                                />
                                                <Typography style={{
                                                    marginBottom: "20px"
                                                }} variant="body1" ><a target="_blank" rel="noopener noreferrer" href={dynSubCategory.anonymousConditionsLink}>{dynSubCategory.conditionsLinktext}</a></Typography>
                                            </>
                                        : null
                                    : null}
                                    { acceptedTerms || dynSubCategory.anonymousConditionsLink == undefined || dynSubCategory.anonymousConditionsLink == "" ?
                                        <>
                                            { dynSubCategory.dynWizards.find(i => i.wizardId == props.match.params.qlink) != undefined && dynSubCategory.dynWizards.find(i => i.wizardId == props.match.params.qlink).warning != undefined && dynSubCategory.dynWizards.find(i => i.wizardId == props.match.params.qlink).warning != "" ?
                                                <p style={{
                                                    color: "red",
                                                    marginBottom: "20px"
                                                }}>{dynSubCategory.dynWizards.find(i => i.wizardId == props.match.params.qlink).warning}</p>
                                            : "" }
                                            <Fab
                                                style={{ width: "100%" }}
                                                variant="extended"
                                                size="medium"
                                                color="primary"
                                                aria-label={t("registrationwizard_anonymous.wizinfopage.startbtn")}
                                                className="add-button"
                                                onClick={() => onCloseInfo()}
                                                disabled={selectedBreed == "" || selectedBirthdate == "" || selectedGender == ""}
                                            >
                                                {t("registrationwizard_anonymous.wizinfopage.startbtn")}
                                            </Fab>
                                        </>
                                    : null}
                                </div>
                            : null }
                        </div>
                    </React.Fragment>
                : null}
                { !loading && typeId != "" && breedWizCategory != undefined ? getWizardFromBreedWizCategory(breedWizCategory).groupings.map((item, key) => 
                    <WizGroup
                        key={key}
                        group={item}
                        animalName={t("registrationwizard_anonymous.thedog")}
                        animalGender={selectedGender != undefined ? (selectedGender === "true" ? "male" : "female" ) : "none"}
                        alltraits={getWizardFromBreedWizCategory(breedWizCategory).traits}
                        currentPageNumber={key + 1}
                        totalNumberOfPages={getWizardFromBreedWizCategory(breedWizCategory).traits.length}
                        display={key === visiblePage}
                        pageUpdatedCallback={traitData => wizardPageUpdated(traitData)}
                    ></WizGroup>
                ) : null}
                {typeId != "" && breedWizCategory != undefined ?
                    <React.Fragment>
                        <div style={{ display: (getWizardFromBreedWizCategory(breedWizCategory).groupings.length == visiblePage && getWizardFromBreedWizCategory(breedWizCategory).groupings.length != 0) ? "block" : "none" }}>
                        {/* <div style={{ display: "block" }}> */}
                            <div className="certificate-shadow-card">
                                <Card classes={{ root: "certificate-card" }}>
                                    { edited ?
                                        <CardContent classes={{ root: "certificate-card-content" }}>
                                            <div>
                                                <Typography style={{ color: "white" }}>
                                                    {props.categoryId == "1" ? t("registrationwizard.wizfinishpage.discount") : ""}
                                                </Typography>
                                
                                                <img style={{ display: "block" }}
                                                    src="/assets/images/alternativ.svg"
                                                    alt=""
                                                    className="certificate-card-image"
                                                />
                                            </div>
                                            <div className="wizfinished-text-container">
                                                <Typography gutterBottom variant="h3">
                                                    {t("registrationwizard.wizfinishpage.registration.succeed")}
                                                </Typography>
                                                <Divider className="divider" />
                                                <Typography gutterBottom variant="body1">
                                                    {t("registrationwizard.wizfinishpage.completed_anonymous")}
                                                </Typography>
                                                <br />
                                                <Typography gutterBottom variant="body1">
                                                    {
                                                        t("registrationwizard.wizfinishpage.description_anonymous")
                                                    }
                                                </Typography>
                                            </div>
                                            {dogId != "" ?
                                                <div className="wizfinished-registrationform">
                                                    <Typography gutterBottom variant="h3">
                                                        {t("registrationwizard.wizfinishpage.registrationform.title")}
                                                    </Typography>
                                                    <Divider className="divider" />
                                                    <Typography gutterBottom variant="body1" dangerouslySetInnerHTML={{ __html: t("registrationwizard.wizfinishpage.registrationform.description").replaceAll("\n", "<br/>") }}>
                                                    </Typography>
                                                    <form className="registration-form">
                                                        {/* form for registration will be a required field */}
                                                        <FormGroup>
                                                            {/* email inputfield */}
                                                            <InputTextField
                                                                id="email"
                                                                value={email}
                                                                label={t("registration.owner.label.email")}
                                                                placeholder={t("registration.owner.placeholder.email")}
                                                                onChange={e => handleEmail(e.target.value)}
                                                                type="email"
                                                                required
                                                            />

                                                            {/* password inputfield. this has states to toggle the visibility of the password in a form of "inputadornment iconbutton". will be a required field */}
                                                            <FormControl required >
                                                                <InputLabel htmlFor="password" classes={{ root: 'registration-text' }}>
                                                                {t("registration.choosepasswd_short")}
                                                            </InputLabel>
                                                                <Input
                                                                    classes={{ root: 'registration-text' }}
                                                                    id="password"
                                                                    value={password}
                                                                    label={t("registration.owner.label.password_short")}
                                                                    placeholder={t("registration.owner.placeholder.password")}
                                                                    onChange={e => handlePassword(e.target.value)}
                                                                    type={showPassword ? "text" : "password"}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="Toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                            >
                                                                                {showPassword ? (
                                                                                    <Visibility />
                                                                                ) : (
                                                                                    <VisibilityOff />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </FormControl>


                                                            {/* first name inputfield. this will be a required field */}
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="firstName"
                                                                value={firstName}
                                                                label={t("registration.owner.label.firstname")}
                                                                placeholder={t("registration.owner.placeholder.firstname")}
                                                                onChange={e => handleFirstName(e.target.value)}
                                                                required
                                                            />
                                                            {/* surname inputfield. this will be a required field */}
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="surName"
                                                                value={surName}
                                                                label={t("registration.owner.label.lastname")}
                                                                placeholder={t("registration.owner.placeholder.lastname")}
                                                                onChange={e => handleSurName(e.target.value)}
                                                                required
                                                            />
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="postCode"
                                                                value={postCode}
                                                                label={t("registration.owner.label.postnumber")}
                                                                placeholder={t("registration.owner.placeholder.postnumber")}
                                                                onChange={e => handlePostCode(e.target.value)}
                                                            />
                                                            <Select
                                                                className="registration-text"
                                                                inputId="react-select-single"
                                                                styles={selectStyle}
                                                                TextFieldProps={{
                                                                    label: t("registration.owner.label.role"),
                                                                    InputLabelProps: {
                                                                        htmlFor: 'react-select-single',
                                                                        shrink: true,
                                                                    },
                                                                }}
                                                                options={roles.map(i => { return { value: i.value, label: t("registration.owner.roles." + i.label) } })}
                                                                value={{ value: role, label: t("registration.owner.placeholder.role") + ": " + t("registration.owner.roles." + role)}}
                                                                placeholder={t("registration.owner.placeholder.role")}
                                                                onChange={handleChangeSingle}
                                                            />
                                                            {role != "Hundeeier" &&
                                                                <InputTextField
                                                                    inputProps={{ style: { color: "#000000" } }}
                                                                    InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                    id="roleNumber"
                                                                    value={roleNumber}
                                                                    label={t("registration.owner.label.idnumber")}
                                                                    onChange={e => handleRoleNumber(e.target.value)}
                                                                />
                                                            }
                                                            <Divider className="divider" />
                                                            
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="dogName"
                                                                value={dogName}
                                                                label={t("registrationwizard_anonymous.userregistration.dogname")}
                                                                placeholder={""}
                                                                onChange={e => setDogName(e.target.value)}
                                                                required
                                                            />
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="dogAlias"
                                                                value={dogAlias}
                                                                label={t("registrationwizard_anonymous.userregistration.dogalias")}
                                                                placeholder={""}
                                                                onChange={e => setDogAlias(e.target.value)}
                                                                required
                                                            />
                                                            <InputTextField
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{ classes: { root: "registration-text" } }}
                                                                id="dogWeight"
                                                                value={dogWeight}
                                                                error={parseInt(dogWeight) > 150 ? true : undefined}
                                                                helperText={parseInt(dogWeight) > 150 ? t("dogregistration.helpertext.weights") : undefined}
                                                                type="number"
                                                                label={t("registrationwizard_anonymous.userregistration.dogweight")}
                                                                onChange={e => setDogWeight(e.target.value)}
                                                            />
                                                            <InputDatePicker
                                                                className="fullwidth"
                                                                inputProps={{ style: { color: "#000000" } }}
                                                                InputLabelProps={{
                                                                    // classes: { root: "registration-text" },
                                                                    shrink: true
                                                                }}
                                                                id="animalBirthdate"
                                                                label={t("registrationwizard_anonymous.userregistration.dogweightdate")}
                                                                value={dogWeightDate}
                                                                onChange={event => setDogWeightDate(event.target.value)}
                                                                required
                                                            />

                                                            <FormControl className="add-spacing">
                                                                <Typography onClick={openModal} className="hover-pointer">
                                                                    {t("registration.privacystatement.title")}
                                                                </Typography>
                                                            </FormControl>

                                                            <FormControl className="add-spacing">
                                                                <Divider />
                                                            </FormControl>

                                                            {/* checkbox for "jeg har lest og aksepterer personvernerkleringen" */}
                                                            <FormControlLabel
                                                                label={t("registration.privacystatement.info")}
                                                                labelPlacement="start"
                                                                control={
                                                                    <Checkbox
                                                                        classes={{ root: "registration-text" }}
                                                                        checked={userAgreementRead}
                                                                        onChange={event => handleCheckbox(event.target.checked)}
                                                                        name="userAgreementRead"
                                                                        value="userAgreementRead"
                                                                    />
                                                                }
                                                            />
                                                            <br />
                                                            <div className="button-container">
                                                                {/* button for "Opprett bruker og start registrering" onthign really to do here yet but making the button itself */}
                                                                <Fab
                                                                    variant="extended"
                                                                    size="medium"
                                                                    color="primary"
                                                                    aria-label="Add"
                                                                    className="create-button"
                                                                    disabled={locking}
                                                                    onClick={() => handleSubmit()}
                                                                >
                                                                    {t("registration.title")}
                                                                </Fab>
                                                            </div>

                                                        </FormGroup>
                                                    </form>
                                                </div>
                                            : null}
                                        </CardContent>
                                    :
                                        <CardContent classes={{ root: "certificate-card-content" }}>
                                            <div>
                                                <Typography style={{ color: "white" }}>
                                                    {""}
                                                </Typography>
                                
                                                <img style={{ display: "none" }} 
                                                    src="/assets/images/alternativ.svg"
                                                    alt=""
                                                    className="certificate-card-image"
                                                />
                                            </div>
                                            <div className="wizfinished-text-container">
                                                <Typography gutterBottom variant="h3">
                                                    {t("registrationwizard.wizfinishpage.registration.failed")}
                                                </Typography>
                                                <Divider className="divider" />
                                                <Typography gutterBottom variant="body1">
                                                    {t("registrationwizard.wizfinishpage.incompleted")}
                                                </Typography>
                                                <br />
                                                <Typography gutterBottom variant="body1">
                                                    {t("registrationwizard.wizfinishpage.description5")}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    }
                                </Card>
                            </div>
                        </div>
                    </React.Fragment>
                : null}

                {!infoVisible && breedWizCategory != undefined && getWizardFromBreedWizCategory(breedWizCategory).traits.length > 1 ?
                    <Stepper
                        incompleteSteps={getIncompleteSteps()}
                        gotoLastStep={lastStep}
                        activeListener={stepperChanged}
                        length={getWizardFromBreedWizCategory(breedWizCategory).groupings.length + 1}
                    />
                :
                    <p><br /></p>
                }

                <div className={"gdpr-modal"}>
                    <Modal
                        open={showModal}
                        onClose={closeModal}
                    >
                        <div className={"modal-content"}>
                            <div className={"gdpr-registration"}>
                                <IconButton className={"gdpr-modal-cross-button"} aria-label="Close" onClick={closeModal}><CloseIcon/></IconButton>
                                <GDPR />
                            </div>
                        </div>
                    </Modal>
                </div>

            </React.Fragment>
        :
            <React.Fragment>
                <NavBar />
                <Typography gutterBottom variant="h3" className="breedwiz-loading-text">
                    {t("registerfeatures.loading")}
                </Typography>
            </React.Fragment>
        }
    </div>;
}

export default AnonymousRegistrationWizard;