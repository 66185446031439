import React, {useState, useEffect} from "react";

//import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import Accordion from '@material-ui/core/Accordion'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

function ExpansionParagraph(props) {
  const [isExpanded, setIsExpanded] = useState(props.open != undefined ? props.open : false);

  const onArrowClick = () => {
    if (!props.disabled) {
      setIsExpanded(isExpanded ? false : true);
    }
    if (props.onArrowClick != undefined) {
      props.onArrowClick();
    }
  };

  useEffect(() => {
    if (props.open != undefined) {
      setIsExpanded(props.open);
    }
  }, [props.open]);

  return (
    <Accordion
      onChange={onArrowClick}
      //defaultExpanded={props.open != undefined ? props.open : false}
      expanded={isExpanded}
      disabled={props.disabled}
      style={
        props.disabled ? {
          backgroundColor: "#fff",
          textDecoration: "line-through",
          textDecorationColor: "var(--tangerine)"
        } : {
          backgroundColor: "#fff"
        }
      }
      classes={{
        root: props.shouldChangeColor
          ? "filter-expansion"
          : "filter-expansion-noBorderChange",
        expanded: "filter-expansion-expanded"
      }}
    >
      <AccordionSummary
        classes={{
          root: "expansion-summary-root",
          content: "expansion-content",
          expanded: "expansion-summary-expanded"
        }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: props.shouldChangeColor
                ? isExpanded
                  ? "#382e92"
                  : "#cbcbde"
                : "#382e92"
            }}
          />
        }
      >
        <Typography
          style={{
            color: props.shouldChangeColor
              ? isExpanded
                ? "#382e92" 
                : "#382e92"
              : "#382e92",
            fontSize: props.shouldChangeColor ? 14 : 24
          }}
          variant="subtitle1"
        >
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: "filter-expansion-details" }}
        variant="subtitle1"
        style={{justifyContent: "unset"}}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

export default ExpansionParagraph;
