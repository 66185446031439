import React, { useState } from "react";
import NavBar from "../../common/Navbar";
import { useTranslate } from 'react-polyglot';

import Select from 'react-select';

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
        border: state.isFocused ? 0 : 0
        }
    })
};

function GalleryHeader(props) {
    const t = useTranslate();
    const [selectedSorting, setSelectedSorting] = useState("NEW");
    const [selectedBreed, setSelectedBreed] = useState("99999");

    // state = {
    //     redirect: false,
    //     selectedSorting: "NEW"
    // }

    // constructor(props) {
    //     super(props);

    //     this.handleBreedChange = this.handleBreedChange.bind(this);
    // }    

    function handleBreedChange(data) {
      //this.setState({selectedBreed: data.value});
      props.breedChangeListener(data.value);
      setSelectedBreed(data.value);
    }

    function changeSorting(sorting) {
        // this.setState({ selectedSorting: sorting });
        // this.props.sortingChangeListener(sorting);
        setSelectedSorting(sorting);
        props.sortingChangeListener(sorting);
    }
    return (
        <div className="gallery-header">
            <NavBar />
            <div className={"breed-selection"}>
                <div className="gallery-select-breed">
                    <p className="breed-dropdown-text">{t("gallery.galleryheader.selectrace")}</p>
                    <Select
                        className="breed-dropdown"
                        inputId="react-select-single"
                        styles={selectStyle}
                        TextFieldProps={{
                            label: t("gallery.galleryheader.race"),
                            InputLabelProps: {
                                htmlFor: 'react-select-single',
                                shrink: true,
                            },
                        }}
                        value={ selectedBreed != "99999" ? { value: selectedBreed, label: t("breeds.list." + selectedBreed) } : { value: "99999", label: t("gallery.galleryheader.allraces") }}
                        placeholder={t("gallery.galleryheader.allraces")}
                        options={[{ value: "99999", label: t("gallery.galleryheader.allraces") }, ...props.breedsList.map(item => { return { label: t("breeds.list." + item.breedId), value: item.breedId } })]}
                        onChange={handleBreedChange}
                    />
                </div>
                <div className="gallery-select-sorting">
                    <button style={{ display: "none"}}
                        className={selectedSorting == "POP" ? "selected" : ""}
                        onClick={() => changeSorting("POP")}
                    >{t("gallery.galleryheader.popularity")}</button>
                    <button
                        className={selectedSorting == "NEW" ? "selected" : ""}
                        onClick={() => changeSorting("NEW")}
                    >{t("gallery.galleryheader.newest")}</button>
                    <button
                        className={selectedSorting == "NAME" ? "selected" : ""}
                        onClick={() => changeSorting("NAME")}
                    >{t("gallery.galleryheader.alphabetical")}</button>
                </div>
            </div>

        </div>
    );
}

export default GalleryHeader;
