import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";

//MATERIAL UI
import { Typography } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';

import Fab from "@material-ui/core/Fab";

import Auth from "../../../Auth";

import './RegistrationWiz.css';
import { useTranslate } from 'react-polyglot';

const headingContainerStyle = {
  overflow: "hidden"
}

const headingStyle = {
  float: "left",
  marginTop: "28px",
  marginLeft: "30px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  fontWeight: "bold",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.43",
  letterSpacing: "3.5px",
  textTransform: "uppercase",
  width: "100%"
}

const descriptionInfoStyle = {
  marginLeft: "30px",
  marginTop: "4px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.43",
  letterSpacing: "0.5px",
}

const infoButtonStyle = {
    float: "right",
    marginRight: "20px"
}

function RegistrationWizInfoPage(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();

  const [dogId, setDogId] = useState(window.location.search.split("?")[1].split("&").find(item => item.indexOf("dogId=") !== -1).split("=")[1]);
  const [existingRegistrations, setExistingRegistrations] = useState([]);
  const [completeRegistrations, setCompleteRegistrations] = useState([]);
  const [expanded, setExpanded] = useState();

  const [acceptedTerms, setAcceptedTerms] = useState([]);

  const [breedWiz, setBreedWiz] = useState(null);

  function getWiz(){
    let language = localStorage.getItem("language");
    _Auth.fetch("/rest/breedwiz/dynamic/" + props.breedId + (language != undefined ? ("?lang=" + language) : "")).then(response => response.json()).then(data => {
      setBreedWiz(data);
    })
    .catch(error => {
      console.error("Error:", error);
    });
  }

  useEffect(() => {
    lookForExisting(dogId, props.category);
    lookForCompleted(dogId, props.category);
  }, []);

  useEffect(() => {
    if (props.breedId != undefined) {
      getWiz();
    }
  }, [props.breedId]);

  function lookForExisting(dogId, category) {
    _Auth.fetch("/rest/breedwiz/incomplete/fordog/" + dogId)
      .then(response => response.json())
      .then(data => {
        setExistingRegistrations(data.filter(item => item.selections.length > 0));
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  function lookForCompleted(dogId, category) {
    _Auth.fetch("/rest/breedwiz/fordog/" + dogId)
      .then(response => response.json())
      .then(data => {
        setCompleteRegistrations(data.filter(item => item.selections.length > 0));
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  function isAccessible(subCategories) {
    let completedWizardIds = completeRegistrations.map(i => i.selections[0].categoryId);

    subCategories = subCategories.map(item => {
      if (item.conditionsWizardsCompleted != undefined) {
        if (item.conditionsWizardsCompleted.every(i => completedWizardIds.includes(i))) {
          return {...item, accessible: true};
        } else {
          return {...item, accessible: false};
        }
      } else {
        return {...item, accessible: true};
      }
    });
    
    return subCategories.filter(i => i.hidden != true);
  }

  function getRequiredWizards(subCategory) {
    if (subCategory.conditionsWizardsCompleted != undefined) {
      return subCategory.conditionsWizardsCompleted.filter(i => !completeRegistrations.map(item => item.selections[0].categoryId).includes(i));
    } else {
      return [];
    }
  }

  function getDynwizardNameById(id) {
    let foundWizard;

    props.category.dynSubCategories.forEach(item => {
      item.dynWizards.forEach(wizard => {
        if (wizard.wizardId == id) {
          foundWizard = wizard;
        }
      });
    });

    if (foundWizard != undefined) {
      return foundWizard.name;
    } else {
      return id;
    }
  }

  function checkResume(technicalTypeId) {
    if (existingRegistrations.length > 0) {
      let lastMatching = existingRegistrations.filter(item => item.selections[0].categoryId == technicalTypeId).pop();
      if (lastMatching != undefined) {
        return lastMatching["complete"] == false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function getLastCompleted(technicalTypeId) {
    if (completeRegistrations.length > 0) {
      let lastMatching = completeRegistrations.filter(item => item.selections[0].categoryId == technicalTypeId && item.complete == true).pop();
      if (lastMatching != undefined) {
        return moment(lastMatching.createdTs * 1000).format("DD.MM.yyyy");
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  const onNextButtonClick = (kategori) => {
    props.kategoriUpdate(kategori)
    props.nextButtonClick();
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 300)
  }

  const onContinueButtonClick = (reg, categoryId) => {
    history.push(window.location.pathname + window.location.search + "&registrationId=" + reg.id + "&categoryId=" + categoryId);
    window.scrollTo(0, 0);
  }

  function handleChangePanel(name) {
    if (expanded != name) {
      setExpanded(name);
    } else {
      setExpanded("");
    }
  }

  return(
    <div className="wizinfopage">
      <div className="wizardTitleContainer">
        <Typography variant="h2" >
          {t("wizardinfo.surveys")}
        </Typography>
        <Typography variant="h6" >
          {t("wizardinfo.description1")}<br/><br/>
          {t("wizardinfo.description2")}
        </Typography>
      </div>
      {props.category != undefined && props.category.dynSubCategories.length != 0 ?
        <React.Fragment>
          {isAccessible(props.category.dynSubCategories).map((dynSubCategory, accordionKey) => 
            <Accordion disabled={!dynSubCategory.accessible} key={accordionKey} expanded={expanded === dynSubCategory.name} onChange={() => handleChangePanel(dynSubCategory.name)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <div className="wizard-card-header" style={headingContainerStyle}>
                  <Typography style={headingStyle} variant="h6" >
                    { dynSubCategory.name } &nbsp;
                    { dynSubCategory.warning != undefined && dynSubCategory.warning != "" ?
                      <span style={{
                        color: "red",
                      }}> ({dynSubCategory.warning})</span>
                    : "" }
                  </Typography>
                  {!dynSubCategory.accessible ?
                    <Typography variant="body1" style={descriptionInfoStyle}>
                      {t("wizardinfo.requires") + ": " + getRequiredWizards(dynSubCategory).map(i => getDynwizardNameById(i)).join(", ")}
                    </Typography>
                  : null}
                </div>
                <Divider classes={{ root: "divider" }} />
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {dynSubCategory.description.split("\n").map((paragraph, textKey) => 
                    <Typography key={textKey} variant="body1" style={descriptionInfoStyle}>{
                      <span dangerouslySetInnerHTML={{__html: paragraph != undefined ? paragraph.replaceAll("\\n", "<br/>") : ""}}></span>
                      //paragraph.split("\b").map((line, lineKey) => <span key={lineKey}>{line}<br/></span>)
                    }</Typography>
                  )}
                  { dynSubCategory.introvideo ?
                    <div style={{
                      textAlign: "center",
                    }}>
                      <video
                        width="320"
                        height="200"
                        controls
                      >
                        <source src={dynSubCategory.introvideo} type="video/mp4" />
                      </video>
                    </div>
                  : null }
                  {dynSubCategory.conditions != undefined && dynSubCategory.conditions != "" ?
                    <div>
                      <hr/>
                      <FormControlLabel
                        className="checkboxcontrol"
                        value="bottom"
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e, checked) => {
                              if (checked) {
                                if (!acceptedTerms.includes(dynSubCategory.categoryId)) {
                                  setAcceptedTerms([...acceptedTerms, dynSubCategory.categoryId]);
                                } else {
                                  setAcceptedTerms(acceptedTerms.filter(i => i != dynSubCategory.categoryId));
                                }
                              } else {
                                setAcceptedTerms(acceptedTerms.filter(i => i != dynSubCategory.categoryId));
                              }
                            }}
                          />
                        }
                        label={dynSubCategory.conditionsText}
                        labelPlacement="end"
                      />
                      {dynSubCategory.conditionsLink != "" ?
                        <Typography variant="body1" style={descriptionInfoStyle}><a target="_blank" href={dynSubCategory.conditionsLink}>{dynSubCategory.conditionsLinktext}</a></Typography>
                      : null}
                      <hr/>
                    </div>
                  : null}
                  {dynSubCategory.guidefile != "" ?
                    <Typography variant="body1" style={descriptionInfoStyle}><a target="_blank" href={dynSubCategory.guidefile}>{t("wizardinfo.guidance")}</a></Typography>
                  : null}
                  <Typography variant="body1" style={descriptionInfoStyle}>{props.taskHelpText}</Typography>

                  <div className="wizardscontainer" style={{ width: "100%", padding: "0px 30px" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {dynSubCategory.conditions == undefined || dynSubCategory.conditions == "" || acceptedTerms.includes(dynSubCategory.categoryId) ? dynSubCategory.dynWizards.map((dynWizard, topkey) => 
                          <React.Fragment key={topkey}>
                            <tr>
                              <td>
                                <div className="titlearea">
                                  <h3>
                                    { dynSubCategory.dynWizards.length > 1 ? dynWizard.name : t("wizardinfo.questionnaire")} &nbsp;
                                    { dynWizard.warning != undefined && dynWizard.warning != "" ?
                                      <span style={{
                                        color: "red",
                                      }}>({dynWizard.warning})</span>
                                    : "" }
                                  </h3>
                                  {checkResume(dynWizard.wizardId) ?
                                    <Tooltip
                                      title={
                                        t("wizardinfo.tooltip.1") + " " +
                                        t("wizardinfo.tooltip.2") + 
                                        " " + new Date(Number(existingRegistrations.filter(item => item.selections[0].categoryId == dynWizard.wizardId).pop().createdTs + "000")).toISOString().split(".")[0].replace("T", " Kl: ")
                                      }
                                      placement="left"
                                      aria-label="add"
                                    >
                                      <div style={ infoButtonStyle }>
                                        <img src="/assets/images/info_icon.svg" alt="" />
                                      </div>
                                    </Tooltip>
                                  : null}
                                </div>
                                <div className="buttonarea">
                                  {checkResume(dynWizard.wizardId) ?
                                    <Fab
                                      variant="extended"
                                      size="medium"
                                      color="primary"
                                      aria-label={t("wizardinfo.continue")}
                                      className="add-button start-registration-button"
                                      onClick={() => onContinueButtonClick(existingRegistrations.filter(item => item.selections[0].categoryId == dynWizard.wizardId && item["complete"] == false).pop(), props.category.categoryId)}
                                    >
                                      {t("wizardinfo.continue")}
                                    </Fab>
                                  : null}
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    aria-label={checkResume(dynWizard.wizardId) ? t("wizardinfo.startnew") : t("wizardinfo.start")}
                                    className="add-button start-registration-button"
                                    onClick={() => onNextButtonClick(dynWizard.wizardId)}
                                  >
                                    {checkResume(dynWizard.wizardId) ? t("wizardinfo.startnew") : t("wizardinfo.start")}
                                  </Fab>
                                </div>
                                { getLastCompleted(dynWizard.wizardId) != undefined ?
                                  <p style={{
                                    marginTop: "22px",
                                    marginBottom: "0px",
                                    fontSize: "14px",
                                    marginLeft: "16px",
                                  }}>{t("wizardinfo.lastcompleted")}: { getLastCompleted(dynWizard.wizardId) }</p>
                                : null}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </React.Fragment>
      : 
        <React.Fragment>
          <Typography variant="h6" >
            {t("wizardinfo.language.nodatafound")}
          </Typography>
        </React.Fragment>
      }
      {/*props.type == 'exterior' ?
        <React.Fragment>
          <Card classes={{ root: "card-details" }} style={{ padding: "0px 0px 20px" }}>
            <CardContent classes={{ root: "card-content" }}>
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  Sammenligne
                </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
              <Typography variant="body1" style={descriptionInfoStyle}>Registrer eksteriøre egenskaper som for eksempel bittstilling og lårvinkel.</Typography>
              <br />
              <Typography variant="body1" style={descriptionInfoStyle}><a target="_blank" href="/assets/documents/sammenlikne-biotail-hjelp.pdf">Klikk her for å lese vår guide før du starter</a></Typography>
              <Typography variant="body1" style={descriptionInfoStyle}>{props.taskHelpText}</Typography>

              <div className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Start registrering"
                  className="add-button start-registration-button"
                  onClick={() => onNextButtonClick(1)}
                >
                  Start registrering
                </Fab>
              </div>
            </CardContent>
          </Card>


          <Card classes={{ root: "card-details" }} style={{ padding: "0px 0px 20px" }}>
            <CardContent classes={{ root: "card-content" }}>
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  Ta mål
              </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
              <Typography variant="body1" style={descriptionInfoStyle}>Ta frem målebåndet og ta ulike mål av hunden.</Typography>
              <br />
              <Typography variant="body1" style={descriptionInfoStyle}><a target="_blank" href="/assets/documents/tamaal-biotail-hjelp.pdf">Klikk her for å lese vår guide før du starter</a></Typography>
              <Typography variant="body1" style={descriptionInfoStyle}>{props.taskHelpText}</Typography>

              <div className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Start registrering"
                  className="add-button start-registration-button"
                  onClick={() => onNextButtonClick(16)}
                >
                  Start registrering
                </Fab>
              </div>
            </CardContent>
          </Card>


          <Card classes={{ root: "card-details" }} style={{ padding: "0px 0px 20px" }}>
            <CardContent classes={{ root: "card-content" }}>
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  Proporsjoner
              </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
              <Typography variant="body1" style={descriptionInfoStyle}>Test ut Biotail® sin egenutviklede og brukervennlige måte å beregne hundens forholdstall på. Hent ut egenskaper på en helt ny måte! </Typography>
              <Typography variant="body1" style={descriptionInfoStyle}>{props.taskHelpText}</Typography>

              <div className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Start registrering"
                  className="add-button start-registration-button"
                  onClick={() => onNextButtonClick(23)}
                >
                  Start registrering
                </Fab>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>
        :
        <React.Fragment>
          <Card classes={{ root: "card-details" }} style={{ padding: "0px 0px 20px" }}>
            <CardContent classes={{ root: "card-content" }}>
              <div className="wizard-card-header" style={headingContainerStyle}>
                <Typography style={headingStyle} variant="h6" >
                  I hverdagen
              </Typography>
              </div>
              <Divider classes={{ root: "divider" }} />
              <Typography variant="body1" style={descriptionInfoStyle}>For å kartlegge din hunds atferd i hverdagen, svarer du på 12 spørsmål om f.eks. hundens oppførsel når fremmede kommer på besøk. Spørsmålene er utviklet i samarbeid med professor i etologi Ruth C. Newberry, Fakultet for biovitenskap, NMBU. </Typography>
              <Typography variant="body1" style={descriptionInfoStyle}>{props.taskHelpText}</Typography>

              <div className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Start registrering"
                  className="add-button start-registration-button"
                  onClick={() => onNextButtonClick(2)}
                >
                  Start registrering
                </Fab>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>
      */}
    </div>
  );
}


export default RegistrationWizInfoPage;
