import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import TaskCard from "../TaskCard";
import Fab from "@material-ui/core/Fab";
import Divider from '@material-ui/core/Divider';

import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';
import TsEBMLEngine from 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';

import { useTranslate } from 'react-polyglot';

import Auth from "../../../../Auth";

function SoundvideoWizPage(props) {
    const t = useTranslate();
    const _Auth = new Auth();

    const [selection, setSelection] = useState("");
    const [soundId, setSoundId] = useState("");
    const [errors, setErrors] = useState([]);

    const audioplayer = useRef(null);

    function triggerPageUpdated(value) {
        props.pageUpdatedCallback({
            ...props.trait,
            value: { video: value, sound: soundId }
        });
    }

    const [selectedCamera, setSelectedCamera] = useState(null);
    const [availableCameras, setAvailableCameras] = useState([]);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [timeleft, setTimeleft] = useState("");
    const [uploading, setUploading] = useState("");

    const [player, setPlayer] = useState(null);

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        setSoundId(GetRandomSound());

        try {
            navigator.getUserMedia({ video: true }, function (stream) {
                console.log("video_capture", "granted");
                
                try {
                    stream.getTracks().forEach(function(track) {
                        if (track.readyState == 'live') {
                            track.stop();
                        }
                    });
                } catch(err) {
                    console.log(err);
                    setErrors([...errors, err]);
                }
    
                initCamera();
            }, function (err) {
                if (err.name === 'PermissionDismissedError') {
                    console.log('video_capture', 'prompt')
                }
                if (err.name === 'PermissionDeniedError') {
                    console.log('video_capture', 'denied')
                }
            })
        } catch(err) {
            console.log(err);
            setErrors([...errors, err]);
        }
    }, []);

    useEffect(() => {
        return () => {
          if (player && !player.isDisposed()) {
            player.dispose();
          }
        };
      }, [player]);

    useEffect(() => {
        if (player != undefined) {
            player.record().setVideoInput(selectedCamera);
        }
    }, [selectedCamera]);

    function initCamera() {
        try {
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                try {
                    setAvailableCameras(devices.filter(i => i.kind == "videoinput"));
                } catch(err) {
                    console.log(err);
                    setErrors([...errors, err]);
                }
            });

            let options = {
                controls: false,
                bigPlayButton: false,
                loop: false,
                fluid: false,
                video: {
                    deviceId: { exact: selectedCamera }
                },
                plugins: {
                    record: {
                        image: false,
                        audio: false,
                        video: true,
                        maxLength: 62,
                        displayMilliseconds: false,
                        debug: false,
                        convertEngine: 'ts-ebml',
                    }
                }
            };

            let player = videojs('myVideo', options, function() {
                try {
                    // print version information at startup
                    const msg = 'Using video.js ' + videojs.VERSION +
                        ' with videojs-record ' + videojs.getPluginVersion('record');
                    videojs.log(msg);
                
                    console.log("videojs-record is ready!");
                } catch(err) {
                    console.log(err);
                    setErrors([...errors, err]);
                }
            });

            player.on('finishConvert', function() {
                setRecordedBlob(player.convertedData);
            });

            setPlayer(player);
        } catch(err) {
            console.log(err);
            setErrors([...errors, err]);
        }
    }

    useEffect(() => {
        try {
            navigator.permissions.query({name: 'camera'}).then((permissionObj) => {
                if (permissionObj.state == 'granted') {
                    initCamera();
                }
            }).catch((err) => {
                console.log('Got error :', err);
                setErrors([...errors, err]);
            });

            if (props.trait.value != undefined) {
                try {
                    let value = JSON.parse(props.trait.value);
                    setSelection(value.video);
                    setSoundId(value.sound);
                } catch (err) {
                    console.debug("no resume value");
                    setErrors([...errors, err]);
                }
            }
        } catch(err) {
            console.log(err);
            setErrors([...errors, err]);
        }
    }, [props.trait.value]);

    function uploadVideo() {
        console.log("Uploading video: " + recordedBlob.name);
        setUploading("uploading");

        var fd = new FormData();
        fd.append('fname', recordedBlob.name);
        fd.append('video', recordedBlob);

        _Auth.fetch('/rest/videoupload/upload', {
            method: 'POST',
            body: fd,
            headers: {
                ContentType: "multipart/form-data"
            }
        }).then(res => {
            return res.text();
        }).then(data => {
            data = JSON.parse(data);
            triggerPageUpdated(data.videoId);
            setUploading("finished");
        })
    }

    function GetRandomSound() {
        let possibilities = [
            "SV1V1",
            "SV1V2",
            "SV1V3",
            "SV2V1",
            "SV2V2",
            "SV2V3",
            "SV3V1",
            "SV3V2",
            "SV3V3",
        ];
        
        let randomItem = possibilities[Math.floor(Math.random() * possibilities.length)];

        return randomItem;
    }

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                { errors.length > 0 ? <div>{errors.map(err => <div>{JSON.stringify(err)}</div>)}</div> : null }
                <TaskCard
                    taskCounter={props.trait.traitName}
                    taskHeader=""
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    style={{overflow: "visible"}}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="dropdown-container" >
                            { selection == "" || selection == undefined ?
                                null
                            :
                                <>
                                    <Divider className="divider" style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }} />
                                    <p style={{
                                        fontWeight: "bold",
                                    }}>{t("registerfeatures.soundvideo.recordingexists")}</p>
                                </>
                            }
                            <div className="audioplayer" style={{
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <audio
                                    src={'/assets/sounds/head_tilting/' + soundId + '.mp3'}
                                    type="audio/mpeg"
                                    ref={audioplayer}
                                ></audio>
                            </div>
                            <div className="cameraselectors" style={{
                                overflow: "auto",
                            }}>
                                <Divider className="divider" style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }} />
                                { availableCameras.length == 0 ?
                                    <>
                                        <p style={{marginBottom: "0px"}}>{t("registerfeatures.soundvideo.nocameras")}</p>
                                        <br/>
                                        <p style={{marginBottom: "0px"}}><em>{t("registerfeatures.soundvideo.androidonly")}</em></p>
                                    </>
                                : null }
                                { availableCameras.map((camera, index) => 
                                    <p key={index} style={{
                                        display: "block",
                                        width: "fit-content",
                                        float: "left",
                                        border: "1px solid",
                                        borderRadius: "26px",
                                        padding: "2px 10px",
                                        cursor: "pointer",
                                        marginRight: "6px",
                                        marginBottom: "6px",
                                        backgroundColor: selectedCamera == camera.deviceId ? "#e0e0e0" : "white",
                                    }} onClick={(evt) => {
                                        setSelectedCamera(camera.deviceId);
                                    }}>{t("registerfeatures.soundvideo.camera") + " " + (index + 1)}</p>
                                )}
                            </div>
                            <div className="videorecorder" style={{
                                maxHeight: "600px",
                                height: "calc(100vh - 200px)",
                                display: selectedCamera == "" || selectedCamera == undefined ? "none" : "block",
                            }}>
                                <video id="myVideo" playsInline autoPlay muted className="video-js vjs-default-skin"></video>
                            </div>
                            { selectedCamera == "" || selectedCamera == undefined ?
                                null
                            :
                                <>
                                    <div style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                        <p style={{
                                            fontWeight: "bold",
                                        }}>{timeleft}</p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                        <Fab
                                            variant="extended"
                                            size="medium"
                                            color="primary"
                                            aria-label={t("registerfeatures.soundvideo.startvideosound")}
                                            className="add-button"
                                            style={{
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                            }}
                                            onClick={() => {
                                                setRecordedBlob(null);
                                                player.record().start();
                                                if (audioplayer.current != undefined) {
                                                    audioplayer.current.addEventListener("timeupdate", function() {
                                                        let time = document.getElementById('timeleft');
                                                        let duration = parseInt( audioplayer.current.duration );
                                                        let currentTime = parseInt( audioplayer.current.currentTime );

                                                        time = duration - currentTime;

                                                        let s;
                                                        let m;
                                                        
                                                        s = time % 60;
                                                        m = Math.floor( time / 60 ) % 60;
                                                        
                                                        s = s < 10 ? "0"+s : s;
                                                        m = m < 10 ? "0"+m : m;
                                                        
                                                        setTimeleft(m+":"+s);
                                                    }, false);

                                                    audioplayer.current.currentTime = 0;
                                                    audioplayer.current.play();
                                                }
                                            }}
                                        >
                                            {t("registerfeatures.soundvideo.startvideosound")}
                                        </Fab>
                                    </div>
                                </>
                            }
                            <div className="videouploader">
                                <Divider className="divider" style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }} />
                                { recordedBlob != null ?
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                        <Fab
                                            variant="extended"
                                            size="medium"
                                            color="primary"
                                            aria-label={t("registerfeatures.soundvideo.upload")}
                                            className="add-button"
                                            disabled={recordedBlob == null || uploading == "uploading" || uploading == "finished"}
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                            onClick={() => {
                                                uploadVideo();
                                            }}
                                        >
                                            { uploading != "finished" ? t("registerfeatures.soundvideo.upload") : t("registerfeatures.soundvideo.uploadfinished")}
                                        </Fab>
                                    </div>
                                : null }
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

SoundvideoWizPage.propTypes = {
    trait: PropTypes.object,
    pageUpdatedCallback: PropTypes.func,
    display: PropTypes.bool
};

export default SoundvideoWizPage;