import React from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";

import NavBar from "../../common/Navbar";
import { useTranslate } from 'react-polyglot';

function Forsikringinfo (){
    const history = useHistory();
    const t = useTranslate();

    return(
        <React.Fragment>
            <NavBar />
            <img className="how-doggo-pic" src="/assets/images/highfive_smaller.jpg" alt="" />
            <div className="button-container" style={{ marginTop: "40px" }}>
                <Fab
                    style={{ marginRight: "20px" }}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    className="add-button"
                    onClick={() => {
                        history.push("/login");
                    }}
                >
                    {t("insurance.login")}
                </Fab>
                <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    className="add-button"
                    onClick={() => {
                        history.push("/registration");
                    }}
                >
                    {t("insurance.registeruser")}
                </Fab>
            </div>
            <div className="how" style={{ margin: "7% 12%" }}>
            <Typography gutterBottom variant="h3">
                {t("insurance.about")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
            {t("insurance.description")}
            </Typography>
            <br />
            <br />
            <Typography gutterBottom variant="h3">
            {t("insurance.reducepremium")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
            {t("insurance.insurancecompany")}
            </Typography>
            <br />
            <br />
            <Typography gutterBottom variant="h3">
                {t("insurance.who")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
                {t("insurance.instruction1")}
                <br/>
                <br/>
                {t("insurance.instruction2")}
            </Typography>
            <br />
            <br />
            <Typography gutterBottom variant="h3">
                {t("insurance.linkofBiotail.title")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
                {t("insurance.linkofBiotail.description")} <a href="https://biotail.no/howdoesitwork" target="_blank">https://biotail.no/howdoesitwork</a>
            </Typography>
            <br />
            <br />
            <Typography gutterBottom variant="h3">
                {t("insurance.linkofAninova.title")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
                {t("insurance.linkofAninova.description")} <a href="https://aninova.no/" target="_blank">https://aninova.no/</a>
            </Typography>
            <br />
            <br />
            <Typography gutterBottom variant="h3">
                {t("insurance.questions.title")}
            </Typography>
            <Divider className="divider" />
            <Typography gutterBottom variant="body1">
                {t("insurance.questions.description")} <a href="https://biotail.no/faq" target="_blank">https://biotail.no/faq</a>
            </Typography>
            </div>
        </React.Fragment>
    );
}

export default Forsikringinfo;
