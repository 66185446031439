import React, {useEffect} from "react";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {nb, sv, enGB} from "date-fns/locale";
import LocaleHandler from '../../LocaleHandler';
import { useTranslate } from 'react-polyglot';

function InputDatePicker (props) {
    const { locale, setLocale } = React.useContext(LocaleHandler);
    const t = useTranslate();

    const languages = {
        no: nb,
        se: sv,
        en: enGB
    }

    useEffect(() => {
        
    }, []) 

    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={languages[locale]}>
            <KeyboardDatePicker
                id={props.id}
                value={props.value != "" ? props.value : null}
                onChange={(data) => {
                    props.onChange({target: { 
                        value: data != null ? (data.getFullYear() + "-" + ((data.getMonth() + 1) < 10 ? "0" : "") + (data.getMonth() + 1) + "-" + (data.getDate() < 10 ? "0" : "") + data.getDate()) : ""
                    }});
                }}
                format={"yyyy.MM.dd"}
                label={props.label}
                required={props.required}
                disabled={props.disabled}
                invalidDateMessage={t("registration.date.invaliddateformat")}
            ></KeyboardDatePicker> 
        </MuiPickersUtilsProvider>
    );
}

export default InputDatePicker;