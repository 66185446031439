import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import CardTypesNumber from "../cardTypes/CardTypesNumber";
import CardTypeNumberHealth from "../cardTypes/CardTypeNumberHealth";
import CardTypeNumberEva from "../cardTypes/CardTypeNumberEva";
import CardTypeNumberJaktprove from "../cardTypes/CardTypeNumberJaktprove";
import CardTypeNumberJaktprovespor from "../cardTypes/CardTypeNumberJaktprovespor";
import CardTypesSexdistribution from "./CardTypesSexdistribution";
import CardTypeExaminationageDistribution from "./CardTypeExaminationageDistribution";
import CardTypeJaktprovePointsAge from "./CardTypeJaktprovePointsAge";
import CardTypeJaktproveSum from "./CardTypeJaktproveSum";
import CardTypeJaktproveSumYear from "./CardTypeJaktproveSumYear";
import CardTypeJaktprovePart from "./jaktproveDistributions/CardTypeJaktprovePart";
import CardTypeJaktprovesporPart from "./jaktproveDistributions/CardTypeJaktprovesporPart";
import CardTypesBirthyearDistribution from "./CardTypesBirthyearDistribution";
import CardTypeSiblingsDistribution from "./CardTypeSiblingsDistribution";
import CardTypeHDResultBirthyear from "./CardTypeHDResultBirthyear";
import CardTypeHDRegistrationsYear from "./CardTypeHDRegistrationsYear";
import CardTypeFcoefavgBirthyear from "./CardTypeFcoefavgBirthyear";
import CardTypePci6avgBirthyear from "./CardTypePci6avgBirthyear";
import CardBiotailWeightAge from "./biotailCards/CardBiotailWeightAge";
import CardTypeEbvStandHelse from "./CardTypeEbvStandHelse";
import CardTypeEbvStandJakt from "./CardTypeEbvStandJakt";
import CardTypeEbvGenTrendJakt from "./CardTypeEbvGenTrendJakt";
import CardTypeEbvGenTrendHD from "./CardTypeEbvGenTrendHD";
import CardTypeEbvRelationshipStand from "./CardTypeEbvRelationshipStand";

function Card(props) {
    let history = useHistory();
    const t = useTranslate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
    }, [props.ebvStandJaktSelected]);

    function DrawCard() {
        switch (props.type) {
            case "number":
                return(
                    <CardTypesNumber
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypesNumber>
                );
            case "sexdistribution":
                return(
                    <CardTypesSexdistribution
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypesSexdistribution>
                );
            case "examinationagedistribution":
                return(
                    <CardTypeExaminationageDistribution
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeExaminationageDistribution>
                );
            case "jaktprovepointsage":
                return(
                    <CardTypeJaktprovePointsAge
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeJaktprovePointsAge>
                );
            case "birthyeardistribution":
                return(
                    <CardTypesBirthyearDistribution
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypesBirthyearDistribution>
                );
            case "siblingsdistribution":
                return(
                    <CardTypeSiblingsDistribution
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeSiblingsDistribution>
                );
            case "hdresultbirthyear":
                return(
                    <CardTypeHDResultBirthyear
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeHDResultBirthyear>
                );
            case "hdregistrationsyear":
                return(
                    <CardTypeHDRegistrationsYear
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeHDRegistrationsYear>
                );
            case "fcoefbirthyear":
                return(
                    <CardTypeFcoefavgBirthyear
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeFcoefavgBirthyear>
                );
            case "pci6birthyear":
                return(
                    <CardTypePci6avgBirthyear
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypePci6avgBirthyear>
                );
            case "numberhealth":
                return(
                    <CardTypeNumberHealth
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeNumberHealth>
                );
            case "numbereva":
                return(
                    <CardTypeNumberEva
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeNumberEva>
                );
            case "numberjaktprove":
                return(
                    <CardTypeNumberJaktprove
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeNumberJaktprove>
                );
            case "numberjaktprovespor":
                return(
                    <CardTypeNumberJaktprovespor
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeNumberJaktprovespor>
                );
            case "jaktprovesum":
                return(
                    <CardTypeJaktproveSum
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeJaktproveSum>
                );
            case "jaktprovesumyear":
                return(
                    <CardTypeJaktproveSumYear
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                    ></CardTypeJaktproveSumYear>
                );
            case "jaktprovepart":
                return(
                    <CardTypeJaktprovePart
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardTypeJaktprovePart>
                );
            case "jaktprovesporpart":
                return(
                    <CardTypeJaktprovesporPart
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardTypeJaktprovesporPart>
                );
            case "biotailweightage":
                return(
                    <CardBiotailWeightAge
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardBiotailWeightAge>
                );
            case "ebvstandhelse":
                return(
                    <CardTypeEbvStandHelse
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardTypeEbvStandHelse>
                );
            case "ebvstandjakt":
                return(
                    <CardTypeEbvStandJakt
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                        selectValue={props.ebvStandJaktSelected}
                        selectChange={val => {
                            if (props.ebvStandJaktChange != undefined) {
                                props.ebvStandJaktChange(val);
                            }
                        }}
                    ></CardTypeEbvStandJakt>
                );
            case "ebvjaktgentrend":
                return(
                    <CardTypeEbvGenTrendJakt
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                        selectValue={props.ebvStandJaktSelected}
                        selectChange={val => {
                            if (props.ebvStandJaktChange != undefined) {
                                props.ebvStandJaktChange(val);
                            }
                        }}
                    ></CardTypeEbvGenTrendJakt>
                );
            case "ebvhdgentrend":
                return(
                    <CardTypeEbvGenTrendHD
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardTypeEbvGenTrendHD>
                );
            case "ebvrelationshipstand":
                return(
                    <CardTypeEbvRelationshipStand
                        examtype={props.examtype}
                        onChangeStart={() => setLoading(true)}
                        onChange={() => setLoading(false)}
                        partnum={props.partnum}
                    ></CardTypeEbvRelationshipStand>
                );
            default:
                return(<div></div>);
        }
    }

    return (
        <div style={{ position: "relative", marginBottom: "30px"}}>
            {loading ?
                <div className="cardloader">
                    <p>{t("dogexplorer.statistics.cards.loading")}</p>
                </div>
            : null}
            {DrawCard()}
        </div>
    );
}

export default Card;