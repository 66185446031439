import React, {useEffect, useState, useRef, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';

import InputTextField from "../../../../common/InputTextField";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Card } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from 'react-select';

import { ExplorerContext } from "../../ExplorerContext";

import fetchHelperService from "../../../../../services/fetchHelper";

import { Bar } from 'react-chartjs-2';
import moment from 'moment';

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    }
  })
};

function CardTypeEbvStandJakt(props) {
    let history = useHistory();
    const t = useTranslate();

    let explorer = useContext(ExplorerContext);

    let chartRef = useRef();

    const [activeData, setActiveData] = useState([]);

    const [data, setData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);

    const [filterMin, setFilterMin] = useState(50);
    const [filterMax, setFilterMax] = useState(150);

    const [filteringOpen, setFilteringOpen] = useState(false);

    const [ebvjaktno, setEbvjaktno] = useState("1");

    const [options, setOptions] = useState([
        { value: "1", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest01") },
        { value: "2", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest02") },
        { value: "3", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest03") },
        { value: "4", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest04") },
        { value: "5", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest05") },
        { value: "6", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest06") },
        { value: "7", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest07") },
        { value: "8", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest08") },
        { value: "9", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest09") },
        { value: "10", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest10") },
        { value: "11", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest11") },
        { value: "12", label: t("dogexplorer.statistics.cards.ebvgentrendjakt.subtest12") },
    ]);

    //const [showAll, setShowAll] = useState(false);

    function fetchData() {
        if (props.onChangeStart != undefined) props.onChangeStart();
        fetchHelperService.fetchRetry("/dataapi/api/dogs/filter/carddata/ebvstandjaktdist", 2000, 3, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                filters: explorer.filters != undefined ? explorer.filters.filter(i => i.type != "regnumber") : [],
                type: props.examtype
            }),
        }).then(response => {
            if (response.ok && response.status === 200) return response.json();
        }).then(json => {
            setData(json);
            setActiveData(json.find(i => i.key == ebvjaktno) != undefined ? json.find(i => i.key == ebvjaktno).data : []);
            setAppliedFilters(JSON.parse(JSON.stringify(explorer.filters)));
            if (props.onChange != undefined) props.onChange();
        }).catch(error => console.error("Error:", error));
    }

    useEffect(() => {
        setActiveData(data.find(i => i.key == ebvjaktno) != undefined ? data.find(i => i.key == ebvjaktno).data : []);
    }, [ebvjaktno]);

    useEffect(() => {
        setEbvjaktno(props.selectValue);
    }, [props.selectValue]);

    useEffect(() => {
        if (
            JSON.stringify(explorer.filters) != JSON.stringify(appliedFilters)
        ) {
            fetchData();
        }
    }, [explorer.filters, props.examtype]);

    return (
        <Card className="dogexplorer-card-ebvstandjakt">
            <h1>{t("dogexplorer.statistics.cards.ebvstandjakt.description")}</h1>
            <Select
                className={"registration-text"}
                inputId="react-select-single"
                styles={selectStyle}
                TextFieldProps={{
                    label: t("dogexplorer.statistics.cards.ebvstandjakt.huntingtest"),
                    InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                    },
                }}
                options={options}
                value={options.find(i => i.value == ebvjaktno)}
                placeholder={ebvjaktno}
                onChange={(data) => {
                    setEbvjaktno(data.value);
                    setFilterMin(50);
                    setFilterMax(150);
                    if (props.selectChange != undefined) {
                        props.selectChange(data.value);
                    }
                }}
            />
            <div>
                <Bar
                    ref={chartRef}
                    chartId="barchart1-1"
                    options={{
                        tooltips: {
                            intersect: false
                        },
                        scales: {
                            xAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.statistics.cards.ebvstandjakt.score")
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: t("dogexplorer.statistics.cards.ebvstandjakt.numberofdogs")
                                    }
                                }
                            ]
                        }
                    }}
                    data={{
                        labels: activeData != undefined ? activeData.slice(50, 150).map((val, key) => key + 50) : [],
                        datasets: [
                            {
                                backgroundColor: "#382e92",
                                label: t("dogexplorer.statistics.cards.ebvstandjakt.number"),
                                data: activeData != undefined ? activeData.slice(50, 150) : []
                            }
                        ]
                    }}
                    maxWidth={50}
                    maxHeight={50}
                    //redraw={true}
                ></Bar>
            </div>
            <p onClick={() => setFilteringOpen(!filteringOpen)} style={{ cursor: "pointer" }}>{t("dogexplorer.statistics.cards.ebvstandjakt.filter")} <FilterListIcon></FilterListIcon></p>
            
            <div style={ filteringOpen ? { height: "auto" } : { height: "0px", overflow: "hidden" }}>
                <InputTextField
                    inputProps={{ style: { color: "#000000" } }}
                    InputLabelProps={{ classes: { root: "registration-text" } }}
                    id="filtermin"
                    value={filterMin}
                    label={t("dogexplorer.statistics.cards.ebvstandjakt.resultover")}
                    placeholder="0"
                    onChange={(evt) => setFilterMin(evt.target.value)}
                />
                <InputTextField
                    inputProps={{ style: { color: "#000000" } }}
                    InputLabelProps={{ classes: { root: "registration-text" } }}
                    id="filtermax"
                    value={filterMax}
                    label={t("dogexplorer.statistics.cards.ebvstandjakt.resultunder")}
                    placeholder="0"
                    onChange={(evt) => setFilterMax(evt.target.value)}
                />
                <Button
                    style={{ width: "auto" }}
                    classes={{ root: "edit-button" }}
                    onClick={() => {
                        let filters = JSON.parse(JSON.stringify(explorer.filters));

                        filters = filters.filter(i => i.type != "jakt.ebvstand." + ebvjaktno);

                        if (filterMin != 50) {
                            filters.push({ type: "jakt.ebvstand." + ebvjaktno, stage: "pre", operation: "gt", value: Number(filterMin) });
                        }
                        if (filterMax != 150) {
                            filters.push({ type: "jakt.ebvstand." + ebvjaktno, stage: "pre", operation: "lt", value: Number(filterMax) });
                        }

                        explorer.updateFilters(filters);
                    }}
                >
                    {t("dogexplorer.statistics.cards.ebvstandjakt.addfilter")}
                </Button>
                {/*<div style={{width: "100%"}}>
                    <FormControlLabel
                        control={<Checkbox checked={Boolean(showAll)} onChange={(evt) => setShowAll(evt.target.checked)} />}
                        label="Vis alle resultater for hundene"
                    />
                </div>*/}
            </div>
        </Card>
    );
}

export default CardTypeEbvStandJakt;