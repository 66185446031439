import React, { useEffect, useState, useRef } from 'react';
import { Prompt } from 'react-router';
import Select from 'react-select';
import { useTranslate } from 'react-polyglot';
import { useHistory } from "react-router-dom";
import ImageEditorRc from 'react-cropper-image-editor';

import InputTextField from "../../common/InputTextField";
import InputDatePicker from "../../common/InputDatePicker";
import { Notification } from 'react-notification';
import {toast} from "react-toastify"

//Material UI components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { FormGroup, FormControl } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment';

import NavBar from "../../common/Navbar";
import Auth from "../../../Auth";
import ImageResizerService from "../../../services/ImageResizer";

import 'cropperjs/dist/cropper.css';
import "react-toastify/dist/ReactToastify.css"

function DogRegistration(){
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const cropper = useRef(null);
  const fileInput = useRef(null);

  let classes = ['dog-img'];

  toast.configure()

  window.STOMPCLIENT = null;
  window.LOCATION_URL = `${window.location.protocol}//${
    window.location.hostname
  }`;

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0
      }
    })
  };

  const notifyInfo = (text) => toast(text, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    className: "success-toast"
  })

  const notifyError = (text) => toast(text, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
  })

  const [animalName, setAnimalName] = useState("");
  const [animalAlias, setAnimalAlias] = useState("");
  const [animalPicture, setAnimalPicture] = useState("/assets/images/placeholder1.PNG");
  const [animalBirthdate, setAnimalBirthdate] = useState("");
  const [animalWeight, setAnimalWeight] = useState("");
  const [animalWeightDate, setAnimalWeightDate] = useState("");
  const [animalID, setAnimalID] = useState("");
  const [animalIdFather, setAnimalIdFather] = useState("");
  const [animalIdMother, setAnimalIdMother] = useState("");
  const [animalBreedId, setAnimalBreedId] = useState("");
  const [animalBreedName, setAnimalBreedName] = useState("");
  const [animalGender, setAnimalGender] = useState(false);
  const [notHavingBaby, setNotHavingBaby] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userAgreementRead, setUserAgreementRead] = useState(false);
  const [redirectToMyDogs, setRedirectToMyDogs] = useState(false);
  const [pictureTaken, setPictureTaken] = useState(false);
  const [postingDog, setPostingDog] = useState(false);
  const [addClass, setAddClass] = useState(false);
  const [breeds, setBreeds] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [isActive, setIsActive] = useState(false);

  function fetchBreedsData(){
    _Auth.fetch("/rest/breedwiz/breeds/")
      .then(response => response.json())
      .then(data => {
        // slett lista med raser
        var breeds = [];
        for (const item of data) {
          breeds.push({ value: item.breedId, label: item.breedId });
        }
        setBreeds(breeds);
      })
      .catch(error => console.error("Error:", error));
  }

  function handleChangeBreed(event) {
    setAnimalBreedId(event.value);
    setAnimalBreedName(event.label);
  }

  function onTakePhoto(dataUri){
    let reader = new FileReader();
    reader.onload = function () {
      let result = btoa(reader.result);
      ImageResizerService.resizeForUpload("data:application/octet-stream;base64," + result, 500, 500, 0.75).then(image => {
        ImageResizerService.getImageSize(image).then(data => {
          setAnimalPicture(image);
          setAddClass(true);
          setShowComponent(false);
          setImageHeight(data.height);
          setImageWidth(data.width);
          if (addClass) classes.push('fancy-picture');
        })
      })
    };
    
    reader.readAsBinaryString(dataUri.target.files[0]);
  }

  const onCameraButtonClick = () => {
    fileInput.current.click();
  }

  function handleSubmit(){
    let dog = [];
    dog = {
      animalId: animalID,
      animalAlias: animalAlias,
      birthDate: animalBirthdate,
      gender: animalGender.toString(),
      name: animalName.trim(),
      ownerId: localStorage.getItem("userid"),
      breedId: animalBreedId,
      weight: parseFloat(animalWeight),
      weights: [{ weight: parseFloat(animalWeight), regDate: animalWeightDate}],
      pictures: [{ imageUrl: pictureTaken ? animalPicture : "/assets/images/doggoplaceholder.svg" }],
      breedName: animalBreedName,
      notHavingBaby: notHavingBaby,
      animalIdFather: animalIdFather,
      animalIdMother: animalIdMother,
      approvedForPublicDisplay: true
    };

    if (animalBreedId == "" || animalName == "") {
      notifyInfo(t("dogregistration.notify.filloutfields"));
      return;
    }

    if (animalBirthdate == "") {
      notifyInfo(t("dogregistration.notify.missedbirthday"));
      return;
    }

    if (animalWeight == "") {
      notifyInfo(t("dogregistration.notify.missedweight"));
      return;
    }

    if (animalWeightDate == "") {
      notifyInfo(t("dogregistration.notify.dateofmeasurement"));
      return;
    }

    if (animalBirthdate.split("-")[0].length > 4) {
      notifyInfo(t("dogregistration.notify.validationofyear"));
      return;
    }

    setPostingDog(true);

    _Auth.fetch("/rest/dogs", {
      method: "POST", // or 'PUT'
      body: JSON.stringify(dog) // data can be `string` or {object}!
    })
      .then(res => {
        res.json();
      })
      .then(response => {
        setPostingDog(false);
        setRedirectToMyDogs(true);
        history.push("/mydogs");
      })
      .catch(error => {
        console.error("Error:", error);
        setPostingDog(false);

      });
  }

  function imageSave(e) {
    setShowComponent(true);
    setPictureTaken(true);
    setAddClass(true);
    setAnimalPicture(e);
    toggleNotification();

    if (addClass) classes.push('fancy-picture');
  }

  function imageClick(){
    if(!pictureTaken) onCameraButtonClick();
  }

  function toggleNotification(){
    setIsActive(isActive);
  }

  useEffect(() => {
    fetchBreedsData();

  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <Prompt
        when={hasChanged}
        message={t("dogregistration.prompt.unsaved")}
      />
      <div className="registration">
        <Notification
          isActive={isActive}
          message={t("dogregistration.prompt.profilepicture")}
          // action="Skjul"
          // title="Title!"
          dismissAfter={4000}
          onDismiss={toggleNotification.bind(this)}
          onClick={() => setIsActive(false)}
        />
        <h1 className="main-headliner">{t("dogregistration.title")}</h1>
        <form className="registration-form">
          {/* button for taking picture of dog, along with the background picture which is the dog */}
          <div className="picture-button-container">
            <div className="dogpicture-content">
              {showComponent ? (
                <img
                  className={classes.join(' ')}
                  src={animalPicture}
                  alt="ImageOfADog"
                  onClick={()=> imageClick()}
                />
              ) : (

                  <ImageEditorRc
                    ref={cropper}
                    crossOrigin='true'
                    src={animalPicture}
                    alt="ImageOfADog"
                    //style={{ height: imageHeight, width: imageWidth }}
                    guides={false}
                    rotatable={true}
                    //aspectRatio={4 / 3}
                    aspectRatio={300 / 275}
                    imageName='image.jpeg'
                    saveImage={imageSave}
                    responseType='blob/base64'
                    //viewMode={2}
                    // minContainerHeight={this.state.imageHeight}
                    // minContainerWidth={this.state.imageWidth}
                    zoomable={false}
                    movable={false}
                  />
                )}
              {/* <h1 className="centered-headliner">Velg hundens profilbilde</h1> */}
            </div>
            <div className="button-container">
              <IconButton
                className="button-placement"
                onClick={()=>onCameraButtonClick()}
              >
                <img src="/assets/images/tabildeknapp.svg" alt="" />
                <div style={{ position: "absolute" }}>
                  <img style={{ verticalAlign: "inherit" }} src="/assets/images/photo_icon.svg" alt="" />
                </div>
              </IconButton>
            </div>

          </div>

          {/* form for registration will be a required field */}
          <FormGroup className={"form-group"}>
            {/* animal name inputfield. this will be a required field */}
            <InputTextField
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{ classes: { root: "registration-text" } }}
              id="animalName"
              value={animalName}
              label={t("dogregistration.label.name")}
              placeholder={t("dogregistration.placeholder.name")}
              onChange={event => setAnimalName(event.target.value)}
              required
            />

            <InputTextField
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{ classes: { root: "registration-text" } }}
              id="animalAlias"
              value={animalAlias}
              label={t("dogregistration.label.alias")}
              placeholder={t("dogregistration.placeholder.alias")}
              onChange={event => setAnimalAlias(event.target.value)}
            />

            <Select
              className="registration-text breedselector"
              inputId="react-select-single"
              styles={selectStyle}
              TextFieldProps={{
                label: t("dogregistration.label.race"),
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
              }}
              options={breeds.map(i => { return { value: i.value, label: t("breeds.list." + i.label) } })}
              placeholder={t("dogregistration.placeholder.race")}
              onChange={event => handleChangeBreed(event)}
            />
            {/* animal sex button. one male and one female. the one chosen will get a blue background and there will afterwards be a state where we will either get a checkbox with "castrated", or "sterilized" as a checker. */}
            <FormControl label={t("dogregistration.selectgender")}>
              <p className="dog-gender-header">{t("dogregistration.selectgender")}</p>
              <div className="dog-gender-container">
                {/* female button */}
                <Button
                  id="animalGenderFemale"
                  onClick={()=>setAnimalGender(false)}
                  variant={animalGender ? "outlined" : "contained"}
                  size="medium"
                  color="primary"
                  className="female-button"
                >
                  {t("dogregistration.gender.female")}
                </Button>
                {/* male button */}
                <Button
                  id="animalGenderMale"
                  onClick={() => setAnimalGender(true)}
                  variant={animalGender ? "contained" : "outlined"}
                  size="medium"
                  color="primary"
                  className="male-button"
                >
                  {t("dogregistration.gender.male")}
                </Button>
                <FormControlLabel
                  className="fruktbar-checker"
                  control={
                    <Checkbox
                      classes={{ root: "registration-text" }}
                      checked={notHavingBaby}
                      onChange={event => setNotHavingBaby(event.target.checked)}
                      value="notHavingBaby"
                      color="primary"
                    />
                  }
                  label={animalGender ? t("dogregistration.breeding.neutered") : t("dogregistration.breeding.sterilized")}
                />
              </div>
            </FormControl>

            {/* animals birthdate inputfield. this will be selected via a calendar. should be required */}
            <InputDatePicker
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{
                // classes: { root: "registration-text" },
                shrink: true
              }}
              id="animalBirthdate"
              label={t("dogregistration.label.birthdate")}
              value={animalBirthdate}
              onChange={event => setAnimalBirthdate(event.target.value)}
              required
            />

            {/* animal weight input. this will recueve an inputadornment with "kg" just to underline for the user what we measure the weight in. we have recieved no requirement for lbs, so we will not take it into consideration as of yet this is a required field*/}
            <div className="weightRegistration">
              <InputTextField
                inputProps={{ style: { color: "#000000" } }}
                InputLabelProps={{ classes: { root: "registration-text" } }}
                id="animalWeight"
                value={animalWeight}
                label={t("dogregistration.label.weights")}
                endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                type="number"
                placeholder={t("dogregistration.placeholder.weights")}
                onChange={event => setAnimalWeight(event.target.value)}
                required
                error={parseInt(animalWeight) > 150 ? true : undefined}
                helperText={parseInt(animalWeight) > 150 ? t("dogregistration.helpertext.weights") : undefined}
              />
              <InputDatePicker
                inputProps={{ style: { color: "#000000" } }}
                InputLabelProps={{
                  // classes: { root: "registration-text" },
                  shrink: true
                }}
                id="animalWeightDate"
                label={t("dogregistration.datepicker.label.weights")}
                value={animalWeightDate}
                onChange={event => setAnimalWeightDate(event.target.value)}
                required
              />
            </div>


            {/* input field for animal ID: NOT required */}
            <InputTextField
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{ classes: { root: "registration-text" } }}
              id="animalID"
              value={animalID}
              label={t("dogregistration.label.animal.id")}
              placeholder={t("dogregistration.placeholder.animal.id")}
              onChange={event => setAnimalID(event.target.value)}
            />

            {/* input field for animal father ID: NOT required */}
            <InputTextField
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{ classes: { root: "registration-text" } }}
              id="animalIdFather"
              value={animalIdFather}
              label={t("dogregistration.label.animal.father")}
              placeholder={t("dogregistration.placeholder.animal.father")}
              onChange={event => setAnimalIdFather(event.target.value)}
              type="animalIdFather"
            />
            {/* input field for animal mother ID: NOT required. comment */}
            <InputTextField
              inputProps={{ style: { color: "#000000" } }}
              InputLabelProps={{ classes: { root: "registration-text" } }}
              id="animalIdMother"
              value={animalIdMother}
              label={t("dogregistration.label.animal.mother")}
              placeholder={t("dogregistration.placeholder.animal.mother")}
              onChange={event => setAnimalIdMother(event.target.value)}
              type="animalIdMother"
            />

            <div className="button-container">
              {/* button for "Opprett bruker og start registrering" onthign really to do here yet but making the button itself */}
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="Add"
                className="create-button"
                disabled={postingDog}
                onClick={handleSubmit}
              >
                {postingDog ? t("dogregistration.sumbit.register") : t("dogregistration.sumbit.registerdog")}

              </Fab>

            </div>
            <input onChange={onTakePhoto} ref={fileInput} style={{ display: "none" }} id="file-input" type="file" name="myImage" accept="image/*" />
          </FormGroup>
        </form>

      </div>
    </React.Fragment>
  );
}

export default DogRegistration;
