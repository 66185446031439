import React, { useState, useEffect } from 'react';

import TaskCard from "../TaskCard";
import { TextField } from '@material-ui/core';

import { useTranslate } from 'react-polyglot';

function FreetextWizPage(props) {
    const t = useTranslate();  

    const [text, setText] = useState("");

    function triggerPageUpdated(e) {
        setText(e.target.value);

        props.pageUpdatedCallback({
            ...props.trait,
            value: { text: e.target.value }
        });
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        try {
            let value = JSON.parse(props.trait.value);
            setText(value.text);
        } catch (ex) {
            console.debug("no resume value");
        }
    }, []);

    return(
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard className="feature-card"
                    taskCounter={props.trait.traitName}
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskHeader={""}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="freetext-content-wrapper" style={{ paddingLeft: "20px" }}>
                            <div className="freetext-textfield" style={{
                                width: "100%",
                                float: "left",
                                padding: "6px",
                                paddingRight: "25px"
                            }}>
                                <TextField
                                    id={props.trait.traidId}
                                    type="text"
                                    placeholder={""}
                                    value={ text }
                                    multiline
                                    rows={ 3 }
                                    onChange={(e) => triggerPageUpdated(e)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );

}

export default FreetextWizPage
