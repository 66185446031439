import React, {useState, useEffect, useReducer} from "react";
import RegisterPaymentButton from "./RegisterPaymentButton"
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ProductCard from "./ProductCard"
import NavBar from "../../common/Navbar";
import ButtonBase from '@material-ui/core/ButtonBase';
import jwt_decode from "jwt-decode"
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import MyDiscountsCard from "../settings/MyDiscountsCard";
import { useTranslate } from 'react-polyglot';

import Auth from "../../../Auth";

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  },
  title: {
    width: "100%",
    height: "32px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "700",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "20px",
    lineHeight: "20px",
    marginTop: "40px",
    padding: "0px 10px",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)",
    marginBottom: "40px",
    padding: "0px 10px",
  },
  discountCard: {
    marginLeft: "15px !important"
  },
  checkstatustext: {
    fontSize: "20px"
  }
})

const ProductPage = () => {
  let _Auth = new Auth();
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();

  const [products, setProducts] = useState([])
  const [selected, setSelected] = useState([])
  const [authorities, setAuthorities] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasProduct, setHasProduct] = useState(false)
  const [checked, setChecked] = useState(false)

  // Get all products
  useEffect(() => {
    cleanUser()
    let decodedToken = jwt_decode(localStorage.getItem("id_token"))
    setAuthorities(decodedToken.authorities)
    setLoading(false)
  },[])

  useEffect(() => {
    if(loading === false)
    getProducts()

  }, [loading === false])

  const cleanUser = () => {
    fetch("/rest/deals/cleanUser", {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`
    })
  })
  }

  const getProducts = () => {
    fetch(`/rest/product`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
      })
    })
    .then(response => {
      if(response.ok && response.status === 200){
        return response.json()
      }
    }) 
    .then(data => {
      if(data && data.length) {
        let result = data.map((el) => {
          let o = Object.assign({}, el);
          o.checked = false;
          if(authorities.includes(el.permissionTag)) {
            o.owned = true;
          }
          return o;
        })
        if(result.some(e => e.owned === true)) {
          setHasProduct(true)
          setChecked(true)
        }
        setProducts(result)
      } else {
        console.log("No discounts on user")
      }
    })
  }

  const handleClick = (e) => {
    e.preventDefault();

    const list = products.map(item => {
        return item.id === e.currentTarget.name
        ? {... item, checked: !item.checked}
        // : {... item, checked: false}
        : item
    })
    setProducts(list);
    setSelected(list.filter(item => item.checked))

  }

  const handleCheckBox = (event) => {
    setChecked(event.target.checked)
  }

  return(
    <React.Fragment>
      <NavBar />
      <Typography className={classes.title} component="h2">
        {t("purchase.productpage.title")}
      </Typography>
      <Typography className={classes.text} color="textSecondary">
        {t("purchase.productpage.description")} <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => history.push("/mysettings")}>{t("purchase.productpage.mypage")}</a>.
        <br/><br/>{ products.some(item => item.owned) ? t("purchase.productpage.already_owned_marking") : ""}
        <br/><br/>
        <a href={"/assets/documents/Kjopsbetingelser.pdf"} target="_blank" style={{ cursor: "pointer", color: "var(--blueberry)" }}>{t("purchase.productpage.termsofpurchase")}</a>
        <br></br>
          {t("purchase.productpage.accepttermofpurchase")}
          <Checkbox
          checked={checked}
          onChange={handleCheckBox}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <br></br>
        <br></br>
      </Typography>
      <div className="row productlist">
        {products.map((product, key) =>
          <div key={key} className="col col-lg-6" style={{marginBottom: "20px", height: "fit-content"}}>
            <form
              name={product.id}
              onClick={!product.owned ? handleClick : undefined}>
              <ProductCard data={product}></ProductCard>
            </form>
          </div>
        )}
      </div>
      <RegisterPaymentButton acceptDeal={checked} ownProduct={hasProduct} disabled={selected.length == 0} data={selected}/>
      {/* <div className={classes.discountCard}>
        <MyDiscountsCard></MyDiscountsCard>
      </div> */}
    </React.Fragment>
  )
}

export default ProductPage
