import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-polyglot';

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)"
  },
  title: {
    width: "260px",
    height: "28px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "8px",
    lineHeight: "20px"
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)"
  },
  buttonStyleLeft: {
    position: "relative",
    padding: "6px 27px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    color: "var(--blueberry)",
    border: "solid 2px var(--blueberry)",
    bottom: "-8px",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#dedede"
    },
  },
  buttonStyleRight: {
    float: "right",
    position: "relative",
    padding: "6px 40px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    border: "none",
    backgroundImage: "linear-gradient(263deg, var(--tealish), var(--blueberry))",
    color: "#ffffff",
    bottom: "-8px",
  }

})


const MySubscriptionsCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();

  return (
    <div className="row">
      <div className="col" >
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} component="h2">
              {t("settings.mysubscriptions.title")}
            </Typography>
            <Typography className={classes.text} color="textSecondary">
              {t("settings.mysubscriptions.description")}
            </Typography>
            <button
            variant="extended"
            size="medium"
            aria-label="Add"
            onClick={() => history.push({pathname: "/paymenthistory", state: {payments: props.paymentHistory} })}
            className={classes.buttonStyleLeft}>{t("settings.mysubscriptions.orderhistory")}</button>
            <button
            variant="extended"
            size="medium"
            aria-label="Add"
            onClick={() => history.push("/products")}
            style={{right: "0px"}}
            className={` ${classes.buttonStyleRight} ${"changepaymentbtn"}`}>{t("settings.mysubscriptions.gotoproducts")}</button>
          </CardContent>
        </Card>
      </div>
    </div>
  )

}

export default MySubscriptionsCard;
