import React, { useEffect, useRef, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router';

import { Line } from 'react-chartjs-2';
import moment from 'moment';

import { useTranslate } from "react-polyglot";

function StatisticsWeight(props) {
  const history = useHistory();
  const t = useTranslate();

  const chartReference = useRef(null);

  const [myDogWeights, setMyDogWeights] = useState([]);
  const [myDogId, setMyDogId] = useState(0);
  const [avgDogWeights, setAvgDogWeights] = useState([]);
  const [weightData, setWeightData] = useState([]);

  function getMonthDiff(date2, date1) {
    date1 = moment(date1);
    date2 = moment(date2);
    
    return date2.diff(date1, "months", true);
  }

  function GetWeightsByAge() {
    if (avgDogWeights != null) {
      return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((age) => {
        let dogsAtAge = props.weightData.filter(dog => new Date(dog["birthDate"]).getFullYear() == (new Date().getFullYear() - age + 1)).map(dog => dog["id"]);
        let traitsAtAge = avgDogWeights.filter(trait => dogsAtAge.includes(trait["dogId"]));
        let traitValuesAtAge = traitsAtAge.map(trait => trait.x);
        
        let sum = traitValuesAtAge.reduce((a, b) => a + b, 0);
        let weight = (sum / traitValuesAtAge.length) || 0;

        if (traitValuesAtAge.length == 0) {
          weight = null; // Spanning gaps where value is missing
        }

        return {
          x: age,
          y: weight
        }
      });
    } else {
      return [];
    }
  }

  useEffect(() => {
    let tempWeightData = [];
    let tempAvgDogWeights = [];
    let tempMyDogId = 0;
    let tempMyDogWeights = [];

    if (props.weightData != undefined && props.weightData != weightData) {
      let avgDogs = [];

      for (let dog of props.weightData) {
        if (dog["id"] != myDogId) {
          for (let weight of dog["weights"]) {
            if (weight["weight"] < 150) { 
              avgDogs.push({ x: getMonthDiff(weight["regDate"], dog["birthDate"]), y: weight["weight"], dogId: dog["id"]});
            }
          }
        }
      }

      tempWeightData = props.weightData;
      tempAvgDogWeights = avgDogs;

      setWeightData(tempWeightData);
      setAvgDogWeights(tempAvgDogWeights);
    }

    if (props.myDog != undefined && props.myDog["weights"] != undefined && myDogId != props.myDog["id"]) {

      tempMyDogId = props.myDog["id"];
      tempMyDogWeights = props.myDog["weights"].map(item => { return { x: getMonthDiff(item["regDate"], props.myDog["birthDate"]), y: Number(item["weight"]).toFixed(2) }});

      setWeightData(tempWeightData);
      setAvgDogWeights(tempAvgDogWeights);
      setMyDogId(tempMyDogId);
      setMyDogWeights(tempMyDogWeights);
    }

    if ((props.myDog == undefined || props.myDog["weights"] == undefined) && myDogId != 0) {
      setWeightData(tempWeightData);
      setAvgDogWeights(tempAvgDogWeights);
      setMyDogId(0);
      setMyDogWeights([]);
    }

    GetWeightsByAge();
  }, [props.weightData, props.myDog]);

  return (
    <React.Fragment>
        <div>
            <Card className={"info-card center"}>
                <card-content>
                <div className={"info-card-content"}>
                    <div className={"info-card-header"}>
                      <div className={"info-card-text-header"}>
                        {t("statistics.areas.weight.title")}
                      </div>
                    </div>
                    <div className={"info-card-body"}>
                      <Typography classes={{ root: "text phoneonly" }} style={{paddingTop: "50px", paddingLeft: "50px"}}>
                        {t("statistics.areas.weight.phone1")}<br/>
                        {t("statistics.areas.weight.phone2")}
                      </Typography>
                      <div style={{width: "100%"}}>
                          <Line
                            ref={chartReference}
                            options={{
                              spanGaps: true,
                              legend: {
                                position: "top",
                                labels: {
                                  usePointStyle: true
                                }
                              },
                              scales: {
                                xAxes: [
                                  {
                                    gridLines: {
                                      zeroLineColor: ["#EEEEEE"],
                                      color: "#EEEEEE",
                                      lineWidth: 2,
                                      zeroLineWidth: 2,
                                      drawTicks: true,
                                      tickMarkLength: 8,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      fontSize: 18,
                                      labelString: t("statistics.areas.weight.age_years")
                                    },
                                    ticks: {
                                      fontColor: "#382e92",
                                      fontSize: 14,
                                      min: 0,
                                      max: (
                                          props.myDog != undefined ?
                                            Math.round(getMonthDiff(new Date(), props.myDog != undefined ? props.myDog["birthDate"] : 220)) :
                                          228
                                        ) + 12,
                                      stepSize: 12,
                                      callback: function(value, index, values) {
                                        return Math.round(value / 12);
                                      }
                                    },
                                    type: "linear",
                                    distribution: "linear"
                                  }
                                ],
                                yAxes: [
                                  {
                                    gridLines: {
                                      zeroLineColor: ["#382e92"],
                                      lineWidth: 2,
                                      zeroLineWidth: 2,
                                      drawTicks: true,
                                      tickMarkLength: 8,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      fontSize: 18,
                                      labelString: t("statistics.areas.weight.weight_kg")
                                    },
                                    ticks: {
                                      stepSize: 5,
                                      suggestedMax: 50,
                                      min: 0,
                                      fontColor: "#382e92",
                                      fontSize: 14
                                    }
                                  }
                                ]
                              }
                            }}
                            data={{
                                datasets: [
                                {
                                    label: props.myDog != undefined ? props.myDog["name"] : t("statistics.areas.weight.no_dog_selected"),
                                    data: myDogWeights,
                                    borderColor: "rgb(255, 139, 18)",
                                    borderWidth: 2,
                                    pointRadius: 2,
                                    pointBackgroundColor: "rgb(255, 139, 18)",
                                    fill: false,
                                },
                                {
                                    label: t("statistics.areas.weight.raceregistrations"),
                                    data: avgDogWeights,
                                    borderColor: "#382e92",
                                    borderWidth: 2,
                                    pointRadius: 1,
                                    pointBackgroundColor: "#382e92",
                                    fill: false,
                                    showLine: false
                                },
                                ],
                                labels: myDogWeights.map(item => item.mnd)
                            }}
                            redraw={true}
                          ></Line>
                          <Typography classes={{ root: "text" }} style={{padding: "50px"}}>
                            {t("statistics.areas.weight.description1")}<br />
                            {t("statistics.areas.weight.description2")}
                          </Typography>
                          { myDogId == 0 ? "" :
                            <Button
                              classes={{ root: "edit-button" }}
                              onClick={() => history.push("/mydog")}
                              style={{marginTop: "-40px"}}
                              >
                              {t("statistics.areas.weight.registerweight")}
                            </Button>
                          }
                      </div>
                    </div>
                  </div>
              </card-content>
            </Card>
        </div>
    </React.Fragment>
  );
}

export default StatisticsWeight;
