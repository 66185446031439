import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import { Line } from 'react-chartjs-2';

import Card from "@material-ui/core/Card";

import Auth from "../../../../Auth";
import { useTranslate } from "react-polyglot";

function StatisticsMeasurement(props) {
  const _Auth = new Auth();
  const t = useTranslate();

  const [breedTraits, setBreedTraits] = useState(null);
  const [myDogId, setMyDogId] = useState(0);
  const [breedId, setBreedId] = useState(0);

  function getMonthDiff(date2, date1) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    var diffYears = date2.getFullYear() - date1.getFullYear();
    var diffMonths = date2.getMonth() - date1.getMonth();
    var diffDays = date2.getDate() - date1.getDate();

    var months = (diffYears * 12 + diffMonths);
    if (diffDays > 0) {
        months += '.' + diffDays;
    } else if (diffDays < 0) {
        months--;
        months += '.' + (new Date(date2.getFullYear(), date2.getMonth(), 0).getDate() + diffDays);
    }
    if (months.toString().includes(".") && months.toString().split(".")[1].length == 1) {
      months = months.toString().split(".")[0] + ".0" + months.toString().split(".")[1];
    }
    return months;
  }

  function getDataBlock(selectionId) {
    return [
      {
        label: props.myDog != undefined ? props.myDog["name"] : t("statistics.areas.measurement.no_dog_selected"),
        data: breedTraits != null && props.myDog != undefined ? breedTraits.filter(item => {
          return item["selections"].length > 0 && item["selections"][selectionId].value != undefined && JSON.parse(item["selections"][selectionId].value) != null && item["selections"][selectionId]["technicalTypeId"] == 3 && item["dogId"] == myDogId;
        }).map(item => {
          return {x: getMonthDiff(Number(item["createdTs"] + "000"), props.myDog["birthDate"]), y: JSON.parse(item["selections"][selectionId].value)["measurementValue"]};
        }) : undefined,
        borderColor: "rgb(255, 139, 18)",
        borderWidth: 2,
        pointRadius: 2,
        pointBackgroundColor: "rgb(255, 139, 18)",
        fill: false,
      },
      {
        label: t("statistics.areas.measurement.raceregistrations"),
        data: breedTraits != null && props.dogs.length > 0  ? breedTraits.filter(item => {
          return item["selections"].length > 0 && item["selections"][selectionId].value != undefined && JSON.parse(item["selections"][selectionId].value) != null && item["selections"][selectionId]["technicalTypeId"] == 3 && item["selections"][selectionId]["measurementValue"] > 0
        }).map(item => {
          let foundDog = props.dogs.find(dog => dog["id"] == item["dogId"]);
          let registertime = Number(item["createdTs"] + "000");
          if (foundDog != undefined) {
            let dogBirthdate = foundDog["birthDate"];
            return {x: getMonthDiff(registertime, dogBirthdate), y: JSON.parse(item["selections"][selectionId].value)["measurementValue"]};
          }
        }) : undefined,
        borderColor: "#382e92",
        borderWidth: 2,
        pointRadius: 1,
        pointBackgroundColor: "#382e92",
        fill: false,
        showLine: false
      },
    ];
  }

  function getTraitCardPart(titleText, selectionId) {
    return(
      <div>
        <div className={"info-card-header"}>
          <div className={"info-card-text-header"}>
            {titleText} {breedTraits != null ? <span className='titleannotation'>({t("statistics.areas.measurement.answers")} {breedTraits.filter(dog => {
              return dog["selections"].length > 0;
            }).map(dog => {
              if (dog["selections"][selectionId].value != undefined && JSON.parse(dog["selections"][selectionId].value) != null && JSON.parse(dog["selections"][selectionId].value)["measurementValue"] != null) {
                return JSON.parse(dog["selections"][selectionId].value)["measurementValue"];
              } else {
                return 0;
              }
            }).filter(item => item != 0).length})</span> : ""}
          </div>
        </div>
        <div className={"info-card-body"}>
          <div style={{ overflow: "auto" }}>
          <div style={{width: "100%"}}>
              <Line
                data={{
                  datasets: [
                    ...getDataBlock(selectionId)
                  ],
                  labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
                }}
                options={{
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          zeroLineColor: ["#EEEEEE"],
                          color: "#EEEEEE",
                          lineWidth: 2,
                          zeroLineWidth: 2,
                          drawTicks: true,
                          tickMarkLength: 8,
                        },
                        scaleLabel: {
                          display: true,
                          fontSize: 18,
                          labelString: t("statistics.areas.measurement.age_years")
                        },
                        ticks: {
                          fontColor: "#382e92",
                          fontSize: 14,
                          min: 0,
                          max: 20 * 12 + 12,
                          stepSize: 12,
                          callback: function(value, index, values) {
                            return Math.round(value / 12);
                          }
                        },
                        type: "linear",
                        distribution: "linear"
                      }
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          zeroLineColor: ["#382e92"],
                          lineWidth: 2,
                          zeroLineWidth: 2,
                          drawTicks: true,
                          tickMarkLength: 8,
                        },
                        scaleLabel: {
                          display: true,
                          fontSize: 18,
                          labelString: t("statistics.areas.measurement.length_cm")
                        },
                        ticks: {
                          fontColor: "#382e92",
                          fontSize: 14
                        }
                      }
                    ]
                  }
                }}
                redraw={true}
              ></Line>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (props.selectedBreedId != undefined && props.selectedBreedId != "") {
      _Auth.fetch("/rest/breedwiz/forbreed/" + props.selectedBreedId)
        .then(response => response.json())
        .then(data => {
          data.forEach(dog => {
            dog["selections"] = dog["selections"].filter(selection => selection["technicalTypeId"] == 3);
          });
          setBreedTraits(data);
          setBreedId(props.breedId);
          setMyDogId(props.myDogId);
        })
        .catch(error => console.error("Error:", error));
    }
  }, [props.selectedBreedId, props.breedId, props.myDogId]);

  return (
    <React.Fragment>
      <div>
        <Card className={"info-card center"}>
          <card-content>
            <div className={"info-card-content"}>
              <div className={"info-card-header"}>
                <div className={"info-card-text-header"}>
                  {t("statistics.areas.measurement.title")} {breedTraits != null ? <span className='titleannotation'>({t("statistics.areas.measurement.answers")} {breedTraits.filter(dog => {
                    return dog["selections"].length > 0;
                  }).map(dog => {
                    if (dog["selections"][0].value != undefined && JSON.parse(dog["selections"][0].value) != null && JSON.parse(dog["selections"][0].value)["measurementValue"] != null) {
                      return JSON.parse(dog["selections"][0].value)["measurementValue"];
                    } else {
                      return 0;
                    }
                  }).filter(item => item != 0).length})</span> : ""}
                </div>
              </div>
              <div className={"info-card-body"}>
                <Typography classes={{ root: "text phoneonly" }} style={{paddingTop: "50px", paddingLeft: "50px"}}>
                  {t("statistics.areas.measurement.phone1")}<br/>
                  {t("statistics.areas.measurement.phone2")}
                  <br/>
                  <br/>
                </Typography>
                <div style={{ overflow: "auto" }}>
                  <div style={{width: "100%"}}>
                    <Line
                      data={{
                        datasets: [
                          {
                            label: props.myDog != undefined ? props.myDog["name"] : t("statistics.areas.measurement.no_dog_selected"),
                            data: breedTraits != null && props.myDog != undefined ? breedTraits.filter(item => {
                              return item["selections"].length > 0 && item["selections"][0].value != undefined && JSON.parse(item["selections"][0].value) != null && item["selections"][0]["technicalTypeId"] == 3 && item["dogId"] == myDogId;
                            }).map(item => {
                              return {x: getMonthDiff(Number(item["createdTs"] + "000"), props.myDog["birthDate"]), y: JSON.parse(item["selections"][0].value)["measurementValue"]};
                            }) : undefined,
                            borderColor: "rgb(255, 139, 18)",
                            borderWidth: 2,
                            pointRadius: 2,
                            pointBackgroundColor: "rgb(255, 139, 18)",
                            fill: false,
                          },
                          {
                            label: t("statistics.areas.measurement.raceregistrations"),
                            data: breedTraits != null && props.dogs.length > 0  ? breedTraits.filter(item => {
                              return item["selections"].length > 0 && item["selections"][0].value != undefined && JSON.parse(item["selections"][0].value) != null && item["selections"][0]["technicalTypeId"] == 3 && item["selections"][0]["measurementValue"] > 0
                            }).map(item => {
                              let foundDog = props.dogs.find(dog => dog["id"] == item["dogId"]);
                              let registertime = Number(item["createdTs"] + "000");
                              if (foundDog != undefined) {
                                let dogBirthdate = foundDog["birthDate"];
                                return {x: getMonthDiff(registertime, dogBirthdate), y: JSON.parse(item["selections"][0].value)["measurementValue"]};
                              }
                            }) : undefined,
                            borderColor: "#382e92",
                            borderWidth: 2,
                            pointRadius: 1,
                            pointBackgroundColor: "#382e92",
                            fill: false,
                            showLine: false
                          },
                        ],
                        labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
                      }}
                      options={{
                        scales: {
                          xAxes: [
                            {
                              gridLines: {
                                zeroLineColor: ["#EEEEEE"],
                                color: "#EEEEEE",
                                lineWidth: 2,
                                zeroLineWidth: 2,
                                drawTicks: true,
                                tickMarkLength: 8,
                              },
                              scaleLabel: {
                                display: true,
                                fontSize: 18,
                                labelString: t("statistics.areas.measurement.age_years")
                              },
                              ticks: {
                                fontColor: "#382e92",
                                fontSize: 14,
                                min: 0,
                                max: 20 * 12 + 12,
                                stepSize: 12,
                                callback: function(value, index, values) {
                                  return Math.round(value / 12);
                                }
                              },
                              type: "linear",
                              distribution: "linear"
                            }
                          ],
                          yAxes: [
                            {
                              gridLines: {
                                zeroLineColor: ["#382e92"],
                                lineWidth: 2,
                                zeroLineWidth: 2,
                                drawTicks: true,
                                tickMarkLength: 8,
                              },
                              scaleLabel: {
                                display: true,
                                fontSize: 18,
                                labelString: t("statistics.areas.measurement.weight_kg")
                              },
                              ticks: {
                                fontColor: "#382e92",
                                fontSize: 14
                              }
                            }
                          ]
                        }
                      }}
                      redraw={true}
                    ></Line>
                  </div>
                </div>
              </div>
              <hr />
              
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part1") + " ", 1)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part2") + " ", 2)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part3") + " ", 3)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part4") + " ", 4)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part5") + " ", 5)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part6") + " ", 6)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part7") + " ", 7)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part8") + " ", 8)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part9") + " ", 9)}
              <hr />
              {getTraitCardPart(t("statistics.areas.measurement.cardparts.part10") + " ", 10)}
              <hr />
              
            </div>
          </card-content>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default StatisticsMeasurement;
