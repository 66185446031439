import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useTranslate } from 'react-polyglot';

toast.configure()

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginRight: "5%",
    borderRadius: "10px",
    boxShadow: "0 4px 35px 1px rgba(0, 0, 0, 0.2)",
    
  },
  title: {
    width: "232px",
    height: "28px",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blueberry)",
    borderBottom: "1px solid orange",
    marginBottom: "8px",
    lineHeight: "20px"
  },
  expireDate: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: "var(--slate)",
    marginBottom: "10px"
  },
  buttonStyleLeft: {
    position: "relative",
    padding: "6px 45px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    color: "var(--blueberry)",
    border: "solid 2px var(--blueberry)",
    bottom: "-8px",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#dedede"
    },
  },
  buttonStyleRight: {
    float: "right",
    ["@media (max-width: 700px)"]: {
      float:"none",
      margin: "0 auto"
    },
    position: "relative",
    padding: "6px 40px",
    textAlign: "center",
    marginTop: "12px",
    height: "40px",
    borderRadius: "35px",
    border: "none",
    backgroundImage: "linear-gradient(263deg, var(--tealish), var(--blueberry))",
    color: "#ffffff",
    bottom: "-8px",
  },
  clear: {
    clear: "both"
  }

})

const MyDiscountsCard = () => {
  const classes = useStyles();
  let history = useHistory();

  const notifyInfo = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    })
  }

  const notifySuccess = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000
    })
  }

  const [discounts, setDiscounts] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [hasDiscounts, setHasDiscounts] = useState(false)
  const t = useTranslate();

  useEffect(() => {
    getDiscountsByUser()
  }, [])

  useEffect(() => {
    if(discounts != null && discounts != undefined && discounts.length > 0) {
      setHasDiscounts(true)
    }
  }, [discounts])

  const objIsEmpty = (obj) => {
    for(let key in obj) {
      if(obj.hasOwnProperty(key))
        return false
    }
    return true;
  }

  const getDiscountsByUser = () => {
    fetch(`/rest/discounts/byuser`, {
      method:"GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        "Content-Type": "application/json"
      })
    })
    .then(response => {
      if(response.ok && response.status === 200) {
       return response.json()
      }
    })
    .then(json => {
      setDiscounts(json)
    })
  }

  const addDiscountCode = (discountCode) => {
    fetch(`/rest/discounts/attach/${discountCode}`, {
      method:"PUT",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        "Content-Type": "application/json"
      })
    })
    .then(response => {
      if(response.ok && response.status === 200)
      return response.json()
    })
    .then(json => {
      if(json && json.length) {
        getDiscountsByUser();
        window.location.reload();
        notifySuccess(t("settings.mydiscount.notify.success.added"))
      } else {
        notifyError(t("settings.mydiscount.notify.error.alreadyused"))
      }
    })
    .catch(error => notifyError(t("settings.mydiscount.notify.error.invalidcode")))
  }

  return (

    <div className="row">
      <div className="col" >
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} component="h2">
            {t("settings.mydiscount.title")}
          </Typography>
          { hasDiscounts ?
            discounts.map(function(item, i){
              return (
              <Typography key={i} className={classes.expireDate} component="p">
                {/* {`Navn: ${item.name}, Prosent: ${item.percentageOff}%, Rabattkode: ${item.code}`} */}
                {t("settings.mydiscount.name")+ item.name + "," + t("settings.mydiscount.procent") + "%," + t("settings.mydiscount.discountcode") + item.code}
              </Typography>
            )
            })
            :
            <Typography className={classes.expireDate} component="p">
              {t("settings.mydiscount.nodiscountcode")}
            </Typography>
          }
          <TextField className={classes.textField}
          id="outlined-discount"
          label={t("settings.mydiscount.discountcode.add")}
          placeholder={t("settings.mydiscount.discountcode.input")}
          value={textFieldValue}
          onChange={e => setTextFieldValue(e.target.value)}
          variant="outlined" />
          <button
          variant="extended"
          size="medium"
          aria-label="Add"
          onClick={() => addDiscountCode(textFieldValue)}
          className={classes.buttonStyleRight}>{t("settings.mydiscount.discountcode.send")}</button>
          <div className={classes.clear}></div>
        </CardContent>
      </Card>
        </div>
      </div>
    )
}

export default MyDiscountsCard;
