import React, {useEffect, useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

import Card from "../cardTypes/Card";

function DogExplorerStatisticsGeneral(props) {
    let history = useHistory();

    useEffect(() => {
        
    }, [props.data])

    return (
        <React.Fragment>
            <div className="dogexplorer-statistics-general">
                <div className="row">
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="number"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                    <div className="col col-lg-6 col-sm-12">
                        <Card
                            type="sexdistribution"
                            examtype={props.type}
                            loading={props.loading}
                        ></Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DogExplorerStatisticsGeneral;