import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { useTranslate } from 'react-polyglot';
import LocaleHandler from '../../../LocaleHandler';

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Menu from "../menu/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactSpeedometer from "react-d3-speedometer";

import NavBar from "../../common/Navbar";

import Auth from "../../../Auth";

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
        border: state.isFocused ? 0 : 0
        }
    })
};

function Status(){
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const { setLocale } = React.useContext(LocaleHandler);

  const [breeds, setBreeds] = useState([]);
  const [breedsFiltered, setBreedsFiltered] = useState([]);
  const [breedSelected, setBreedSelected] = useState(null);
  const [breedsList, setBreedsList] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [redirectHome, setRedirectHome] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  //let chartReference = {};

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     breeds: [],
  //     breedsFiltered: [],
  //     breedsList: [],
  //     loading: false,
  //     redirectHome: false,
  //   };

  //   this.logout = this.logout.bind(this);
  //   this.showMenuBtn = this.showMenuBtn.bind(this);
  //   this.closeMenu = this.closeMenu.bind(this);
  //   this.handleBreedChange = this.handleBreedChange.bind(this);
  // }

  function handleBreedChange(evt){
    let tmpBreeds = breeds.filter(breed => breed["first"]["breedId"] == evt.value);
    setBreedsFiltered(tmpBreeds);
    setBreedSelected(evt.value);
    //setState({breedsFiltered: breeds});
  }

  //function componentDidMount(){
  function fetchBreedStatus(){
    //let that = this;
    //setState({loading: true});
    setLoading(true);
    _Auth.fetch("/rest/breedwiz/breedstatus/")
      .then(response => response.json())
      .then(data => {
        setBreeds(data);
        setBreedsList(data.map(breed => { return { breedId: breed["first"]["breedId"], breedName: breed["first"]["breedName"] }}));
        setLoading(false);
        // that.setState({
        //   breeds: data,
        //   breedsList: data.map(breed => { return { breedId: breed["first"]["breedId"], breedName: breed["first"]["breedName"] }}),
        //   loading: false
        // });
      })
      .catch(error => console.error("Error:", error));
  }

  function showMenuBtn(){
    //this.setState({ showMenu: true });
    setShowMenu(true);
  }

  function closeMenu(){
    //this.setState({ showMenu: false });
    setShowMenu(false);
  }

  function logout(){
    _Auth.logout();
  }

  function handleRedirectHome(){
    //setRedirectHome(true);
    history.push("/");
  }

  useEffect(() => {
    fetchBreedStatus();

  }, []);

    // if (this.state.redirectHome) {
    //   return <Redirect to={{
    //     pathname: ("/")
    //   }} />;
    // }
  return (
    <React.Fragment>
      <div className="status">
        <div className="landingpage">
          { window.innerWidth > 600 ? (
            <div className="top-menu-container" style={ showMenu ? { 
              display: "block",
              position: "fixed",
              width: "400px",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              right: "-20px",
              transition: "right 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "400px",
              zIndex: "1000",
              top: "0px",
              right: "-500px",
              transition: "right 250ms linear"
            }}>
            <Menu
              crossBtnListener={ closeMenu }
            />
          </div>
          ) : (
            <div className="top-menu-container" style={ showMenu ? { 
              display: "block",
              position: "fixed",
              width: "100%",
              height: "auto",
              zIndex: "1000",
              top: "0px",
              left: "0px",
              transition: "left 250ms linear"
            } : {
              display: "block",
              position: "fixed",
              width: "100%",
              zIndex: "1000",
              top: "0px",
              left: "calc(-100% - 100px)",
              transition: "left 250ms linear"
            }}>
            <Menu
              crossBtnListener={ closeMenu }
            />
            </div>
          ) }
          <div className="heading">
            <div className="burger-container">
              <div className="langswitchers MuiButtonBase-root MuiButton-root MuiButton-text loginbutton">
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_no.svg"
                  alt=""
                  onClick={() => {
                    setLocale("no");
                    localStorage.setItem("language", "no");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_se.svg"
                  alt=""
                  onClick={() => {
                    setLocale("se");
                    localStorage.setItem("language", "se");
                  }}
                />
                <img
                  width="30px"
                  className="language-icon"
                  src="/assets/images/flag_gb.svg"
                  alt=""
                  onClick={() => {
                    setLocale("en");
                    localStorage.setItem("language", "en");
                  }}
                />
              </div>
              { _Auth.loggedIn() ? 
                <Button onClick={ logout } className="loginbutton">
                  {t("status.logout")}
                </Button>
              :
                <Button component={Link} to="/login" className="loginbutton">
                  {t("status.login")}
                </Button>
              }
              { _Auth.loggedIn() ? 
                ""
              :
                <Button component={Link} to="/registration" className="loginbutton">
                  {t("status.newuser")}
                </Button>
              }
              <IconButton
                onClick={ showMenuBtn }
                className="menubutton"
              >
                <img
                  className="burger"
                  src="/assets/images/burger_blue.svg"
                  alt=""
                />
              </IconButton>
            </div>
            <div className="biotail-container">
              <img
                //onClick={() => setState({ redirectHome: true })}
                onClick={handleRedirectHome}
                className="biotail-logo"
                style={{ cursor: "pointer" }}
                src="/assets/images/biotail.svg"
                alt=""
              />
            </div>
          </div>
          <div className="header" style={{ backgroundColor: "#2e3694" }}>
            <img
              className="headerImage"
              src="/assets/images/headerbilde_statistikk.jpg"
              alt=""
              style={{ marginTop: "-130px", opacity: "0.3" }}
            />
            <div className="description">
              <div className={"info-text-container"}>
                <h3>{t("status.breedstatistics")}</h3>
              </div>
            </div>
          </div>

          <div className="statistics-diclaimer">
            <div className={"info-text-container"}>
              <Typography className={"info-text"}>
              {t("status.description1")}
                <br/>
                <br/>
                {t("status.description2")}
                <br/>
                <br/>
                <span style={{fontWeight:"600"}}>{t("status.accessible")}</span>
              </Typography>
              <table>
                <tbody>
                  <tr>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}><span style={{color: "rgb(255, 0, 0)"}}>&#x25A0;</span>{t("status.report.title.red.title")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.red.description1")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.red.description2")}&emsp;</p></td>
                  </tr>
                  <tr>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}><span style={{color: "rgb(248, 143, 39)"}}>&#x25A0;</span>{t("status.report.title.orange.title")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.orange.description1")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.orange.description2")}&emsp;</p></td>
                  </tr>
                  <tr>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}><span style={{color: "rgb(242, 242, 76)"}}>&#x25A0;</span>{t("status.report.title.yellow.title")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.yellow.description1")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.yellow.description2")}&emsp;</p></td>
                  </tr>
                  <tr>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}><span style={{color: "rgb(175, 239, 111)"}}>&#x25A0;</span>{t("status.report.title.green.title")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.green.description1")}&emsp;</p></td>
                    <td><p style={{ fontWeight: "400", fontSize:"20px", color: "#382e92", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif" }}>{t("status.report.title.green.description2")}&emsp;</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Card className={"info-card center"}>
            <card-content>
              <Select
                className="breed-dropdown"
                inputId="react-select-single"
                styles={selectStyle}
                TextFieldProps={{
                    label: t("status.race.label"),
                    InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                    },
                }}
                value={breedSelected != null && breedSelected != 9999 ? { value: breedSelected, label: t("breeds.list." + breedSelected)} : ""}
                placeholder={t("status.race.placeholder")}
                options={[{ value: 9999, label: t("status.race.options") }, ...breedsList.map(item => { return { label: t("breeds.list." + item.breedId), value: item.breedId } })]}
                onChange={handleBreedChange}
                components={{ NoOptionsMessage: function() { return loading ? <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("status.race.loading")}</p> : <p style={{ textAlign:"center", fontSize:"20", fontWeight:"400" }}>{t("status.race.nothingfound")}</p>}.bind(this) }}
              />
            </card-content>
          </Card>

          <Card className={"info-card center"}>
            <card-content>
              <div className={"info-card-content"}>
                <div className={"info-card-header"}>
                  <div className={"info-card-text-header"}>
                    {t("status.statisticalstatus.title")}
                  </div>
                </div>
                <div className={"info-card-body"}>
                  { (loading ? 
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <CircularProgress />
                      <p style={{ textAlign: "center", margin: "60px" }}>{t("status.statisticalstatus.loading")}</p>
                    </div>
                  : "") }
                  <div className="breedstatuslist">
                    <List className="">
                      { breedsFiltered.length != 0 ?
                        breedsFiltered.map((breed, key) => 
                          <div key={key}>
                            <ListItem>
                              <ListItemText primary={t("breeds.list." + breed["first"]["breedId"])} />
                              <ListItemSecondaryAction>
                                <div style={{float: "right", padding: "4px"}}>
                                  <ReactSpeedometer
                                    maxValue={70}
                                    width={110}
                                    height={65}
                                    ringWidth={14}
                                    value={breed["second"][3]}
                                    customSegmentStops={[0, 10, 30, 50, 70]}
                                    needleColor="#382e92"
                                    startColor="red"
                                    segments={4}
                                    endColor="lightgreen"
                                    maxSegmentLabels={0}
                                    needleHeightRatio={0.5}
                                  />
                                  <p style={{textAlign: "center"}}>{t("status.statisticalstatus.measure")}</p>
                                </div>
                                <div style={{float: "right", padding: "4px"}}>
                                  <ReactSpeedometer
                                    maxValue={70}
                                    width={110}
                                    height={65}
                                    ringWidth={14}
                                    value={breed["second"][2]}
                                    customSegmentStops={[0, 10, 30, 50, 70]}
                                    needleColor="#382e92"
                                    startColor="red"
                                    segments={4}
                                    endColor="lightgreen"
                                    maxSegmentLabels={0}
                                    needleHeightRatio={0.5}
                                  />
                                  <p style={{textAlign: "center"}}>{t("status.statisticalstatus.behavior")}</p>
                                </div>
                                <div style={{float: "right", padding: "4px"}}>
                                  <ReactSpeedometer
                                    maxValue={70}
                                    width={110}
                                    height={65}
                                    ringWidth={14}
                                    value={breed["second"][1]}
                                    customSegmentStops={[0, 10, 30, 50, 70]}
                                    needleColor="#382e92"
                                    startColor="red"
                                    segments={4}
                                    endColor="lightgreen"
                                    maxSegmentLabels={0}
                                    needleHeightRatio={0.5}
                                  />
                                  <p style={{textAlign: "center"}}>{t("status.statisticalstatus.exterior")}</p>
                                </div>
                                <div style={{float: "right", padding: "4px"}}>
                                  <ReactSpeedometer
                                    maxValue={70}
                                    width={110}
                                    height={65}
                                    ringWidth={14}
                                    value={breed["second"][0]}
                                    customSegmentStops={[0, 10, 30, 50, 70]}
                                    needleColor="#382e92"
                                    startColor="red"
                                    segments={4}
                                    endColor="lightgreen"
                                    maxSegmentLabels={0}
                                    needleHeightRatio={0.5}
                                  />
                                  <p style={{textAlign: "center"}}>{t("status.statisticalstatus.weight")}</p>
                                </div>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </div>
                        )
                      : 
                        !loading ?
                          <Typography className={"info-text"}>
                            {t("status.statisticalstatus.selectbreed")}
                          </Typography>
                        : null
                      }
                    </List>
                  </div>
                </div>
              </div>
            </card-content>
          </Card>

          <div className={"bottom-text"}>
            <div className={"collaborators"}>
              <div className={"collaborators-title"}>
                <img
                  className="b-image2"
                  src="/assets/images/B.svg"
                  alt=""
                />
                <br/>
                <p className="ownertext">
                  {t("landingpage.survey.collaborators.owner")} <u><a style={{ color: "white" }} target="_blank" href="http://aninova.no/">Aninova</a></u>
                </p>
              </div>
              <div className={"strek2 hide-on-desktop"}></div>
            </div>
            <div className={"contact"}>
              <u><Link className={"link"} to="/howdoesitwork" >{t("landingpage.survey.collaborators.about")}</Link></u> <br />
              <u><Link className={"link"} to="/gdpr" >{t("landingpage.survey.collaborators.cookies")}</Link></u> <br />
              <div className={"strek2"}></div>
                {t("landingpage.survey.collaborators.contact")} <u><a className={"link"} href="mailto:post@aninova.no" >post@aninova.no</a></u> {t("landingpage.survey.collaborators.or")} <u><a style={{ color: "white" }} target="_blank" href="https://www.facebook.com/biotail/">Facebook</a></u>.
              <div className={"strek2"}></div>
              <div className={"developer-text"}>{t("landingpage.survey.collaborators.developedby")} <u><a className={"link"} href="https://kongarthur.no" target="_blank">Kong Arthur</a></u></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Status;
