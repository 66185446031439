import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

//MATERIAL UI
import TaskCard from "../TaskCard";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useTranslate } from 'react-polyglot';

function CheckboxesWizPage(props) {
    const t = useTranslate();

    const [selections, setSelections] = useState([]);

    function triggerPageUpdated(value = selections) {
        props.pageUpdatedCallback({
            ...props.trait,
            value: {
                options: value,
            }
        });
    }

    function handleSelects(e, checked, key, option) {
        if (!checked) {
            let temp = selections.filter(i => i != option);
            setSelections(temp);
            triggerPageUpdated(temp);
        } else {
            if (!selections.includes(option)) {
                let temp = selections;
                temp.push(option);
                setSelections(JSON.parse(JSON.stringify(temp)));
                triggerPageUpdated(JSON.parse(JSON.stringify(temp)));
            }
        }
    }

    function stringReplacer(str) {
        str = str.replace(new RegExp("(\\[dog-name\\]|%alias%)", 'g'), props.animalName);
        str = str.replace(new RegExp("(\\[han/hun\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.he") : t("registerfeatures.doggenders.she")));
        str = str.replace(new RegExp("(\\[hans/hennes\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.his") : t("registerfeatures.doggenders.hers")));
        str = str.replace(new RegExp("(\\[han/henne\\])", 'g'), (props.animalGender == "male" ? t("registerfeatures.doggenders.him") : t("registerfeatures.doggenders.her")));
        return str;
    }

    useEffect(() => {
        if (props.trait.value != undefined) {
            try {
                let value = JSON.parse(props.trait.value);
                setSelections(value.options);
            } catch (ex) {
                console.debug("no resume value");
            }
        }
    }, [props.trait.value]);

    return (
        <React.Fragment>
            <div style={{ display: props.display ? "block" : "none" }}>
                <TaskCard
                    taskCounter={props.trait.traitName}
                    taskHeader=""
                    noInfo={props.trait.traitInfoName == "" ? true : undefined}
                    taskBody={stringReplacer(props.trait.traitDescription)}
                    style={{overflow: "visible"}}
                    taskInfoHeader={stringReplacer(props.trait.traitInfoName)}
                    taskInfoBody={stringReplacer(props.trait.traitInfoDescription)}
                    children={
                        <div className="checkboxes-container" >
                            {props.trait.options.map((option, key) => 
                                <FormControlLabel
                                    className="checkboxcontrol"
                                    key={key}
                                    value="bottom"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e, checked) => handleSelects(e, checked, key, option)}
                                            checked={selections.includes(option)}
                                        />
                                    }
                                    label={stringReplacer(option)}
                                    labelPlacement="bottom"
                                />
                            )}
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
}

CheckboxesWizPage.propTypes = {
    trait: PropTypes.object,
    pageUpdatedCallback: PropTypes.func,
    display: PropTypes.bool
};

export default CheckboxesWizPage;