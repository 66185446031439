import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom"
import { Redirect } from 'react-router';
import { MyContext } from "../../context/MyContext";
import { useTranslate } from 'react-polyglot';

import Icon from "@material-ui/core/Icon";
import DogDetails from "../../common/DogDetails";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";

import RegisterPaymentButton from "../purchase/RegisterPaymentButton"
import NavBar from "../../common/Navbar";
import Auth from "../../../Auth";

const MyDogs = () => {
  let _Auth = new Auth();

  const [dogs, setDogs] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);

  const [productId, setProductId] = useState("1")
  const t = useTranslate();

  useEffect(() => {
    _Auth.fetch(`/rest/dogs/owner/${localStorage.getItem("userid")}`)
    .then(response => response.json())
    .then(data => {
      setDogs(dogs => dogs.concat(data))
      handleLoading()
    })
    .catch(error => console.log(`Error: ${error}`))
  }, [])

  const handleLoading = () => setLoading(!loading);

  const handleChange = () => {
    setRedirect({redirect: true});
  };

    if (redirect) {
      return <Redirect to={"/dogregistration"} />;
    }
    const { Consumer } = MyContext;
    if (!loading) {
      return (
        <Consumer>
          {context => (
            <React.Fragment>
              <NavBar />
              <div className="my-dogs">
                <div className="my-dogs-title">
                  <Typography variant="subtitle1">{t("mydogs.title")}</Typography>
                </div>
                {dogs.map((item, i) => {
                  return (<DogDetails
                    open={dogs.length == 1}
                    name={item.name}
                    breedId={item.breedId}
                    breedName={t("breeds.list." + item.breedId)}
                    age={new Date(item.birthDate).toISOString()}
                    gender={item.gender}
                    weight={item.weights.length > 0 ? item.weights[item.weights.length - 1]["weight"].toString() : item.weight.toString()}
                    id={item.id}
                    animalId={item.animalId}
                    key={item.id}
                  />);
                })}
                {dogs.length == 0 &&
                  <div className="text-wrapper">
                    <Typography gutterBottom variant="h3">
                      {t("mydogs.nodata")}
                    </Typography>
                    <Divider className="divider" />
                    <Typography gutterBottom variant="body1">
                      {t("mydogs.registration")}
                    </Typography>
                  </div>
                }
                <div className="button-container">
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    className="add-button"
                    onClick={() => handleChange()}
                  >
                    {t("mydogs.add")}
                  </Fab>
                </div>
              </div>
            </React.Fragment>
          )}
        </Consumer>
      );
    } else {
      return (
        <Consumer>
          {context => (
            <React.Fragment>
              <NavBar />
              <div className="my-dogs">
                <div className="my-dogs-title">
                  <Typography variant="subtitle1">{t("mydogs.title")}</Typography>
                </div>
                <Typography gutterBottom variant="h3" className="loading-text">
                  {t("mydogs.loading")}
            </Typography>
              </div>
            </React.Fragment>
          )}
        </Consumer>
      );
    }
}

export default MyDogs;
