import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router';
import { useTranslate } from 'react-polyglot';
import { useHistory } from "react-router-dom";
import { Notification } from 'react-notification';
import {toast} from "react-toastify"
import Select from 'react-select';
import { Prompt } from 'react-router';
import ImageEditorRc from 'react-cropper-image-editor';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputDatePicker from "../../common/InputDatePicker";
import TextField from "@material-ui/core/TextField";
import NavBar from "../../common/Navbar";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import CloseIcon from "@material-ui/icons/Close";

import SimpleSlider from "../../common/SimpleSlider";
import FeatureModal from "./FeatureModal";
import Auth from "../../../Auth";
import ImageResizerService from "../../../services/ImageResizer";

import "react-toastify/dist/ReactToastify.css"
import 'cropperjs/dist/cropper.css';

function MyDog(props) {
  const _Auth = new Auth();
  const history = useHistory();
  const t = useTranslate();
  const cropper = useRef(null);

  toast.configure();
  const notifyInfo = (text) => toast(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      className: "success-toast"
  });

  const ranges = [
    { value: false,  label: t("mydog.gender.ranges.female") },
    { value: true, label: t("mydog.gender.ranges.male") }
  ];

  function certificateName(item) {
    switch (item.selections[0].categoryId) {
      case 1: return 'Sammenligne'
      case 2: return 'I Hverdagen'
      case 16: return 'Ta mål'
      case 23: return 'Proporsjoner'
      case 53: return 'Atferdsregistrering Del 1'
      case 54: return 'Atferdsregistrering Del 2'
      case 55: return 'Atferdsregistrering Del 3'
      case 56: return 'Aktivitetslogg'
      case 57: return 'Early Experience Questionnaire'
      case 58: return 'Treningsdagbok'

      case 76: return 'You and your dog'
      case 71: return 'Current experience'
      case 70: return 'Early experience'

      case 77: return 'Du og din hund'
      case 73: return 'Nåværende erfaring'
      case 72: return 'Tidlig erfaring'

      case 78: return 'Du och din hund'
      case 75: return 'Nuvarande erfarenhet'
      case 74: return 'Tidig erfarenhet'

      default: return 'Navn ikke funnet'
    }
  }

  const notifyError = (text) => toast(text, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      className: "warning-toast"
  })

  const selectStyle = {
    control: (base, state) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: 0,
      "&:hover": {
        border: 0
      }
    })
  };

  const [dbId, setDBId] = useState(props.location.state != undefined ? props.location.state.dbId : 
        props.location.search.split("?")[1]
        .split("&")
        .find(item => item.indexOf("id=") != -1)
        .split("=")[1]);

  const [redirectToStatistics, setRedirectToStatistics] = useState(false);
  const [breeds, setBreeds] = useState([]);
  const [redirectDeleted, setRedirectDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editDisabled, setEditDisabled] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [galleryShow, setGalleryShow] = useState(true);
  const [weights, setWeights] = useState([]);
  const [dogRegistrations, setDogRegistrations] = useState(undefined);
  const [latestRegistrationDate, setLatestRegistrationDate] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState();
  const [showModal, setShowModal] = useState(false);
  const [photoToolOpen, setPhotoToolOpen] = useState(false);
  const [animalPicture, setAnimalPicture] = useState("");
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [savingtext, setSavingtext] = useState("");

  const [editInfoData, setEditInfoData] = useState({
    breedName: "",
    breedId: props.location.state != undefined ? props.location.state.breedId : "123",
    name: "Loading ..",
    animalAlias: "",
    age: "",
    gender: "",
    weight: "",
    weights: [],
    id: "",
    notHavingBaby: false,
    animalIdFather: "",
    animalIdMother: "",
    galleryShow: true,
    pictures: [],
  });

  const setEditName = value => { setEditInfoData(editInfoData => ({...editInfoData, name: value})); setHasChanged(true);}
  const setEditBreedName = value => { setEditInfoData(editInfoData => ({...editInfoData, breedName: value})); setHasChanged(true);}
  const setEditBreedId = value => { setEditInfoData(editInfoData => ({...editInfoData, breedId: value})); setHasChanged(true); }
  const setEditAnimalAlias = value => { setEditInfoData(editInfoData => ({...editInfoData, animalAlias: value})); setHasChanged(true);}
  const setEditNotHavingBaby = value => { setEditInfoData(editInfoData => ({...editInfoData, notHavingBaby: value})); setHasChanged(true);}
  const setEditGender = value => { setEditInfoData(editInfoData => ({...editInfoData, gender: value})); setHasChanged(true);}
  const setEditAge = value => { setEditInfoData(editInfoData => ({...editInfoData, age: value})); setHasChanged(true);}
  const setEditWeight = value => { setEditInfoData(editInfoData => ({...editInfoData, weight: value})); setHasChanged(true);}
  const setEditAnimalIdFather = value => { setEditInfoData(editInfoData => ({...editInfoData, animalIdFather: value})); setHasChanged(true);}
  const setEditAnimalIdMother = value => { setEditInfoData(editInfoData => ({...editInfoData, animalIdMother: value})); setHasChanged(true);}
  const setEditAnimalId = value => { setEditInfoData(editInfoData => ({...editInfoData, animalId: value})); setHasChanged(true);}
  const setEditPictures = value => { setEditInfoData(editInfoData => ({...editInfoData, pictures: value})); setHasChanged(true);}
  const setEditGalleryShow = value => { setEditInfoData(editInfoData => ({...editInfoData, galleryShow: value})); setHasChanged(true);}


  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function deleteDog() {
    let deleteConfirmation = window.confirm("Er du sikker på at du vil slette denne hunden?");
    if (deleteConfirmation == true) {
      _Auth.fetch("/rest/dogs/" + dbId, {
        method: "DELETE"
      })
      .then(response => {
        setRedirectDeleted(true);
        history.push("/mydogs");
      })
      .catch(error => console.error("Error:", error));
    } else {
      console.log("canceled deleting dog");
    }
  }

  function addWeightRegistration(){
    let tempWeights = weights != undefined ? weights : "";
    if(tempWeights != undefined){
      tempWeights.push({weight: "", regDate:
        new Date(Date.now())
          .toISOString()
          .split("T")[0]
          .split("-")[0] +
        "-" +
        new Date(Date.now())
          .toISOString()
          .split("T")[0]
          .split("-")[1] +
        "-" +
        new Date(Date.now())
          .toISOString()
          .split("T")[0]
          .split("-")[2]
      }); 
      setWeights(JSON.parse(JSON.stringify(tempWeights)));
    }
  }

  function editButtonClick(){
    if (editDisabled == false) {
      if (editInfoData["breedId"] == "" || editInfoData["name"] == "") {
        notifyInfo(t("mydog.notify.missedfields"));
        return;
      }

      if (editInfoData["age"].split("-")[0].length > 4) {
        notifyInfo(t("mydog.notify.invalidbirthday"));
        return;
      }
      setSavingtext(t("mydog.saving"));

      _Auth.fetch("/rest/dogs/" + dbId, {
        method: "PUT",
        body: JSON.stringify({
          id: dbId,
          animalId: editInfoData["animalId"],
          birthDate: editInfoData["age"],
          gender: editInfoData["gender"],
          name: editInfoData["name"].trim(),
          animalAlias: editInfoData["animalAlias"] != undefined && editInfoData["animalAlias"] != null ? editInfoData["animalAlias"].trim() : "",
          ownerId: localStorage.getItem("userid"),
          breedId: editInfoData["breedId"],
          breedName: editInfoData["breedName"],
          weight: parseFloat(editInfoData["weight"]),
          weights: weights,
          pictures: editInfoData["pictures"],
          notHavingBaby: editInfoData["notHavingBaby"],
          animalIdFather: editInfoData["animalIdFather"],
          animalIdMother: editInfoData["animalIdMother"],
          approvedForPublicDisplay: galleryShow
        })
      })
        .then(res => {res.json()})
        .then(response => {
          setSavingtext(t("mydog.saved"));
          setHasChanged(false);
          getInfo();
        })
        .catch(error => console.error("Error:", error));
    }
    /*setState({
      editDisabled: !editDisabled
    });*/
  };

  function registerFeaturesClick(){
    if (hasChanged) {
      let approved = window.confirm("Du har ulagrede endringer! Er du sikker på at du vil forlate siden?");
      if (approved) {
        history.push("/dogfeatureregistration/exterior?dogId=" + dbId + "&breedId=" + editInfoData["breedId"]);
      }
    } else {
      history.push("/dogfeatureregistration/exterior?dogId=" + dbId + "&breedId=" + editInfoData["breedId"]);
    }
  };

  function fetchBreedsData(){
    _Auth.fetch("/rest/breedwiz/breeds/")
      .then(response => response.json())
      .then(data => {
        // slett lista med raser
        let breeds = [];
        for (const item of data) {
          breeds.push({ value: item.breedId, label: item.breedId });
        }
        setBreeds(breeds);
      })
      .catch(error => console.error("Error:", error));
  }

  function handleChangeBreed(event) {
    setEditBreedId(event.value);
    setEditBreedName(event.label);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getInfo() {
    _Auth.fetch("/rest/dogs/id/" + dbId)
      .then(response => response.json())
      .then(data => {
        setDBId(data.id);
        setLoading(false);
        setEditInfoData(data);
        setGalleryShow(data.approvedForPublicDisplay);
        setEditAnimalAlias(data.animalAlias != undefined && data.animalAlias != null ? data.animalAlias : "");
        setEditWeight(data.weight.toString());
        setEditGender(data.gender === 'true');
        setEditAge(data.birthDate != undefined && data.birthDate != 0
          ? new Date(data.birthDate)
            .toISOString()
            .split("T")[0]
            .split("-")[0] +
          "-" +
          new Date(data.birthDate)
            .toISOString()
            .split("T")[0]
            .split("-")[1] +
          "-" +
          new Date(data.birthDate)
            .toISOString()
            .split("T")[0]
            .split("-")[2]
          : "N/A"
        );
        setWeights(data.weights.map(weightItem => {
          return {
            id: weightItem["id"],
            createdTs: weightItem["createdTs"],
            weight: weightItem["weight"],
            regDate:
              weightItem["regDate"] != undefined && weightItem["regDate"] != 0
              ? new Date(weightItem["regDate"])
                .toISOString()
                .split("T")[0]
                .split("-")[0] +
              "-" +
              new Date(weightItem["regDate"])
                .toISOString()
                .split("T")[0]
                .split("-")[1] +
              "-" +
              new Date(weightItem["regDate"])
                .toISOString()
                .split("T")[0]
                .split("-")[2]
              : "N/A"
          };
        }));
      })
      .catch(error => console.error("Error:", error));
  }

  function fetchRegistrationData(){
    _Auth.fetch("/rest/breedwiz/fordog/" + dbId)
      .then(response => response.json())
      .then(data => {
        setDogRegistrations(data.map(item => {
          item.createdDate = new Date(parseInt(item.createdTs + "000"));
          return item;
        }));
        setLatestRegistrationDate(data[data.length - 1] != undefined ? new Date(parseInt(data[data.length - 1].createdTs + "000")) : "");
      })
      .catch(error => console.error("Error:", error));
  }

  function setImageLast(index, isChanged){
    let tmpPictures = editInfoData["pictures"];
    let chosenPicture = tmpPictures[index];
    tmpPictures.splice(index, 1);
    tmpPictures.unshift(chosenPicture);

    setEditPictures(tmpPictures);
    setHasChanged(true);

    if (isChanged) {
      toggleNotification();
    }
  }

  function photoTakenListener(photo) {
    resizedataURL(photo)
  }

  function resizedataURL(image) {
    let tmpPictures = editInfoData["pictures"];
    tmpPictures = tmpPictures.filter((item) => { return item.imageUrl.indexOf("placeholder") == -1 });

    ImageResizerService.resizeForUpload(image, 500, 500, 0.75).then(imageURI => {
      ImageResizerService.getImageSize(imageURI).then(data => {
        setPhotoToolOpen(true);
        setAnimalPicture(imageURI);
        setImageHeight(data.height);
        setImageWidth(data.width);

        document.getElementsByClassName("image-edit-container")[0].style.marginLeft = String("calc(50% - " + ((data.width / 2) + 10) + "px)");
      });
    });
  }

  function imageSave(e) {
    let pictures = editInfoData["pictures"];
    pictures = pictures.filter((item) => { return item.imageUrl.indexOf("placeholder") == -1 });

    pictures.unshift({ imageUrl: e });
    setEditPictures(pictures);
    setHasChanged(true);
    if (editDisabled) {
      setEditDisabled(false);
    }

    setPhotoToolOpen(false);
    toggleNotification();
  }

  function photoRemovedListener(index) {
    let pictures = editInfoData["pictures"];
    pictures.splice(index, 1);
    setEditPictures(pictures);
    setHasChanged(true);
  }

  function closeModal(){
    setShowModal(false);
  };

  function openModal(item){
    setShowModal(true);
    setSelectedRegistration(item);
  };

  function toggleNotification() {
    setIsActive(!isActive);
    setTimeout(()=> {setIsActive(false); }, 5000);
  }

  function getMarginLeft(){
    let width = document.width;
    return width / 2 - imageWidth;
  }

  function handleRedirectToStatistics(){
    setRedirectToStatistics(true);
    history.push("/statistics/weight");
    if (redirectToStatistics) {
      return <Redirect to={{
        pathname: ("/statistics/weight"),
        state: {
          dbId: dbId,
          breedId: editInfoData["breedId"]
        }
      }} />;
    }
  }

  function handleOnChangeWeights(key, value){
    let inputText = value.replace(",", ".");
    if (!isNaN(inputText) || inputText.charAt(inputText.length - 1) == ".") 
    {
      let tempWeights = weights;
      tempWeights[key]["weight"] = inputText;
      setWeights(JSON.parse(JSON.stringify(tempWeights)));
    }
  }

  function handleOnChangeRegistrationDate(key, value){
    let tempWeights = weights;
    tempWeights[key]["regDate"] = value;
    setWeights(JSON.parse(JSON.stringify(tempWeights)));
  }

  useEffect(() => {
    if (loading) {
        setLoading(false);
        getInfo();
        fetchBreedsData();
        fetchRegistrationData();
    }
  }, []);

  return (
    <React.Fragment>
      { !loading ?
        <React.Fragment>
          <Prompt
            when={hasChanged}
            message={t("mydog.unsaved")}
          />
          <NavBar />
          <div className="my-dog">
            <Notification
              isActive={isActive}
              message={t("mydog.profilepicture")}
              //action="Skjul"
              // title="Title!"
              dismissAfter={4000}
              onDismiss={toggleNotification}
              onClick={() => setIsActive(false)}
            />
            <div className="my-dog-title">
              <Typography variant="subtitle1">{t("mydog.title")}</Typography>
            </div>
            <Card className="my-dog-card">
              <CardContent>
                <div className='dog-features-form' >
                  {!photoToolOpen ? (
                    <SimpleSlider
                      sliderArray={editInfoData["pictures"] != undefined ? editInfoData["pictures"].map((item, index) => {
                        if (item != null)
                          return {
                            id: index.toString(),
                            value: index.toString(),
                            sliderPicture: item.imageUrl,
                            description: "Profilbilde" + index
                          };
                      }) : ""}
                      addButton={true}
                      removeButton={!editDisabled}
                      selectorButton={!editDisabled}
                      selectorButtonSelected={t("mydog.selectedpicture")}
                      selectorButtonDeselected={t("mydog.unselectedpicture")}
                      shouldMoveToFirst={true}
                      selectorButtonCallback={(index, isChanged) => setImageLast(index, isChanged)}
                      onPhotoTaken={(photo) => photoTakenListener(photo)}
                      onPhotoRemoved={(index) => photoRemovedListener(index)}
                    />
                  ) : (
                      <div className={"image-edit-container"}>
                        <ImageEditorRc
                          className={"image-edit"}
                          ref={cropper}
                          crossOrigin='true'
                          src={animalPicture}
                          alt="ImageOfADog"
                          style={{ height: imageHeight, width: imageWidth }}
                          guides={false}
                          rotatable={true}
                          aspectRatio={300 / 275}
                          imageName='image.jpeg'
                          saveImage={imageSave}
                          responseType='blob/base64'
                          viewMode={3}
                          zoomable={false}
                          movable={false}
                        />

                      </div>
                    )}
                </div>
                <div className="name-container">
                  <Typography variant="h6">{t("breeds.list." + editInfoData["breedId"])}</Typography>
                </div>
                <div className="savebtn">
                  <Button
                    classes={{ root: "edit-button" }}
                    onClick={editButtonClick}
                  >
                    {editDisabled ? t("mydog.edit") : t("mydog.save")}
                  </Button>
                </div>
                <div style={{ width: "80px", float: "right"}}>
                  <p>{savingtext}</p>
                </div>

                <div className="edit-text-fields">
                  <TextField
                    id="standard-name"
                    label={t("mydog.name")}
                    //className={classes.textField}
                    value={editInfoData["name"] != undefined ? editInfoData["name"] : ""}
                    onChange={event => setEditName(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />
                  <TextField
                    id="standard-animalAlias"
                    label={t("mydog.alias")}
                    //className={classes.textField}
                    value={editInfoData["animalAlias"] != undefined ? editInfoData["animalAlias"] : ""}
                    onChange={event => setEditAnimalAlias(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />

                  <p className="fieldlabel">{t("mydog.race")}</p>
                  <Select
                    className={editDisabled ? "registration-text dog-sex-dropdown disabled" : "registration-text dog-sex-dropdown"}
                    inputId="react-select-single"
                    styles={selectStyle}
                    label={t("mydog.race")}
                    options={breeds.map(i => { return { value: i.value, label: t("breeds.list." + i.label) } })}
                    value={{value: editInfoData["breedId"], label: t("breeds.list." + editInfoData["breedId"])}}
                    onChange={event => handleChangeBreed(event)}
                    isDisabled={editDisabled}
                  />

                  <p className="fieldlabel">{t("mydog.birthday")}</p>
                  <InputDatePicker
                    id="standard-age"
                    //label={t("mydog.birthday")}
                    //className={classes.textField}
                    value={editInfoData["age"] != undefined ? editInfoData["age"]: ""}
                    onChange={event => setEditAge(event.target.value)}
                    margin="normal"
                    disabled={editDisabled}
                    fullWidth={true}
                  />

                  <p className="fieldlabel">{t("mydog.sex")}</p>
                  <Select
                    className={editDisabled ? "registration-text dog-sex-dropdown disabled" : "registration-text dog-sex-dropdown"}
                    inputId="react-select-single"
                    styles={selectStyle}
                    TextFieldProps={{
                      //label: t("mydog.race"),
                      InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: true,
                      },
                    }}
                    options={ranges}
                    placeholder={editInfoData["gender"] == true ? t("mydog.gender.ranges.male") : t("mydog.gender.ranges.female")}
                    isDisabled={editDisabled}
                    onChange={event => setEditGender(event.value)}
                  />

                  <FormControlLabel
                    className="fruktbar-checker"
                    control={
                      <Checkbox
                        checked={editInfoData["notHavingBaby"]}
                        onChange={event => setEditNotHavingBaby(event.target.checked)}
                        //value="notHavingBaby"
                        value={editInfoData["notHavingBaby"]}
                        color="primary"
                        disabled={editDisabled}
                      />
                    }
                    label={editInfoData["gender"] == true ? t("mydog.nothavingbaby.castrated") : t("mydog.nothavingbaby.sterilized")}
                  />
                  <div className="weightRegistrations">
                    <h6>{t("mydog.weightregistration")}</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>{t("mydog.weightinkilos")}</th>
                          <th>{t("mydog.dateofweighing")}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        { weights != undefined &&  weights.length != 0 ? weights.map((weight, key) => {
                            return(
                              <tr key={key}>
                                <td>
                                  <TextField
                                    id={"standard-weight-" + key}
                                    className="dateInput"
                                    //label="Vekt"
                                    value={weight["weight"]}
                                    onChange={evt => handleOnChangeWeights(key, evt.target.value)}
                                    margin="normal"
                                    disabled={editDisabled}
                                    fullWidth={true}
                                    type="number"
                                    error={parseInt(weight["weight"]) > 150 ? true : undefined}
                                    helperText={parseInt(weight["weight"]) > 150 ? t("dogregistration.helpertext.weights") : undefined}
                                  />
                                </td>
                                <td>
                                  <InputDatePicker
                                    id={"standard-weightdate-" + key}
                                    //label="Dato for veiing"
                                    value={weight["regDate"]}
                                    onChange={evt => handleOnChangeRegistrationDate(key,evt.target.value)}
                                    disabled={editDisabled}
                                    margin="normal"
                                    fullWidth={true}
                                  />
                                </td>
                                <td>
                                {
                                  editDisabled ? "" :
                                    <Button
                                      classes={{ root: "edit-button" }}
                                      onClick={evt => {
                                        let tempWeights = weights;
                                        tempWeights.splice(key, 1);
                                        //setState({ weights: tempWeights });
                                        setWeights(tempWeights);
                                      }}
                                    >
                                      {t("mydog.delete")}
                                    </Button>
                                }
                                </td>
                              </tr>
                            )
                          }) : ""
                        }
                      </tbody>
                    </table>
                    {
                      editDisabled ? "" :
                        <Button
                          classes={{ root: "edit-button" }}
                          onClick={() => addWeightRegistration()}
                        >
                          {t("mydog.add")}
                        </Button>
                    }
                  </div>
                  <br/>
                  <div className="regnumberRegistrations">
                    <h6>{t("mydog.registration.number")}</h6>
                    <TextField
                      id="standard-id"
                      label={t("mydog.registrationnumber")}
                      //className={classes.textField}
                      value={editInfoData["animalId"]}
                      onChange={evt => setEditAnimalId(evt.target.value)}
                      //margin="normal"
                      disabled={editDisabled}
                      fullWidth={true}
                    />
                    <TextField
                      id="father-id"
                      label={t("mydog.registration.father")}
                      //className={classes.textField}
                      value={editInfoData["animalIdFather"]}
                      onChange={evt => setEditAnimalIdFather(evt.target.value)}
                      //margin="normal"
                      disabled={editDisabled}
                      fullWidth={true}
                    />

                    <TextField
                      id="mother-id"
                      label={t("mydog.registration.mother")}
                      //className={classes.textField}
                      value={editInfoData["animalIdMother"]}
                      onChange={evt => setEditAnimalIdMother(evt.target.value)}
                      //margin="normal"
                      disabled={editDisabled}
                      fullWidth={true}
                    />
                    <FormControlLabel
                      disabled={editDisabled}
                      control={
                        <Checkbox
                          checked={galleryShow}
                          color="primary"
                          value="galleryShow"
                          onChange={evt => setGalleryShow(evt.target.checked)}
                        />
                      }
                      label={t("mydog.showdog")}
                      labelPlacement="start"
                    />
                  </div>
                </div>
                <div className="edit-container">
                  <div className="deletebtn">
                    <Button
                      classes={{ root: "delete-button" }}
                      style={editDisabled ? { display: "none" } : { display: "block" }}
                      onClick={deleteDog}
                    >
                      {t("mydog.deletedog")}
                    </Button>
                  </div>
                  <div className="savebtn">
                    <Button
                      classes={{ root: "edit-button" }}
                      onClick={editButtonClick}
                    >
                      {editDisabled ? t("mydog.edit") : t("mydog.save")}
                    </Button>
                  </div>
                  <div style={{ width: "80px", float: "right"}}>
                    <p>{savingtext}</p>
                  </div>
                </div>
              </CardContent>
            </Card>
            <br />

            <div className="my-dog-card" style={{ padding: "20px" }}>
              <Typography gutterBottom variant="h3">
                {t("mydog.statistics.title")}
              </Typography>
              <Divider className="divider" />
              <Typography gutterBottom variant="body1">
                {t("mydog.statistics.description")}
              </Typography>
              <Button
                classes={{ root: "edit-button" }}
                onClick={ handleRedirectToStatistics }
                style={{width: "120px"}}
              >
                {t("mydog.statistics.gotostatistics")}
              </Button>
              <br />
              <br />
              {/* <div className="button-container">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Add"
                  className="registerexterior-button"
                  disabled={!true}
                  onClick={registerFeaturesClick}
                >
                  Registrer Eksteriørtrekk
              </Fab>
              </div> */}
            </div>

            <br/>

            {dogRegistrations != undefined && dogRegistrations.length != 0 ? (
              <div style={{ margin: "auto" }}>
                {/* <div className="button-container-newregisters">
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    className="registerexterior-button"
                    disabled={!true}
                    onClick={registerFeaturesClick}
                  >
                    Start ny registrering
                  </Fab>
                </div> */}
                <div className="earlier-registrations">
                  <div className="earlier-registrations-header">
                    <h3>{t("mydog.certificates")}</h3>
                  </div>
                  {
                    dogRegistrations.map((item, i) => {

                      return (
                        <div className="earlier-registrations-list" key={i}>
                          <div className="earlier-registrations-item">

                            {/* <img
                              src={
                                item.selections.find(selection => selection.imageUrl != null) != undefined
                                  ? item.selections.find(selection => selection.imageUrl != null).imageUrl : pictures[0].imageUrl
                              }
                              alt=""
                            />  */}

                            {item.selections.find(selection => selection.imageUrl != null) != undefined ?
                              <img
                                src={item.selections.find(selection => selection.imageUrl != null).imageUrl}
                                alt="" />
                              :
                              <div className="mini-certificate-wrapper">
                                <p className="mini-certificate-title">{certificateName(item)}</p>
                                <img
                                  className="mini-certificate-background"
                                  src="assets/images/certificate.svg"
                                  alt="" />
                                <img
                                  className="mini-certificate-image"
                                  src={editInfoData["pictures"][0] != null ? editInfoData["pictures"][0].imageUrl : ""}
                                  alt="" />
                              </div>
                            }


                            <div>
                              <p className="earlier-registrations-item-title">{
                                item.createdDate.toISOString().split("T")[0].split("-")[2] + "." +
                                item.createdDate.toISOString().split("T")[0].split("-")[1] + "." +
                                item.createdDate.toISOString().split("T")[0].split("-")[0]
                              }</p>
                              <Divider className="divider" />
                              <p className="earlier-registrations-item-title">{certificateName(item)}</p>
                              <p style={{ display: "none" }} className="earlier-registrations-item-text">{item.selections.map(function (selection) {
                                return (selection.imageUrl != null ? 1 : 0) + (selection.traitValueImageUrl != null ? 1 : 0);
                              }).reduce((a, b) => a + b, 0)} bilder</p>

                              <button
                                onClick={() => openModal(item)}
                              >
                                {t("mydog.checkregistration")}
                              </button>

                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
                <div className="my-dog-card" style={{ padding: "20px" }}>
                  <Typography gutterBottom variant="h3">
                    {t("mydog.registrations.exterior")}
                </Typography>
                  <Divider className="divider" />
                  <Typography gutterBottom variant="body1">
                    {t("mydog.registrations.nodatafound")}
                </Typography>
                  <br />
                  <br />
                  {/* <div className="button-container">
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="Add"
                      className="registerexterior-button"
                      disabled={!true}
                      onClick={registerFeaturesClick}
                    >
                      Registrer Eksteriørtrekk
                  </Fab>
                  </div> */}
                </div>
              )}

            <div className={"dog-feature-modal"}>
              <Modal
                open={showModal}
                onClose={closeModal}
              >
                <div className={"dog-feature-modal-content"}>
                  <IconButton className={"dog-feature-modal-cross-button"} aria-label="Close" onClick={closeModal}><CloseIcon /></IconButton>
                  <div className={"feature-card-modal"}>
                    <FeatureModal
                      profilePicture={ editInfoData["pictures"][0] != null ? editInfoData["pictures"][0].imageUrl : ""}
                      animalId={editInfoData["id"]}
                      animalName={editInfoData["name"]}
                      animalAlias={editInfoData["animalAlias"]}
                      animalGender={editInfoData["gender"] == true ? "male" : "female"}
                      questionnaireName={selectedRegistration != undefined ? certificateName(selectedRegistration) : ""}
                      selectedRegistration={selectedRegistration} />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div className="button-container">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="back"
              className="add-button"
              onClick={() => props.history.push("/mydogs")}
            >
                {t("mydog.goback")}
            </Fab>
          </div>
        </React.Fragment>
      :
        <React.Fragment>
          <NavBar />
          <div className="my-dog">
            <div className="my-dog-title">
              <Typography variant="subtitle1">{t("mydog.title")}</Typography>
            </div>
            <Card className="my-dog-card">
              <Typography gutterBottom variant="h3" className="loading-text">
                {t("mydog.loading")}
              </Typography>
            </Card>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
// }

export default MyDog;
